import { useLocation } from '@reach/router'
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics'
import { useEffect } from 'react'
import useLogger from './useLogger'
import { getEnv } from 'utils/env'
import isBrowser from 'utils/isBrowser'

const useAnalytics = () => {
  const location = useLocation()
  const { log } = useLogger()

  useEffect(() => {
    if (isBrowser()) {
      try {
        FirebaseAnalytics.logEvent('page_view', {
          page: location.pathname,
          source: getEnv('SOURCE_MODE'),
          stage: getEnv('STAGE'),
        })
      } catch (error) {
        log('error', 'Firebase Analytics page view', {
          error,
        })
      }
    }
  }, [])
}

export default useAnalytics
