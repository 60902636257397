import React from 'react'
import cc from 'classcat'
import NotLoggedIn from 'components/NotLoggedIn'
import Container from 'components/Container'
import EditProfile from 'screens/EditProfile'
import Notifications from 'screens/Notifications'
import Billing from 'screens/Billing'
import StudioTeachers from 'screens/StudioTeachers'
import getEntityName from 'utils/getEntityName'
import getPersonName from 'utils/getPersonName'
import MyProfilePicture from 'components/MyProfilePicture'
import Button from 'components/Button'
import StudioPictures from 'screens/StudioPictures'
import { useModalScreen } from 'components/ModalScreen'
import { navigate } from 'clients/navigation.client'
import { useAuth } from 'stores/auth.store'
import { Account_Role_Enum } from 'generated/graphql'
import { Entity, Person } from 'types'
import { ReactComponent as IconAccount } from 'assets/icons/avatar-filled.svg'
import { ReactComponent as IconNotifications } from 'assets/icons/gong-filled.svg'
import { ReactComponent as IconCreditCard } from 'assets/icons/credit-card-filled.svg'
import { ReactComponent as IconTeacher } from 'assets/icons/teacher-filled.svg'
import { ReactComponent as IconStudio } from 'assets/icons/studio-filled.svg'
import { ReactComponent as IconHeart } from 'assets/icons/heart-filled.svg'
import { ReactComponent as IconPin } from 'assets/icons/pin-filled.svg'
import { ReactComponent as IconArchive } from 'assets/icons/archive-filled.svg'
import { ReactComponent as IconHelp } from 'assets/icons/help.svg'
import ReviewsButton from 'components/ReviewsButton'
import LegalInformation from 'screens/LegalInformation'
import Locations from 'screens/Locations'
import Favorites from 'screens/Favorites'
import TeachingDocuments from 'screens/TeachingDocuments'
import { AppVersion } from '@ionic-native/app-version'
import { Deploy } from 'cordova-plugin-ionic'
import * as Sentry from '@sentry/react'
import isBrowser from 'utils/isBrowser'
import { useLoadingBlock } from 'components/LoadingBlock'
import TermsAndConditions from 'screens/TermsAndConditions'
import PrivacyPolicy from 'screens/PrivacyPolicy'
import LiveSupportButton from 'components/LiveSupportButton'
import { Plugins } from '@capacitor/core'
import { SUPPORT_PAGE_URL } from 'globalConstants'
import { isWebapp } from 'utils/env'
import CrossLink from 'components/CrossLink'

const { Browser } = Plugins

const AccountScreen = () => {
  const { isAuthenticated, account, canTeachAClass } = useAuth()
  const modal = useModalScreen()
  const options = useOptions(account.type)

  // https://tawk.to/chat/6084df5d5eb20e09cf364d16/1f43gh1r4/?$_tawk_popout=true

  if (!isAuthenticated()) {
    return (
      <>
        <Container
          className="flex flex-col justify-center h-full"
          $size="compact"
        >
          <NotLoggedIn />
        </Container>
        {!isWebapp() && <BuildVersion />}
      </>
    )
  }

  return (
    <div className="flex flex-col h-full">
      <Container topBottomSpace className="safe-area-mt flex-grow">
        {/* Profile Picture */}
        <div className="text-center">
          <MyProfilePicture size={122} />
        </div>

        {/* Name */}
        <div
          className={cc([
            'pt-4',
            !canTeachAClass() && 'pb-2', // we only apply this class if the fn returns false because the next element will take care of that to create more space for it since it's clickable
          ])}
        >
          <DisplayName />
          {account.username && (
            <div className="text-center text-gray-600">@{account.username}</div>
          )}
        </div>

        {/* Reviews */}
        {canTeachAClass() && (
          <div className="text-center pt-2 pb-3">
            <ReviewsButton
              type={
                account.type === Account_Role_Enum.Teacher
                  ? 'teacher'
                  : 'studio'
              }
              id={account.id}
            />
          </div>
        )}

        {/* Options */}
        <ul className="list-none grid grid-cols-2 md:grid-cols-3 gap-5 pt-4 pb-8">
          {options.map(({ text, onClick, to, Icon }, index) => (
            <li key={`${text}-${index}`} className="w-full">
              <CrossLink
                className="block relative w-full aspect-ratio-square shadow-lg bg-white border-2 border-white md:hover:border-primary"
                data-testid={`option`}
                data-value={index}
                onClick={onClick}
                to={to}
              >
                <span className="absolute inset-0 flex flex-col justify-center items-center">
                  <Icon
                    width="30"
                    height="30"
                    className="inline-block fill-primary"
                  />
                  <span className="block pt-5 px-3 heading-3">{text}</span>
                </span>
              </CrossLink>
            </li>
          ))}
        </ul>

        {/* Public profile */}
        {canTeachAClass() && (
          <>
            <div className="mt-6 mb-10 bg-white p-3 border border-dashed border-primary border-gray-500">
              <h3 className="heading-2 text-primary">Did you know?</h3>
              <p className="py-2">
                Your profile is publicly available to be used in social media
                platforms (e.g. Instagram bio) and other platforms.
              </p>

              <Button $size="sm" to={`/u/${account.username || account.id}`}>
                View Public Profile
              </Button>
            </div>
          </>
        )}

        {/* OTHER BUTTONS */}
        <div className="py-2">
          <Button
            type="button"
            $type="primary"
            $fluid
            onClick={() => {
              if (window.confirm('Are you sure you want to log out?')) {
                navigate('/logout')
              }
            }}
            data-testid="button-logout"
          >
            Logout
          </Button>
        </div>
        <LiveSupportButton />

        <ReportBugButton />
      </Container>

      <div className="bg-gray-300 pt-4">
        <div className="text-center pb-2">
          <button
            className="text-primary body-2 px-4"
            onClick={() => {
              modal.open({
                header: 'Terms & Conditions',
                body: <TermsAndConditions />,
              })
            }}
          >
            Terms &amp; Conditions
          </button>
          <button
            className="text-primary body-2 px-4"
            onClick={() => {
              modal.open({
                header: 'Privacy Policy',
                body: <PrivacyPolicy />,
              })
            }}
          >
            Privacy Policy
          </button>
        </div>

        {!isWebapp() && <BuildVersion />}

        <div className="body-3 p-1 text-center text-gray-500">
          Account ID: {account.id}
        </div>
      </div>
    </div>
  )
}

export default AccountScreen

const DisplayName = () => {
  const { account, isEntity } = useAuth()
  const { profile } = account

  return (
    <>
      <div
        className="heading-1 text-center text-primary"
        data-testid="profile-display-name"
      >
        {isEntity()
          ? getEntityName(profile as Entity)
          : getPersonName(profile as Person)}
      </div>
    </>
  )
}

const useOptions = (type: Account_Role_Enum) => {
  const { open, close } = useModalScreen()

  switch (type) {
    case Account_Role_Enum.Student: {
      return [
        {
          text: 'Manage Profile',
          Icon: IconAccount,
          to: '/account/manage-profile',
          onClick: () => {
            open({
              header: 'Manage Profile',
              body: <EditProfile />,
            })
          },
        },
        {
          text: 'Notifications',
          Icon: IconNotifications,
          to: '/account/notifications',
          onClick: () => {
            open({
              header: 'Notifications',
              body: <Notifications close={close} />,
            })
          },
        },
        {
          text: 'Favorites',
          Icon: IconHeart,
          to: '/account/favorites',
          onClick: () => {
            open({
              header: 'Favorite Classes',
              body: <Favorites />,
            })
          },
        },
        {
          text: 'Billing',
          Icon: IconCreditCard,
          to: '/account/billing',
          onClick: () => {
            open({
              header: 'Billing',
              body: <Billing />,
            })
          },
        },
        {
          text: 'Help',
          Icon: IconHelp,
          onClick: () => {
            Browser.open({
              url: SUPPORT_PAGE_URL,
              windowName: 'Help',
              presentationStyle: 'popover',
            })
          },
        },
      ]
    }
    case Account_Role_Enum.Studio: {
      return [
        {
          text: 'Manage Profile',
          Icon: IconAccount,
          to: '/account/manage-profile',
          onClick: () => {
            open({
              header: 'Manage Profile',
              body: <EditProfile />,
            })
          },
        },
        {
          text: 'Studio Photos',
          Icon: IconStudio,
          to: '/account/studio-photos',
          onClick: () => {
            open({
              header: 'Studio Pictures',
              body: <StudioPictures />,
            })
          },
        },
        {
          text: 'Locations',
          Icon: IconPin,
          to: '/account/locations',
          onClick: () => {
            open({
              header: 'Locations',
              body: <Locations />,
            })
          },
        },
        {
          text: 'Teachers',
          Icon: IconTeacher,
          to: '/account/teachers',
          onClick: () => {
            open({
              header: 'Teachers',
              body: <StudioTeachers />,
            })
          },
        },
        {
          text: 'Legal Information',
          Icon: IconArchive,
          to: '/account/legal-information',
          onClick: () => {
            open({
              header: 'Legal Information',
              body: <LegalInformation />,
            })
          },
        },
        {
          text: 'Billing',
          Icon: IconCreditCard,
          to: '/account/billing',
          onClick: () => {
            open({
              header: 'Billing',
              body: <Billing />,
            })
          },
        },
        {
          text: 'Notifications',
          Icon: IconNotifications,
          to: '/account/notifications',
          onClick: () => {
            open({
              header: 'Notifications',
              body: <Notifications close={close} />,
            })
          },
        },
        {
          text: 'Help',
          Icon: IconHelp,
          onClick: () => {
            Browser.open({
              url: SUPPORT_PAGE_URL,
              windowName: 'Help',
              presentationStyle: 'popover',
            })
          },
        },
      ]
    }
    case Account_Role_Enum.Teacher: {
      return [
        {
          text: 'Manage Profile',
          Icon: IconAccount,
          to: '/account/manage-profile',
          onClick: () => {
            open({
              header: 'Manage Profile',
              body: <EditProfile />,
            })
          },
        },
        {
          text: 'Notifications',
          Icon: IconNotifications,
          to: '/account/notifications',
          onClick: () => {
            open({
              header: 'Notifications',
              body: <Notifications close={close} />,
            })
          },
        },
        {
          text: 'Favorites',
          Icon: IconHeart,
          to: '/account/favorites',
          onClick: () => {
            open({
              header: 'Favorite Classes',
              body: <Favorites />,
            })
          },
        },
        {
          text: 'Locations',
          Icon: IconPin,
          to: '/account/locations',
          onClick: () => {
            open({
              header: 'Locations',
              body: <Locations />,
            })
          },
        },
        {
          text: 'Legal Information',
          Icon: IconArchive,
          to: '/account/legal-information',
          onClick: () => {
            open({
              header: 'Legal Information',
              body: <LegalInformation />,
            })
          },
        },
        {
          text: 'Billing',
          Icon: IconCreditCard,
          to: '/account/billing',
          onClick: () => {
            open({
              header: 'Billing',
              body: <Billing />,
            })
          },
        },
        {
          text: 'Teaching Documents',
          Icon: IconArchive,
          onClick: () => {
            open({
              header: 'Teaching Documents',
              body: <TeachingDocuments />,
            })
          },
        },
        {
          text: 'Help',
          Icon: IconHelp,
          onClick: () => {
            Browser.open({
              url: SUPPORT_PAGE_URL,
              windowName: 'Help',
              presentationStyle: 'popover',
            })
          },
        },
      ]
    }
    default: {
      return []
    }
  }
}

const BuildVersion = () => {
  const [appVersion, setAppVersion] = React.useState('N/A')
  const [buildVersion, setBuildVersion] = React.useState('N/A')
  const [checking, setChecking] = React.useState(false)

  React.useEffect(() => {
    if (!isBrowser()) {
      AppVersion.getVersionNumber().then((versionNumber) => {
        setAppVersion(versionNumber)
      })

      Deploy.getCurrentVersion().then((info) => {
        if (info?.versionId) {
          setBuildVersion(info.versionId)
        }
      })
    }
  }, [])

  return (
    <div
      className="text-center body-3 text-gray-600 pt-1 pb-4"
      onClick={async () => {
        alert(
          'The App will check for updates. Please stay connected to internet.',
        )

        if (checking) {
          return
        }

        setChecking(true)

        const { available } = await Deploy.checkForUpdate()

        if (available) {
          await Deploy.downloadUpdate()

          await Deploy.extractUpdate()

          window.alert(
            'Ohmunity has been updated to the latest version! The app will reload',
          )

          setChecking(false)

          await Deploy.reloadApp()
        } else {
          alert('You are currently using the latest version of Ohmunity™')
          setChecking(false)
        }
      }}
    >
      Version {appVersion} - Build {buildVersion}
    </div>
  )
}

const ReportBugButton = () => {
  const loadingBlock = useLoadingBlock()
  const { account, isEntity } = useAuth()

  return (
    <Button
      className="flex justify-center items-center text-center"
      $fluid
      $type="tertiary"
      $size="sm"
      onClick={() => {
        loadingBlock.open()
        Sentry.showReportDialog({
          onLoad: () => {
            loadingBlock.close()
          },
          user: {
            email: account.profile?.email,
            name: isEntity()
              ? (account.profile as Entity).name
              : getPersonName(account.profile as Person),
          },
        })
      }}
    >
      Report bug
    </Button>
  )
}
