import * as yup from 'yup'
import moment from 'moment'
import { ApolloClient } from '@apollo/client'
import {
  QUERY_SIGNUP_CHECK_EMAIL,
  QUERY_SIGNUP_CHECK_PHONE_NUMBER,
} from 'graphql/queries'
import { onlyNumbers } from './regex'
import {
  CREDIT_CARD_LENGTH,
  LEGAL_PERSON_AGE,
  LONG_TEXT_BOUNDARIES,
  REGEX_IS_AMEX_CARD,
} from 'globalConstants'
import { AccountsByUsernameDocument } from 'generated/graphql'

export const firstName = yup
  .string()
  .required('This field is required')
  .min(2, 'First name must be at least 2 characters')
  .max(15, 'First name must be at most 15 characters')

export const lastName = yup
  .string()
  .required('This field is required')
  .min(2, 'Last name must be at least 2 characters')
  .max(15, 'Last name must be at most 15 characters')

export const entityName = yup
  .string()
  .required('This field is required')
  .min(3)
  .max(20)

export const email = yup
  .string()
  .email('Invalid email')
  .required('This field is required')

export const uniqueEmail = (
  client: ApolloClient<any>,
  exceptFor?: string | null,
) =>
  email.test('uniqueEmail', 'Email is taken', async (value) => {
    if (!value) {
      return false
    }

    if (exceptFor && value === exceptFor) {
      return true
    }

    try {
      const result = await client.query({
        query: QUERY_SIGNUP_CHECK_EMAIL,
        variables: {
          email: value.toLocaleLowerCase().trim(),
        },
      })

      return (
        result.data.profile_entity_aggregate.aggregate.count === 0 &&
        result.data.profile_person_aggregate.aggregate.count === 0
      )
    } catch (error) {
      return true
    }
  })

export const phoneNumber = yup
  .string()
  .optional()
  .test(
    'phoneNumber',
    'Invalid phone number',
    (value) => !value || onlyNumbers(value).length === 10,
  )

export const uniquePhoneNumber = (
  client: ApolloClient<any>,
  exceptFor?: string | null,
) =>
  phoneNumber.test(
    'uniquePhoneNumber',
    'Phone Number is taken',
    async (value) => {
      if (!value || (exceptFor && value === exceptFor)) {
        return true
      }

      try {
        const result = await client.query({
          query: QUERY_SIGNUP_CHECK_PHONE_NUMBER,
          variables: {
            phoneNumber: onlyNumbers(value),
          },
        })

        return (
          result.data.profile_entity_aggregate.aggregate.count === 0 &&
          result.data.profile_person_aggregate.aggregate.count === 0
        )
      } catch (error) {
        return true
      }
    },
  )

export const birthday = yup
  .string()
  .required('Birthday is required')
  .test('valid-date', 'Invalid date, please verify', (value) => {
    const isValid = moment(value, 'YYYY-MM-DD', true).isValid()

    return isValid
  })
  .test(
    'legal-age',
    `You must be at least ${LEGAL_PERSON_AGE} years old`,
    (value) => {
      const m = moment(value, 'YYYY-MM-DD')

      if (!m.isValid()) {
        return false
      }

      const yearsOld = moment().diff(m, 'years')

      // valid if it's 18 or older
      return yearsOld >= LEGAL_PERSON_AGE
    },
  )
  .test('too-old', 'Invalid date, please verify', (value) => {
    const m = moment(value, 'YYYY-MM-DD')

    if (!m.isValid()) {
      return false
    }

    const yearsOld = moment().diff(m, 'years')

    // valid if it's 99 or younger
    return yearsOld < 100
  })

export const bankAccountNumber = yup
  .string()
  .required('This field is required')
  .matches(/^[0-9]+$/, 'This field should only contain numbers')
  .min(6)
  .max(17)

export const bankRouting = yup
  .string()
  .required('This field is required')
  .matches(/^[0-9]+$/, 'This field should only contain numbers')
  .min(9)
  .max(9)

export const creditCardNumber = yup
  .string()
  .required('This field is required')
  .test('number', 'Invalid credit card number', (value) => {
    if (!value) {
      return false
    }

    const clearCardNumber = (value: string): string => value.replace(/\D+/g, '')
    const number = clearCardNumber(value)
    const isAmex = REGEX_IS_AMEX_CARD.test(number)
    const expectedLength = isAmex
      ? CREDIT_CARD_LENGTH.amex
      : CREDIT_CARD_LENGTH.other

    return expectedLength === number.length
  })

export const creditCardCVC = yup
  .string()
  .required('This field is required')
  .matches(/^[0-9]+$/, 'This field should only contain numbers')
  .min(3)
  .max(4)

export const digits = (n: number) =>
  yup
    .string()
    .required('This field is required')
    .matches(new RegExp(`^[0-9]{${n}}$`), `Must be exactly ${n} digits`)

export const zipCode = digits(5)

export const longText = yup
  .string()
  .required('This field is required')
  .min(LONG_TEXT_BOUNDARIES.MIN)
  .max(LONG_TEXT_BOUNDARIES.MAX)

export const ein = digits(9)

export const address = {
  address: yup.string().required('This field is required'),
  addressSecondary: yup.string().optional(),
  city: yup.string().required('This field is required'),
  state: yup.string().required('This field is required'),
  zipCode: zipCode,
}

export const className = yup
  .string()
  .required('This field is required')
  .min(5)
  .max(30)

export const url = yup
  .string()
  .optional()
  .max(255, 'URL must be at most 255 characters')

export const username = (
  client: ApolloClient<any>,
  exceptFor?: string | null,
) =>
  yup
    .string()
    .notRequired()
    .max(20)
    .test(
      'onlyAlpha',
      'Username can only contain letters and numbers',
      (value) => {
        if (!value) {
          return true
        } else {
          return /^[a-zA-Z0-9]+$/g.test(value)
        }
      },
    )
    .test('uniqueUsername', 'Username is taken', async (value) => {
      if (!value || (exceptFor && value === exceptFor)) {
        return true
      }

      try {
        const result = await client.query({
          query: AccountsByUsernameDocument,
          variables: {
            username: (value || '').toLocaleLowerCase().trim(),
          },
        })

        return result.data.account.length === 0
      } catch (error) {
        return true
      }
    })

export const externalLinks = yup.array().of(
  yup.object().shape({
    text: yup
      .string()
      .required('This field is required')
      .min(5, 'Must be at least 2 characters')
      .max(20, 'Must be at most 20 characters'),
    url: url.required('Valid URL is required'),
  }),
)

export const termsAndConditions = yup
  .boolean()
  .required()
  .test(
    'isChecked',
    'You need to read and agree on our Terms and Conditions.',
    (value) => value === true,
  )
