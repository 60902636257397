import moment from 'moment'
import { pick, isEqual, uniqBy, map, omit } from 'lodash/fp'
import {
  Class,
  Class_Date_Time,
  Class_Recurrence_Enum,
  UpdateClassDateTimesMutation,
} from 'generated/graphql'
import { FormValues, DateTime } from './types'
import {
  DB_DATE_FORMAT,
  DB_TIME_FORMAT,
  SELECT_TIME_FORMAT,
} from 'globalConstants'
import { utcDayToLocalDay } from 'utils/date'

export const classToFormGeneral = (clazz: Class): FormValues['general'] => ({
  ...pick(
    ['online', 'name', 'description', 'costType', 'yogaType', 'level'],
    clazz,
  ),
  location_id: clazz.location?.id || '',
  price: clazz.price.toString(),
  setting: clazz.setting || '',
  picture: clazz.picture as string,
  maxCapacity: clazz.maxCapacity,
  safetyGuidelines: clazz.safetyGuidelines.map((s) => s.safety_guideline_id),
})

export const dateTimeToTimeFormValues = (
  recurrence: Class_Recurrence_Enum,
  datesTimes: Class_Date_Time[],
  local = true,
): FormValues['dateAndTime'] => ({
  type: recurrence,
  dateTime: datesTimes.map((dateTime) => {
    const common = {
      id: dateTime.id,
      start: (local
        ? moment(dateTime.start, DB_TIME_FORMAT)
        : moment.utc(dateTime.start, DB_TIME_FORMAT)
      ).format(SELECT_TIME_FORMAT),
      end: (local
        ? moment(dateTime.end, DB_TIME_FORMAT)
        : moment.utc(dateTime.end, DB_TIME_FORMAT)
      ).format(SELECT_TIME_FORMAT),
      teacher_id: dateTime.teacher.id,
    }

    if (dateTime.date) {
      const utcDate = moment.utc(
        `${dateTime.date} ${dateTime.start}`,
        `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
      )

      if (local) {
        utcDate.local()
      }

      return {
        date: utcDate.format(DB_DATE_FORMAT),
        ...common,
      }
    }

    return {
      day: local
        ? utcDayToLocalDay(dateTime.day!, dateTime.start)
        : dateTime.day!,
      ...common,
    }
  }) as DateTime[],
})

export const classToFormInitialValues = (clazz: Class): FormValues => ({
  general: {
    ...classToFormGeneral(clazz),
    safetyGuidelines: clazz.safetyGuidelines.map((x) => x.safety_guideline.id),
  },
  dateAndTime: dateTimeToTimeFormValues(clazz.recurrence, clazz.dates_times),
})

export const hasGeneralChanged = (
  clazz: Class,
  values: FormValues['general'],
  omitFields?: string[],
) => {
  const fromClass = omitFields
    ? omit(omitFields, classToFormGeneral(clazz))
    : classToFormGeneral(clazz)
  const fromValues = omitFields ? omit(omitFields, values) : values

  return !isEqual(fromClass, fromValues)
}

export const classifyDateTimeChanges = (
  clazz: Class,
  values: FormValues['dateAndTime'],
) => {
  const currentDatesTimesAsValues = dateTimeToTimeFormValues(
    clazz.recurrence,
    clazz.dates_times,
    false,
  )

  // the loop already covers this case but this case saves computing time!
  if (currentDatesTimesAsValues.type !== values.type) {
    return {
      type: values.type,
      dateTime: {
        removed: currentDatesTimesAsValues.dateTime,
        added: map(omit('id'), values.dateTime),
        updated: [],
      },
    }
  }

  return {
    type: values.type,
    dateTime: uniqBy(isEqual, [
      ...currentDatesTimesAsValues.dateTime,
      ...values.dateTime,
    ]).reduce<{
      updated: DateTime[]
      added: DateTime[]
      removed: DateTime[]
    }>(
      (acc, dt) => {
        if (dt.id) {
          let foundInNewValues = values.dateTime.find(({ id }) => id === dt.id)
          const foundInCurrent = currentDatesTimesAsValues.dateTime.find(
            ({ id }) => id === dt.id,
          )

          // TODO: Make it a bit more readable
          if (foundInNewValues) {
            foundInNewValues = omit(
              foundInNewValues.date ? 'day' : 'date',
              foundInNewValues,
            )
          }

          if (!foundInNewValues) {
            return {
              ...acc,
              removed: [...acc.removed, dt],
            }
          } else if (
            !isEqual(foundInCurrent, foundInNewValues) &&
            isEqual(dt, foundInNewValues)
          ) {
            return {
              ...acc,
              updated: [...acc.updated, dt],
            }
          } else {
            return { ...acc }
          }
        } else {
          // No id? then it's new
          return {
            ...acc,
            added: [...acc.added, dt],
          }
        }
      },
      {
        updated: [],
        added: [],
        removed: [],
      },
    ),
  }
}

export const removeDeletedClasses = (deletedIds: string[]) => (
  datesTimes: Class_Date_Time[],
): Class_Date_Time[] =>
  datesTimes.filter((dateTime) => !deletedIds.includes(dateTime.id))

export const updateAndAddClasses = (
  insertedUpdated: UpdateClassDateTimesMutation['insert_class_date_time'],
) => (datesTimes: Class_Date_Time[]): Class_Date_Time[] =>
  insertedUpdated?.returning
    ? [
        ...(datesTimes.map(
          (dateTime) =>
            insertedUpdated.returning.find(
              (record) => record.id === dateTime.id,
            ) || dateTime,
        ) as Class_Date_Time[]),
        ...(insertedUpdated?.returning.filter(
          (record) => !datesTimes.some((dt) => dt.id === record.id),
        ) as Class_Date_Time[]),
      ]
    : datesTimes

export const localDateTimeToUTC = (dateTimes: DateTime[]): DateTime[] =>
  dateTimes.map(({ id, teacher_id, date, day, start, end }) => {
    if (date) {
      const start_utc = moment(
        `${date} ${start}`,
        `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
      ).utc()

      return {
        id,
        teacher_id,
        date: start_utc.format(DB_DATE_FORMAT),
        start: start_utc.format(SELECT_TIME_FORMAT),
        end: moment(`${date} ${end}`, `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`)
          .utc()
          .format(SELECT_TIME_FORMAT),
      }
    } else {
      const start_utc = moment(start, SELECT_TIME_FORMAT).day(day!).utc()

      return {
        id,
        teacher_id,
        day: start_utc.day(),
        start: start_utc.format(SELECT_TIME_FORMAT),
        end: moment(end, SELECT_TIME_FORMAT).utc().format(SELECT_TIME_FORMAT),
      }
    }
  })
