import React from 'react'
import cc from 'classcat'
import remove from 'lodash/fp/remove'
import isEqual from 'lodash/fp/isEqual'
import useToggle from 'hooks/useToggle'
import { Control, Controller } from 'react-hook-form'
import { ReactComponent as X } from 'assets/symbols/x.svg'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'
import { ReactComponent as Minus } from 'assets/symbols/minus.svg'

type Option = {
  id: string
  text: string
}

type SelectorProps = {
  label: string
  options: Option[]
  isOpen: boolean
  onChange: any
  value?: string
}

const Selector = ({
  label,
  options,
  isOpen,
  value,
  onChange,
}: SelectorProps) => {
  const [displayOptions, toggleDisplayOptions] = useToggle(isOpen)
  const selectedOptions = value && value.length > 0 ? value.split(',') : []

  return (
    <>
      {/* Button / Label */}
      <button
        type="button"
        className="flex w-full items-center justify-between py-2 my-2"
        onClick={() => {
          toggleDisplayOptions()
        }}
      >
        <span className="heading-3 text-primary">{label}</span>

        <span data-testid="min-plus">
          {displayOptions ? <Minus width={20} /> : <Plus width={20} />}
        </span>
      </button>

      {/* Options */}
      {displayOptions && (
        <ul className="grid grid-cols-3 gap-3" data-testid="list-all-options">
          {options.map(({ id, text }, i) => {
            const isActive = selectedOptions.includes(id)
            return (
              <li key={['tag', i, id].join('-')} data-testid={`options-${i}`}>
                <button
                  type="button"
                  className={cc([
                    'w-full border-2 uppercase rounded-full px-1 py-2',
                    {
                      'border-primary text-primary': !isActive,
                      'border-primary bg-primary text-white': isActive,
                    },
                  ])}
                  style={{
                    // TODO: we need to re-structure the font sizing in tailwind to match our design
                    fontSize: 10,
                  }}
                  onClick={() => {
                    const newValue = isActive
                      ? remove(isEqual(id), selectedOptions)
                      : [...selectedOptions, id]

                    onChange(newValue.join(','))
                  }}
                >
                  {text}
                </button>
              </li>
            )
          })}
        </ul>
      )}

      {!displayOptions && selectedOptions.length > 0 && (
        <ul data-testid="list-selected-options">
          {selectedOptions.map((option, i) => (
            <li
              key={['selectedOption', i, option].join('-')}
              className="inline-flex items-center rounded-full border border-primary text-primary uppercase mr-2 mb-2"
              style={{
                // TODO: we need to re-structure the font sizing in tailwind to match our design
                fontSize: 10,
              }}
            >
              <span className="pl-2">{option}</span>
              <button
                data-testid={`button-options-${i}`}
                type="button"
                className="p-2"
                onClick={() => {
                  onChange(remove(isEqual(option), selectedOptions).join(','))
                }}
              >
                <X width={10} height={10} />
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

type Props = {
  label: string
  control: Control
  name: string
  options: Option[]
  isOpen: boolean
  ['data-testid']?: string
}

const TagSelector = (props: Props) => (
  <Controller
    control={props.control}
    name={props.name}
    render={({ value, onChange }: any) => (
      <div data-testid={props['data-testid']}>
        <Selector {...props} value={value} onChange={onChange} />
      </div>
    )}
  />
)

TagSelector.defaultProps = {
  isOpen: false,
}

export default TagSelector
