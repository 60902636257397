import logger from 'utils/logger'
import moment from 'moment-timezone'

export const getDeviceTimezone = (): string => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  } catch (error) {
    logger.error(`Unable to get device's timezone`, {
      error,
      Intl,
    })
    return moment.tz.zone.name
  }
}
