import { useClient } from 'urql'
import {
  AccountTimezoneDocument,
  AccountTimezoneQuery,
  UpdateAccountTimezoneDocument,
} from 'generated/graphql'
import { useEffect } from 'react'
import { getDeviceTimezone } from 'utils/timezone'
import { useAuth } from 'stores/auth.store'
import { useAlert } from 'components/AlertModal'

const useCheckAccountTimezone = (): void => {
  const client = useClient()
  const { account, isAuthenticated } = useAuth()
  const alert = useAlert()

  useEffect(() => {
    if (
      !localStorage.getItem('IGNORE_AUTO_TIMEZONE_SETUP') &&
      isAuthenticated() &&
      account.id
    ) {
      client
        .query<AccountTimezoneQuery>(AccountTimezoneDocument, {
          id: account.id,
        })
        .toPromise()
        .then(({ data }) => {
          if (!data) {
            return
          }

          const timezone = data?.account_by_pk?.timezone
          const localTimezone = getDeviceTimezone()
          const updateTimezone = async () => {
            await client
              .mutation(UpdateAccountTimezoneDocument, {
                id: account.id,
                timezone: localTimezone,
              })
              .toPromise()
          }

          if (!timezone) {
            alert.send({
              title: 'Timezone setup',
              body: `We detected that the timezone for your Account has not been setup yet. Would you like to use your current timezone "${localTimezone}"`,
              buttons: [
                {
                  children: 'Yes, please',
                  onClick: () => {
                    updateTimezone().then()
                  },
                },
                {
                  $type: 'secondary',
                  children: 'Not at this time',
                },
              ],
            })
          } else if (timezone !== localTimezone) {
            alert.send({
              title: 'Timezone setup',
              body: `We detected that your Account timezone is "${timezone}" but your current location timezone is "${localTimezone}", would you like to use "${localTimezone}" as your default timezone?`,
              buttons: [
                {
                  $type: 'primary',
                  children: 'Yes, please',
                  onClick: () => {
                    updateTimezone().then()
                  },
                },
                {
                  $type: 'secondary',
                  children: 'Not at this time',
                },
                {
                  $type: 'tertiary',
                  children: 'Do not ask again',
                  onClick: () => {
                    localStorage.setItem('IGNORE_AUTO_TIMEZONE_SETUP', '1')
                  },
                },
              ],
            })
          }
        })
    }
  }, [isAuthenticated(), account])
}

export default useCheckAccountTimezone
