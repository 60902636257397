export const minuteToString = (minute: number) =>
  minute < 10 ? `0${minute.toString()}` : minute.toString()

export const get15minInterval = (type: '12-clock' | '24-clock') => {
  if (type === '24-clock') {
    return new Array(24 * 4).fill(0).map((_v, i) => {
      const div = i / 4
      const int = div | 0
      const hour: number = int
      const minute = (div - int) * 60

      return [hour, minute].map(minuteToString).join(':')
    })
  } else {
    // 12-clock
    return new Array(24 * 4).fill(0).map((_v, i) => {
      const div = i / 4
      const int = div | 0
      const hour: number = int <= 12 ? int : int - 12
      const minute = (div - int) * 60

      return [
        [hour, minute].map(minuteToString).join(':'),
        int < 12 ? 'am' : 'pm',
      ].join(' ')
    })
  }
}
