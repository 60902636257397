import Container from 'components/Container'
import { withModalScreen } from 'components/ModalScreen'

const Search = () => {
  return (
    <Container>
      <SearchContent />
    </Container>
  )
}

export default withModalScreen(Search)

const SearchContent = () => {
  return <div>Testing</div>
}
