import { mapValues } from 'lodash/fp'
import { useAuth } from 'stores/auth.store'
import logger from 'utils/logger'

const useLogger = () => {
  const { account, isAuthenticated } = useAuth()

  const log = (
    level: 'error' | 'warning' | 'info',
    message: string,
    extra?: Record<string, any>,
  ) => {
    logger.log(level, message, {
      ...mapValues((value) => {
        if (value instanceof Error) {
          return value.message
        } else {
          return value
        }
      }, extra),
      account,
      authenticated: isAuthenticated(),
    })
  }

  const logUnhandledCase = (message: string, extra?: Record<string, any>) => {
    log('error', ['UNHANDLED CASE', message].join(' :: '), extra)
  }

  return { log, logUnhandledCase }
}

export default useLogger
