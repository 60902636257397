import upperFirst from 'lodash/fp/upperFirst'
import Container from 'components/Container'
import FormField, { Label, DropdownOption } from 'components/FormField'
import DaysSelector from 'components/DaysSelector'
import TagSelector from 'components/TagSelector'
import Button from 'components/Button'
import { useForm } from 'react-hook-form'
import { useQuery } from '@apollo/client'
import { QUERY_YOGA_TYPES } from 'graphql/queries'
import { CLASS_LEVELS, CLASS_SETTINGS } from 'globalConstants'
import patternMatching from 'utils/patternMatching'
// import Switch from 'components/Switch'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

/*
NOTE:
Cannot use 'GenericQueryRender' due to re-render issue
*/

export enum SortBy {
  Price = 'price',
  Rating = 'rating',
}

export enum Direction {
  Ascendant = 'asc',
  Descendant = 'desc',
}

export type Values = {
  online?: boolean
  sortBy?: [SortBy, Direction]
  days: number[]
  types: string[]
  levels: string[]
  setting: string[]
}

export type InitialValues = Partial<Values>

type FormValues = {
  online?: boolean
  sortBy?: string
  days?: string
  types?: string
  levels?: string
  setting?: string
}

const OPTIONS = Object.values(SortBy).reduce<[SortBy, Direction][]>(
  (options, sortBy) => {
    Object.values(Direction).forEach((direction) => {
      options = [...options, [sortBy, direction]]
    })

    return options
  },
  [],
)

const convertFormValuesToValues = ({
  online,
  sortBy,
  days,
  types,
  levels,
  setting,
}: FormValues): Values => {
  return {
    online,
    sortBy: sortBy
      ? patternMatching<string, [SortBy, Direction]>([
          ['price-asc', [SortBy.Price, Direction.Ascendant]],
          ['price-desc', [SortBy.Price, Direction.Descendant]],
          ['rating-asc', [SortBy.Rating, Direction.Ascendant]],
          ['rating-desc', [SortBy.Price, Direction.Descendant]],
        ])(sortBy)
      : undefined,
    days: days ? days.split(',').map((day) => parseInt(day, 10)) : [],
    types: types ? types.split(',') : [],
    levels: levels ? levels.split(',') : [],
    setting: !online && setting ? setting.split(',') : [],
  }
}

const convertInitialValuesToFormValues = ({
  online,
  sortBy,
  days,
  types,
  levels,
  setting,
}: InitialValues): FormValues => {
  return {
    online,
    sortBy: sortBy?.join('-'),
    days: days?.join(','),
    types: types?.join(','),
    levels: levels?.join(','),
    setting: online ? '' : setting?.join(','),
  }
}

type Props = {
  onChange: (values: Values) => void
  initialValues?: InitialValues
  onlineOnly?: boolean
}

const SortAndFilter = ({ onChange, initialValues, onlineOnly }: Props) => {
  const yogaTypeResult = useQuery(QUERY_YOGA_TYPES)
  const { control, handleSubmit, watch } = useForm({
    defaultValues: initialValues
      ? convertInitialValuesToFormValues(initialValues)
      : {},
  })
  const isOnline = onlineOnly || watch('online')

  return (
    <Container topBottomSpace>
      <form
        onSubmit={handleSubmit((values) => {
          onChange(convertFormValuesToValues(values))
        })}
      >
        {/* Virtual Class */}
        {/* {!onlineOnly && (
          <div className="py-2">
            <div className="flex justify-between items-center mb-3">
              <div>Virtual Classes Only</div>
              <Switch data-testid="virtual" name="online" control={control} />
            </div>
          </div>
        )} */}

        {/* Sort by */}
        <div className="py-2">
          <Label>Sort by</Label>
          <FormField
            type="dropdown"
            name="sortBy"
            control={control}
            placeholder="Relevance"
            data-testid="sort"
          >
            {OPTIONS.map(([sortBy, direction], i) => (
              <DropdownOption
                key={`sort-option-${i}`}
                value={[sortBy, direction].join('-')}
              >
                {`${upperFirst(sortBy)} (${
                  direction === Direction.Ascendant ? 'lowest' : 'highest'
                } to ${
                  direction === Direction.Ascendant ? 'highest' : 'lowest'
                }) ${direction === Direction.Ascendant ? '↑' : '↓'}`}
              </DropdownOption>
            ))}
          </FormField>
        </div>

        {/* Days */}
        <div
          className="py-5 mt-2 flex-col space-y-2"
          data-testid="day-selector"
        >
          <Label>Days</Label>
          <DaysSelector name="days" control={control} />
        </div>

        {/* Types of Yoga */}
        {!yogaTypeResult.loading && !yogaTypeResult.error && (
          <div className="py-2" data-testid="types-yoga">
            <TagSelector
              data-testid="yoga-selector"
              label="Types of Yoga"
              name="types"
              control={control}
              options={yogaTypeResult.data.yoga_type.map(({ value }: any) => ({
                id: value,
                text: value,
              }))}
            />
          </div>
        )}

        <hr className="border border-gray-400 my-2" />

        {/* Levels */}
        <div className="py-2" data-testid="levels">
          <TagSelector
            data-testid="levels-selector"
            label="levels"
            name="levels"
            control={control}
            options={CLASS_LEVELS}
          />
        </div>

        <hr className="border border-gray-400 my-2" />

        {/* Setting */}
        {!isOnline && (
          <div className="py-2" data-testid="setting">
            <TagSelector
              data-testid="setting-selector"
              label="Setting"
              name="setting"
              control={control}
              options={CLASS_SETTINGS}
            />
          </div>
        )}

        {/* CTA */}
        <div className="py-4" data-testid="button">
          <Button type="submit" $fluid>
            Show Result
          </Button>
        </div>
      </form>

      <SpaceForKeyboard />
    </Container>
  )
}

export default SortAndFilter
