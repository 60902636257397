const List = ({ children }: any) => (
  <ul data-testid="list-suggestion">{children}</ul>
)

const Item = ({ children, ...props }: any) => (
  <li className="py-3 border-b border-gray-400 " {...props}>
    {children}
  </li>
)

List.Item = Item

export default List
