import { createContext, useContext, useState } from 'react'
import {
  Class_Level_Enum,
  Class_Setting_Enum,
  Yoga_Type_Enum,
} from 'generated/graphql'
import { PREFERENCES_STORE_KEY } from 'globalConstants'
import makeStorage from 'utils/makeStorage'
import { ACCOUNT, AUTH } from 'config'
import { Account } from 'types'
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics'
import isBrowser from 'utils/isBrowser'

// TODO: Unit Testing missing because of Note

/*
NOTE
------------
The use of Context is "temporary" because Apollo Websocket does not
work with 'setContext'. This creates an issue with all Websockets connections
when the users goes from anonymous (not logged in) to logged in, because
the Headers don't get updated.
The use of 'refreshClient' solves the problem for now, only if it's called
after the Account gets setup, it creates a new apollo client that's used
by ApolloProvider.
*/

export type Preferences = {
  yogaTypes: Yoga_Type_Enum[]
  settings: Class_Setting_Enum[]
  levels: Class_Level_Enum[]
}
export type AuthPayload = {
  token: string
  expiresAt: number
  account: Account
}

export type AuthData = Pick<AuthPayload, 'token' | 'expiresAt'>

export const AuthStorage = makeStorage<AuthData>(AUTH.storageKey)

export const AccountStorage = makeStorage<Account>(
  ACCOUNT.storageKey,
  ACCOUNT.defaultData,
)

// TODO: Can we use 'makeStorage'?
export const _INTERNAL_setStoredPreferences = (newPreferences: Preferences) => {
  localStorage.setItem(PREFERENCES_STORE_KEY, JSON.stringify(newPreferences))
}

export const _INTERNAL_getStoredPreferences = (): Preferences | null => {
  const stored = localStorage.getItem(PREFERENCES_STORE_KEY)

  if (!stored) {
    return null
  }

  try {
    return JSON.parse(stored)
  } catch (err) {
    console.error(`Cannot decode '${PREFERENCES_STORE_KEY}'`, stored)
    return null
  }
}

export const Context = createContext<{
  auth: AuthData | null
  setAuth: (newAuth: AuthData) => void

  account: Account
  setAccount: (newAccount: Account) => void

  resetAuthAndAccount: () => void

  preferences: Preferences | null
  setPreferences: (newPreferences: Preferences) => void
  refreshPreferences: () => void
}>({
  auth: null,
  setAuth: () => {
    throw new Error(`Cannot consume outside Global Provider`)
  },
  account: AccountStorage.get() || ACCOUNT.defaultData,
  setAccount: () => {
    throw new Error(`Cannot consume outside Global Provider`)
  },
  resetAuthAndAccount: () => {
    throw new Error(`Cannot consume outside Global Provider`)
  },
  preferences: _INTERNAL_getStoredPreferences(),
  setPreferences: () => {
    throw new Error(`Cannot consume outside Global Provider`)
  },
  refreshPreferences: () => {
    throw new Error(`Cannot consume outside Global Provider`)
  },
})

export const useGlobalProvider = () => {
  return useContext(Context)
}

export const GlobalProvider = ({ children }: { children: any }) => {
  const [auth, setAuth] = useState(AuthStorage.get())
  const [account, setAccount] = useState(
    AccountStorage.get() || ACCOUNT.defaultData,
  )
  const [preferences, setPreferences] = useState(
    _INTERNAL_getStoredPreferences(),
  )
  const refreshPreferences = () => {
    setPreferences(_INTERNAL_getStoredPreferences())
  }
  const resetAuthAndAccount = () => {
    AuthStorage.reset()
    AccountStorage.reset()
    setAuth(null)
    setAccount(ACCOUNT.defaultData)
  }

  return (
    <Context.Provider
      value={{
        auth,
        setAuth: (newAuth) => {
          setAuth(newAuth)
          AuthStorage.set(newAuth)
        },
        account,
        setAccount: (newAccount) => {
          setAccount(newAccount)
          AccountStorage.set(newAccount)

          if (!isBrowser()) {
            FirebaseAnalytics.setUserId(newAccount.id)
          }
        },
        resetAuthAndAccount,
        preferences,
        setPreferences: (newPreferences: Preferences) => {
          _INTERNAL_setStoredPreferences(newPreferences)
          setPreferences(newPreferences)
        },
        refreshPreferences,
      }}
    >
      {children}
    </Context.Provider>
  )
}
