import { ReactComponent as IconAccount } from 'assets/icons/avatar.svg'
import { ReactComponent as IconAccountActive } from 'assets/icons/avatar-filled.svg'
import { ReactComponent as IconExplore } from 'assets/icons/ohmunity.svg'
import { ReactComponent as IconExploreActive } from 'assets/icons/ohmunity-filled.svg'
import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg'
import { ReactComponent as IconSearchActive } from 'assets/icons/magnifying-glass-filled.svg'
import { ReactComponent as IconMat } from 'assets/icons/yoga-mat.svg'
import { ReactComponent as IconMatActive } from 'assets/icons/yoga-mat-filled.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as IconCalendarActive } from 'assets/icons/calendar-filled.svg'
import { Account_Role_Enum } from 'generated/graphql'
import { Account } from 'types'

// includes all possible buttons. It filters out the ones that are not available
// for example, "Favorites" is not an available route for anonymous, teachers, and studios.
// TODO: Should we make it a fn as 'mainRoutes' and pass the account type?
export const MENU_LINKS = [
  {
    id: 'explore',
    text: 'Explore',
    Icon: IconExplore,
    IconActive: IconExploreActive,
  },
  {
    id: 'search',
    text: 'Search',
    Icon: IconSearch,
    IconActive: IconSearchActive,
  },
  {
    id: 'myClasses',
    text: 'My Classes',
    Icon: IconMat,
    IconActive: IconMatActive,
  },
  {
    id: 'bookedClasses',
    text: 'Booked Classes',
    Icon: IconCalendar,
    IconActive: IconCalendarActive,
    topSpace: 4,
  },
  {
    id: 'account',
    text: 'Account',
    Icon: IconAccount,
    IconActive: IconAccountActive,
  },
]

export const AUTH = {
  storageKey: process.env.REACT_APP_AUTH_STORAGE_KEY || 'auth',
}

export const ACCOUNT: {
  storageKey: string
  defaultData: Account
} = {
  storageKey: 'loggedInAccount',
  defaultData: {
    id: '',
    type: Account_Role_Enum.Anonymous,
  },
}
