import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import MyProfilePicture from 'components/MyProfilePicture'
import { useFormCarousel } from 'forms/FormCarousel/context'
import LiveSupportButton from 'components/LiveSupportButton'

const Picture = () => {
  const { next, initialValues } = useFormCarousel()
  const [picture, setPicture] = React.useState(initialValues?.picture || '')
  const [loading, setLoading] = React.useState(false)

  return (
    <Container topBottomSpace className="flex flex-col h-full">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">Upload a profile picture</div>
      </div>

      <div className="flex flex-col flex-grow justify-center">
        <div className="m-auto">
          <MyProfilePicture
            size={200}
            onChange={(newPicture) => {
              setPicture(newPicture)
            }}
            onAction={(action) => {
              if (action === 'loading') {
                setLoading(true)
              } else {
                setLoading(false)
              }
            }}
          />
        </div>
      </div>

      <Button
        $fluid
        form="form-levels"
        loading={loading}
        onClick={() => {
          next({
            picture,
          })
        }}
      >
        Get Started
      </Button>

      <LiveSupportButton />
    </Container>
  )
}

export default Picture
