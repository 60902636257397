import cc from 'classcat'
import { ReactComponent as ArrowBack } from 'assets/symbols/arrow-back.svg'
import { ReactComponent as Close } from 'assets/symbols/x.svg'
import { useFormCarousel } from './context'

type Props = {
  canSkip?: boolean
  onCancel?: () => void
  $cancelType: 'back' | 'close'
  $rootClassName?: string
}

const Header = ({ canSkip, onCancel, $cancelType, $rootClassName }: Props) => {
  const { current, total, previous, next } = useFormCarousel()
  const BackComponent =
    current > 0 || $cancelType === 'back' ? ArrowBack : Close

  return (
    <div className={cc(['z-10', $rootClassName])}>
      <div className={'flex items-center h-16'}>
        {/* Left */}
        <div className="w-20">
          {(onCancel || current > 0) && (
            <button
              className="p-5"
              onClick={() => {
                if (current > 0) {
                  previous()
                } else {
                  onCancel?.()
                }
              }}
            >
              <BackComponent width="22" className="fill-primary" />
            </button>
          )}
        </div>

        {/* Center */}
        <div className="flex flex-grow items-center justify-center">
          {new Array(total).fill(null).map((_, i) => {
            const isActive = i === current
            return (
              <div
                key={`form-carousel-dot-${i}`}
                className={cc([
                  'm-1',
                  {
                    'bg-gray-400': !isActive,
                    'bg-primary': isActive,
                  },
                ])}
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 10,
                }}
              />
            )
          })}
        </div>

        {/* Right */}
        <div className="w-20">
          {canSkip && (
            <button
              className="w-20 heading-3 text-primary px-4 py-3 text-right"
              onClick={() => {
                next()
              }}
            >
              Skip
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
