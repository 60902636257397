import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'
import FormField from 'components/FormField'
import * as Validation from 'utils/formFieldValidations'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

const validationSchema = yup.object().shape({
  firstName: Validation.firstName,
  lastName: Validation.lastName,
})

const FirstAndLast = () => {
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { dirtyFields, isValid } = formState
  const canContinue = (dirtyFields.firstName && dirtyFields.lastName) || isValid

  return (
    <Container topBottomSpace className="flex flex-col h-full justify-between">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">What is your name?</div>
      </div>

      <form
        onSubmit={handleSubmit((values) => {
          next(values)
        })}
      >
        {/* First Name */}
        <div className="py-1">
          <FormField
            type="text"
            name="firstName"
            placeholder="First Name *"
            register={register}
            error={errors?.firstName?.message}
            isBig
            data-testid="input-first-name"
          />
        </div>

        {/* Last Name */}
        <div className="py-1">
          <FormField
            type="text"
            name="lastName"
            placeholder="Last Name *"
            register={register}
            error={errors?.lastName?.message}
            isBig
          />
        </div>

        <div className="pt-4">
          <Button $fluid disabled={!canContinue}>
            Next
          </Button>
        </div>

        {isKeyboardOnScreen && (
          <SpaceForKeyboard $size="lg" autoControlled={false} />
        )}
      </form>
    </Container>
  )
}

export default FirstAndLast
