import { useLayoutEffect, createRef } from 'react'
import lottie from 'lottie-web'

type Props = {
  json: any
  width: number
}

const Lottie = ({ json, width }: Props) => {
  const defaultOptions = {
    clearCanvas: false,
    hideOnTransparent: true,
    progressiveLoad: true,
  }

  const ref = createRef<HTMLDivElement>()

  useLayoutEffect(() => {
    if (ref && ref.current) {
      lottie.loadAnimation({
        rendererSettings: defaultOptions,
        animationData: json,
        autoplay: true,
        container: ref.current,
        loop: true,
        renderer: 'svg',
      })
    }
  }, [ref])

  return (
    <div
      ref={ref}
      style={{
        width,
      }}
    />
  )
}

export default Lottie
