import React, { Context } from 'react'
import cc from 'classcat'
import Page from 'components/Page'
import { initial, last } from 'lodash/fp'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { ReactComponent as IconBack } from 'assets/icons/arrow-left.svg'

import styles from './index.module.scss'

export const TRANSITION_DURATION = 500

export type Navigate = (item: StackItem) => void

export type Back = () => void

export type StackComponentProps<T = any> = {
  data?: T
  navigate?: Navigate
  back?: Back
}

export type StackMainComponentProps = {
  data?: any
  navigate?: Navigate
}

type OnBackAction = {
  close: () => void
}

export type StackItem = {
  title: string
  Component: React.FC<StackComponentProps>
  data?: any
  onBack?: (action?: OnBackAction) => void
}

type ContextShape = {
  navigate: Navigate
  back: Back
}

export const makeContext = (): Context<ContextShape> =>
  React.createContext({
    navigate: (item: StackItem) => {
      console.error(`You need to wrap it in StackNavigator`, item)
    },
    back: () => {
      console.error(`You need to wrap it in StackNavigator`)
    },
  })

export const useStack = (context: Context<ContextShape>) => {
  const { navigate, back } = React.useContext(context)

  return {
    navigate,
    back,
  }
}

type Props = {
  Context: Context<ContextShape>
  Component: React.FC<StackMainComponentProps>
  title?: string
  className?: string
  data?: any
}

const StackNavigation = ({ Context, Component, className, data }: Props) => {
  const [stack, setStack] = React.useState<StackItem[]>([])

  const navigate = (newScreen: any) => {
    setStack((stack) => [...stack, newScreen])
  }

  const back = (force?: boolean) => {
    // The logic is needed inside the 'setStack' fn to make sure we always get the latest 'stack'
    setStack((currentStack) => {
      if (currentStack.length < 1) {
        console.error(
          'cannot go back because there are no more items in the Stack',
        )

        return currentStack
      }

      const current = last(currentStack)

      // 'force' is present to bypass any prevention from 'onBack'
      if (!force && current?.onBack) {
        // checks if 'onBack' has args
        // if it doesn't then it's being used just as callback for the event
        // otherwise it's being used to control 'back'
        if (current?.onBack.length === 0) {
          current?.onBack()
        } else {
          current.onBack({
            close: () => back(true), // will call 'back' making sure the close action is forced
          })

          return currentStack
        }
      }

      // all good at this point, we pop the stack
      return initial(currentStack)
    })
  }

  return (
    <Context.Provider value={{ navigate, back }}>
      <TransitionGroup className={cc(['h-full', className])}>
        <Component data={data} navigate={navigate} />
        {stack.map(({ title, Component, data }, i) => (
          <CSSTransition
            key={`stack-${i}`}
            timeout={TRANSITION_DURATION}
            classNames={styles}
          >
            <div className="fixed w-full h-full top-0 left-0 z-992">
              <div
                className="absolute inset-0"
                onClick={() => {
                  back()
                }}
              />
              <Page className="absolute w-full md:max-w-md md:w-3/5 h-full top-0 right-0">
                <Page.Header
                  before={
                    <button
                      className="block p-2"
                      onClick={() => {
                        back()
                      }}
                    >
                      <IconBack width={20} />
                    </button>
                  }
                >
                  {title}
                </Page.Header>
                <Page.Content>
                  <Component data={data} navigate={navigate} back={back} />
                </Page.Content>
              </Page>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Context.Provider>
  )
}

export default StackNavigation
