import React, { createContext, useContext } from 'react'
import { Class, Class_Date_Time } from 'generated/graphql'

export type ContextProps = {
  classId: string
  dateTimeID: string
  clazz: Class
  setClazz: (newClazz: Class) => void
}

const ctx = createContext<ContextProps | null>(null)

export const Provider = ctx.Provider
export const Consumer = ctx.Consumer

export const useClassContext = () => useContext(ctx)

export function withContext(Component: React.FC<ContextProps>) {
  return () => {
    const data = useClassContext()

    if (!data) {
      return null
    } else {
      return <Component {...data} />
    }
  }
}

type WithDateTimeProps = ContextProps & {
  dateTime: Class_Date_Time
}

// eslint-disable-next-line react/display-name
export const withDateTime = (Component: React.FC<WithDateTimeProps>) => ({
  dateTimeID,
  clazz,
  ...rest
}: ContextProps) => {
  const dateTime = clazz.dates_times.find((dt) => dt.id === dateTimeID)

  if (!dateTime) {
    return null
  }

  return (
    <Component
      clazz={clazz}
      dateTimeID={dateTimeID}
      dateTime={dateTime}
      {...rest}
    />
  )
}
