import React from 'react'
import cc from 'classcat'

export interface PageHeaderProps {
  children?: React.ReactNode
  above?: React.ReactNode
  below?: React.ReactNode
  before?: React.ReactNode
  after?: React.ReactNode
}

export const Header = ({
  children,
  above,
  below,
  before,
  after,
}: PageHeaderProps) => {
  const SIDE_WIDTH = 45

  return (
    <div className="safe-area-pt">
      {above}
      {children && (
        <div className="flex justify-center items-center bg-white px-2 py-4 w-full">
          <div
            style={{
              width: SIDE_WIDTH,
              minWidth: SIDE_WIDTH,
              maxWidth: SIDE_WIDTH,
            }}
          >
            {before}
          </div>
          <div className="flex-grow heading-3 leading-none text-left py-2 text-primary">
            {children}
          </div>
          <div>{after}</div>
        </div>
      )}
      {below}
    </div>
  )
}

export const Content = ({ children }: any) => (
  <div className="relative flex flex-col flex-grow h-full overflow-y-auto smooth-scroll-y bg-gray-200">
    {children}
  </div>
)

export const Footer = ({ children }: any) => <div>{children}</div>

const Page = ({ children, className, style }: any) => (
  <div
    className={cc(['h-screen flex flex-col overflow-hidden', className])}
    style={style}
  >
    {children}
  </div>
)

Page.Header = Header
Page.Content = Content
Page.Footer = Footer

export default Page
