import React from 'react'
import ms from 'ms'
import moment from 'moment'
import Container from 'components/Container'
import FormField, { ErrorMessage, DropdownOption } from 'components/FormField'
import GenericQueryRender from 'components/GenericQueryRender'
import FormAddCard from 'forms/FormAddCard'
import Button from 'components/Button'
import CallToAction from 'components/CallToAction'
import { useAuth } from 'stores/auth.store'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'
import {
  Class,
  Class_Cost_Type_Enum,
  Class_Date_Time,
  BookClassUpdateIntentDocument,
  BookClassIntentDocument,
  BillingSourcesDocument,
  CreateClassBookOrderDocument,
} from 'generated/graphql'
import { ReactComponent as YogaPose1 } from 'assets/illustrations/crow.svg'
import { ReactComponent as YogaPose2 } from 'assets/illustrations/childs-pose.svg'
import { ReactComponent as YogaPose3 } from 'assets/illustrations/headstand.svg'
import * as yup from 'yup'
import locationToString from 'utils/locationToString'
import {
  DB_DATE_FORMAT,
  DB_TIME_FORMAT,
  GENERIC_ERROR_MESSAGE,
} from 'globalConstants'
import { fromUntil, utcDateOrDayToLocalDate } from 'utils/date'
import getPersonName from 'utils/getPersonName'
import { useModalScreen } from 'components/ModalScreen'
import { useApolloClient } from '@apollo/client'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useToast } from 'components/ToastMessage'
import useLogger from 'hooks/useLogger'
import Badge from 'components/Badge'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import useDeviceCalendar from 'hooks/useDeviceCalendar'
import { addressStringify } from 'utils/address'
import {
  getCheckoutClass,
  removeCheckoutClass,
  setCheckoutClass,
} from 'stores/checkout.store'
import { loadStripe, PaymentIntent } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import * as Validation from 'utils/formFieldValidations'
import { isWebapp } from 'utils/env'
import { navigate } from 'clients/navigation.client'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

type Tokens = {
  token: string
  cardToken: string
}

type PaymentStatus = PaymentIntent.Status

type Props = {
  data: {
    clazz: Class
    dateTime: Class_Date_Time
    classEventID: string
  }
}

const Checkout = ({ data }: Props) => {
  const modalScreen = useModalScreen()
  const loadingBlock = useLoadingBlock()
  const toast = useToast()
  const client = useApolloClient()
  const { log } = useLogger()
  const { isAuthenticated } = useAuth()
  const { createEvent } = useDeviceCalendar()
  const [result, setResult] = React.useState<{
    id?: string
    status: PaymentStatus
    message?: string
  } | null>(null)
  const { clazz, dateTime, classEventID } = data
  const bookClass = async ({
    classEventID,
    classDateTimeID,
    tokens,
    donationAmount,
    paymentMethodID,
    saveCard,
  }: {
    classDateTimeID: string
    classEventID: string
    tokens?: Tokens
    donationAmount?: number
    paymentMethodID?: string
    saveCard?: boolean
  }) => {
    loadingBlock.open({
      $size: 'md',
      subtext: 'Processing payment... Please do not leave the app.',
      cancelTimer: ms('30s'),
    })

    try {
      let checkoutClass = getCheckoutClass(classDateTimeID)

      if (!checkoutClass) {
        const newPaymentIntent = await client.mutate({
          mutation: BookClassIntentDocument,
          variables: {
            classDateTimeID,
          },
        })

        if (newPaymentIntent.errors) {
          throw new Error(newPaymentIntent.errors[0].message)
        }

        setCheckoutClass(classDateTimeID, {
          classEventID,
          intentID: newPaymentIntent.data.intentID,
          clientSecret: newPaymentIntent.data.clientSecret,
        })

        checkoutClass = getCheckoutClass(classDateTimeID)
      }

      if (donationAmount != null && clazz.price !== donationAmount) {
        const amountUpdated = await client.mutate({
          mutation: BookClassUpdateIntentDocument,
          variables: {
            object: {
              intentID: checkoutClass!.intentID,
              amount: donationAmount,
            },
          },
        })

        if (amountUpdated.errors) {
          throw new Error(amountUpdated.errors[0].message)
        }
      }

      // We first create a "book order"
      const response = await client.mutate({
        mutation: CreateClassBookOrderDocument,
        variables: {
          object: {
            class_id: clazz.id,
            class_date_time_id: classDateTimeID,
            class_event_id: classEventID,
            recorded_data: {
              from: 'buyer',
              note: 'class book order, waiting for payment',
            },
          },
        },
      })

      if (response.errors) {
        throw new Error(response.errors[0].message || GENERIC_ERROR_MESSAGE)
      }

      if (!response.data) {
        log('error', 'Class Book Order', {
          error: "null 'data' from 'CreateClassBookOrder'",
        })
        throw new Error(GENERIC_ERROR_MESSAGE)
      }

      const stripe = await stripePromise
      const confirmPaymentResponse = await stripe?.confirmCardPayment(
        checkoutClass!.clientSecret,
        {
          payment_method: tokens
            ? {
                card: {
                  token: tokens.token,
                },
              }
            : paymentMethodID,
          save_payment_method: tokens && saveCard,
          setup_future_usage: tokens && saveCard ? 'off_session' : undefined,
        },
      )

      if (!confirmPaymentResponse) {
        throw new Error(
          'Unable to process the payment. Please contact support inmediatelly.',
        )
      }

      if (confirmPaymentResponse.error) {
        throw new Error(confirmPaymentResponse.error.message)
      }

      removeCheckoutClass(classDateTimeID)
      setResult({
        status: confirmPaymentResponse.paymentIntent.status,
      })
    } catch (error) {
      log('error', 'Book Class', {
        error,
      })

      toast.notify({
        type: 'failure',
        message: error.message,
      })
    }

    loadingBlock.close()
  }

  const localDate = utcDateOrDayToLocalDate({
    date: dateTime.date,
    day: dateTime.day,
    start: dateTime.start,
  })
  const localStart = moment
    .utc(dateTime.start, DB_TIME_FORMAT)
    .local()
    .format(DB_TIME_FORMAT)
  const localEnd = moment
    .utc(dateTime.end, DB_TIME_FORMAT)
    .local()
    .format(DB_TIME_FORMAT)

  if (!isAuthenticated()) {
    return <Container topBottomSpace>Please login first</Container>
  }

  if (result) {
    switch (result.status) {
      case 'succeeded': {
        return (
          <div className="fixed top-0 right-0 bottom-0 left-0">
            <CallToAction
              Icon={YogaPose3}
              iconStyle={{ width: 'w-32', heigth: 'h-64', margin: '-mb-2' }}
              title="You are all set for your class tomorrow!"
              description="Enjoy your class! Namaste"
              ctas={[
                {
                  text: 'Add to calendar',
                  onClick: () => {
                    const event = {
                      title: `Yoga Class with ${getPersonName(
                        dateTime.teacher.person,
                      )}`,
                      location: clazz.online
                        ? 'Virtual'
                        : addressStringify(clazz.location!),
                      body: `Yoga Class "${clazz.name}" with ${getPersonName(
                        dateTime.teacher.person,
                      )} from ${fromUntil(localStart, localEnd).join(' - ')}`,
                      startDate: moment(
                        `${localDate} ${localStart}`,
                        `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
                      ).toDate(),
                      endDate: moment(
                        `${localDate} ${localEnd}`,
                        `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
                      ).toDate(),
                    }

                    createEvent(event)
                  },
                },
                {
                  text: 'Explore more classes',
                  $type: 'secondary',
                  onClick: () => {
                    if (isWebapp()) {
                      navigate('/search/classes')
                    } else {
                      modalScreen.close()
                    }
                  },
                },
              ]}
              $color="green"
            />
          </div>
        )
      }
      case 'processing': {
        return (
          <div className="fixed top-0 right-0 bottom-0 left-0">
            <CallToAction
              Icon={YogaPose1}
              iconStyle={{ width: 'w-32', heigth: 'h-32', margin: '-mb-8' }}
              title="Almost There!"
              description="We will send you a notification once your payment has been approved"
              ctas={[
                {
                  text: 'Continue',
                  onClick: () => {
                    if (isWebapp()) {
                      setResult(null)
                    } else {
                      modalScreen.close()
                    }
                  },
                },
              ]}
              $color="purple"
            />
          </div>
        )
      }
      default: {
        return (
          <div className="fixed top-0 right-0 bottom-0 left-0">
            <CallToAction
              Icon={YogaPose2}
              iconStyle={{ width: 'w-48', heigth: 'h-32', margin: '-mb-12' }}
              title={
                <>
                  Oops! Lets take a moment...
                  <br />
                  {result.message}
                  <br />
                </>
              }
              description="Please revise your card infromation"
              ctas={[
                {
                  text: 'Try again',
                  onClick: () => {
                    setResult(null)
                  },
                },
              ]}
              $color="red"
            />
          </div>
        )
      }
    }
  }

  return (
    <>
      <Container topBottomSpace className="mb-4">
        {/* Studio */}
        <div
          style={{
            WebkitMaskPosition: '',
          }}
        >
          {/* Where */}
          <div className="mb-6">
            {!clazz.online && (
              <p className="text-gray-600">{clazz.location!.name}</p>
            )}
            {clazz.online ? (
              <Badge>Virtual</Badge>
            ) : (
              <p className="body-2">{locationToString(clazz.location!)}</p>
            )}
          </div>

          {/* Who & When */}
          <div>
            <p className="heading-2 text-primary">
              {clazz.name} - {getPersonName(dateTime.teacher.person)}
            </p>
            <p className="body-2">
              {moment(localStart, DB_TIME_FORMAT).format('hh:mm')} -{' '}
              {moment(localEnd, DB_TIME_FORMAT).format('hh:mm A')}
            </p>
            <p className="body-2">{localDate}</p>
          </div>
        </div>
      </Container>

      <GenericQueryRender
        query={BillingSourcesDocument}
        variables={{
          withCards: true,
          withBankAccounts: false,
        }}
        fetchPolicy="network-only"
        dataAccess="billingSources"
        Success={(props) => (
          <>
            <Form
              data={{
                ...data,
                ...props.data,
              }}
              onSuccess={({
                donationAmount,
                tokens,
                paymentMethodID,
                saveCard,
              }) => {
                bookClass({
                  classEventID,
                  tokens,
                  donationAmount,
                  paymentMethodID,
                  classDateTimeID: dateTime.id,
                  saveCard,
                })
              }}
            />
          </>
        )}
      />
    </>
  )
}

export default Checkout

type FormProps = {
  data: {
    cards: Array<any>
    clazz: Class
  }
  onSuccess: (values: {
    donationAmount?: number
    tokens?: Tokens
    paymentMethodID?: string
    saveCard?: boolean
  }) => void
}

const Form = ({ data, onSuccess }: FormProps) => {
  const [loading, setLoading] = React.useState(false)
  const { log } = useLogger()
  const { cards, clazz } = data
  const hasStoredPaymentMethods = cards.length > 0
  const validationSchema = yup.object().shape({
    donationAmount:
      clazz.costType === Class_Cost_Type_Enum.Donation
        ? yup
            .number()
            .required()
            .min(clazz.price || 0)
        : yup.number().optional(),
    storedPaymentMethod: hasStoredPaymentMethods
      ? yup.string().required()
      : yup.string().optional(),
    termsAndConditions: Validation.termsAndConditions,
  })
  const { handleSubmit, register, errors, watch, control, formState } = useForm(
    {
      defaultValues: {
        donationAmount: clazz.price || '',
        storedPaymentMethod: '',
        saveCard: false,
        termsAndConditions: false,
      },
      resolver: yupResolver(validationSchema),
    },
  )
  const paymentOption = watch('storedPaymentMethod')
  const donationAmount = watch('donationAmount')

  const submitCheckout = (tokens: Tokens | null) => async (values: any) => {
    setLoading(true)
    try {
      await onSuccess({
        donationAmount: values.donationAmount,
        tokens: tokens || undefined,
        paymentMethodID: values.storedPaymentMethod,
        saveCard: values.saveCard,
      })
    } catch (error) {
      log('error', 'Checkout Form Submit', {
        error,
      })
    }
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitCheckout(null))} id="form-checkout">
        {clazz.costType === Class_Cost_Type_Enum.Donation ? (
          // Donation
          <div className="w-full h-auto bg-nude">
            <Container topBottomSpace>
              <div className="flex items-center">
                <p className="heading-3 text-primary w-full">
                  How much would you <br />
                  like to donate for <br />
                  this class?
                </p>

                <div className="w-10/12 pl-2">
                  <FormField
                    type="number"
                    name="donationAmount"
                    register={register}
                    prefix="$"
                  />
                  <p className="mt-1 w-full text-primary text-center heading-4">
                    ${clazz.price} minimum donation
                  </p>
                </div>
              </div>
              {errors.donationAmount?.message && (
                <ErrorMessage>{errors.donationAmount?.message}</ErrorMessage>
              )}
            </Container>
          </div>
        ) : (
          // Price
          <div className="w-full bg-nude">
            <Container topBottomSpace>
              <div className="flex space-x-4 items-center text-primary">
                <p className="heading-3">Class Price</p>
                <p className="number-1">${clazz.price}</p>
              </div>
            </Container>
          </div>
        )}

        <Container>
          {/* SELECT PAYMENT METHOD */}
          {hasStoredPaymentMethods && (
            <div className="py-2 mt-6">
              <FormField
                type="dropdown"
                name="storedPaymentMethod"
                label="Choose your Payment Method"
                control={control}
                error={errors.storedPaymentMethod?.message}
                $rootClassName="shadow-none"
                placeholder="Select Payment Method"
              >
                {cards.map(({ id, brand, last4 }) => (
                  <DropdownOption key={`payment-method-${id}`} value={id}>
                    {`${brand} ${last4}`}
                  </DropdownOption>
                ))}
                <option value="new">New Payment Method</option>
              </FormField>
            </div>
          )}
        </Container>
      </form>

      <Container topBottomSpace>
        {(paymentOption === 'new' || !hasStoredPaymentMethods) && (
          <Elements stripe={stripePromise}>
            <FormAddCard
              onSubmit={async (_paymentMethod, token, cardToken) => {
                await handleSubmit(
                  submitCheckout({
                    token,
                    cardToken,
                  }),
                )()
              }}
              hideButton
            />

            {/* <div className="flex justify-between pt-6 items-center">
              <p
                className="font-regular text-sm"
                style={{ letterSpacing: 0.5 }}
              >
                Save credit card for future
              </p>
              <Switch name="saveCard" control={control} />
            </div> */}
          </Elements>
        )}

        <TermsAndConditionsField control={control} name="termsAndConditions" />

        {(!!paymentOption || !hasStoredPaymentMethods) && (
          <div className="pt-6">
            <Button
              type="submit"
              $type="primary"
              $fluid
              loading={loading || formState.isSubmitting}
              form={
                paymentOption === 'new' || !hasStoredPaymentMethods
                  ? 'form-add-payment-method'
                  : 'form-checkout'
              }
            >
              Book {`&`} Pay $
              {clazz.costType === Class_Cost_Type_Enum.Donation
                ? donationAmount
                : clazz.price}
            </Button>
          </div>
        )}
      </Container>

      <SpaceForKeyboard />
    </>
  )
}
