import Container from 'components/Container'
import { withContext, withDateTime } from './classContext'
import getPersonName from 'utils/getPersonName'
import ReviewsList from 'components/ReviewsList'
import PicturesCarousel from './PicturesCarousel'
import ClassTabs from './Tabs'
import {
  TeacherInfoByPkDocument,
  TeacherInfoByPkQuery,
} from 'generated/graphql'
import { useQuery } from 'urql'
// import Button from 'components/Button'

const TeacherInfo = withContext(
  withDateTime(({ dateTime }) => {
    const [{ data, fetching }] = useQuery<TeacherInfoByPkQuery>({
      query: TeacherInfoByPkDocument,
      variables: {
        teacherID: dateTime.teacher.id,
      },
    })

    return (
      <>
        {/* Header */}
        <PicturesCarousel
          pictures={[data?.teacher_by_pk?.cover_picture]
            .filter(Boolean)
            .map((url) => ({
              id: 'teacher-cover-picture',
              url: url!,
            }))}
          loading={fetching}
        />
        <ClassTabs />

        {/* Body */}
        <Container topBottomSpace className="flex-grow">
          <div className="flex-col space-y-2">
            <div className="heading-1 text-primary">
              About {getPersonName(dateTime.teacher.person)}
            </div>

            <div className="pb-2">{data?.teacher_by_pk?.bio}</div>

            {/* {data?.teacher_by_pk?.account_id && (
              <Button $size="sm" to={`/${data.teacher_by_pk.account_id}`}>
                Learn more
              </Button>
            )} */}
          </div>

          <div className="heading-2 text-primary mt-8 mb-3">
            Teacher&apos;s Reviews
          </div>
          <ReviewsList
            type="teacher"
            id={dateTime.teacher.id}
            nothingType="just-text"
          />
        </Container>
      </>
    )
  }),
)

export default TeacherInfo
