import cc from 'classcat'
import useDebugger from 'hooks/useDebugger'
import useKeyboardListener from 'hooks/useKeyboardListener'

type Props = {
  $size: 'xs' | 'sm' | 'md' | 'lg'
  autoControlled?: boolean
}

const SpaceForKeyboardElement = ({ $size }: Props) => {
  const debug = useDebugger()

  return (
    <div
      className={cc([
        'flex justify-center items-center',
        {
          'space-for-keyboard-xs': $size === 'xs',
          'space-for-keyboard-sm': $size === 'sm',
          'space-for-keyboard-md': $size === 'md',
          'space-for-keyboard-lg': $size === 'lg',
          'border border-dashed border-red-500': debug,
        },
      ])}
      children={debug ? 'Space For Keyboard' : null}
    />
  )
}

const SpaceForKeyboardControlled = (props: Props) => {
  const debug = useDebugger()
  const isKeyboardOnScreen = useKeyboardListener()

  if (!isKeyboardOnScreen && !debug) {
    return null
  }

  return <SpaceForKeyboardElement {...props} />
}

const SpaceForKeyboard = (props: Props) => {
  if (props.autoControlled) {
    return <SpaceForKeyboardControlled {...props} />
  } else {
    return <SpaceForKeyboardElement {...props} />
  }
}

SpaceForKeyboard.defaultProps = {
  $size: 'md',
  autoControlled: true,
}

export default SpaceForKeyboard
