import React, { ChangeEvent, KeyboardEvent } from 'react'
import useIsMounted from 'hooks/useIsMounted'
import minTwoDigitsNumber from 'utils/minTwoDigitsNumber'
import Countdown from 'react-countdown'
import Button from 'components/Button'
import ServerErrorMessage from 'components/ServerErrorMessage'
import OhmVerificationCode from '@ohmunity/verification-code'
import FormField from 'components/FormField'
import { MergedStates } from 'utils/stateUnion'

/*
<SpaceForKeyboard /> not needed in this file
*/

type Props = {
  sentTo: string
  onSubmit: (code: string) => void
  state: MergedStates
  expiresIn?: number
  onExpired?: () => void
  onResend?: () => void
  $hasLargeFields?: boolean
}

const VerificationCode = ({
  sentTo,
  expiresIn,
  onExpired,
  onSubmit,
  onResend,
  state,
}: Props) => {
  const isMounted = useIsMounted()
  const [showResendButton, setShowResendButton] = React.useState(false)
  const timer = React.createRef<number>()

  React.useEffect(() => {
    if (!showResendButton) {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      ;(timer.current as any) = setTimeout(() => {
        if (isMounted.current) {
          setShowResendButton(true)
        }
      }, 1000 * 5)
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [showResendButton])

  return (
    <>
      <p className="text-center pb-2">
        We have sent you a 6 digit verification code to{' '}
        <strong>{sentTo}</strong>
        <br />
        <br />
        Enter your code here
      </p>

      <div className="py-4">
        <div className="py-2">
          <OhmVerificationCode
            type="number"
            total={6}
            onComplete={(code) => {
              onSubmit(code)
            }}
            placeholder="X"
            disabled={state.loading}
            clearAfterComplete
            $className="grid gap-2 grid-cols-6"
            Component={React.forwardRef(
              ({ onChange, onFocus, onKeyDown, ...props }, ref) => (
                <FormField
                  $inputClassName="text-center"
                  register={ref}
                  {...props}
                  onChange={(_v, e) =>
                    onChange(e as ChangeEvent<HTMLInputElement>)
                  }
                  onFocus={(_v, e) =>
                    onFocus(e as ChangeEvent<HTMLInputElement>)
                  }
                  onKeyDown={(e) =>
                    onKeyDown(e as KeyboardEvent<HTMLInputElement>)
                  }
                />
              ),
            )}
          />
        </div>

        {state.errors.length > 0 && (
          <ServerErrorMessage>{state.errors[0].message}</ServerErrorMessage>
        )}

        {expiresIn && (
          <Countdown
            date={expiresIn}
            renderer={({ minutes, seconds }) => (
              <div className="text-center body-2 pt-1 text-gray-600">
                code expires in {minTwoDigitsNumber(minutes, 0)}:
                {minTwoDigitsNumber(seconds, 0)}
              </div>
            )}
            onComplete={() => {
              if (onExpired) {
                onExpired()
              }
            }}
          />
        )}
      </div>

      {sentTo.includes('@') && (
        <div className="mt-2 bg-white text-yellow-700 text-center p-2 animate-pulse">
          Can't find it? Please check your Spam folder.
        </div>
      )}

      <div className="pt-8">
        {showResendButton && onResend && (
          <Button
            type="button"
            $type="tertiary"
            $fluid
            disabled={state.loading}
            onClick={() => {
              setShowResendButton(false)
              onResend()
            }}
          >
            Resend code
          </Button>
        )}
        {/* Fake space to avoid viewpoint jump after the Resend button shows up */}
        {onResend && !showResendButton && <div style={{ height: 52 }} />}
      </div>
    </>
  )
}

export default VerificationCode
