import React from 'react'
import RoundedPicture from 'components/RoundedPicture'
import getPersonName from 'utils/getPersonName'
import getPicture from 'utils/getPicture'
import { Profile_Person } from 'generated/graphql'

type Props = {
  teacherID: string
  person: Profile_Person
}

const TeacherCard = ({ person }: Props) => {
  return (
    <div className="flex items-center p-2 w-full bg-white">
      <div>
        <RoundedPicture image={getPicture(person.picture)} size={90} />
      </div>
      <div>
        <div className="pl-4 text-primary heading-3">
          {getPersonName(person)}
        </div>
        {/* <div className="pl-4 body-2">
          {'Restorative, Hatha Vinyasa, Meditation Vinyasa, Kundalini'}
        </div> */}
      </div>
    </div>
  )
}

export default TeacherCard
