import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'

import CirlceCheckboxes from 'components/CircleCheckboxes'
import { useForm } from 'react-hook-form'
import { Class_Level_Enum } from 'generated/graphql'
import { useFormCarousel } from 'forms/FormCarousel/context'

const Levels = () => {
  const { next, initialValues } = useFormCarousel()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      levels: initialValues?.levels || '',
    },
  })

  return (
    <Container topBottomSpace className="flex flex-col h-full">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">Choose your level</div>
      </div>

      <form
        onSubmit={handleSubmit((values) => {
          next(values)
        })}
        className="flex flex-col flex-grow justify-center"
        id="form-levels"
      >
        <CirlceCheckboxes
          control={control}
          name="levels"
          grid={{
            colums: 1,
            gap: 3,
          }}
          options={[
            Class_Level_Enum.Beginner,
            Class_Level_Enum.Intermediate,
            Class_Level_Enum.Advanced,
          ]}
          $textSize="md"
        />
      </form>

      <Button $fluid form="form-levels">
        Next
      </Button>
    </Container>
  )
}

export default Levels
