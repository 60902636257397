import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import Button from 'components/Button'
import Container from 'components/Container'
import PhoneNumberField from 'formFields/PhoneNumberField'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import FormField, { FormGroup } from 'components/FormField'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'
import { FormProvider, useForm } from 'react-hook-form'
import { useApolloClient } from '@apollo/client'

const Info = () => {
  const client = useApolloClient()
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const validationSchema = yup.object().shape({
    name: Validation.entityName,
    email: Validation.uniqueEmail(client),
    phoneNumber: Validation.uniquePhoneNumber(client),
    bio: Validation.longText,
    termsAndConditions: yup
      .boolean()
      .required()
      .test(
        'isChecked',
        'You need to read and agree on our Terms and Conditions.',
        (value) => value === true,
      ),
  })
  const formMethods = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      bio: '',
      termsAndConditions: false,
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, register, errors, control, formState } = formMethods
  const { isSubmitting } = formState

  return (
    <FormProvider {...formMethods}>
      <Container
        topBottomSpace
        className="flex flex-col min-h-full justify-between"
      >
        <div className="h-14 text-center">
          <div className="heading-1 text-primary">
            Tell us about your studio
          </div>
        </div>

        <form
          onSubmit={handleSubmit((values) => {
            next(values)
          })}
        >
          <FormGroup>
            <FormField
              type="text"
              name="name"
              label="Studio name *"
              register={register}
              error={errors?.name?.message}
            />

            <FormField
              type="text"
              name="email"
              label="Email *"
              register={register}
              error={errors?.email?.message}
            />

            <PhoneNumberField
              name="phoneNumber"
              label="Phone Number"
              placeholder="XXX XXX XXXX"
              control={control}
            />

            <FormTextWithCounter
              name="bio"
              label="Write an introduction for your studio *"
              $inputClassName="h-56"
            />

            <TermsAndConditionsField
              name="termsAndConditions"
              control={control}
            />
          </FormGroup>

          <div className="pt-4">
            <Button $fluid type="submit" loading={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
      </Container>

      {isKeyboardOnScreen && (
        <SpaceForKeyboard $size="lg" autoControlled={false} />
      )}
    </FormProvider>
  )
}

export default Info
