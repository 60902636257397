import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import Button from 'components/Button'
import Container from 'components/Container'
import FormField from 'components/FormField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'
import { useApolloClient } from '@apollo/client'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

const Email = () => {
  const client = useApolloClient()
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const validationSchema = yup.object().shape({
    email: Validation.uniqueEmail(client),
    termsAndConditions: Validation.termsAndConditions,
  })
  const { handleSubmit, register, errors, control, formState } = useForm({
    defaultValues: {
      email: '',
      termsAndConditions: false,
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { dirtyFields, isValid } = formState

  const canContinue = dirtyFields.email || isValid

  return (
    <>
      <Container
        topBottomSpace
        className="flex flex-col h-full justify-between"
      >
        <div className="h-14 text-center">
          <div className="heading-1 text-primary">What is your email?</div>
        </div>

        <form
          onSubmit={handleSubmit((values) => {
            next(values)
          })}
        >
          {/* Email */}
          <div className="py-1">
            <FormField
              type="email"
              name="email"
              placeholder="Email *"
              register={register}
              error={errors?.email?.message}
              isBig
            />
          </div>

          <TermsAndConditionsField
            name="termsAndConditions"
            control={control}
          />

          <div className="pt-4">
            <Button $fluid disabled={!canContinue}>
              Next
            </Button>
          </div>

          {isKeyboardOnScreen && (
            <SpaceForKeyboard $size="lg" autoControlled={false} />
          )}
        </form>
      </Container>
    </>
  )
}

export default Email
