import yogaStudioPlaceholder from 'assets/placeholders/yoga-studio.jpg'
import { Carousel } from 'react-responsive-carousel'
import BackgroundImage from 'components/BackgroundImage'

type Props = {
  pictures: {
    id: string
    url: string
  }[]
  loading?: boolean
  $size: 'sm' | 'md'
  $showArrows: boolean
  $autoPlay: boolean
}

const PicturesCarousel = ({
  pictures,
  loading,
  $size,
  $showArrows,
  $autoPlay,
}: Props) => {
  const HEIGHT = $size === 'md' ? 300 : 200
  return (
    <div className="bg-white">
      {loading ? (
        <div
          className="bg-gray-400 animate-pulse"
          style={{
            height: HEIGHT,
          }}
        />
      ) : pictures.length > 1 ? (
        <Carousel
          showArrows={$showArrows}
          showThumbs={false}
          showStatus={false}
          autoPlay={$autoPlay}
        >
          {pictures.map((picture) => (
            <BackgroundImage
              key={picture.id}
              className="w-full"
              image={picture.url}
              height={HEIGHT}
            />
          ))}
        </Carousel>
      ) : (
        <BackgroundImage
          className="w-full"
          image={pictures.length > 0 ? pictures[0].url : yogaStudioPlaceholder}
          height={HEIGHT}
        />
      )}
    </div>
  )
}

PicturesCarousel.defaultProps = {
  $size: 'sm',
  $showArrows: false,
  $autoPlay: false,
}

export default PicturesCarousel
