import React from 'react'

const CardDots = () => (
  <div
    className="inline-block pl-2 pr-1"
    style={{
      lineHeight: 0.2,
      height: 5,
    }}
  >
    ····
  </div>
)

export default CardDots
