import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

const trackClassTime = (
  start_timestamp: number | string,
  end_timestamp: number | string,
): string | null => {
  const hasEnded = dayjs().isAfter(end_timestamp)

  if (hasEnded) {
    return `Class ended`
  }

  const hasStarted = dayjs().isBetween(start_timestamp, end_timestamp)

  if (hasStarted) {
    const diff = dayjs().diff(start_timestamp, 'minutes')
    return diff > 0
      ? `Class started ${diff} ${diff > 1 ? 'minutes' : 'minute'} ago`
      : 'Class just started'
  }

  const minutesToStart = dayjs(start_timestamp).diff(dayjs(), 'minutes')

  if (minutesToStart <= 15) {
    return `Class starts in ${minutesToStart} ${
      minutesToStart > 1 ? 'minutes' : 'minute'
    }`
  }

  return null
}

export default trackClassTime
