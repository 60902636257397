import React from 'react'
import get from 'lodash/fp/get'
import GenericLoading from 'components/Loading'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import {
  useQuery,
  DocumentNode,
  useSubscription,
  ObservableQueryFields,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { VariableMap } from '@apollo/client/core/LocalState'

type AdditionalProps = {
  [K: string]: any
}

type Props<D = any, E = any> = {
  context?: string
  dataAccess?: string
  additionalProps?: AdditionalProps
  Loading?: React.FC
  NoResults?: React.FC<any> // TODO: Automatically inject 'additionalProps' type
  Success: React.FC<{
    data: D
    fetchMore?: ObservableQueryFields<D, any>['fetchMore']
  }> // TODO: Dynamically add AdditionalProps
  Failure?: React.FC<{ error: E }>
}

type RenderProps = Props & {
  loading: boolean
  error?: any
  data?: any
  fetchMore?: ObservableQueryFields<any, any>['fetchMore']
}

const Render = ({
  loading,
  error,
  data,
  context,
  dataAccess,
  additionalProps,
  Loading,
  Failure,
  NoResults,
  Success,
  fetchMore,
}: RenderProps) => {
  if (loading) {
    if (Loading) {
      return <Loading />
    } else {
      return (
        <div className="flex items-center justify-center min-h-full">
          <GenericLoading context={context} />
        </div>
      )
    }
  }

  if (error) {
    return Failure ? (
      <Failure error={error} />
    ) : (
      <div>
        {console.error(error)}
        {error?.message || GENERIC_ERROR_MESSAGE}
      </div>
    )
  }

  const dataToUse = dataAccess ? get(dataAccess, data) : data

  if (NoResults && dataToUse?.length < 1) {
    return <NoResults {...additionalProps} />
  }

  return <Success data={dataToUse} fetchMore={fetchMore} {...additionalProps} />
}

type QueryProps = {
  query: DocumentNode
  variables?: VariableMap
  fetchPolicy?: WatchQueryFetchPolicy
} & Props

export function GenericQueryRender({
  query,
  variables,
  fetchPolicy,
  ...rest
}: QueryProps) {
  const result = useQuery(query, { variables, fetchPolicy })

  return <Render {...result} {...rest} />
}

type SubscriptionProps = Props & {
  subscription: DocumentNode
  variables?: VariableMap
}

export const GenericSubscriptionRender = ({
  subscription,
  variables,
  ...rest
}: SubscriptionProps) => {
  const result = useSubscription(subscription, { variables })

  return <Render {...result} {...rest} />
}

export default GenericQueryRender
