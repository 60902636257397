import React from 'react'
import { useAuth } from 'stores/auth.store'

export type Mode = 'unknown' | 'practice' | 'teach'

type ChangeMode = (newMode: Mode) => void

const storedMode = (): Mode => {
  const acceptable = ['practice', 'teach']
  const stored = localStorage.getItem('mode')

  return stored && acceptable.includes(stored) ? (stored as Mode) : 'unknown'
}

const Context = React.createContext<[Mode, ChangeMode]>([
  'unknown',
  () => undefined,
])

const useMode = () => React.useContext(Context)

const applyTheme = (mode: Mode, isTeacher: boolean) => {
  document.querySelector('body')?.classList.remove('theme-teacher')

  if (isTeacher && mode === 'teach') {
    document.querySelector('body')?.classList.add('theme-teacher')
  }

  localStorage.setItem('mode', mode)
}

const getDefaultMode = (isTeacher: boolean) => {
  const prevMode = storedMode()

  if (isTeacher) {
    return prevMode === 'unknown' ? 'teach' : prevMode
  } else {
    return prevMode === 'unknown' ? prevMode : 'unknown'
  }
}

export const ModeProvider = ({ children }: any) => {
  const { account, isTeacher } = useAuth()
  const [mode, setMode] = React.useState<Mode>(getDefaultMode(isTeacher()))

  React.useEffect(() => {
    applyTheme(mode, isTeacher())
  }, [mode])

  React.useEffect(() => {
    const teacher = isTeacher()
    const fixMode = getDefaultMode(teacher)

    if (mode !== fixMode) {
      setMode(fixMode)
    }
  }, [account.type])

  return <Context.Provider value={[mode, setMode]}>{children}</Context.Provider>
}

export default useMode
