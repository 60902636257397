import React from 'react'
import cc from 'classcat'
import locationToString from 'utils/locationToString'
import Button from 'components/Button'
import FormField, {
  Label,
  ErrorMessage,
  DropdownOption,
} from 'components/FormField'
import Container from 'components/Container'
import Switch from 'components/Switch'
import InputSlider from 'components/InputSlider'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Location, Yoga_Type, Class_Cost_Type_Enum } from 'generated/graphql'
import * as yup from 'yup'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import * as Validation from 'utils/formFieldValidations'
import { useFormCarousel } from 'forms/FormCarousel'
import FormLocation from 'forms/FormLocation'
import { useModalScreen, withModalScreen } from 'components/ModalScreen'
import { useApolloClient } from '@apollo/client'
import { QUERY_CREATE_CLASS_REQUIRED_DATA } from 'graphql/queries'
import { TRANSITION_DURATION } from 'components/StackNavigation'
import MultiPictureUpload, {
  MultiPictureUploadContext,
} from 'components/MultiPictureUpload'
import { FEE_SERVICE_IN_PERCENTAGE } from 'globalConstants'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { useAuth } from 'stores/auth.store'
import { useManageClassContext } from './context'
import { useAlert } from 'components/AlertModal'
import SafetyGuidelinesList from 'components/SafetyGuidelinesList'

const validationSchema = yup.object().shape({
  online: yup.bool().optional(),
  location_id: yup.string().optional(),
  yogaType: yup.string().required('This field is required'),
  name: Validation.className,
  description: Validation.longText,
  price: yup.string().when('costType', {
    is: Class_Cost_Type_Enum.Price,
    then: (s: any) => s.required(),
    otherwise: (s: any) => s.optional(),
  }),
  level: yup.string().required('This field is required'),
  setting: yup.string().when('online', {
    is: (value: boolean) => !!value,
    then: yup.string().optional(),
    otherwise: yup.string().required('This field is required'),
  }),
  maxCapacity: yup
    .number()
    .transform((value) => {
      if (isNaN(value)) {
        return 0
      }
      return value
    })
    .required('Max Capacity is required')
    .min(1)
    .max(100),
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const General = () => {
  const { account } = useAuth()
  const modal = useModalScreen()
  const client = useApolloClient()
  const alert = useAlert()
  const { next, initialValues } = useFormCarousel()
  const { options } = useManageClassContext()
  const formMethods = useForm({
    defaultValues: {
      online: false,
      location_id: '',
      name: '',
      description: '',
      costType: '',
      yogaType: '',
      price: '',
      level: '',
      setting: '',
      maxCapacity: '',
      safetyGuidelines: [],
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    errors,
    formState,
  } = formMethods
  const watchOnline = watch('online')
  const watchCostType = watch('costType')
  const [disableButton, setDisableButton] = React.useState<boolean>(false)
  const { isSubmitted } = formState

  return (
    <FormProvider {...formMethods}>
      <Container topBottomSpace>
        <div className="flex justify-between items-center mb-3">
          <div style={{ letterSpacing: 0.5 }}>This is a virtual class</div>
          <Switch name="online" control={control} data-testid="input-online" />
        </div>

        <form
          onSubmit={handleSubmit((values) => {
            next(values)
          })}
        >
          {/* LOCATION */}
          <div
            className={cc([
              'py-2',
              {
                hidden: watchOnline,
              },
            ])}
          >
            <FormField
              type="dropdown"
              name="location_id"
              control={control}
              label="Location"
              error={errors.location_id?.message}
              placeholder="Select a location"
              data-testid="input-location"
              onChange={(value) => {
                if (value === 'add') {
                  setTimeout(() => {
                    setValue('location_id', '')
                  }, TRANSITION_DURATION)

                  modal.open({
                    header: 'Add Location',
                    body: (
                      <Container topBottomSpace>
                        <FormLocation
                          onSubmit={(newLocation) => {
                            const queried = client.readQuery({
                              query: QUERY_CREATE_CLASS_REQUIRED_DATA,
                              variables: {
                                accountID: account.id,
                              },
                            })

                            client.writeQuery({
                              query: QUERY_CREATE_CLASS_REQUIRED_DATA,
                              data: {
                                ...queried,
                                location: [...queried?.location, newLocation],
                              },
                            })

                            // TODO: 'setValue' does not work because 'writeQuery' re-renders the component
                            setValue('location_id', newLocation.id)
                          }}
                        />
                      </Container>
                    ),
                  })
                }
              }}
            >
              {options!.locations.length &&
                options!.locations.map((location) => (
                  <DropdownOption
                    key={`location-${location.id}`}
                    value={location.id}
                  >
                    {`${location.name} - ${locationToString(
                      location as Location,
                    )}`}
                  </DropdownOption>
                ))}
              <DropdownOption value="add">Add Location</DropdownOption>
            </FormField>
          </div>

          {/* YOGA TYPE */}
          <div className="py-2">
            <FormField
              type="dropdown"
              name="yogaType"
              control={control}
              label="Yoga Type"
              error={errors.yogaType?.message}
              placeholder="Select a type"
              data-testid="input-yoga-type"
            >
              {options!.yogaTypes.length ? (
                options!.yogaTypes.map((yogaType: Yoga_Type) => (
                  <DropdownOption
                    key={`yogaType-${yogaType.value}`}
                    value={yogaType.value}
                  >
                    {yogaType.value}
                  </DropdownOption>
                ))
              ) : (
                <DropdownOption>No yoga types found</DropdownOption>
              )}
            </FormField>
          </div>

          {/* LEVEL */}
          <div className="py-2">
            <FormField
              type="dropdown"
              name="level"
              control={control}
              label="Level"
              error={errors.level?.message}
              placeholder="Select a level"
              data-testid="input-level"
            >
              {options!.levels.length &&
                options!.levels.map((level) => (
                  <DropdownOption
                    key={`level-${level.value}`}
                    value={level.value}
                  >
                    {level.value}
                  </DropdownOption>
                ))}
            </FormField>
          </div>

          {/* Setting */}
          <div
            className={cc([
              'py-2',
              {
                hidden: watchOnline,
              },
            ])}
          >
            <FormField
              type="dropdown"
              name="setting"
              control={control}
              label="Setting"
              error={errors.setting?.message}
              placeholder="Select a setting"
              data-testid="input-setting"
            >
              {options!.settings.length &&
                options!.settings.map((setting) => (
                  <DropdownOption
                    key={`setting-${setting.value}`}
                    value={setting.value}
                  >
                    {setting.value}
                  </DropdownOption>
                ))}
            </FormField>
          </div>

          {/* NAME */}
          <div className="py-2">
            <FormField
              type="text"
              name="name"
              register={register}
              label="Class Name"
              error={errors.name?.message}
              data-testid="input-class-name"
            />
          </div>

          {/* DESCRIPTION */}
          <div className="py-2">
            <FormTextWithCounter
              name="description"
              label="Description"
              $inputClassName="h-56"
              data-testid="input-description"
            />
          </div>

          {/* MAX CAPACITY */}
          <div className="py-2">
            <FormField
              type="number"
              name="maxCapacity"
              register={register}
              label="Max Capacity"
              error={errors.maxCapacity?.message}
              data-testid="input-max-capacity"
            />
          </div>

          {/* PRICE */}
          <div className="py-2">
            <Label>Price</Label>
            <div
              className={cc([
                'flex',
                {
                  'border border-red-500': errors.costType,
                },
              ])}
            >
              <FormField
                type="radio"
                id="costByPrice"
                name="costType"
                label="By Price"
                value={Class_Cost_Type_Enum.Price}
                register={register}
                data-testid="input-by-price"
                onChange={() => {
                  setValue('price', '')
                }}
              />
              <div className="pl-3">
                <FormField
                  type="radio"
                  id="costByDonation"
                  name="costType"
                  label="By Donation"
                  value={Class_Cost_Type_Enum.Donation}
                  register={register}
                  data-testid="input-by-donation"
                  onChange={() => {
                    setValue('price', '')
                  }}
                />
              </div>
            </div>
            {errors.costType && (
              <ErrorMessage>{errors.costType?.message}</ErrorMessage>
            )}
          </div>

          {watchCostType === Class_Cost_Type_Enum.Price && (
            <div className="py-2">
              <FormField
                type="number"
                name="price"
                placeholder="Amount"
                register={register}
                error={errors.price?.message}
                prefix="$"
                data-testid="input-amount"
              />
            </div>
          )}

          {watchCostType === Class_Cost_Type_Enum.Donation && (
            <div className="py-2">
              <Label>Minimum donation amount</Label>
              <div className="px-1.5 pb-4">
                <InputSlider
                  min={1}
                  max={20}
                  name="price"
                  control={control}
                  prefix="$"
                  data-testid="input-donation-amount"
                />
              </div>
              {errors.price && (
                <ErrorMessage>{errors.price?.message}</ErrorMessage>
              )}
            </div>
          )}

          {/* PICTURE */}
          <div className="py-4">
            <Label>Class Photo</Label>
            <input type="hidden" name="picture" ref={register} />
            <MultiPictureUploadContext
              limit={1}
              initialPictures={
                initialValues?.picture ? [initialValues.picture] : undefined
              }
              onChange={(change) => {
                setDisableButton(change)
              }}
            >
              <MultiPictureUpload
                onDone={(value) => {
                  formMethods.setValue('picture', value.map((x) => x.url)[0])
                  setDisableButton(false)
                }}
              />
            </MultiPictureUploadContext>
          </div>

          {/* SAFETY GUIDELINES */}
          {options?.safetyGuidelines && options.safetyGuidelines.length > 0 && (
            <div
              className={cc([
                'py-2',
                {
                  hidden: watchOnline,
                },
              ])}
            >
              <Label>Safety Guidelines</Label>
              <ul>
                {options.safetyGuidelines.map(({ id, label }) => (
                  <li key={`safety-guideline_${id}`}>
                    <FormField
                      type="checkbox"
                      name="safetyGuidelines"
                      value={id}
                      label={label}
                      id={`safety-guideline_${id}`}
                      register={register}
                      data-testid={`input-safety-guideline-${id}`}
                    />
                  </li>
                ))}
              </ul>
              <button
                type="button"
                className="body-3 py-4 pr-4 text-gray-700"
                data-testid="button-safety-guideline-read-more"
                onClick={() => {
                  alert.send({
                    $type: 'info',
                    title: 'Safety Guidelines',
                    body: (
                      <div className="space-y-2">
                        <p className="pb-8">Predefined safety guidelines</p>
                        <div className="text-left">
                          <SafetyGuidelinesList
                            list={options.safetyGuidelines}
                          />
                        </div>
                      </div>
                    ),
                    buttons: [
                      {
                        children: 'Close',
                      },
                    ],
                  })
                }}
              >
                Learn More
              </button>
            </div>
          )}

          {/* TODO: Not sure why 'formState.isValid' is false the first time we submit the form even when it's actually valid */}
          {isSubmitted && Object.keys(errors).length > 0 && (
            <div className="py-3">
              <ServerErrorMessage>
                The form has some errors, please check.
              </ServerErrorMessage>
            </div>
          )}

          <div className="py-5">
            <Button
              type="submit"
              $fluid
              disabled={disableButton}
              data-testid="button-continue"
            >
              Continue
            </Button>
          </div>

          <p
            className="text-center text-gray-700 pt-1"
            style={{
              fontSize: '0.8em',
            }}
          >
            Ohmunity™ charges a {FEE_SERVICE_IN_PERCENTAGE}% on the price of
            your class per student.
          </p>

          <SpaceForKeyboard />
        </form>
      </Container>
    </FormProvider>
  )
}

export default React.memo(withModalScreen<{}>(General))
