import Container from 'components/Container'
import { FC } from 'react'
import { isWebapp } from 'utils/env'
import { IonIcon } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'

const WebBackLayout = ({ children }: any) => {
  console.log('--window.history', window.history)

  return (
    <>
      {window.history.length > 0 && (
        <Container topBottomSpace>
          <button
            className="flex items-center p-3 transform hover:-translate-x-1 transition"
            onClick={() => {
              window.history.back()
            }}
          >
            <IonIcon icon={arrowBackOutline} />
            <span className="pl-2">Back</span>
          </button>
        </Container>
      )}
      {children}
    </>
  )
}

export default WebBackLayout

export function WithWebBackLayout<Props = unknown>(Component: FC<Props>) {
  return (props: Props): any => {
    if (isWebapp()) {
      return (
        <WebBackLayout>
          <Component {...props} />
        </WebBackLayout>
      )
    } else {
      return <Component {...props} />
    }
  }
}
