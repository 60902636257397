import React from 'react'
import cc from 'classcat'
import useLogger from 'hooks/useLogger'
import placeholderYogaStudio from 'assets/placeholders/yoga-studio.jpg'
import ReviewStars from 'components/ReviewStars'
import BackgroundImage from 'components/BackgroundImage'
import { Class } from 'generated/graphql'
import { ReactComponent as IconFavorites } from 'assets/icons/heart.svg'
import { ReactComponent as IconFavoritesComplete } from 'assets/icons/heart-filled.svg'
import { useAuth } from 'stores/auth.store'
import {
  friendlyLocalDate,
  fromUntil,
  utcDateOrDayToLocalDate,
} from 'utils/date'
import { useToast } from 'components/ToastMessage'
import { CREATE_FAVORITE_CLASS, DELETE_FAVORITE_CLASS } from 'graphql/mutations'
import { useApolloClient } from '@apollo/client'
import Badge from 'components/Badge'
import styles from './index.module.scss'
import CrossLink from 'components/CrossLink'
import locationToString from 'utils/locationToString'

type Props = {
  clazz: Class
  onClick: (clazz: any) => void
  dateTimeID: string
  distance?: number
  isFavoriteClazz?: boolean
  locatedAt?: string
  to: string
}

const ClassCard = ({
  clazz,
  onClick,
  distance,
  dateTimeID,
  locatedAt,
  to,
}: Props) => {
  const { isAuthenticated, canAssistToClass, account } = useAuth()
  const { log } = useLogger()
  const dateTime = clazz.dates_times.find(
    (dateTime) => dateTime.id === dateTimeID,
  )

  const { notify } = useToast()

  const client = useApolloClient()
  const [favoriteClass, setFavoriteClass] = React.useState<boolean>(
    !!dateTime?.favorite_class,
  )

  if (!dateTime) {
    log(
      'error',
      `ClassCard date time not found using 'dateTimeID' "${dateTimeID}"`,
    )
    return null
  }

  const localDate = utcDateOrDayToLocalDate({
    date: dateTime.date,
    day: dateTime.day,
    start: dateTime.start,
  })

  if (!localDate) {
    log('error', `ClassCard date not found`, {
      dateTime,
    })
    return null
  }

  const classInfo = {
    studioName: clazz.owner?.entity?.name,
    className: clazz.name,
    price: clazz.price,
    time: fromUntil(dateTime.start, dateTime.end).join(' - '),
    when: friendlyLocalDate(localDate),
    reviews: clazz.reviews_aggregate?.aggregate?.avg?.value ?? 0,
  }

  const booked = false

  return (
    <div
      className={cc([
        'relative transition hover:ring-2 ring-primary ring-opacity-50',
        {
          'border-2 border-green': booked,
        },
      ])}
      data-testid={`clazz-${clazz.id}`}
    >
      {booked && (
        <div
          className="absolute heading-3 bg-green text-white px-1 top-full text-center"
          style={{
            fontSize: 8,
            left: -2,
            right: -2,
          }}
        >
          Booked
        </div>
      )}
      <CrossLink
        className="grid grid-cols-2 shadow-lg bg-white"
        style={{ minHeight: 220 }}
        onClick={() => {
          onClick(clazz)
        }}
        to={to}
      >
        {/* LEFT */}
        <div className="grid grid-rows-3 py-4 pl-5 pr-3">
          {/* TOP */}
          <div>
            {clazz.online ? (
              <Badge>Virtual class</Badge>
            ) : (
              <div
                className={cc([
                  'text-primary mt-1 opacity-60',
                  {
                    'heading-4': !locatedAt,
                    'heading-3': locatedAt,
                  },
                ])}
              >
                {locatedAt ||
                (distance != null && `${distance} miles`) ||
                clazz.location
                  ? locationToString(clazz.location!)
                  : ''}
              </div>
            )}
          </div>

          {/* CENTER */}
          <div
            className="flex flex-col justify-center"
            data-testid={`date-time-${dateTime.id}`}
          >
            <div className="heading-2 text-primary">{classInfo.studioName}</div>

            <div className="body-2">{classInfo.when}</div>
            <div className="body-2">{classInfo.time}</div>
          </div>

          {/* BOTTOM */}
          <div className="flex items-end">
            <div className="body-2">{classInfo.className}</div>
          </div>
        </div>

        {/* RIGHT */}
        <BackgroundImage
          className="flex flex-col justify-between relative"
          image={clazz.picture || placeholderYogaStudio}
        >
          {/* Top (shadow only) */}
          <div
            className={cc([
              styles.shadowTop,
              'absolute top-0 right-0 text-right',
            ])}
          />

          {/* Botton */}
          <div
            className={cc([
              styles.shadowBottom,
              'flex items-end absolute bottom-0 w-full',
            ])}
          >
            <div className="flex justify-between items-center w-full p-3">
              <ReviewStars value={classInfo.reviews} color="light" />
              <div className="text-white number-1 leading-none">
                ${classInfo.price}
              </div>
            </div>
          </div>
        </BackgroundImage>
      </CrossLink>

      {/* Heart */}
      {isAuthenticated() &&
        clazz.owner.id !== account.id &&
        canAssistToClass() && (
          <button
            className="absolute top-0 right-0 p-5 -m-2"
            onClick={async () => {
              try {
                if (!favoriteClass) {
                  await client.mutate({
                    mutation: CREATE_FAVORITE_CLASS,
                    variables: {
                      class_id: clazz.id,
                      class_date_time_id: dateTimeID,
                    },
                  })

                  setFavoriteClass(true)
                } else if (dateTime.favorite_class) {
                  await client.mutate({
                    mutation: DELETE_FAVORITE_CLASS,
                    variables: {
                      id: dateTime.favorite_class.id,
                    },
                  })

                  setFavoriteClass(false)
                }
              } catch (err) {
                notify({
                  message: 'Something went wrong',
                  type: 'failure',
                })
              }
            }}
          >
            {!favoriteClass ? (
              <IconFavorites
                className="inline-block fill-white z-60"
                style={{ width: 20 }}
              />
            ) : (
              <IconFavoritesComplete
                className="inline-block fill-white z-60"
                style={{ width: 20 }}
              />
            )}
          </button>
        )}
    </div>
  )
}

export default ClassCard
