import './styles'
import ReactDOM from 'react-dom'
import isBrowser from 'utils/isBrowser'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Plugins, StatusBarStyle } from '@capacitor/core'
import { Integrations } from '@sentry/tracing'
import App from './App'

const { StatusBar, Device } = Plugins

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      // We can do something here
      return event
    },
  })
} else {
  console.debug(
    'process.env.REACT_APP_SENTRY_DSN',
    !!process.env.REACT_APP_SENTRY_DSN,
  )
  console.debug(
    'process.env.REACT_APP_SENTRY_RELEASE',
    !!process.env.REACT_APP_SENTRY_RELEASE,
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

const injectDeviceClassName = async () => {
  const bodyElement = document.querySelector('body')
  const platform = (await Device.getInfo()).platform

  // TS typecheck
  if (bodyElement) {
    bodyElement.classList.add(platform)
  }
}

const startApp = async () => {
  const platform = (await Device.getInfo()).platform

  if (!isBrowser()) {
    StatusBar.setBackgroundColor({ color: '#ffffff' })
    StatusBar.setStyle({
      style: StatusBarStyle.Light,
    })

    if (platform === 'android') {
      // Because of https://github.com/ionic-team/capacitor/issues/2840#issuecomment-808389373
      StatusBar.setOverlaysWebView({
        overlay: false,
      })
    }
  }

  ReactDOM.render(<App />, document.getElementById('root'), () => {
    setTimeout(() => {
      const loadingAppElement = document.getElementById('loading-app')

      if (loadingAppElement) {
        loadingAppElement.className = 'loaded'

        setTimeout(() => {
          loadingAppElement.remove()
        }, 100)
      }
    }, 500)

    injectDeviceClassName()
  })
}

const init = async () => {
  if (isBrowser()) {
    startApp()
  } else {
    document.addEventListener('deviceready', startApp, false)
  }
}

init()
