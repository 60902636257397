import React from 'react'
import loading from 'assets/loading.gif'

export type Size = 'xs' | 'sm' | 'md' | 'lg'

interface Props {
  $size: Size
  context?: string
  children?: React.ReactNode
}

const widthMap = {
  xs: 14,
  sm: 25,
  md: 40,
  lg: 60,
}

export const LoadingSpinner = ({ $size }: { $size: Size }) => (
  <img
    src={loading}
    alt="Loading"
    width={widthMap[$size]}
    className="inline-block"
  />
)

const Loading = ({ $size, context, children }: Props) => {
  if (context && process.env.REACT_APP_DEBUG) {
    console.debug(`Loading Component :: ${context}`)
  }

  return (
    <div className="text-center p-2">
      <LoadingSpinner $size={$size} />
      {children && <div className="body-2 text-primary mt-2">{children}</div>}
    </div>
  )
}

Loading.defaultProps = {
  $size: 'md',
}

export default Loading
