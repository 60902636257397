import ReviewStars from 'components/ReviewStars'
import BackgroundImage from 'components/BackgroundImage'
import { Carousel } from 'react-responsive-carousel'

type Props = {
  data: {
    id: string | number
    title: string
    subtitle: string
    images: string[]
    spokenLanguages?: string[]
    reviews: {
      score: number
      total: number
    }
    duration: string
    availability: string
    pricePerTotalPeople: string
    price: number
  }
  onClick: (id: string | number) => void
}

const EventCard = ({ data, onClick }: Props) => {
  const {
    id,
    title,
    subtitle,
    images,
    spokenLanguages,
    reviews,
    duration,
    availability,
    pricePerTotalPeople,
    price,
  } = data

  return (
    <div
      className="bg-white shadow-lg"
      role="button"
      onClick={() => {
        onClick(id)
      }}
    >
      {images.length > 0 && (
        <Carousel showArrows={false} infiniteLoop showThumbs={false}>
          {images.map((image, index) => (
            <div className="relative" key={`${id}-retreat-image-${index}`}>
              <BackgroundImage className="w-full" image={image} height={150}>
                <div className="absolute top-0 left-0 w-full h-12 bg-gradient-to-b from-green opacity-70" />
                <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-green opacity-70" />
              </BackgroundImage>
            </div>
          ))}
        </Carousel>
      )}

      {/* Header */}
      <div className="py-4 px-3">
        <div className="flex items-center justify-between">
          {spokenLanguages && spokenLanguages.length > 0 && (
            <div
              className="heading-3 text-gray-600 pr-2"
              style={{
                fontSize: '0.7em',
              }}
            >
              {spokenLanguages.join(', ')}
            </div>
          )}
          <div className="flex items-center justify-center">
            <ReviewStars value={reviews.score} />
            <div
              className="body-3 pl-1"
              style={{
                paddingTop: 1,
              }}
            >
              {reviews.total > -1 ? `(${reviews.total})` : ''}
            </div>
          </div>
        </div>

        {/* Title */}
        <div className="text-primary pt-3 body-1">{title}</div>

        <div className="text-gray-600 pt-2 heading-3">{availability}</div>
      </div>

      {/* Details */}
      <div className="px-2 pb-2 body-2">
        <div className="grid gap-2 p-3 bg-nude">
          <div className="flex justify-between items-center">
            <div>{duration}</div>
            <div>{pricePerTotalPeople}</div>
          </div>

          <div className="flex justify-between items-center">
            <div>{subtitle}</div>
            <div className="flex items-center">
              {/* <span className="body-2 pr-2 opacity-40">From</span> */}
              <span
                className="font-mono text-primary"
                style={{
                  fontSize: '2em',
                }}
              >
                ${price}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventCard
