import React from 'react'
import cc from 'classcat'
import Billing from 'screens/Billing'
import { lowerCase, capitalize } from 'lodash/fp'
import { ReactComponent as IconCheck } from 'assets/symbols/checkmark.svg'
import { useModalScreen } from 'components/ModalScreen'
import { AccountStatus } from 'generated/graphql'
import { useAlert } from 'components/AlertModal'
import TeachingDocuments from 'screens/TeachingDocuments'
import { INACTIVE_ACCOUNT_KEY } from 'globalConstants'
import LegalInformation from 'screens/LegalInformation'

type Thing = AccountStatus

export const codeToText = (thing: Thing): React.ReactNode => {
  const { id, completed } = thing
  switch (id) {
    case INACTIVE_ACCOUNT_KEY.account: {
      return completed
        ? 'Your account is activated.'
        : 'Your account has been disabled. Please contact Support.'
    }
    case INACTIVE_ACCOUNT_KEY.accountVerification: {
      return completed ? (
        'Your account is verified.'
      ) : (
        <>Your account is still being verified by Ohmunity™.</>
      )
    }
    case INACTIVE_ACCOUNT_KEY.company: {
      return completed
        ? "Your Company's information is up to date."
        : "Your Company's information is not complete."
    }
    case INACTIVE_ACCOUNT_KEY.address: {
      return completed
        ? "Your Company's address is up to date."
        : "Your Company's address is missing."
    }
    case INACTIVE_ACCOUNT_KEY.externalAccount: {
      return completed
        ? 'At least one bank account was added.'
        : 'At least one bank account is needed.'
    }
    case INACTIVE_ACCOUNT_KEY.teachingDocuments: {
      return 'Proof of Teaching Insurance is missing.'
    }
    case INACTIVE_ACCOUNT_KEY.legalInformation: {
      return 'Legal Information is missing.'
    }
    default: {
      return thing.reason || capitalize(lowerCase(thing.id))
    }
  }
}

const useMissingCodeCallback = () => {
  const modal = useModalScreen()
  const alert = useAlert()

  const fn = (thing: Thing): (() => void) => {
    switch (thing.id) {
      case INACTIVE_ACCOUNT_KEY.account: {
        return () => {
          alert.send({
            title: 'Reason',
            body:
              thing.reason ||
              'Your account is inactive. Please contact support to know more about it.',
            buttons: [
              {
                children: 'Ok',
              },
            ],
          })
        }
      }
      case INACTIVE_ACCOUNT_KEY.accountVerification: {
        return () => {
          alert.send({
            title: 'Patience is a virtue',
            body: 'We will notify you once your verification is complete',
            buttons: [
              {
                children: 'Ok',
              },
            ],
          })
        }
      }
      case INACTIVE_ACCOUNT_KEY.company: {
        return () => {}
      }
      case INACTIVE_ACCOUNT_KEY.address: {
        return () => {}
      }
      case INACTIVE_ACCOUNT_KEY.externalAccount: {
        return () => {
          modal.open({
            header: 'Billing',
            body: <Billing />,
          })
        }
      }
      case INACTIVE_ACCOUNT_KEY.teachingDocuments: {
        return () => {
          modal.open({
            header: 'Teaching Documents',
            body: <TeachingDocuments />,
          })
        }
      }
      case INACTIVE_ACCOUNT_KEY.legalInformation: {
        return () => {
          modal.open({
            header: 'Legal Information',
            body: <LegalInformation />,
          })
        }
      }
      default: {
        return () => {}
      }
    }
  }

  return fn
}

type Props = {
  things: Thing[]
}

const completedThingsFirst = (things: Thing[]) => [
  ...things.filter((thing) => thing.completed),
  ...things.filter((thing) => !thing.completed),
]

const ThingsTodo = ({ things }: Props) => {
  const fn = useMissingCodeCallback()

  return (
    <ul className="grid gap-y-3 pl-4">
      {completedThingsFirst(things).map((thing, i) => (
        <li
          key={`thing-${i}`}
          className={cc([
            'relative bg-white shadow-sm py-6 pl-6 pr-4 body-2',
            {
              'active:bg-gray-100': !thing.completed,
            },
          ])}
          onClick={() => {
            if (!thing.completed) {
              fn(thing)()
            }
          }}
        >
          {codeToText(thing)}
          <span
            className={cc([
              'absolute flex items-center justify-center w-8 h-8 top-1/2 left-0 rounded-full transform -translate-y-1/2 -translate-x-1/2 border',
              {
                'border-gray-500 bg-gray-100': !thing.completed,
                'border-primary bg-primary': thing.completed,
              },
            ])}
          >
            <IconCheck
              width="16"
              className={thing.completed ? 'fill-white' : 'fill-gray-500'}
            />
          </span>
        </li>
      ))}
    </ul>
  )
}

export default ThingsTodo
