import React from 'react'
import minTwoDigitsNumber from 'utils/minTwoDigitsNumber'
import DeletableList from 'components/DeletableList'
import CreditCardIcon from 'components/CreditCardIcon'
import { useApolloClient } from '@apollo/client'
import { useAuth } from 'stores/auth.store'
import { useAlert } from 'components/AlertModal'
import useActionMutation from 'hooks/useActionMutation'
import { ReactComponent as Checkmark } from 'assets/symbols/checkmark.svg'
import CardDots from './CardDots'
import {
  AccountStatus,
  AccountStatusDocument,
  BillingSourcesDocument,
  DeleteBillingSourceDocument,
  UpdateDefaultSourceDocument,
} from 'generated/graphql'
import { INACTIVE_ACCOUNT_KEY } from 'globalConstants'

type SourceType = 'card' | 'bankAccount'

interface ListPaymentMethodProps {
  type: SourceType
  data: any[]
}

const ListSources = ({ type, data }: ListPaymentMethodProps) => {
  const client = useApolloClient()
  const alert = useAlert()
  const { canTeachAClass } = useAuth()

  const actionMutation = useActionMutation()

  const [sources, setSources] = React.useState(data)

  React.useEffect(() => {
    setSources(data)
  }, [data])

  return (
    <>
      <DeletableList
        $type="list"
        $itemBgClass="bg-gray-100"
        onDelete={(index) => {
          const selectedSource = sources[index]

          if (selectedSource.isDefault) {
            alert.send({
              title: 'It cannot be deleted',
              body: `The Bank Account ${selectedSource.last4} is the default bank account. To be able to delete it, please select another bank first as default.`,
              buttons: [
                {
                  children: 'Ok',
                },
              ],
            })
          } else {
            alert.send({
              body: `Are you sure you want to delete the card ending in ${selectedSource.last4}?`,
              buttons: [
                {
                  children: 'Yes, delete',
                  onClick: async () => {
                    await actionMutation({
                      mutation: {
                        mutation: DeleteBillingSourceDocument,
                        variables: {
                          id: selectedSource.id,
                        },
                      },
                      errorContext: `Delete Billing Source "${selectedSource.id}"`,
                      onSuccess: () => {
                        const filteredSources = sources.filter(
                          ({ id }) => id !== selectedSource.id,
                        )

                        const cached = client.readQuery({
                          query: BillingSourcesDocument,
                          variables: {
                            withCards: true,
                            withBankAccounts: canTeachAClass(),
                          },
                        })

                        client.writeQuery({
                          query: BillingSourcesDocument,
                          variables: {
                            withCards: true,
                            withBankAccounts: canTeachAClass(),
                          },
                          data: {
                            billingSources: {
                              ...cached.billingSources,
                              [`${type}s`]: filteredSources,
                            },
                          },
                        })

                        if (type === 'bankAccount') {
                          const cachedAccountStatus = client.readQuery({
                            query: AccountStatusDocument,
                          })

                          if (
                            cachedAccountStatus &&
                            cachedAccountStatus.accountStatus &&
                            cachedAccountStatus.accountStatus.length > 0
                          ) {
                            client.writeQuery({
                              query: AccountStatusDocument,
                              data: {
                                accountStatus: (cachedAccountStatus.accountStatus as AccountStatus[]).map(
                                  (status) => {
                                    if (
                                      status.id ===
                                      INACTIVE_ACCOUNT_KEY.externalAccount
                                    ) {
                                      return {
                                        ...status,
                                        completed: true,
                                      }
                                    } else {
                                      return status
                                    }
                                  },
                                ),
                              },
                            })
                          }
                        }

                        setSources(filteredSources)
                      },
                      autoNotifySuccess: {
                        text: `The card ending in ${selectedSource.last4} has been deleted`,
                      },
                    })
                  },
                },
                {
                  $type: 'tertiary',
                  children: 'Cancel',
                },
              ],
            })
          }
        }}
      >
        {sources.map((source, i) => (
          <div
            key={`payment-method-${i}`}
            className="flex items-center justify-center justify-between"
            onClick={() => {
              if (type === 'bankAccount' && !source.isDefault) {
                alert.send({
                  body: `Would you like to make this Bank Account ending in ${source.last4} your default Bank Account?`,
                  buttons: [
                    {
                      children: 'Yes, continue',
                      onClick: async () => {
                        await actionMutation({
                          mutation: {
                            mutation: UpdateDefaultSourceDocument,
                            variables: {
                              id: source.id,
                            },
                          },
                          errorContext: 'Billing Update Default Source',
                          onSuccess: () => {
                            const cached = client.readQuery({
                              query: BillingSourcesDocument,
                              variables: {
                                withCards: true,
                                withBankAccounts: canTeachAClass(),
                              },
                            })

                            client.writeQuery({
                              query: BillingSourcesDocument,
                              variables: {
                                withCards: true,
                                withBankAccounts: canTeachAClass(),
                              },
                              data: {
                                billingSources: {
                                  ...cached.billingSources,
                                  bankAccounts: cached.billingSources.bankAccounts.map(
                                    (bankAccount: any) => ({
                                      ...bankAccount,
                                      isDefault: bankAccount.id === source.id,
                                    }),
                                  ),
                                },
                              },
                            })
                          },
                          autoNotifySuccess: {
                            text: `Bank Account ending in ${source.last4} is the new Default Bank Account.`,
                          },
                        })
                      },
                    },
                    {
                      $type: 'tertiary',
                      children: 'No, cancel',
                    },
                  ],
                })
              }
            }}
          >
            {type === 'card' ? (
              <>
                <div className="flex items-center">
                  <CreditCardIcon
                    brand={source.brand}
                    className="inline-block"
                  />
                  <CardDots />
                  <div>{source.last4}</div>
                </div>
                <div>
                  Exp. {minTwoDigitsNumber(source.expMonth)}/{source.expYear}
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <div className="w-6">
                    {source.isDefault && (
                      <Checkmark width="15" className="fill-primary" />
                    )}
                  </div>
                  <strong className="text-primary">{source.bankName}</strong>
                </div>
                <div className="flex items-center">
                  <CardDots />
                  <div>{source.last4}</div>
                </div>
              </>
            )}
          </div>
        ))}
      </DeletableList>
    </>
  )
}

export default ListSources
