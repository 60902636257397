import { Fragment } from 'react'
import Container from 'components/Container'
import FormField, { FormGroup } from 'components/FormField'
import GenericQueryRender from 'components/GenericQueryRender'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  AccountExternalLinksDocument,
  AccountExternalLinksQuery,
  useInsertUpdateAccountExternalLinksMutation,
} from 'generated/graphql'
import { useAuth } from 'stores/auth.store'
import Button from 'components/Button'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { yupResolver } from '@hookform/resolvers'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { ReactComponent as IconTrashCan } from 'assets/icons/trash-can.svg'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

type Props = {
  onComplete?: () => void
}

const ExternalLinks = (props: Props) => {
  const { account } = useAuth()

  return (
    <Container topBottomSpace>
      <GenericQueryRender
        fetchPolicy="cache-and-network"
        query={AccountExternalLinksDocument}
        variables={{
          accountID: account.id,
        }}
        dataAccess="account_link"
        Success={({ data }) => <Form {...props} initialValues={data} />}
      />
    </Container>
  )
}

type FormProps = Props & {
  initialValues?: AccountExternalLinksQuery['account_link']
}

const validationSchema = yup.object().shape({
  items: Validation.externalLinks,
})

const Form = ({ initialValues, onComplete }: FormProps) => {
  const { register, formState, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      items:
        initialValues && initialValues.length
          ? initialValues.map(({ text, url }) => ({ text, url }))
          : [],
    },
    resolver: yupResolver(validationSchema),
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  })
  const { isDirty, errors } = formState
  const [
    insertUpdate,
    insertUpdateState,
  ] = useInsertUpdateAccountExternalLinksMutation({
    onCompleted: (data) => {
      if (data.insert_account_link) {
        onComplete?.()
      }
    },
  })

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        await insertUpdate({
          variables: {
            objects: values.items || [],
          },
        })
      })}
    >
      {
        fields.map((field, index) => (
          <Fragment key={field.id}>
            <div className="flex items-center">
              <div className="flex-grow pr-4">
                <FormGroup>
                  <FormField
                    type="text"
                    name={`items[${index}].text`}
                    label="Text"
                    register={register}
                    error={errors.items?.[index]?.text?.message}
                    defaultValue={field.text}
                  />
                  <FormField
                    type="url"
                    name={`items[${index}].url`}
                    label="URL"
                    register={register}
                    error={errors.items?.[index]?.url?.message}
                    onBlur={(value) => {
                      if (value && !value.startsWith('http')) {
                        setValue(`items[${index}].url`, `http://${value}`)
                      }
                    }}
                    defaultValue={field.url}
                  />
                </FormGroup>
              </div>
              <button
                type="button"
                className="p-2 text-center"
                onClick={() => {
                  remove(index)
                }}
              >
                <IconTrashCan className="inline-block w-5 fill-red-500" />
                <span className="block body-3 text-red-500 pt-1">Remove</span>
              </button>
            </div>
            <hr className="border-gray-500 my-6" />
          </Fragment>
        )) as any
      }

      <FormGroup>
        <Button
          type="button"
          $type="secondary"
          $fluid
          onClick={() => {
            append({
              text: '',
              url: '',
            })
          }}
        >
          Add link
        </Button>

        {insertUpdateState.error && (
          <ServerErrorMessage>
            {insertUpdateState.error.message}
          </ServerErrorMessage>
        )}

        <Button $fluid disabled={!isDirty || insertUpdateState.loading}>
          Save changes
        </Button>
      </FormGroup>

      <SpaceForKeyboard $size="lg" />
    </form>
  )
}

export default ExternalLinks
