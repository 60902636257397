import React from 'react'
import moment from 'moment'
import ReviewStars from 'components/ReviewStars'
import defaultAvatar from 'assets/user-avatar.png'
import getPersonName from 'utils/getPersonName'
import patternMatching from 'utils/patternMatching'
import Button from 'components/Button'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { DocumentNode, useQuery } from '@apollo/client'
import { Profile_Person } from 'generated/graphql'
import {
  QUERY_CLASS_REVIEWS,
  QUERY_STUDIO_REVIEWS,
  QUERY_TEACHER_REVIEWS,
} from 'graphql/queries'
import NothingYet from 'components/NothingYet'
import { useAuth } from 'stores/auth.store'

export type Value = 1 | 2 | 3 | 4 | 5

export type ReviewCardProps = {
  value: Value
  photo?: string | null
  name: string
  date: string
  title?: string | null
  description?: string | null
}

export const ReviewCard = ({
  value,
  photo,
  name,
  date,
  title,
  description,
}: ReviewCardProps) => {
  const userPhoto = photo ? photo : defaultAvatar

  return (
    <div className="text-left">
      {/* TOP */}
      <div className="mb-2">
        <ReviewStars value={value} />
      </div>

      {/* MIDDLE */}
      <div className="flex mb-2">
        <img src={userPhoto} alt="12" className="w-12 h-12 rounded-full" />
        <div className="ml-3 m-auto">
          <div className="heading-3 mb-px">{name}</div>
          <div className="body-2 text-gray-500">{date}</div>
        </div>
      </div>

      {/* BOTTOM */}
      <div className="heading-3 text-primary mb-2">{title}</div>
      <div className="body-2">{description}</div>
    </div>
  )
}

const LIMIT = 10

type Type = 'class' | 'studio' | 'teacher'

type Props = {
  type: Type
  id: string
  nothingType: 'just-text' | 'nothing-yet'
}

const ReviewsList = ({ type, id, nothingType }: Props) => {
  const { account } = useAuth()
  const [query, variables, dataAccess] =
    patternMatching<Type, [DocumentNode, Record<string, string>, string]>([
      ['class', [QUERY_CLASS_REVIEWS, { classID: id }, 'class_review']],
      ['studio', [QUERY_STUDIO_REVIEWS, { studioID: id }, 'studio_review']],
      ['teacher', [QUERY_TEACHER_REVIEWS, { teacherID: id }, 'teacher_review']],
    ])(type) || []
  const { loading, error, data: rawData, fetchMore } = useQuery(query!, {
    variables: {
      offset: 0,
      limit: LIMIT,
      ...variables,
    },
  })
  const data = rawData?.[dataAccess!]
  const [canFetchMore, setCanFetchMore] = React.useState(true)

  const nothingYetMessage =
    account.id === id
      ? 'You have no reviews yet.'
      : `This ${type} has no reviews yet.`

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ServerErrorMessage>{error.message}</ServerErrorMessage>
  }

  if (data && data.length < 1) {
    if (nothingType === 'nothing-yet') {
      return <NothingYet title={nothingYetMessage} />
    } else {
      return <>{nothingYetMessage}</>
    }
  }

  return (
    <>
      {/* TODO: Replace 'any' */}
      {data.map((review: any, i: number) => {
        return (
          <React.Fragment key={`review-${review.id}`}>
            {i > 0 && <hr className="border-t-2 border-gray-400 my-4" />}

            <ReviewCard
              {...{
                value: patternMatching<number, Value>(
                  [
                    [1, 1],
                    [2, 2],
                    [3, 3],
                    [4, 4],
                    [5, 5],
                  ],
                  1,
                )(review.value),
                photo: review.account.person?.picture,
                name: getPersonName(review.account.person as Profile_Person),
                date: moment.utc(review.created_at).local().calendar(),
                title: review.title,
                description: review.description,
              }}
            />
          </React.Fragment>
        )
      })}
      {canFetchMore && (
        <Button
          className="mt-6"
          type="button"
          $type="secondary"
          $fluid
          onClick={() => {
            fetchMore?.({
              variables: {
                offset: data.length,
              },
            }).then((result) => {
              if (
                !result.data ||
                (result.data as any)[dataAccess!].length < LIMIT
              ) {
                setCanFetchMore(false)
              }
            })
          }}
        >
          Load more reviews
        </Button>
      )}
    </>
  )
}

export default ReviewsList
