import { memoize } from 'lodash'
import { OhmRoute } from 'types'
import { MENU_LINKS } from 'config'

export const toolbarWithTo = memoize((routes: OhmRoute[]) =>
  MENU_LINKS.filter(({ id }) => routes.some((route) => route.id === id)).map(
    (menu) => ({
      ...menu,
      to: routes.find((route) => route.id === menu.id)?.path,
    }),
  ),
)
