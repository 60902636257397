import Button from 'components/Button'
import FormField, { FormGroup, Label } from 'components/FormField'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { ApolloError, useApolloClient } from '@apollo/client'
import ServerErrorMessage from 'components/ServerErrorMessage'
import * as Validation from 'utils/formFieldValidations'
import PhoneNumberField from 'formFields/PhoneNumberField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import FormTextWithCounter from 'forms/FromTextWithCounter'

export type InitialValues = {
  name?: string
  username?: string
  email?: string
  phoneNumber?: string | null
  bio?: string | null
  contactPhoneNumber?: string | null
}

interface Props {
  buttonText: string
  onSubmit: (values: InitialValues) => void
  state: {
    loading?: boolean
    error?: ApolloError | undefined
  }
  initialValues: InitialValues
  canEditEmail?: boolean
}

const FormEntity = ({
  buttonText,
  onSubmit,
  state,
  initialValues,
  canEditEmail,
}: Props) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    name: Validation.entityName,
    username: Validation.username(client, initialValues.username),
    phoneNumber: Validation.uniquePhoneNumber(
      client,
      initialValues.phoneNumber,
    ),
    ...(canEditEmail
      ? {
          email: Validation.uniqueEmail(client, initialValues.email),
        }
      : undefined),
    bio: Validation.longText,
    contactPhoneNumber: Validation.phoneNumber,
  })
  const formMethods = useForm({
    defaultValues: {
      name: initialValues.name || '',
      username: initialValues.username || '',
      email: initialValues.email || '',
      phoneNumber: initialValues.phoneNumber || '',
      bio: initialValues.bio || '',
      contactPhoneNumber: initialValues.contactPhoneNumber || '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, errors, control } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup title="Private" subtitle="login and notifications purposes">
          {/* Email */}
          {canEditEmail ? (
            <FormField
              type="text"
              label="Email"
              name="email"
              register={register}
              error={errors?.email?.message}
              disabled={!canEditEmail}
            />
          ) : (
            <div>
              <Label>Email</Label>
              <div>{initialValues.email || 'N/A'}</div>
            </div>
          )}

          {/* Phone Number */}
          <PhoneNumberField
            label="Phone Number"
            name="phoneNumber"
            control={control}
          />
        </FormGroup>

        <hr className="border-gray-400 my-6" />

        <FormGroup title="Public">
          {/* Name */}
          <FormField
            type="text"
            label="Name"
            name="name"
            register={register}
            error={errors?.name?.message}
          />

          {/* Username */}
          <FormField
            type="text"
            label="Username"
            name="username"
            register={register}
            error={errors?.username?.message}
          />

          <PhoneNumberField
            label="Public Contact Phone Number"
            name="contactPhoneNumber"
            control={control}
            sublabel="This is for your Students to contact you for emergencies/problems."
          />

          {/* Bio */}
          <FormTextWithCounter name="bio" label="Bio" $inputClassName="h-56" />
        </FormGroup>

        {state.error && (
          <ServerErrorMessage>{state.error.message}</ServerErrorMessage>
        )}

        {/* Submit */}
        <div className="pt-6">
          <Button type="submit" $type="primary" $fluid loading={state.loading}>
            {buttonText}
          </Button>
        </div>

        <SpaceForKeyboard $size="lg" />
      </form>
    </FormProvider>
  )
}

FormEntity.defaultProps = {
  buttonText: 'Submit',
  canEditPicture: true,
  canEditEmail: true,
}

export default FormEntity
