import { omit } from 'lodash/fp'

const STORAGE_KEY = 'checkout'

type Key = string

export type CheckoutMetadata = {
  classEventID: string
  intentID: string
  clientSecret: string
}

export type CheckoutData = Record<Key, CheckoutMetadata>

const set = (data: CheckoutData) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
}

const get = (): CheckoutData => {
  try {
    const item = localStorage.getItem(STORAGE_KEY)

    return item ? JSON.parse(item) : {}
  } catch (error) {
    console.error(error)

    const resetedData = {}

    set(resetedData)

    return resetedData
  }
}

export const setCheckoutClass = (
  classDateTimeID: string,
  metadata: CheckoutMetadata,
) => {
  set({
    ...omit(classDateTimeID, get()),
    [classDateTimeID]: metadata,
  })
}

export const getCheckoutClasses = () => get()

export const getCheckoutClass = (
  classDateTimeID: string,
): CheckoutMetadata | undefined => get()[classDateTimeID]

export const removeCheckoutClass = (classDateTimeID: string) => {
  set(omit(classDateTimeID, get()))
}
