import { useEffect, createRef, useState } from 'react'
import cc from 'classcat'
import useToggle from 'hooks/useToggle'
import { Control, Controller, ControllerRenderProps } from 'react-hook-form'
import { CSSTransition } from 'react-transition-group'
import { ReactComponent as IconCarrot } from 'assets/icons/carrot.svg'
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifying-glass.svg'
import { ReactComponent as IconCheckmark } from 'assets/symbols/checkmark.svg'
import { ReactComponent as IconX } from 'assets/symbols/x.svg'
import styles from './index.module.scss'
import FormField from 'components/FormField'

type Props = CommonProps & {
  name: string
  control: Control
}

const DropdownField = ({ name, control, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={(props) => (
        <>
          <Select controllerProps={props} {...rest} />
        </>
      )}
    />
  )
}

export default DropdownField

type Option = {
  value: string
  text: string
}

type CommonProps = {
  options: Option[]
  ['data-testid']?: string
  $inputClassName?: string
  placeholder?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

type SelectProps = CommonProps & {
  controllerProps: ControllerRenderProps
}

const Select = ({
  controllerProps,
  placeholder,
  $inputClassName,
  options,
  onChange,
  disabled,
  'data-testid': dataTestID,
}: SelectProps) => {
  const [isOpen, toggle] = useToggle(false)
  const ref = createRef<HTMLLIElement>()
  const listRef = createRef<HTMLUListElement>()
  const [filter, setFilter] = useState('')
  const filteredOptions = options.filter((option) =>
    option.text.toLowerCase().includes(filter),
  )

  useEffect(() => {
    if (ref && ref.current && listRef && listRef.current) {
      ref.current.focus()

      listRef.current.scrollTo({
        top: ref.current.offsetTop - listRef.current.offsetTop,
      })
    }
  }, [ref])

  return (
    <>
      {/* Input */}
      <button
        type="button"
        className={cc([
          'relative block w-full',
          {
            'opacity-60': disabled,
          },
        ])}
        onClick={() => {
          if (!disabled) {
            toggle()
          }
        }}
        data-testid={dataTestID}
      >
        <div className={cc(['flex items-center', $inputClassName])}>
          {controllerProps.value == null || controllerProps.value === '' ? (
            <span className="text-gray-500 truncate">{placeholder}</span>
          ) : (
            <span className="truncate">
              {
                options.find((option) => option.value === controllerProps.value)
                  ?.text
              }
            </span>
          )}
        </div>
        <IconCarrot
          width={15}
          height={15}
          className="absolute floating-middle-right mr-3 fill-gray-800"
        />
      </button>

      {/* Options Box */}
      <CSSTransition
        in={isOpen}
        timeout={150}
        classNames={styles}
        unmountOnExit
      >
        {() => (
          <div
            className="z-991 fixed inset-0 flex justify-center items-center"
            data-testid="dropdown-field-options-box"
          >
            <div
              className={cc([
                styles.overlay,
                'absolute inset-0 bg-black bg-opacity-40',
              ])}
              onClick={() => {
                toggle()
              }}
            />
            <div
              className={cc([
                styles.box,
                'z-2 bg-white shadow-lg w-4/5 max-h-4/5 overflow-hidden flex flex-col',
              ])}
              style={{
                maxHeight: '80%',
              }}
            >
              <div className="flex justify-between items-center py-2 bg-primary text-white">
                <button
                  className="p-3"
                  onClick={() => {
                    toggle()
                  }}
                  type="button"
                >
                  <IconX width={15} className="fill-white" />
                </button>

                {placeholder && (
                  <div className="heading-3 truncate px-2">{placeholder}</div>
                )}

                <div className="pr-10" />
              </div>

              <FormField
                name="dropdown-filter"
                type="text"
                onChange={(value) => {
                  setFilter(value)
                }}
                Icon={MagnifyingGlass}
                placeholder="Filter options"
              />

              {filteredOptions.length ? (
                <ul className="max-h-full overflow-y-auto" ref={listRef}>
                  {filteredOptions.map((option) => (
                    <li
                      key={`option-${controllerProps.name}-${option.value}`}
                      className="block"
                      onClick={() => {
                        controllerProps.onChange(option.value)
                        onChange?.(option.value)
                        toggle()
                      }}
                      ref={
                        controllerProps.value != null &&
                        option.value === controllerProps.value
                          ? ref
                          : undefined
                      }
                    >
                      <button
                        type="button"
                        className="w-full flex justify-between items-center border-t border-gray-300 p-4 active:bg-gray-200 hover:bg-gray-200"
                        data-testid="dropdown-field-option"
                        data-value={option.value}
                      >
                        <span
                          className={cc([
                            'truncate',
                            {
                              'text-primary':
                                controllerProps.value != null &&
                                option.value === controllerProps.value,
                            },
                          ])}
                        >
                          {option.text}
                        </span>
                        {controllerProps.value != null &&
                          option.value === controllerProps.value && (
                            <IconCheckmark
                              width={10}
                              height={10}
                              className="mr-2"
                            />
                          )}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="p-4 text-center">No options found</div>
              )}
            </div>
          </div>
        )}
      </CSSTransition>
    </>
  )
}
