import { Calendar } from '@ionic-native/calendar'
import { ActionSheet } from '@ionic-native/action-sheet'
import { useToast } from 'components/ToastMessage'
import useLogger from './useLogger'

// Note: library results are not typed
type CalendarResult = {
  id: string | number
  name: string
  displayName?: string // Android
  isPrimary?: boolean // iOS
  type?: string // iOS
}

const getCalendars = async () =>
  (await Calendar.listCalendars()).filter(
    (calendar: CalendarResult) =>
      calendar.isPrimary || calendar.type === 'CalDAV',
  ) as CalendarResult[]

const selectCalendar = async (
  calendars: CalendarResult[],
): Promise<CalendarResult | null> => {
  const selectedCalendarIndex =
    (await ActionSheet.show({
      title: 'Please select a calendar',
      buttonLabels: calendars.map(({ name }) => name),
      addCancelButtonWithLabel: 'Cancel',
    })) - 1

  const selectedCalendar = calendars[selectedCalendarIndex]

  return selectedCalendar || null
}

const useDeviceCalendar = () => {
  const toast = useToast()
  const { log } = useLogger()

  const createEvent = async ({
    title,
    location,
    body,
    startDate,
    endDate,
  }: {
    title: string
    location?: string
    body: string
    startDate: Date
    endDate: Date
  }) => {
    const calendars = await getCalendars()

    let canAdd = true
    let options = {}
    if (calendars.length > 0) {
      const selectedCalendar = await selectCalendar(calendars)

      if (selectedCalendar) {
        options = {
          calendarId: selectedCalendar.id,
        }
      } else {
        canAdd = false // it has been canceled by the user
      }
    }

    if (canAdd) {
      try {
        await Calendar.createEventWithOptions(
          title,
          location,
          body,
          startDate,
          endDate,
          options,
        )
        toast.notify({
          type: 'success',
          message: 'The event has been added into your calendar!',
        })
      } catch (error) {
        log('error', 'Add Event Device Calendar', {
          error,
        })
        toast.notify({
          type: 'failure',
          message: 'Failed to add the event into your Calendar.',
        })
      }
    }
  }

  return {
    createEvent,
  }
}

export default useDeviceCalendar
