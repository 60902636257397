/*
This component inclues the Location Form and the form submittion
*/

import isEmpty from 'lodash/fp/isEmpty'
import pick from 'lodash/fp/pick'
import omit from 'lodash/fp/omit'
import useLogger from 'hooks/useLogger'
import Button from 'components/Button'
import FormField from 'components/FormField'
import FormAddress, {
  Values,
  formAddressValidationSchema,
} from 'forms/FormAddress'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { yupResolver } from '@hookform/resolvers'
import { DeepMap, FormProvider, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { CREATE_LOCATION, UPDATE_LOCATION_BY_PK } from 'graphql/mutations'
import {
  Location,
  Location_Insert_Input,
  Location_Set_Input,
} from 'generated/graphql'
import { Primitive } from 'types'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

type Props = {
  onSubmit?: (newValues: Location) => void
  location?: Location
}

export const updateOnlyWhatChanges = (values: Location_Set_Input) => (
  changed: DeepMap<Record<string, Primitive>, boolean>,
) => {
  const exposedFields = ['name', 'addressSecondary', 'zipCode']
  const hasHidden = Object.keys(changed).some((v) => !exposedFields.includes(v))

  if (hasHidden) {
    return omit(
      exposedFields.filter((field) => !(field in changed)),
      values,
    )
  } else {
    return pick(
      exposedFields.filter((field) => field in changed),
      values,
    )
  }
}

const FormLocation = ({ onSubmit, location }: Props) => {
  const isUpdate = !!location
  const formMethods = useForm({
    defaultValues: {
      name: location?.name || '',
      address: location?.address || '',
      addressSecondary: location?.addressSecondary || '',
      city: location?.city || '',
      state: location?.state || '',
      zipCode: location?.zipCode || '',
      placeID: location?.placeID || '',
      coordinateLng: location?.coordinates.coordinates[0] || '',
      coordinateLat: location?.coordinates.coordinates[1] || '',
    },
    resolver: yupResolver(formAddressValidationSchema),
  })
  const { handleSubmit, register, formState, errors } = formMethods
  const { log } = useLogger()
  const [mutate, { loading, error }] = useMutation(
    isUpdate ? UPDATE_LOCATION_BY_PK : CREATE_LOCATION,
  )
  const { isSubmitted, dirtyFields } = formState

  const submit = async (values: Values) => {
    // eslint-disable-next-line no-constant-condition
    const valuesToData: Location_Insert_Input | Location_Set_Input = {
      name: values.name,
      address: values.address,
      addressSecondary: values.addressSecondary,
      city: values.city,
      state: values.state,
      zipCode: values.zipCode,
      placeID: values.placeID,
      coordinates: {
        type: 'Point',
        coordinates: [
          parseFloat(values.coordinateLng),
          parseFloat(values.coordinateLat),
        ],
      },
    }

    const result = await mutate({
      variables: isUpdate
        ? {
            locationID: location?.id,
            set: updateOnlyWhatChanges(valuesToData)(dirtyFields),
          }
        : {
            object: valuesToData,
          },
    })

    if (!result.errors) {
      await onSubmit?.(
        isUpdate
          ? result.data.update_location_by_pk
          : result.data.insert_location_one,
      )
    } else {
      log('error', `Form Location ${isUpdate ? 'Update' : 'Create'}`, {
        errors: result.errors,
      })
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="py-1" data-testid="form-location-input-name">
          <FormField
            type="text"
            name="name"
            register={register}
            label="Name *"
            required
            error={errors.name?.message}
          />
        </div>

        <FormAddress />

        {error && (
          <ServerErrorMessage>{GENERIC_ERROR_MESSAGE}</ServerErrorMessage>
        )}

        {isSubmitted &&
          Object.getOwnPropertyNames(errors).filter((errorKey) =>
            ['city', 'state'].includes(errorKey),
          ).length > 0 && (
            <ServerErrorMessage>
              The form has some errors, please verify all the fields
            </ServerErrorMessage>
          )}

        {/* BUTTON */}
        <div className="py-4" data-testid="button-save-changes">
          <Button
            type="submit"
            $type="primary"
            $fluid
            loading={loading}
            disabled={isEmpty(dirtyFields)}
          >
            {isUpdate ? 'Save Changes' : 'Add Location'}
          </Button>
        </div>

        <SpaceForKeyboard />
      </form>
    </FormProvider>
  )
}

export default FormLocation
