import React from 'react'
import cc from 'classcat'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'

type Props = {
  onClick: () => void
  innerRef?: any
  disabled?: boolean
}

const Empty = ({ innerRef, onClick, disabled }: Props) => (
  <button
    ref={innerRef}
    type="button"
    onClick={() => {
      if (!disabled) {
        onClick()
      }
    }}
    className={cc([
      'w-full h-full flex items-center justify-center bg-white border-2 border-dashed border-gray-400',
      {
        'opacity-60': disabled,
      },
    ])}
    style={{ fontSize: '2em' }}
  >
    <Plus width="20" height="20" className="fill-primary" />
  </button>
)

export default Empty
