import React from 'react'
import Button from 'components/Button'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { PaymentMethod } from '@stripe/stripe-js'
import { Label } from 'components/FormField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
interface Props {
  onSubmit: (
    paymentMethod: PaymentMethod,
    token: string,
    cardToken: string,
  ) => void
  hideButton?: boolean
}

const FormAddCard = ({ onSubmit, hideButton }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const toast = useToast()
  const { log } = useLogger()
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setLoading(true)

    if (!stripe || !elements) {
      log('error', 'Stripe JS', {
        error: `"stripe" or "elements" not present`,
      })

      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })

      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (cardElement) {
      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      })

      const { error: tokenError, token } = await stripe.createToken(cardElement)

      if (error || tokenError) {
        log('error', 'Stripe Create Payment Method', {
          error,
        })

        toast.notify({
          type: 'failure',
          message: error?.message || tokenError?.message,
        })
      } else {
        onSubmit(paymentMethod!, token?.id!, token?.card?.id!)
      }
    } else {
      log('error', 'Stripe JS', {
        error: `"stripe" or "elements" not present`,
      })

      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} id="form-add-payment-method">
      <div className="py-2">
        <Label>Card Number</Label>
        <CardNumberElement className="appearance-none bg-white text-gray-800 placeholder-gray-500 resize-none h-11 border border-gray-300 shadow-sm p-3 w-full focus:outline-none focus:border-green focus:shadow-lg body-1" />
      </div>

      <div className="py-2 grid grid-cols-2 gap-4">
        <div>
          <Label>Expiration</Label>
          <CardExpiryElement className="appearance-none bg-white text-gray-800 placeholder-gray-500 resize-none h-11 border border-gray-300 shadow-sm p-3 w-full focus:outline-none focus:border-green focus:shadow-lg body-1" />
        </div>
        <div>
          <Label>CVC</Label>
          <CardCvcElement className="appearance-none bg-white text-gray-800 placeholder-gray-500 resize-none h-11 border border-gray-300 shadow-sm p-3 w-full focus:outline-none focus:border-green focus:shadow-lg body-1" />
        </div>
      </div>

      {!hideButton && (
        <div className="pt-4">
          <Button type="submit" $fluid loading={loading}>
            Add
          </Button>
        </div>
      )}

      <SpaceForKeyboard />
    </form>
  )
}

export default FormAddCard
