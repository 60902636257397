import { FC } from 'react'
import { ReactComponent as AppStore } from 'assets/app-store-badge-black.svg'
import { ReactComponent as GooglePlay } from 'assets/google-play-badge.svg'
import { APP_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from 'globalConstants'
import { isWebapp } from 'utils/env'

type Props = {
  children: any
}

const NotOptimizedForWebapp = ({ children }: Props) => {
  if (isWebapp()) {
    return (
      <div
        className="w-full max-w-lg m-auto"
        style={{ minWidth: 370 }}
        data-testid="not-optimized"
      >
        <div className="bg-white m-4 mb-12 p-4 shadow text-center space-y-4">
          <p className="text-red-500">
            This page has not been optimized for Web yet.
          </p>

          <p>For a better experience, download our free app:</p>

          <div className="inline-grid grid-cols-2 gap-2">
            <a
              href={APP_STORE_LINK}
              target="_blank"
              rel="noreferrer"
              data-testid="app-store"
            >
              <AppStore className="h-10 sm:h-12" />
            </a>
            <a
              href={GOOGLE_PLAY_STORE_LINK}
              target="_blank"
              rel="noreferrer"
              data-testid="google-play-store"
            >
              <GooglePlay className="h-10 sm:h-12" />
            </a>
          </div>
        </div>
        {children}
      </div>
    )
  }
  return children
}

export default NotOptimizedForWebapp

export const WithNotOptimizedForWebapp = (Component: FC) => (props: any) => (
  <NotOptimizedForWebapp>
    <Component {...props} />
  </NotOptimizedForWebapp>
)
