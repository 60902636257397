import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'

import { useFormCarousel } from 'forms/FormCarousel/context'

const Finish = () => {
  const { next } = useFormCarousel()

  return (
    <Container topBottomSpace className="flex flex-col h-full">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">
          Now that we got this far,
          <br />
          would you like to sign up?
        </div>
      </div>

      <div className="flex-grow" />

      <div>
        <Button
          $fluid
          onClick={() => {
            next({
              next: 'signup',
            })
          }}
        >
          Continue to sign up
        </Button>
        <Button
          $fluid
          $type="tertiary"
          onClick={() => {
            next({
              next: 'explore',
            })
          }}
        >
          Later, let's explore
        </Button>
      </div>
    </Container>
  )
}

export default Finish
