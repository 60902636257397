import Button from 'components/Button'
import useLogger from 'hooks/useLogger'
import FormAddress from 'forms/FormAddress'
import BirthdayField from 'formFields/BirthdayField'
import FormField, { FormGroup } from 'components/FormField'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useAlert } from 'components/AlertModal'
import { useAuth } from 'stores/auth.store'
import { Person } from 'types'
import { CreateIndividualAccountDocument } from 'generated/graphql'
import { useMutation } from 'urql'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

const validationSchema = yup.object().shape({
  firstName: Validation.firstName,
  lastName: Validation.lastName,
  birthday: Validation.birthday,
  last4ssn: Validation.digits(4),
  ...Validation.address,
})

export type Values = {
  firstName: string
  lastName: string
  birthday: string
  last4ssn: string
  address: {
    primary: string
    secondary?: string
    city: string
    state: string
    zipCode: string
  }
}

type Props = {
  onAdded: (data: Values) => void
  initialValues?: Partial<Values>
}

const IndividualLegalInformation = ({ initialValues, onAdded }: Props) => {
  const alert = useAlert()
  const { notify } = useToast()
  const { log } = useLogger()
  const { account, updateAccount } = useAuth()
  const [createInvAccResult, createInvAcc] = useMutation(
    CreateIndividualAccountDocument,
  )
  const form = useForm({
    defaultValues: {
      firstName: initialValues?.firstName || '',
      lastName: initialValues?.lastName || '',
      birthday: initialValues?.birthday || '',
      last4ssn: initialValues?.last4ssn || '',
      address: initialValues?.address?.primary || '',
      addressSecondary: initialValues?.address?.secondary || '',
      city: initialValues?.address?.city || '',
      state: initialValues?.address?.state || '',
      zipCode: initialValues?.address?.zipCode || '',

      birthdayYear: initialValues?.birthday
        ? parseInt(initialValues?.birthday.split('-')[0], 10)
        : '',
      birthdayMonth: initialValues?.birthday
        ? parseInt(initialValues?.birthday.split('-')[1], 10)
        : '',
      birthdayDay: initialValues?.birthday
        ? parseInt(initialValues?.birthday.split('-')[2], 10)
        : '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, errors, formState } = form
  const { touched } = formState

  const submit = async (values: any) => {
    try {
      const parsedValues = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        birthday: values?.birthday,
        last4ssn: values?.last4ssn,
        address: {
          primary: values?.address,
          secondary: values?.addressSecondary,
          city: values?.city,
          zipCode: values?.zipCode,
          state: values?.state,
        },
      }
      const response = await createInvAcc(parsedValues)

      if (response.error) {
        log('error', 'Create Invidivual Account', {
          error: response.error,
        })
        notify({
          message: response.error.message || GENERIC_ERROR_MESSAGE,
          type: 'failure',
        })
      } else {
        onAdded(parsedValues)

        if (touched.firstName || touched.lastName) {
          const profile = account.profile as Person
          updateAccount({
            profile: {
              ...profile,
              firstName: parsedValues.firstName,
              lastName: parsedValues.lastName,
            },
          } as any)
        }
      }
    } catch (error) {
      log('error', 'Create Individual Account', {
        error,
      })
      notify({
        message: error.message,
        type: 'failure',
      })
    }
  }

  return (
    <FormProvider {...form}>
      <form
        onSubmit={handleSubmit((values: any) => {
          const changes = [
            touched.firstName && 'First Name',
            touched.lastName && 'Last Name',
          ].filter(Boolean)

          if (changes.length > 0) {
            alert.send({
              body: `Changes you made on ${changes.join(
                ' and ',
              )} will be updated in your Profile as well`,
              buttons: [
                {
                  children: 'Ok, Continue',
                  onClick: () => {
                    submit(values)
                  },
                },
                {
                  $type: 'tertiary',
                  children: 'Cancel',
                },
              ],
            })
          } else {
            submit(values)
          }
        })}
      >
        <FormGroup>
          {/* First Name */}
          <FormField
            type="text"
            label="First Name *"
            name="firstName"
            register={register}
            error={errors?.firstName?.message}
          />

          {/* Last Name */}
          <FormField
            type="text"
            label="Last Name *"
            name="lastName"
            register={register}
            error={errors?.lastName?.message}
          />

          <BirthdayField name="birthday" label="Date of Birth *" />

          {/* Last 4 SSN */}
          <FormField
            type="number"
            label="SSN Last 4 *"
            name="last4ssn"
            register={register}
            error={errors?.last4ssn?.message}
            sublabel="Required to verify your identity and enable Payouts"
            maxLength={4}
            max={9999}
            min={1000}
          />
        </FormGroup>

        {/* Address */}
        <FormAddress type="new" />

        {/* CREATE */}
        <div className="pt-6 pb-6">
          <Button
            type="submit"
            $type="primary"
            $fluid
            loading={createInvAccResult.fetching}
          >
            Save
          </Button>
        </div>
      </form>

      <SpaceForKeyboard $size="lg" />
    </FormProvider>
  )
}

export default IndividualLegalInformation
