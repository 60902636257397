import cc from 'classcat'
import { Link, useLocation } from '@reach/router'
import { ReactComponent as Logo } from 'assets/logo.svg'
import useToggle from 'hooks/useToggle'
import { useRoutes } from 'Router'
import { toolbarWithTo } from 'utils/menu'
import { IonIcon } from '@ionic/react'
import { menuOutline, closeOutline } from 'ionicons/icons'
import { useAuth } from 'stores/auth.store'
import DefaultAvatar from 'assets/user-avatar.png'
import BackgroundImage from 'components/BackgroundImage'

const WebHeader = () => {
  const { isAuthenticated, account } = useAuth()
  const [isOpen, toggle] = useToggle(false)

  return (
    <>
      <div className="z-8 fixed w-full top-0 p-2 bg-white bg-opacity-90 text-center backdrop-filter backdrop-blur">
        <button
          className="absolute left-6 top-1/2 transform -translate-y-1/2"
          onClick={() => {
            toggle()
          }}
          data-testid="button-menu"
        >
          <IonIcon icon={menuOutline} className="block" size="large" />
        </button>

        <Link to="/" data-testid="ohmunity-logo">
          <Logo className="w-28 h-16 inline-block ml-4" />
        </Link>

        {isAuthenticated() && (
          <Link
            to="/account"
            className="absolute h-full top-0 right-0 flex items-center p-4"
            data-testid="loggedin-user-avatar"
          >
            <BackgroundImage
              image={account.profile?.picture || DefaultAvatar}
              className="w-12 h-12 rounded-full border border-gray-400"
            />
          </Link>
        )}
      </div>

      {isOpen && <Menu toggle={toggle} />}
    </>
  )
}

export default WebHeader

const Menu = ({ toggle }: any) => {
  const { pathname } = useLocation()
  const routes = useRoutes()

  return (
    <>
      <div className="z-9 fixed inset-0 bg-black bg-opacity-20 backdrop-filter backdrop-blur" />
      <div className="z-10 fixed top-0 left-0 w-64 h-full bg-white text-left p-2 bg-opacity-80 backdrop-filter backdrop-blur">
        <button
          className="p-4"
          onClick={() => {
            toggle()
          }}
        >
          <IonIcon icon={closeOutline} className="block" size="large" />
        </button>

        <ul>
          {toolbarWithTo(routes).map(({ to, text, IconActive, Icon }: any) => {
            const isActive = pathname === to
            const IconComponent = isActive ? IconActive : Icon

            return (
              <li key={to} className="text-center p-3">
                <Link
                  to={to}
                  className={cc([
                    'block p-2 text-center',
                    {
                      'hover:bg-gray-300 hover:shadow': !isActive,
                    },
                  ])}
                  onClick={(event) => {
                    if (isActive) {
                      event.preventDefault()
                    } else {
                      toggle()
                    }
                  }}
                  data-testid="menu-link"
                  data-value={to}
                >
                  <IconComponent
                    width="27"
                    className="inline-block fill-primary"
                  />
                  <div
                    className={cc([
                      'pt-2',
                      {
                        'text-primary': isActive,
                        'text-gray-700': !isActive,
                      },
                    ])}
                  >
                    {text}
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}
