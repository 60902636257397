import * as Sentry from '@sentry/react'
import ms from 'ms'
import isBrowser from 'utils/isBrowser'
import patternMatching from 'utils/patternMatching'
import Loading from 'components/Loading'
import Router from './Router'
import useLogger from 'hooks/useLogger'
import usePushNotification from 'hooks/usePushNotification'
import { LocationProvider } from '@reach/router'
import { history } from 'clients/navigation.client'
import { Provider as AuthProvider } from 'stores/auth.store'
import { GlobalProvider } from 'stores/global.store'
import { ModalScreenProvider } from 'components/ModalScreen'
import { ToastProvider } from 'components/ToastMessage'
import { AlertProvider } from 'components/AlertModal'
import { LoadingBlockProvider } from 'components/LoadingBlock'
import { ModeProvider } from 'hooks/useMode'
import { Deploy } from 'cordova-plugin-ionic'
import { DebuggerProvider } from 'hooks/useDebugger'
import ApolloClientProvider from 'clients/apollo.client'
import GraphqlClientProvider from 'clients/graphql.client'
import composeProviders from 'utils/composeProviders'
import { APP_CRASHED_MESSAGE } from 'globalConstants'
import { useState, useEffect } from 'react'
import useBranchIO from 'hooks/useBranchIO'
import useCheckAccountTimezone from 'hooks/useCheckAccountTimezone'
import { Helmet } from 'react-helmet'
import { getEnv } from 'utils/env'
import Cohere from 'cohere-js'
import { ReactComponent as Logo } from 'assets/logo.svg'

type Status = 'loading' | 'downloading' | 'installing'

const App = () => {
  console.debug('App render')
  const [loading, setLoading] = useState(isBrowser() ? false : true)

  const onDeviceReady = () => {
    setLoading(false)
  }

  const onBackButton = (e: any) => {
    e.preventDefault()
  }

  useEffect(() => {
    document.addEventListener('backbutton', onBackButton, false)
    document.addEventListener('deviceready', onDeviceReady, false)

    if (getEnv('COHERE_API_KEY', true)) {
      console.debug('Cohere init')
      Cohere.init(getEnv('COHERE_API_KEY'))

      Cohere.addSessionUrlListener((...args) => {
        console.log('cohere', args)
      })
    }

    // Best Practices + avoiding memory leak under hot reloading
    return () => {
      document.removeEventListener('backbutton', onBackButton, false)
      document.removeEventListener('deviceready', onDeviceReady, false)

      Cohere.stop()
    }
  }, [])

  if (!process.env.WE_ARE_OPEN) {
    return (
      <div className="fixed inset-0 flex items-center justify-center flex-col">
        <Logo className="w-48" />
        <h1 className="heading-1 pt-4 text-primary">Sorry we are closed</h1>
      </div>
    )
  }

  if (loading) {
    console.debug('App render loading')
    return (
      <div className="flex items-center justify-center h-full">
        <Loading context="App" />
      </div>
    )
  }

  console.debug('App render content')

  const pageTitle = 'Ohmunity™ - Yoga Classes nearby & online'
  const pageDescription =
    'Discover Yoga Classes nearby and live-streaming, retreats, teacher training programs, daily tips, inspirational reads, and more'
  const pageUrl = 'https://web.ohmunity.com'

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="index, follow" />

        {/* Open Graph */}
        <meta property="og:url" content={pageUrl} key="ogurl" />
        <meta
          property="og:image"
          content="https://ohmunity-assets.s3.us-east-2.amazonaws.com/og-image.png"
          key="ogimage"
        />
        <meta property="og:site_name" content="Ohmunity" key="ogsitename" />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta
          property="og:description"
          content={pageDescription}
          key="ogdesc"
        />

        <link rel="canonical" href={pageUrl} />
      </Helmet>
      {composeProviders(
        [
          DebuggerProvider,
          GlobalProvider,
          GraphqlClientProvider,
          ApolloClientProvider,
          AuthProvider,
          ModeProvider,
          LoadingBlockProvider,
          ToastProvider,
          AlertProvider,
          ModalScreenProvider,
        ],
        <AppContent />,
      )}
      <div
        className="fixed top-0 left-0 w-full safe-area-pt z-500"
        style={{
          background:
            'linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%)',
        }}
      />
    </>
  )
}

export default Sentry.withErrorBoundary(App, {
  fallback: APP_CRASHED_MESSAGE,
  beforeCapture: (scope) => {
    scope.setExtra('component', 'App')
  },
})

const AppContent = Sentry.withErrorBoundary(
  () => {
    console.debug('App content')
    const [ready, setReady] = useState(false)
    const [loaded, setLoaded] = useState(0)
    const [status, setStatus] = useState<Status>('loading')
    const { log } = useLogger()

    console.debug('ready', ready)

    const checkForUpdates = async (shouldConfirm: boolean) => {
      if (process.env.NODE_ENV === 'development' || isBrowser()) {
        setReady(true)
      } else {
        try {
          console.debug('before maybeDeleteOldVersion')
          // We try to delete old versions if there are any
          await maybeDeleteOldVersion((error) => {
            log('error', 'Maybe Delete Old Build Version', {
              error,
            })
          })

          console.debug('before newVersion')
          const newVersion = await Deploy.checkForUpdate()

          if (newVersion.available) {
            console.debug('new version available')

            const confirmed = shouldConfirm
              ? window.confirm(
                  'There is a new version of the app, would you like to update now? (it will reload the app).',
                )
              : true

            if (confirmed) {
              setReady(false)
              setStatus('downloading')

              await Deploy.downloadUpdate((progress) => {
                setLoaded(progress ?? 0)
              })

              setLoaded(0)
              setStatus('installing')

              await Deploy.extractUpdate((progress) => {
                setLoaded(progress ?? 0)
              })

              window.alert(
                'Ohmunity™ has been updated to the latest version! The app will reload',
              )

              await Deploy.reloadApp()
            }
          } else {
            setReady(true)
          }
        } catch (error) {
          const message =
            error instanceof Error
              ? error.message
              : typeof error === 'string'
              ? error
              : ''

          if (!message.includes('The device maybe offline')) {
            log('error', 'Appflow Deploy Extra New Version', {
              error,
            })
          }

          setReady(true)
        }
      }
    }

    useEffect(() => {
      console.debug('checkForUpdates')
      checkForUpdates(false)
    }, [])

    useEffect(() => {
      const timer = setInterval(() => {
        checkForUpdates(true)
      }, ms('30m'))

      return () => {
        clearInterval(timer)
      }
    }, [])

    if (!ready) {
      return (
        <div className="flex items-center justify-center h-full">
          <Loading context="Ionic Update">
            {statusToStr(status)}
            {(status === 'downloading' || status === 'installing') &&
              ` - ${loaded}%`}
          </Loading>
        </div>
      )
    }

    return <AppLoaded />
  },
  {
    fallback: APP_CRASHED_MESSAGE,
    beforeCapture: (scope) => {
      scope.setExtra('component', 'AppContent')
    },
  },
)

const AppLoaded = Sentry.withErrorBoundary(
  () => {
    usePushNotification()
    useCheckAccountTimezone()
    useBranchIO()

    return (
      <LocationProvider history={history}>
        <Router />
      </LocationProvider>
    )
  },
  {
    fallback: APP_CRASHED_MESSAGE,
    beforeCapture: (scope) => {
      scope.setExtra('component', 'AppLoaded')
    },
  },
)

// UTILITIES

const statusToStr = patternMatching<Status, string>([
  ['loading', 'Loading app...'],
  ['downloading', 'Downloading update'],
  ['installing', 'Installing update'],
])

const maybeDeleteOldVersion = async (onError: (error: any) => void) => {
  const versions = await Deploy.getAvailableVersions()
  const current = await Deploy.getCurrentVersion()

  if (versions.length > 1) {
    const oldVersion = versions[0].versionId

    if (current?.versionId !== oldVersion) {
      try {
        await Deploy.deleteVersionById(oldVersion)
      } catch (error) {
        onError(error)
      }
    }
  }
}
