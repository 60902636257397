type Props = {
  children: any
}

const Note = ({ children }: Props) => {
  return (
    <div className="bg-white p-4 shadow">
      <p className="heading-2 text-primary pb-2">Note</p>
      {children}
    </div>
  )
}

export default Note
