import React from 'react'
import useIsMounted from 'hooks/useIsMounted'
import { navigate } from 'clients/navigation.client'
import { useAuth } from 'stores/auth.store'
import { ReactComponent as Meditation } from 'assets/illustrations/meditation.svg'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Logout = (_props: any) => {
  const isMounted = useIsMounted()
  const { logout } = useAuth()

  React.useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        logout()
        navigate('/')
      }
    }, 1000)
  }, [logout])

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <Meditation width="120" className="fill-primary" />
      <p className="heading-2 text-primary">See you soon!</p>
    </div>
  )
}

export default Logout
