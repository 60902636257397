import { DB_DATE_FORMAT, SELECT_TIME_FORMAT } from 'globalConstants'
import moment from 'moment'
import { dayToDate } from './date'

type DateTime = {
  id: string
  start: string
  end: string
  teacher_id: string
} & (
  | {
      date: string
    }
  | {
      day: number
    }
)

const validateDateTimesCollision = (
  dateTimes: DateTime[],
):
  | {
      overlap: true
      indexA: number
      indexB: number
    }
  | { overlap: false } => {
  for (let i = 0; i < dateTimes.length; i++) {
    for (let j = 0; j < dateTimes.length; j++) {
      if (i !== j) {
        const a = dateTimes[i]
        const b = dateTimes[j]

        const dateA =
          'day' in a
            ? dayToDate(moment.utc(), a.day, { formatted: true })
            : a.date
        const dateB =
          'day' in b
            ? dayToDate(moment.utc(), b.day, { formatted: true })
            : b.date

        const startA = moment.utc(
          [dateA, a.start].join('-'),
          [DB_DATE_FORMAT, SELECT_TIME_FORMAT].join('-'),
        )
        const endA = moment.utc(
          [dateA, a.end].join('-'),
          [DB_DATE_FORMAT, SELECT_TIME_FORMAT].join('-'),
        )
        const startB = moment.utc(
          [dateB, b.start].join('-'),
          [DB_DATE_FORMAT, SELECT_TIME_FORMAT].join('-'),
        )
        const endB = moment.utc(
          [dateB, b.end].join('-'),
          [DB_DATE_FORMAT, SELECT_TIME_FORMAT].join('-'),
        )

        if (
          startA.isSame(startB) ||
          startA.isBetween(startB, endB) ||
          endA.isBetween(startB, endB) ||
          startB.isBetween(startA, endA) ||
          endB.isBetween(startA, endA)
        ) {
          return {
            overlap: true,
            indexA: i,
            indexB: j,
          }
        }
      }
    }
  }

  return {
    overlap: false,
  }
}

export default validateDateTimesCollision
