import Welcome from './Welcome'
import Student from './Student'
import Teacher from './Teacher'
import Studio from './Studio'
import AccountCreated from './AccountCreated'
import StudentPreferences from 'screens/StudentPreferences'
import { Router } from '@reach/router'
import { isWebapp } from 'utils/env'

const Signup = () => {
  return (
    <Router className={isWebapp() ? 'h-full' : 'fixed inset-0'}>
      <Welcome path="/" />
      <StudentPreferences path="/preferences" />
      <Student path="/student" />
      <Teacher path="/teacher" />
      <Studio path="/studio" />
      <AccountCreated path="/done" />
    </Router>
  )
}

export default Signup
