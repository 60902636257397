import React from 'react'
import cc from 'classcat'
import { ReactComponent as Close } from 'assets/symbols/x.svg'

import styles from './index.module.scss'
import patternMatching from 'utils/patternMatching'
import { isWebapp } from 'utils/env'

const TIMER_IN_SECS = 1000 * 10

type ContextValue = {
  notify: (toast: Toast) => void
  dismiss: (index: number) => void
}

const Context = React.createContext<ContextValue>({
  notify: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
  dismiss: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
})

type Type = 'info' | 'success' | 'failure' | 'warning'

type Timer = number

type Toast = {
  type: Type
  title?: string
  message: React.ReactNode
  preventAutoClose?: boolean
  onClose?: () => void
}

type ToastProviderProps = {
  children: any
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [state, setState] = React.useState<Array<[Toast, Timer]>>([])

  const dismiss = (index: number) => {
    setState((currentState) => {
      const newState = [...currentState]

      if (index != null) {
        const timer = currentState[index][1]

        window.clearTimeout(timer)

        const [[removedState]] = newState.splice(index, 1)

        removedState?.onClose?.()
      }

      return newState
    })
  }

  const notify = (toast: Toast) => {
    if (toast.preventAutoClose) {
      setState([...state, [toast, -1]])
    } else {
      let timerID = -1
      timerID = window.setTimeout(() => {
        setState((currentState) => {
          const index = currentState.findIndex(([, t]) => t === timerID)
          const newState = [...currentState]

          if (index != null) {
            newState.splice(index, 1)
          }

          return newState
        })
      }, TIMER_IN_SECS)

      setState([...state, [toast, timerID]])
    }
  }

  return (
    <Context.Provider
      value={{
        notify,
        dismiss,
      }}
    >
      {children}

      {state.length > 0 && (
        <div
          className={cc([
            'fixed w-full max-h-full left-0 overflow-auto z-995',
            {
              'top-0': !isWebapp(),
              'bottom-0': isWebapp(),
            },
          ])}
          data-testid="toast-m"
        >
          <ul>
            {state.map(([{ type, title, message }], i) => (
              <li
                key={`toast-${i}`}
                className={cc([
                  {
                    'safe-area-pt': !isWebapp() && i === 0,
                  },
                ])}
              >
                <div
                  className={cc([
                    'backdrop-filter backdrop-blur bg-white bg-opacity-60',
                    {
                      'p-2': isWebapp(),
                    },
                  ])}
                >
                  <ToastMessage
                    type={type}
                    title={title}
                    message={message}
                    onClick={() => {
                      dismiss(i)
                    }}
                    animated
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Context.Provider>
  )
}

export const useToast = (): ContextValue => React.useContext(Context)

const typeToBgColor = patternMatching<Type, string>([
  ['info', 'bg-primary'],
  ['success', 'bg-green'],
  ['failure', 'bg-red-700'],
  ['warning', 'bg-yellow-500'],
])

const typeToTextColor = patternMatching<Type, string>([
  ['info', 'text-white'],
  ['success', 'text-white'],
  ['failure', 'text-white'],
  ['warning', ''],
])

const typeToFillColor = patternMatching<Type, string>([
  ['info', 'fill-white'],
  ['success', 'fill-white'],
  ['failure', 'fill-white'],
  ['warning', ''],
])

type ToastMessageProps = {
  type: Type
  message: React.ReactNode
  onClick: () => void
  animated?: boolean
  title?: string
}

export const ToastMessage = ({
  type,
  title,
  message,
  onClick,
  animated,
}: ToastMessageProps) => {
  return (
    <button
      type="button"
      className={cc([
        'flex w-full p-4 shadow bg-opacity-95',
        typeToBgColor(type),
        {
          [styles.toast]: animated,
        },
      ])}
      onClick={() => {
        onClick()
      }}
      data-testid="toast"
    >
      <div className="flex-grow" data-testid={`toast-${type}`}>
        {title && (
          <div
            className={cc(['heading-3 pb-1 text-left', typeToTextColor(type)])}
            data-testid="toast-title"
          >
            {title}
          </div>
        )}
        <div
          className={cc(['flex-grow text-left', typeToTextColor(type)])}
          style={{
            maxHeight: 100,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          data-testid="toast-message"
        >
          {message}
        </div>
      </div>
      <div className="ml-4">
        <Close className={cc([typeToFillColor(type)])} width={20} />
      </div>
    </button>
  )
}

ToastMessage.defaultProps = {
  type: 'info',
}
