import * as yup from 'yup'
import moment from 'moment'
import useLogger from 'hooks/useLogger'
import Button from 'components/Button'
import CheckListField from 'formFields/CheckListField'
import FormField, { FormGroup } from 'components/FormField'
import { useAlert } from 'components/AlertModal'
import { useForm } from 'react-hook-form'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useToast } from 'components/ToastMessage'
import { Class_Date_Time, useCancelBookingMutation } from 'generated/graphql'
import { refetchClassAlreadyBooked } from 'hooks/useClassAlreadyBooked'
import { yupResolver } from '@hookform/resolvers'
import { CLASS_BOOK_CANCEL_LIMIT_TIME_IN_HOURS } from 'globalConstants'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { useState } from 'react'

type Props = {
  classBookID: string
  startTimestamp: string
  dateTime: Class_Date_Time
}

const getTimeLeft = (startTimestamp: string, precise = true) =>
  moment.utc(startTimestamp).diff(moment.utc(), 'hours', precise)

const hasFullRefund = (startTimestamp: string) =>
  getTimeLeft(startTimestamp) >= CLASS_BOOK_CANCEL_LIMIT_TIME_IN_HOURS

const CancelForm = ({ classBookID, dateTime }: Props) => {
  const alert = useAlert()
  const toast = useToast()
  const loadingBlock = useLoadingBlock()
  const formMethods = useForm({
    defaultValues: {
      reason: '',
      comments: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        reason: yup.string().required('Please select an option'),
        comments: yup.string().notRequired(),
      }),
    ),
  })
  const { log } = useLogger()
  const { handleSubmit, register, control, formState } = formMethods
  const { errors } = formState
  const [cancelBooking] = useCancelBookingMutation({
    refetchQueries: [refetchClassAlreadyBooked(dateTime)],
    onCompleted: () => {
      loadingBlock.close()
      alert.dismiss()
      toast.notify({
        type: 'success',
        message: 'The booking has been cancelled and a refund has been sent',
      })
    },
    onError: (error) => {
      log('error', 'Cancel Booking', {
        error,
      })
      loadingBlock.close()
      toast.notify({
        type: 'failure',
        message: error.message,
      })
    },
  })

  return (
    <>
      <form
        className="text-left"
        onSubmit={handleSubmit(({ reason, comments }) => {
          loadingBlock.open({
            $size: 'md',
            zIndex: 1000,
          })
          cancelBooking({
            variables: {
              classBookID,
              reason: (reason || '')
                .split(',')
                .map((str) => str.trim())
                .join(','),
              comments,
            },
          })
        })}
      >
        <FormGroup>
          <CheckListField
            name="reason"
            control={control}
            options={[
              {
                id: 'time',
                text: 'The time is not convinient',
              },
              {
                id: 'not-feeling-well',
                text: 'I am not feeling well',
              },
              {
                id: 'changed-mind',
                text: 'I changed my mind',
              },
              {
                id: 'wrong-class',
                text: 'I purchased wrong class',
              },
              {
                id: 'other',
                text: 'Other',
              },
            ]}
            error={errors.reason?.message}
          />

          <FormField
            type="longtext"
            name="comments"
            register={register}
            label="Additional Comments"
            $inputClassName="h-32"
            error={errors.comments?.message}
          />
        </FormGroup>

        <Button $type="tertiary" $fluid className="mt-3">
          Confirm Cancellation
        </Button>

        <SpaceForKeyboard />
      </form>
    </>
  )
}

export const CancelBookingTrigger = (props: Props) => {
  const alert = useAlert()

  return (
    <Button
      $fluid
      onClick={() => {
        alert.send({
          title: 'Are you sure?',
          body: (
            <>
              <CancelModal {...props} />
            </>
          ),
          buttons: [
            {
              $type: 'primary',
              children: 'No, I changed my mind',
            },
          ],
        })
      }}
    >
      Cancel Booking
    </Button>
  )
}

const CancelModal = (props: Props) => {
  const [confirmed, setConfirmed] = useState(false)

  if (!confirmed) {
    return (
      <>
        <div className="space-y-2 pb-8">
          <p>Cancelling a class cannot be undone.</p>
          {hasFullRefund(props.startTimestamp) ? (
            <p>You will receive a full refund in the next 1-2 business days</p>
          ) : (
            <p className="text-red-500">
              <u className="underline">NOTE:</u>
              <br />
              You are about to cancel your booking{' '}
              {getTimeLeft(props.startTimestamp, false)}hs before the class
              begins.
              <br />
              Cancellations made less than 24 hrs before the beginning of the
              class will receive a <u className="underline">50% refund</u>.
            </p>
          )}
        </div>
        <Button
          $type="tertiary"
          type="button"
          onClick={() => {
            setConfirmed(true)
          }}
        >
          Continue with Cancellation
        </Button>
      </>
    )
  }

  return <CancelForm {...props} />
}
