import cc from 'classcat'
import moment from 'moment'
import { useParams, Link } from '@reach/router'
import BackgroundImage from 'components/BackgroundImage'
import Container from 'components/Container'
import Loading from 'components/Loading'
import RoundedPicture from 'components/RoundedPicture'
import ServerErrorMessage from 'components/ServerErrorMessage'
import {
  InsertHelpActivatingAccountDocument,
  InsertHelpActivatingAccountMutation,
  PublicProfileDocument,
  PublicProfileQuery,
  SearchClassesDocument,
  SearchClassesQuery,
} from 'generated/graphql'
import {
  DB_DATE_FORMAT,
  DB_TIME_FORMAT,
  GENERIC_ERROR_MESSAGE,
  SHORT_DOMAIN,
} from 'globalConstants'
import NotFound from 'screens/NotFound'
import { useMutation, useQuery } from 'urql'
import getPicture from 'utils/getPicture'
import ReviewStars from 'components/ReviewStars'
import Sections from 'components/Sections'
import useLogger from 'hooks/useLogger'
import { ReactComponent as IconFacebook } from 'assets/social-media/facebook.svg'
import { ReactComponent as IconInstagram } from 'assets/social-media/instagram.svg'
import { ReactComponent as IconTwitter } from 'assets/social-media/twitter.svg'
import { ReactComponent as IconTiktok } from 'assets/social-media/tiktok.svg'
import { utcDateOrDayToLocalDate } from 'utils/date'
import ClassCard from 'components/ClassCard'
import { useModalScreen } from 'components/ModalScreen'
import ClassDetailsScreen from 'screens/ClassDetails'
import { capitalize, sortBy } from 'lodash'
import { take } from 'lodash/fp'
import PicturesCarousel from 'screens/ClassDetails/PicturesCarousel'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share'
import { useEffect, useState } from 'react'
import copyTextToClipboard from 'utils/copyTextToClipboard'
import ms from 'ms'
import { useAuth } from 'stores/auth.store'
import { Helmet } from 'react-helmet'
import Button from 'components/Button'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { useAlert } from 'components/AlertModal'
import FormField, { ErrorMessage } from 'components/FormField'
import FormGroup from 'components/FormGroup'

const UPCOMING_CLASSES_LIMIT = 4

const UserProfile = () => {
  const { username } = useParams() || {}

  if (!username) {
    return <NotFound />
  }

  return <RenderProfile usernameOrID={username} />
}

export default UserProfile

type Props = {
  usernameOrID: string
}

const RenderProfile = ({ usernameOrID }: Props) => {
  const { account, isAuthenticated } = useAuth()
  const [{ fetching, error, data }] = useQuery<PublicProfileQuery>({
    query: PublicProfileDocument,
    variables: {
      accountID: usernameOrID,
      username: usernameOrID,
    },
    requestPolicy: 'cache-and-network',
  })

  if (fetching) {
    return (
      <Container topBottomSpace className="text-center">
        <Loading />
      </Container>
    )
  }

  if (error) {
    if (error.message.includes('Profile not found')) {
      return <NotFound />
    } else {
      return (
        <Container topBottomSpace className="text-center">
          <ServerErrorMessage>{error.message}</ServerErrorMessage>
        </Container>
      )
    }
  }

  if (!data || !data.publicProfile) {
    return (
      <Container topBottomSpace className="text-center">
        <ServerErrorMessage>{GENERIC_ERROR_MESSAGE}</ServerErrorMessage>
      </Container>
    )
  }

  const { publicProfile } = data
  const coverPictures = publicProfile.coverPictures
  const picture = publicProfile.picture
  const {
    id,
    active,
    type,
    name,
    username,
    reviewsAvg,
    about,
    externalLinks,
    languages,
    teaches,
    certifications,
    socialMedia,
    location,
  } = publicProfile
  const showCover = (coverPictures && coverPictures.length > 0) || picture
  const pageTitle = `${name} at Ohmunity`
  const pageDescription =
    about ||
    (type === 'teacher' || type === 'studio'
      ? `${name} is a Yoga ${capitalize(
          type,
        )} that promotes Yoga Classes at Ohmunity.com.`
      : `${name} is a yogi at Ohmunity.com interested in Yoga.`)
  const pageUrl = `https://${SHORT_DOMAIN}/${username || id}`

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta charSet="UTF-8" />
        <meta name="description" content={pageDescription} />
        <meta name="robots" content="index, follow" />

        {/* Open Graph */}
        <meta property="og:url" content={pageUrl} key="ogurl" />
        <meta
          property="og:image"
          content={
            picture ||
            'https://ohmunity-assets.s3.us-east-2.amazonaws.com/og-image.png'
          }
          key="ogimage"
        />
        <meta property="og:site_name" content="Ohmunity" key="ogsitename" />
        <meta property="og:title" content={pageTitle} key="ogtitle" />
        <meta
          property="og:description"
          content={pageDescription}
          key="ogdesc"
        />

        <link rel="canonical" href={pageUrl} />
      </Helmet>

      <div className="lg:px-5 max-w-4xl mx-auto">
        <Sections $evenClassName="bg-gray-200" $oddClassName="bg-gray-100">
          <div className={cc(['bg-white'])}>
            {/* Cover */}
            <div className={cc(['relative'])}>
              {showCover && (
                <>
                  {coverPictures && coverPictures.length > 0 ? (
                    <PicturesCarousel
                      pictures={(coverPictures as string[]).map(
                        (url, index) => ({
                          url,
                          id: index.toString(),
                        }),
                      )}
                      $size="md"
                      $showArrows
                      $autoPlay
                    />
                  ) : (
                    <div className="overflow-hidden">
                      <BackgroundImage
                        image={coverPictures?.[0] || picture || ''}
                        className={cc([
                          {
                            'filter blur-sm': !coverPictures?.[0],
                            'h-80': coverPictures?.[0],
                            'h-40': !coverPictures?.[0],
                          },
                        ])}
                      />
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Content */}
            <div className="md:flex items-center justify-between py-4">
              {/* Picture + Name (resume) */}
              <div className="flex items-center">
                <div
                  className={cc([
                    {
                      '-mt-20': showCover,
                    },
                  ])}
                >
                  <div
                    className={cc([
                      'ml-4 backdrop-filter backdrop-blur p-1 bg-white bg-opacity-60 rounded-full',
                      {},
                    ])}
                  >
                    <RoundedPicture image={getPicture(picture)} size={140} />
                  </div>

                  <div className="text-center">
                    <ReviewStars value={reviewsAvg || 0} />
                  </div>
                </div>

                <div className={cc(['pl-4 text-primary'])}>
                  <div className="heading-1">{name}</div>
                  {username && <div>@{username}</div>}
                  {location && <div>{location}</div>}
                </div>
              </div>

              {socialMedia && socialMedia.length > 0 && (
                <div className="text-center md:text-right mx-5 pt-8 md:pt-0">
                  <h2 className="heading-2 pb-1">Get in touch</h2>
                  <ul className="inline-flex items-center -mx-2">
                    {socialMedia.map(({ type, url }, i) => (
                      <li className="m-2" key={`social-media-${i}-${type}`}>
                        <SocialIcon type={type} url={url} />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Upcoming classes */}
          <UpcomingClasses accountID={publicProfile.id} />

          {/* About */}
          {about && (
            <div className="p-6">
              <h2 className="heading-2 pb-4 text-primary">About</h2>
              {about}
            </div>
          )}

          {/* Links */}
          {externalLinks && externalLinks.length > 0 && (
            <div className="p-6">
              <h2 className="heading-2 pb-4 text-primary">Links</h2>

              <ul className="grid grid-cols-3 gap-3">
                {externalLinks.map((link, i) => (
                  <li key={`link-${i}-${link.text}`}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-block underline hover:no-underline"
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Additional */}
          {((languages && languages.length > 0) ||
            (teaches && teaches.length > 0) ||
            (certifications && certifications.length > 0)) && (
            <div className="p-6 space-y-8">
              {/* Languages */}
              {languages && languages.length > 0 && (
                <div>
                  <h2 className="heading-2 pb-2 text-primary">Languages</h2>
                  {languages.join(', ')}
                </div>
              )}

              {/* Teaches */}
              {teaches && teaches.length > 0 && (
                <div>
                  <h2 className="heading-2 pb-2 text-primary">
                    Teaching styles
                  </h2>
                  {teaches.join(', ')}
                </div>
              )}

              {/* Certifications */}
              {certifications && certifications.length > 0 && (
                <div>
                  <h2 className="heading-2 pb-2 text-primary">
                    Certifications
                  </h2>
                  {certifications.join(', ')}
                </div>
              )}
            </div>
          )}
        </Sections>

        {/* Share profile */}
        {active && (
          <div className="py-12 px-2 max-w-md mx-auto">
            <div className="border border-dashed border-gray-500 bg-white p-6 text-center">
              <h3 className="heading-2 text-primary">Share profile</h3>
              <ul className="inline-flex items-center justify-center mt-2">
                <li>
                  <FacebookShareButton
                    url={pageUrl}
                    quote={pageTitle}
                    className="mx-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </li>

                <li>
                  <TwitterShareButton url={pageUrl} className="mx-1">
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </li>

                <li>
                  <LinkedinShareButton url={pageUrl} className="mx-1">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                </li>

                <li>
                  <WhatsappShareButton url={pageUrl} className="mx-1">
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </li>

                <li>
                  <EmailShareButton url={pageUrl} className="mx-1">
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </li>
              </ul>

              <div className="mt-2">
                <OhmLink username={username || id} />

                {id === account.id && (
                  <div className="body-3 pt-2 text-gray-600">
                    <strong>Note:</strong> You can change your username on the{' '}
                    <strong>Manage Profile</strong> screen.
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {!active && (
          <ClaimProfile slug={usernameOrID} type={type} name={name} id={id} />
        )}

        {!isAuthenticated() && (
          <div className="text-center py-6">
            <Link to="/" data-testid="ohmunity-logo">
              <Logo className="w-28 h-16 inline-block ml-4" />
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

type SocialIconProps = {
  url: string
  type: string
}

const SocialIcon = ({ url, type }: SocialIconProps) => {
  const { log } = useLogger()
  const completeUrl = url.startsWith('http')
    ? url
    : `https://${type}.com/${url}`

  switch (type) {
    case 'facebook': {
      return (
        <a
          href={completeUrl}
          target="_blank"
          rel="noreferrer"
          className="block w-5 h-5 hover:opacity-75"
        >
          <IconFacebook className="fill-primary" />
        </a>
      )
    }
    case 'instagram': {
      return (
        <a
          href={completeUrl}
          target="_blank"
          rel="noreferrer"
          className="block w-5 h-5 hover:opacity-75"
        >
          <IconInstagram className="fill-primary" />
        </a>
      )
    }
    case 'twitter': {
      return (
        <a
          href={completeUrl}
          target="_blank"
          rel="noreferrer"
          className="block w-5 h-5 hover:opacity-75"
        >
          <IconTwitter className="fill-primary" />
        </a>
      )
    }
    case 'tiktok': {
      return (
        <a
          href={completeUrl}
          target="_blank"
          rel="noreferrer"
          className="block w-5 h-5 hover:opacity-75"
        >
          <IconTiktok className="fill-primary" />
        </a>
      )
    }
    default: {
      log('warning', `Invalid Social Media type`, {
        url,
        type,
      })
      return null
    }
  }
}

const sortClassesByDateTime = (
  classes: SearchClassesQuery['class'],
): { clazzIndex: number; dateTimeIndex: number; timestamp: number }[] =>
  take(
    UPCOMING_CLASSES_LIMIT,
    sortBy(
      classes.reduce((arr, clazz, i) => {
        clazz.dates_times.forEach((dt, ii) => {
          const date = utcDateOrDayToLocalDate(dt)
          const timestamp = moment(
            `${date} ${dt.start}`,
            `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
          ).valueOf() as number

          arr.push({ clazzIndex: i, dateTimeIndex: ii, timestamp })
        })

        return arr
      }, [] as { clazzIndex: number; dateTimeIndex: number; timestamp: number }[]),
      'timestamp',
    ),
  )

const UpcomingClasses = ({ accountID }: { accountID: string }) => {
  const { log } = useLogger()
  const modal = useModalScreen()
  const from = moment.utc()
  const until = moment.utc().add(7, 'days')
  const datesTimesWhere = {
    _or: [
      {
        date: {
          _is_null: true,
        },
      },
      {
        date: {
          _gte: from.format(DB_DATE_FORMAT),
          _lte: until.format(DB_DATE_FORMAT),
        },
      },
    ],
  }
  const [{ error, data }] = useQuery<SearchClassesQuery>({
    query: SearchClassesDocument,
    variables: {
      where: {
        account_id: { _eq: accountID },
        dates_times: datesTimesWhere,
      },
      datesTimesWhere,
      limit: 20,
      offset: 0,
    },
  })

  if (error) {
    log('error', `User Profile Upcoming Classes`, {
      error,
    })
  }

  if (data && data.class && data.class.length > 0) {
    return (
      <div className="p-6 my-4">
        <h2 className="heading-2 pb-4 text-primary">
          Upcoming Class{data.class.length > 1 ? 'es' : ''}
        </h2>
        <ul className={cc(['grid gap-4 md:grid-cols-2'])}>
          {sortClassesByDateTime(data.class).map(
            ({ clazzIndex, dateTimeIndex }) => {
              const clazz = data.class[clazzIndex]

              return (
                <li key={`class-${clazzIndex}-${dateTimeIndex}`}>
                  <ClassCard
                    clazz={clazz as any}
                    onClick={() => {
                      modal.open({
                        header: 'Class Details',
                        body: (
                          <ClassDetailsScreen
                            classId={clazz.id}
                            dateTimeID={clazz.dates_times[dateTimeIndex].id}
                          />
                        ),
                      })
                    }}
                    dateTimeID={clazz.dates_times[dateTimeIndex].id}
                    to={`/class/${clazz.id}/${clazz.dates_times[dateTimeIndex].id}`}
                  />
                </li>
              )
            },
          )}
        </ul>
      </div>
    )
  }

  return null
}

const OhmLink = ({ username }: { username: string }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false)
      }, ms('5s'))

      return () => {
        clearTimeout(timer)
      }
    }
  }, [copied])

  return (
    <button
      className="relative inline-block py-2 px-8"
      onClick={() => {
        copyTextToClipboard(`https://${SHORT_DOMAIN}/${username}`)
        setCopied(true)
      }}
    >
      {SHORT_DOMAIN}/<strong>{username}</strong>
      {copied && (
        <span className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm">
          Copied to clipboard
        </span>
      )}
    </button>
  )
}

const ClaimProfile = ({
  slug,
  type,
  name,
  id,
}: {
  slug: string
  type: string
  name: string
  id: string
}) => {
  const { isAuthenticated } = useAuth()
  const alert = useAlert()

  if (!isAuthenticated()) {
    return (
      <div className="border-2 border-primary m-5 p-4 text-center bg-white space-y-8">
        <h3 className="heading-1 text-primary">Is this your profile?</h3>

        <div className="inline-block w-full max-w-sm">
          <Button
            type="button"
            $fluid
            onClick={() => {
              window.open(
                `mailto:hello@ohmunity.com?subject=Claiming my Profile&body=Hi Ohmunity. \n\nI would like to Claim my Profile, https://ohm.link/${slug}`,
                '_blank',
              )
            }}
          >
            Claim my profile
          </Button>
          <Button
            $type="link"
            $fluid
            type="button"
            onClick={() => {
              window.open(
                `mailto:hello@ohmunity.com?subject=Remove my Profile&body=Hi Ohmunity. \n\nI would like to have my profile removed from the Application, https://ohm.link/${slug}`,
                '_blank',
              )
            }}
          >
            Remove my profile
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="border-2 border-primary m-5 p-4 text-center bg-white space-y-8">
      <h3 className="heading-1 text-primary">
        Do you know this {capitalize(type)}?
      </h3>

      <p>
        <strong>{name}</strong>'s account is still{' '}
        <strong className="text-red-500">not active</strong>. Help us activating
        it!
      </p>

      <div className="inline-block w-full max-w-sm">
        <Button
          type="button"
          $fluid
          onClick={() => {
            alert.send({
              $type: 'info',
              title: `${name}'s account`,
              body: <ActivateAccount id={id} name={name} />,
              buttons: [],
            })
          }}
        >
          Activate account
        </Button>
      </div>
    </div>
  )
}

const ActivateAccount = ({ id, name }: { id: string; name: string }) => {
  const alert = useAlert()
  const [
    { fetching, error, data },
    helpActivate,
  ] = useMutation<InsertHelpActivatingAccountMutation>(
    InsertHelpActivatingAccountDocument,
  )

  if (data?.insert_help_activating_account_one?.id) {
    return (
      <div>
        <p className="pt-2 pb-6">
          Thank you! We are getting in contact with {name} and we'll let you
          know as soon as the account is active with classes!
        </p>
        <Button
          type="button"
          $fluid
          onClick={() => {
            alert.dismiss()
          }}
        >
          Close
        </Button>
      </div>
    )
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()

        const contactInfo = e.currentTarget.emailOrPhoneNumber.value

        helpActivate({
          contactInfo,
          accountID: id,
        })
      }}
    >
      <p className="pt-2 pb-6">
        Please provide an email and/or phone number so we can contact {name} and
        fully activate the account.
      </p>

      <FormGroup>
        <FormField
          type="text"
          name="emailOrPhoneNumber"
          placeholder="Enter email or phone number"
          required
        />

        {error && <ErrorMessage>{error.message}</ErrorMessage>}

        <Button type="submit" $fluid loading={fetching}>
          Send
        </Button>
      </FormGroup>

      <Button
        type="button"
        $type="tertiary"
        $fluid
        onClick={() => {
          alert.dismiss()
        }}
        disabled={fetching}
      >
        Cancel
      </Button>
    </form>
  )
}

/*
<SpaceForKeyboard /> not needed in this file
*/
