import Container from 'components/Container'
import {
  ModalScreenProvider,
  makeContext as makeModalContext,
} from 'components/ModalScreen'
import StackNavigation, {
  makeContext,
  useStack,
} from 'components/StackNavigation'
import { useToast } from 'components/ToastMessage'
import { useAuth } from 'stores/auth.store'
import Certifications from './Certifications'
import ExternalLinks from './ExternalLinks'
import General from './General'
import Languages from './Languages'
import SocialMedia from './SocialMedia'
import YogaStyles from './YogaStyles'

const StackContext = makeContext()
const ModalInternalContext = makeModalContext()

const EditProfile = () => (
  <StackNavigation Context={StackContext} Component={OptionsWithModal} />
)

const OptionsWithModal = () => (
  <ModalScreenProvider context={ModalInternalContext}>
    <Options />
  </ModalScreenProvider>
)

const Options = () => {
  const { isAuthenticated, canTeachAClass, isEntity } = useAuth()
  const { navigate, back } = useStack(StackContext)
  const toast = useToast()

  if (!isAuthenticated()) {
    return <div>Requires login</div>
  }

  const OPTIONS = canTeachAClass()
    ? [
        {
          text: `${isEntity() ? `Studio` : `Personal`} Information`,
          Component: General,
        },
        {
          text: 'Teaching Styles',
          Component: YogaStyles,
        },
        {
          text: 'Social Media',
          Component: SocialMedia,
        },
        {
          text: 'Links',
          Component: ExternalLinks,
        },
        {
          text: 'Languages',
          Component: Languages,
        },
        {
          text: 'Certifications',
          Component: Certifications,
        },
      ]
    : [
        {
          text: 'Personal Information',
          Component: General,
        },
        {
          text: 'Yoga Styles',
          Component: YogaStyles,
        },
        {
          text: 'Social Media',
          Component: SocialMedia,
        },
      ]

  return (
    <Container topBottomSpace>
      <div className="heading-1 text-primary text-center pb-10">
        What would you like to edit?
      </div>
      <ul className="grid grid-cols-2 gap-6">
        {OPTIONS.map(({ text, Component }, index) => (
          <li key={`option-${index}`}>
            <button
              type="button"
              className="heading-3 block w-full h-24 p-2 shadow-lg text-center bg-white active:bg-gray-300"
              onClick={() => {
                navigate({
                  title: text,
                  Component: () => (
                    <Component
                      onComplete={() => {
                        back()
                        setTimeout(() => {
                          toast.notify({
                            type: 'success',
                            message: `Your ${text} has been updated.`,
                          })
                        }, 500)
                      }}
                    />
                  ),
                })
              }}
            >
              {text}
            </button>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default EditProfile
