import React from 'react'

// const DEBUG_DEFAULT = process.env.NODE_ENV === 'development'
const DEBUG_DEFAULT = false

const DebuggerContext = React.createContext<boolean>(DEBUG_DEFAULT)

export const DebuggerProvider = ({ children }: any) => {
  const [debug] = React.useState(DEBUG_DEFAULT)

  return (
    <DebuggerContext.Provider value={debug}>
      {children}
    </DebuggerContext.Provider>
  )
}

const useDebugger = () => React.useContext(DebuggerContext)

export default useDebugger
