import Container from 'components/Container'
import Button from 'components/Button'
import Modal from 'components/Modal'
import NotLoggedIn from 'components/NotLoggedIn'
import { useAuth } from 'stores/auth.store'
import {
  Class,
  ClassAttendingDocument,
  Class_Date_Time,
  FindClassEventDocument,
  useBookClassIntentMutation,
} from 'generated/graphql'
import Checkout from 'screens/Checkout'
import { ReactComponent as X } from 'assets/symbols/x.svg'
import GenericQueryRender from 'components/GenericQueryRender'
import {
  CheckoutMetadata,
  getCheckoutClass,
  setCheckoutClass,
} from 'stores/checkout.store'
import { useToast } from 'components/ToastMessage'
import { ErrorMessage } from 'components/FormField'
import useLogger from 'hooks/useLogger'
import { dateTimeToISO, utcDateOrDayToUTCDate } from 'utils/date'
import { useState, useEffect, createContext, useContext } from 'react'
import Loading from 'components/Loading'

type Props = {
  clazz: Class
  dateTime: Class_Date_Time
}

type ContextData = Props & {
  toggle: () => void
}

const Context = createContext<null | ContextData>(null)

const BookAndPay = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Button $fluid onClick={toggle}>
        Book and Pay
      </Button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        $contentType="content-base"
        $modalClassName="bg-gray-200"
        LazyChildren={() => (
          <Context.Provider
            value={{
              ...props,
              toggle,
            }}
          >
            <BookAndPayLazyContent />
          </Context.Provider>
        )}
      />
    </>
  )
}

export default BookAndPay

const BookAndPayLazyContent = () => {
  const { dateTime, clazz, toggle } = useContext(Context)!
  const { isAuthenticated } = useAuth()
  const { log } = useLogger()

  if (!isAuthenticated()) {
    return (
      <Container topBottomSpace className="bg-gray-200">
        <div className="w-full relative mb-6">
          <X
            role="button"
            onClick={toggle}
            className="absolute top-0 right-0 w-4"
          />
        </div>
        <NotLoggedIn inlineSignup />
      </Container>
    )
  }

  return (
    <GenericQueryRender
      query={FindClassEventDocument}
      fetchPolicy="network-only"
      variables={{
        classDateTimeID: dateTime.id,
        start: dateTimeToISO(
          utcDateOrDayToUTCDate({
            date: dateTime.date,
            day: dateTime.day,
            start: dateTime.start,
          }),
          dateTime.start,
        ),
      }}
      dataAccess="class_event[0].id"
      NoResults={() => {
        log('error', 'Checkout Class Event not found', {
          clazz,
          dateTime,
        })

        return (
          <Container topBottomSpace>
            <ErrorMessage>
              Unfortunatelly, we cannot process the checkout for this class at
              this time. Please try again or contact support.
            </ErrorMessage>
          </Container>
        )
      }}
      Success={({ data: classEventID }) => {
        if (!classEventID) {
          log('error', 'Checkout Class Event not found', {
            clazz,
            dateTime,
          })
          return (
            <Container topBottomSpace>
              <ErrorMessage>
                Unfortunatelly, we cannot process the checkout for this class at
                this time. Please try again or contact support.
              </ErrorMessage>
            </Container>
          )
        }

        return (
          <GenericQueryRender
            query={ClassAttendingDocument}
            fetchPolicy="network-only"
            variables={{
              classEventID,
            }}
            dataAccess="classAttending.total"
            Success={({ data: total }) => {
              if (total >= clazz.maxCapacity) {
                return (
                  <Container topBottomSpace>
                    <ErrorMessage>
                      We are sorry, you can't book this class right now because
                      is full.
                    </ErrorMessage>
                  </Container>
                )
              }

              return (
                <BookAndPayLazyContentWithClassEventID
                  classEventID={classEventID}
                />
              )
            }}
          />
        )
      }}
    />
  )
}

const BookAndPayLazyContentWithClassEventID = ({
  classEventID,
}: {
  classEventID: string
}) => {
  const toast = useToast()
  const { dateTime, clazz, toggle } = useContext(Context)!
  const { log } = useLogger()
  const { isAuthenticated } = useAuth()
  const [
    checkoutClassState,
    setCheckoutClassState,
  ] = useState<CheckoutMetadata | null>(getCheckoutClass(dateTime.id) || null)
  const [bookClassIntent] = useBookClassIntentMutation({
    onCompleted: ({ bookClassIntent }) => {
      if (bookClassIntent) {
        setCheckoutClass(dateTime.id, {
          classEventID,
          clientSecret: bookClassIntent.clientSecret,
          intentID: bookClassIntent.intentID,
        })

        setCheckoutClassState(getCheckoutClass(dateTime.id)!)
      }
    },
    onError: (error) => {
      log('error', `Book Class Intent`, {
        error,
      })
      toast.notify({
        type: 'failure',
        message: error.message,
      })
    },
  })

  useEffect(() => {
    if (isAuthenticated() && !checkoutClassState) {
      bookClassIntent({
        variables: {
          object: {
            classDateTimeID: dateTime.id,
            classEventID,
          },
        },
      })
    }
  }, [isAuthenticated()])

  if (!isAuthenticated()) {
    return (
      <Container topBottomSpace className="bg-gray-200">
        <div className="w-full relative mb-6">
          <X
            role="button"
            onClick={toggle}
            className="absolute top-0 right-0 w-4"
          />
        </div>
        <NotLoggedIn inlineSignup />
      </Container>
    )
  }

  if (!checkoutClassState) {
    return (
      <Container topBottomSpace>
        <Loading />
      </Container>
    )
  }

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        className="absolute top-0 right-0 p-4 bg-transparent"
      >
        <X className="w-4" />
      </button>
      <Checkout
        data={{
          clazz,
          dateTime,
          classEventID,
        }}
      />
    </>
  )
}
