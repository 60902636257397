import useIsMounted from 'hooks/useIsMounted'
import searchFilterVariables from 'utils/searchFilterVariables'
import sortToOrderBy from 'utils/sortToOrderBy'
import differenceBetweenCoordinates from 'utils/distanceBetweenCoordinates'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Container from 'components/Container'
import SearchLocation, {
  VirtualClassOrLocation,
} from 'components/SearchLocation'
import ServerErrorMessage from 'components/ServerErrorMessage'
import ClassCard from 'components/ClassCard'
import ClassDetailsScreen from 'screens/ClassDetails'
import Tabs, { ItemId } from 'components/Tabs'
import SortAndFilter, {
  Values as SortAndFilterValues,
} from 'screens/SortAndFilter'
import { QUERY_SEARCH_CLASSES } from 'graphql/queries'
import {
  CloseButton,
  useModalScreen,
  withModalScreen,
} from 'components/ModalScreen'
import { useQuery } from '@apollo/client'
import { TimeOfTheDay } from 'types'
import {
  DB_DATE_FORMAT,
  DB_TIME_FORMAT,
  SEARCH_RESULTS_LIMIT,
  TIME_OF_THE_DAY_RANGE,
} from 'globalConstants'
import { Class, Class_Date_Time } from 'generated/graphql'
import { sortBy } from 'lodash/fp'
import { extendMoment } from 'moment-range'
import * as _moment from 'moment'
import CallToAction from 'components/CallToAction'
import { ReactComponent as YogaPose2 } from 'assets/illustrations/childs-pose.svg'
import { useAuth } from 'stores/auth.store'
import { dayToDate, utcDateOrDayToUTCDate } from 'utils/date'
import { useState, useContext, createContext, useEffect } from 'react'
import Page from 'components/Page'
import { SwitchUncontrolled } from 'components/Switch'
import { isWebapp } from 'utils/env'

const moment = extendMoment(_moment)

const TABS = [
  {
    id: 'morning',
    text: 'Morning',
  },
  {
    id: 'afternoon',
    text: 'Afternoon',
  },
  {
    id: 'evening',
    text: 'Evening',
  },
]

const SearchContext = createContext<{
  location: VirtualClassOrLocation | null
  setLocation: (newLocation: VirtualClassOrLocation | null) => void
  activeTab: ItemId
  setActiveTab: (newTab: ItemId) => void
}>({
  location: null,
  setLocation: () => undefined,
  activeTab: TABS[0].id,
  setActiveTab: () => undefined,
})

const useSearchContext = () => useContext(SearchContext)

type Props = {
  closeModal: () => void
}

const Search = ({ closeModal }: Props) => {
  const [location, setLocation] = useState<VirtualClassOrLocation | null>(null)
  const [activeTab, setActiveTab] = useState<ItemId>(
    autodetectTab() || TABS[0].id,
  )

  const online = !!location?.virtualClassEverywhere

  return (
    <>
      {!isWebapp() && (
        <Page.Header
          before={<CloseButton close={() => closeModal()} />}
          after={
            <div className="flex items-center">
              <span className="body-2 text-primary pr-2">Virtual Only</span>
              <SwitchUncontrolled
                value={online}
                onChange={() => {
                  setLocation({
                    ...(location || {}),
                    virtualClassEverywhere: !online,
                  })
                }}
              />
            </div>
          }
        >
          Classes
        </Page.Header>
      )}
      <SearchContext.Provider
        value={{
          location,
          setLocation,
          activeTab,
          setActiveTab,
        }}
      >
        <SearchContent />
      </SearchContext.Provider>
    </>
  )
}

export default withModalScreen(Search)

const SearchContent = () => {
  const [locating, setLocating] = useState(true)
  const { location, setLocation, activeTab, setActiveTab } = useSearchContext()
  const online = !!location?.virtualClassEverywhere
  const showSearchInput = (!isWebapp() && !online) || isWebapp()
  const disableSearchInput = isWebapp() && online

  return (
    <>
      {showSearchInput && (
        <Container className={isWebapp() ? '' : 'bg-white'}>
          {/* <div className="safe-area-pt pb-3">
          <SearchClass />
        </div> */}
          <div className="py-3 relative">
            <SearchLocation
              includeStoredLocation={!location}
              onSearch={setLocation}
              onLoading={(state) => {
                setLocating(state)
              }}
              disabled={disableSearchInput}
            />
          </div>
        </Container>
      )}

      {location ? (
        <>
          {!process.env.REACT_APP_EXPERIMENT_DISABLE_CLASSES_TABS && (
            <Tabs items={TABS} onChange={setActiveTab} activeTab={activeTab} />
          )}
          <Container xSpace bottomSpace>
            <Results />
          </Container>
        </>
      ) : locating ? (
        <>
          <Loading />
          <div className="text-center">Locating...</div>
        </>
      ) : (
        <>
          <CallToAction
            Icon={YogaPose2}
            iconStyle={{ width: 'w-48', heigth: 'h-32', margin: '-mb-12' }}
            title="Oops! Looks like your location cannot be reached"
            description="Please enter your location manually"
            $color="light"
          />
        </>
      )}
    </>
  )
}

type ClassesByDateTime = {
  clazz: Class
  dateTime: Class_Date_Time
  timestamp: number
}

export const byDateTime = (
  clazz: Class,
  dateTime: Class_Date_Time,
  date: string,
): ClassesByDateTime => {
  return {
    clazz,
    dateTime: dateTime,
    timestamp: moment(
      `${date} ${dateTime.start}`,
      `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`,
    ).valueOf() as number,
  }
}

const classesByDateTime = (
  classes: Class[],
  sortAndFilter: SortAndFilterValues | null,
): ClassesByDateTime[] => {
  const todayDay = moment().day()
  const filterDates = sortAndFilter
    ? sortAndFilter.days
        .map((day) => {
          if (todayDay === day) {
            /*
            Can only be explained with an example:
              Let's assume that today is Tuesday 15:00 and the class is recurrent (every tuesday) at 13:00,
              we need the date of today for classes > 15:00 and date of next tuesday for classes < 15:00
            */
            return [
              dayToDate(moment(), day, { formatted: true }),
              dayToDate(moment(), day + 7, { formatted: true }),
            ]
          } else {
            return dayToDate(moment(), day, { formatted: true })
          }
        })
        .flat()
    : []

  const mapped = classes.reduce<ClassesByDateTime[]>(
    (classesAcc, clazz: Class) => {
      const mappedDatesTimes = clazz.dates_times.reduce<ClassesByDateTime[]>(
        (acc, dateTime) => {
          const date = utcDateOrDayToUTCDate({
            date: dateTime.date,
            day: dateTime.day,
            start: dateTime.start,
          })

          if (filterDates.length === 0 || filterDates.includes(date)) {
            return [...acc, byDateTime(clazz, dateTime, date)]
          } else {
            return [...acc]
          }
        },
        [],
      )

      return [...classesAcc, ...mappedDatesTimes]
    },
    [],
  )

  return sortBy('timestamp', mapped)
}

const Results = () => {
  const { location, activeTab, setLocation } = useSearchContext()
  const timeOfTheDay = activeTab as TimeOfTheDay
  // Utilities
  const isMounted = useIsMounted()
  const modal = useModalScreen()
  const { account } = useAuth()

  // Sorting & Filtering
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [offset, setOffset] = useState(0)
  const [
    sortAndFilter,
    setSortAndFilter,
  ] = useState<SortAndFilterValues | null>(null)

  const builtVariables = searchFilterVariables(
    location!,
    timeOfTheDay,
    sortAndFilter,
    account.id,
  )

  const online = !!location?.virtualClassEverywhere

  const { loading, error, data, fetchMore } = useQuery(QUERY_SEARCH_CLASSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...builtVariables,
      datesTimesWhere: builtVariables?.where?.dates_times, // without this, the result of Class will bring all the Dates Times without filtering them because it's a relationshiop.
      orderBy: sortToOrderBy(sortAndFilter?.sortBy),
      limit: SEARCH_RESULTS_LIMIT,
      offset: 0, // CANNOT use "offset" state because it's being handled by 'fetchMore -> updateQuery'
    },
  })

  // Load More
  useEffect(() => {
    if (offset > 0 && isMounted.current) {
      fetchMore<any>({
        variables: {
          offset,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (
            !fetchMoreResult ||
            fetchMoreResult.class.length < SEARCH_RESULTS_LIMIT
          ) {
            setCanLoadMore(false)
          }

          if (!fetchMoreResult) {
            return prev
          }

          return {
            ...prev,
            class: [...prev.class, ...fetchMoreResult.class],
          }
        },
      })
    }
  }, [offset])

  // Prevents the first call of the previous 'useEffect's
  useEffect(() => {
    if (data && data.class?.length < SEARCH_RESULTS_LIMIT) {
      setCanLoadMore(false)
    }
  }, [data])

  return (
    <>
      {loading && <Loading />}

      {error && <ServerErrorMessage>{error.message}</ServerErrorMessage>}

      {!loading && (
        <div className="flex justify-between items-center">
          <div>
            {isWebapp() && (
              <div className="flex items-center">
                <span className="body-2 text-primary pr-2">Virtual Only</span>
                <SwitchUncontrolled
                  value={online}
                  onChange={() => {
                    setLocation({
                      ...(location || {}),
                      virtualClassEverywhere: !online,
                    })
                  }}
                />
              </div>
            )}
          </div>
          <button
            className="body-2 text-primary mt-5 mb-3"
            type="button"
            onClick={() => {
              modal.open({
                header: {
                  children: 'Sort & Filter',
                  before: <CloseButton close={modal.close} />,
                  after: (
                    <Button
                      $type="link"
                      $size="sm"
                      onClick={() => {
                        setSortAndFilter(null)
                        modal.close()
                      }}
                    >
                      Clear All
                    </Button>
                  ),
                },
                body: (
                  <SortAndFilter
                    onlineOnly={!!location?.virtualClassEverywhere}
                    initialValues={sortAndFilter || undefined}
                    onChange={(filters) => {
                      setSortAndFilter(filters)
                      modal.close()
                    }}
                  />
                ),
              })
            }}
          >
            Sort & Filter
          </button>
        </div>
      )}

      {data?.class && data.class.length === 0 && (
        <CallToAction
          Icon={YogaPose2}
          iconStyle={{ width: 'w-48', heigth: 'h-32', margin: '-mb-12' }}
          title="No classes found!"
          description="Try another location and/or change the Sort & Filter settings."
          $color="light"
        />
      )}

      {location && data?.class && data.class.length > 0 && (
        <>
          <ul>
            {classesByDateTime(data.class, sortAndFilter).map(
              ({ clazz, dateTime }, i: number) => (
                <li key={`class-card-${i}`} className="py-2">
                  <ClassCard
                    to={`/class/${clazz.id}/${dateTime.id}`}
                    clazz={clazz}
                    onClick={(clazz) => {
                      modal.open({
                        header: 'Class Details',
                        body: (
                          <ClassDetailsScreen
                            classId={clazz.id}
                            preloadedClass={clazz}
                            dateTimeID={dateTime.id}
                          />
                        ),
                      })
                    }}
                    dateTimeID={dateTime.id}
                    distance={
                      location.place
                        ? differenceBetweenCoordinates(
                            location.place.coordinates!,
                            {
                              lng: clazz.location?.coordinates.coordinates[0],
                              lat: clazz.location?.coordinates.coordinates[1],
                            },
                          )
                        : undefined
                    }
                  />
                </li>
              ),
            )}
          </ul>

          {canLoadMore && (
            <div className="pt-3">
              <Button
                onClick={() => {
                  setOffset(offset + SEARCH_RESULTS_LIMIT)
                }}
                $fluid
              >
                Load more
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

const autodetectTab = (): TimeOfTheDay | null => {
  const now = moment()
  const options: TimeOfTheDay[] = ['morning', 'afternoon', 'evening']

  for (const option of options) {
    const [from, to] = TIME_OF_THE_DAY_RANGE[option]

    if (option === 'evening') {
      if (
        now.diff(moment(from, DB_TIME_FORMAT)) > 0 ||
        now.diff(moment(to, DB_TIME_FORMAT)) < 0
      ) {
        return 'evening'
      }
    } else {
      const range = moment.range(
        moment(from, DB_TIME_FORMAT),
        moment(to, DB_TIME_FORMAT),
      )

      if (now.within(range)) {
        return option
      }
    }
  }

  return null
}
