import React, { ChangeEvent, KeyboardEvent, OptionHTMLAttributes } from 'react'
import cc from 'classcat'
import pick from 'lodash/fp/pick'
import omit from 'lodash/fp/omit'
import ReactInputMask from 'react-input-mask'
import { Control, Controller } from 'react-hook-form'
import styles from './index.module.scss'
import DropdownField from 'formFields/DropdownField'
import _FormGroup from 'components/FormGroup'

/*
NOTES
-----------
Be careful with 'control'
https://github.com/react-hook-form/react-hook-form/issues/4034
https://github.com/react-hook-form/react-hook-form/blob/5106b799ae0b659c529595ed6d9a392a7662c30d/src/useFieldArray.ts#L89-L108
*/

type InputMode =
  | 'text'
  | 'tel'
  | 'numeric'
  | 'none'
  | 'url'
  | 'email'
  | 'decimal'
  | 'search'

export const Label = ({
  children,
  htmlFor,
  $fontSize,
}: {
  children: string | React.ReactElement
  htmlFor?: string
  $fontSize?: 'heading-3' | 'heading-2'
}) => (
  <label
    className={cc([
      'block pb-1 text-primary',
      {
        'heading-2': $fontSize === 'heading-2',
        'heading-3': $fontSize === 'heading-3',
      },
    ])}
    htmlFor={htmlFor}
  >
    {children}
  </label>
)

Label.defaultProps = {
  $fontSize: 'heading-3',
}

export const ErrorMessage = ({ children }: any) => (
  <div className="text-red-800 pt-1 text-left">{children}</div>
)

export const DropdownOption = (
  props: OptionHTMLAttributes<HTMLOptionElement> & {
    children: string
  },
) => <option {...props} />

export const FormGroup = _FormGroup

export type Props = {
  // Same name as native html
  type:
    | 'text'
    | 'longtext'
    | 'dropdown'
    | 'radio'
    | 'number'
    | 'date'
    | 'mask'
    | 'tel'
    | 'checkbox'
    | 'email'
    | 'url'
  id?: string
  name?: string
  value?: string
  disabled?: boolean
  placeholder?: string
  readOnly?: boolean
  onChange?: (
    newValue: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void
  onFocus?: (
    value: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void
  onBlur?: (
    value: string,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => void
  onClick?: () => void
  onKeyDown?: (
    e: KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  style?: any
  required?: boolean
  autoFocus?: boolean
  maxLength?: number
  min?: number | string
  max?: number | string
  pattern?: string
  inputMode?: InputMode
  ['data-id']?: string | number
  ['data-testid']?: string

  // Custom names, no conflicts with native html
  children?: any
  label?: string | React.ReactElement
  register?: any
  control?: Control
  initialValue?: string
  error?: string
  mask?: string | Array<string | RegExp>
  maskChar?: string | null
  Icon?: any
  $rootClassName?: string
  $inputClassName?: string
  defaultValue?: string | number
  isBig?: boolean
  prefix?: string
  registerOptions?: any
  sublabel?: string | React.ReactElement
}

const splitNativeAttrsFromCustom = (props: Props) => {
  // const nativeAttrsList = [
  //   'type',
  //   'id',
  //   'name',
  //   'value',
  //   'disabled',
  //   'placeholder',
  //   'readOnly',
  //   'onChange',
  //   'onFocus',
  //   'onBlur',
  //   'onClick',
  //   'onKeyDown',
  //   'style',
  //   'required',
  //   'autoFocus',
  //   'maxLength',
  //   'min',
  //   'max',
  //   'pattern',
  //   'data-id',
  //   'inputMode',
  // ]
  const customAttrsList = [
    'children',
    'label',
    'register',
    'control',
    'initialValue',
    'error',
    'mask',
    'maskChar',
    'Icon',
    '$rootClassName',
    '$inputClassName',
    'defaultValue',
    'isBig',
    'prefix',
    'registerOptions',
    'sublabel',
  ]

  return {
    nativeAttrs: omit(customAttrsList, props),
    customAttrs: pick(customAttrsList, props),
  }
}

const FormField = (props: Props) => {
  const { nativeAttrs, customAttrs } = splitNativeAttrsFromCustom(props)
  const {
    type,
    id,
    name,
    value,
    readOnly,
    onChange,
    onClick,
    onBlur,
    onFocus,
    ['data-testid']: dataTestID,
  } = nativeAttrs
  const {
    Icon,
    label,
    error,
    register,
    control,
    initialValue,
    $rootClassName,
    mask,
    maskChar,
    children,
    defaultValue,
    isBig,
    $inputClassName,
    registerOptions,
    sublabel,
  } = customAttrs

  const [, setFocus] = React.useState(false)
  const [text, setText] = React.useState(initialValue)

  if (!id && !name) {
    throw new Error("Provide either 'id' or 'name' prop to FormField")
  }

  const isControlled = !register && !control && value == null

  const commonAttrs = {
    ...nativeAttrs,
    id: (id || name) as string,
    name: (name || id) as string,
    ref: register
      ? typeof register === 'function'
        ? register(registerOptions)
        : register
      : control?.register(registerOptions),
    defaultValue,
    type,
    value: isControlled ? text ?? defaultValue : value,
    onChange: (
      e: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { value } = e.target
      const cleanValue = value.trim()

      if (isControlled) {
        setText(cleanValue)
      }

      if (control && name && cleanValue !== value) {
        control.setValue(name, cleanValue)
      }

      onChange?.(e.target.value, e)
    },
    onFocus: (
      e?: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      setFocus(true)
      if (e) {
        onFocus?.(e.target.value, e)
      }
    },
    onBlur: (
      e?: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      setFocus(false)

      if (e) {
        e.target.value = (e.target.value || '').trim()
        onBlur?.(e.target.value, e)
      }
    },
    onClick: () => {
      onClick?.()
    },
  }
  const commonClassName =
    'appearance-none bg-white text-gray-800 placeholder-gray-500 resize-none'

  switch (type) {
    case 'text':
    case 'number':
    case 'tel':
    case 'email':
    case 'url':
    case 'mask': {
      const className = cc([
        commonClassName,
        'h-11 border border-gray-300 shadow-sm p-3 w-full focus:outline-none',
        {
          'focus:border-green focus:shadow-lg': !error && !readOnly,
          'pl-10':
            Icon || (customAttrs.prefix && customAttrs.prefix.length > 1),
          'pl-6': customAttrs.prefix && customAttrs.prefix.length === 1,
          'border-red-800': error,
          'bg-gray-300': nativeAttrs.disabled,
          'h-16 uppercase heading-2': !!isBig,
          'body-1': !isBig,
        },
        $inputClassName,
      ])
      const currentElementProps =
        type === 'number'
          ? {
              inputMode: 'numeric' as 'numeric',
              pattern: '[0-9]*',
            }
          : type === 'email'
          ? { inputMode: 'email' as 'email' }
          : {}

      return (
        <>
          <div className={$rootClassName}>
            {label && <Label htmlFor={name || id}>{label}</Label>}
            <div className="relative">
              {type === 'mask' ? (
                <Controller
                  {...omit('ref', commonAttrs)}
                  control={control}
                  render={(props) => (
                    <ReactInputMask
                      {...props}
                      onChange={(e) => {
                        props.onChange(e)

                        if (onChange) {
                          onChange(e.target.value, e)
                        }
                      }}
                      className={className}
                      mask={mask as any}
                      maskChar={maskChar}
                      placeholder={commonAttrs.placeholder}
                      inputMode={nativeAttrs.inputMode}
                      pattern={nativeAttrs.pattern}
                      data-testid={nativeAttrs['data-testid']}
                      {...currentElementProps}
                    />
                  )}
                />
              ) : (
                <input
                  {...commonAttrs}
                  {...currentElementProps}
                  className={className}
                  style={{ letterSpacing: '0.5px' }}
                  autoCapitalize="none"
                />
              )}
              {customAttrs.prefix && (
                <span className="absolute top-2/4 transform -translate-y-2/4 left-3">
                  {customAttrs.prefix}
                </span>
              )}
              {Icon && (
                <Icon
                  className={cc([styles.icon, 'absolute ml-2 fill-gray-700'])}
                  style={{ height: 20, maxWidth: 25 }}
                />
              )}
            </div>
            {sublabel && (
              <div className="body-3 pt-1 text-gray-600 text-right">
                {sublabel}
              </div>
            )}
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )
    }

    case 'longtext': {
      return (
        <>
          {label && <Label htmlFor={name || id}>{label}</Label>}
          <textarea
            {...commonAttrs}
            className={cc([
              commonClassName,
              'block border border-gray-300 shadow-sm px-2 py-2  w-full focus:outline-none',
              {
                'focus:border-green focus:shadow-lg': !error && !readOnly,
                'pl-10': Icon,
                'border-red-800': error,
              },
              $inputClassName,
            ])}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )
    }

    case 'dropdown': {
      if (!control) {
        throw new Error('Form Field "dropdown" requires "control"')
      }

      return (
        <>
          {label && <Label htmlFor={name || id}>{label}</Label>}

          <DropdownField
            data-testid={dataTestID}
            $inputClassName={cc([
              'appearance-none border border-gray-300 pl-2 pr-8 py-2 text-gray-900 w-full focus:outline-none',
              {
                'focus:border-green focus:shadow-lg': !error && !readOnly,
                'pl-10': Icon,
                'border-red-800': error,
                'h-11': !isBig,
                'h-16 uppercase heading-2': !!isBig,
                'bg-white': !commonAttrs.disabled,
                'bg-gray-400': commonAttrs.disabled,
              },
              $inputClassName,
            ])}
            name={commonAttrs.name}
            placeholder={commonAttrs.placeholder}
            control={control!}
            options={React.Children.toArray(children)
              .filter(Boolean)
              .map((child: any) => ({
                value: child.props.value,
                text: child.props.children,
              }))}
            onChange={(newValue) => {
              if (onChange) {
                onChange?.(newValue, {} as any)
              }
            }}
            disabled={commonAttrs.disabled}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )
    }

    case 'radio':
    case 'checkbox': {
      return (
        <>
          <div className="flex items-center">
            <div className="relative">
              <input
                {...omit(
                  ['onChange', 'onFocus', 'onBlur', 'data-testid'],
                  commonAttrs,
                )}
                type={type}
                className={type === 'radio' ? styles.radio : styles.checkbox}
              />
              <div
                className={
                  type === 'radio' ? styles.radioMark : styles.checkboxMark
                }
              />
            </div>
            {label && (
              <label
                className={[
                  'body-2 text-gray-900 leading-none z-10',
                  $inputClassName,
                ]
                  .filter(Boolean)
                  .join(' ')}
                htmlFor={id}
                style={{
                  // hardcoded values since they are very tied to the 'styles.radiomark'
                  paddingTop: 6,
                  paddingBottom: 6,
                  marginLeft: -28,
                  paddingLeft: 30,
                  letterSpacing: 0.5,
                }}
                {...pick('data-testid', nativeAttrs)}
              >
                {label}
              </label>
            )}
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )
    }

    case 'date': {
      return (
        <>
          <div className={cc(['relative', $rootClassName])}>
            {label && <Label htmlFor={name || id}>{label}</Label>}
            <input
              {...commonAttrs}
              className={cc([
                commonClassName,
                'border border-gray-300 shadow-sm px-2 py-2 w-full focus:outline-none',
                {
                  'focus:border-green focus:shadow-lg': !error && !readOnly,
                  'pl-10': Icon,
                  'border-red-800': error,
                },
                $inputClassName,
              ])}
              autoCapitalize="none"
            />
            {Icon && (
              <Icon
                className={cc([styles.icon, 'absolute ml-2'])}
                style={{ height: 20, maxWidth: 25 }}
              />
            )}
          </div>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )
    }

    default:
      return <div>Invalid Type ${type}</div>
  }
}

FormField.defaultProps = {
  initialValue: '',
  autoComplete: '!off',
}

export default FormField

/*
<SpaceForKeyboard /> not needed in this file
*/
