import { flow, filter, join } from 'lodash/fp'
import { Location } from 'generated/graphql'

const joinBySpace = flow(filter(Boolean), join(' '))

const joinByComma = flow(filter(Boolean), join(', '))

const locationToString = (location: Location) => {
  return joinByComma([
    joinBySpace([location.address, location.addressSecondary]),
    location.city,
    joinBySpace([location.state, location.zipCode]),
  ])
}

export default locationToString
