import Button from 'components/Button'
import { useStack } from 'components/StackNavigation'
import FormAddCard from 'forms/FormAddCard'
import Container from 'components/Container'
import { useApolloClient } from '@apollo/client'
import { useAuth } from 'stores/auth.store'
import StackContext from './context'
import useActionMutation from 'hooks/useActionMutation'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import {
  AddCreditCardDocument,
  BillingSourcesDocument,
} from 'generated/graphql'

/*
<SpaceForKeyboard /> not needed, used in FormAddCard
*/

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
)

const AddPaymentMethod = () => {
  const client = useApolloClient()
  const { navigate, back } = useStack(StackContext)
  const { canTeachAClass } = useAuth()
  const actionMutation = useActionMutation()

  return (
    <Button
      type="button"
      $type="primary"
      $fluid
      onClick={() => {
        navigate({
          title: 'Add a Card',
          // eslint-disable-next-line react/display-name
          Component: () => (
            <Container topBottomSpace>
              <Elements stripe={stripePromise}>
                <FormAddCard
                  onSubmit={async (_paymentMethod, token) => {
                    await actionMutation({
                      mutation: {
                        mutation: AddCreditCardDocument,
                        variables: {
                          cardToken: token,
                        },
                      },
                      errorContext: 'Add Credit Card',
                      onSuccess: (newCard: any) => {
                        const cached = client.readQuery({
                          query: BillingSourcesDocument,
                          variables: {
                            withCards: true,
                            withBankAccounts: canTeachAClass(),
                          },
                        })

                        client.writeQuery({
                          query: BillingSourcesDocument,
                          variables: {
                            withCards: true,
                            withBankAccounts: canTeachAClass(),
                          },
                          data: {
                            billingSources: {
                              ...cached.billingSources,
                              cards: [
                                ...cached.billingSources.cards,
                                newCard.addCreditCard,
                              ],
                            },
                          },
                        })

                        back()
                      },
                      autoNotifySuccess: {
                        text: 'The Card has been added successfuly.',
                      },
                    })
                  }}
                />
              </Elements>
            </Container>
          ),
        })
      }}
    >
      + Add a Card
    </Button>
  )
}

export default AddPaymentMethod
