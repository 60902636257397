import { ApolloError } from '@apollo/client'

type State = {
  loading: boolean
  error?: ApolloError | null
  data?: any
}

export type MergedStates = {
  loading: boolean
  errors: ApolloError[]
  datas: any[]
  error: ApolloError | null
  data: any | null
}

function stateUnion(states: State[]): MergedStates {
  const loading = states.some(({ loading }) => loading)

  if (loading) {
    return {
      loading,
      errors: [],
      datas: [],
      error: null,
      data: null,
    }
  }

  const errors = states
    .map(({ error }) => error)
    .filter(Boolean) as ApolloError[]
  const datas = states.map(({ data }) => data).filter(Boolean)

  return {
    loading: false,
    errors,
    datas,
    error: errors[0] || null,
    data:
      datas.length > 0
        ? datas.reduce((acc, data) => ({ ...acc, ...data }), {})
        : null,
  }
}

export default stateUnion
