import React from 'react'
import FormField, { ErrorMessage } from 'components/FormField'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import LocationAutocomplete from 'components/LocationAutocomplete'
import { useFormContext } from 'react-hook-form'

export interface Values {
  name: string
  address: string
  addressSecondary?: string
  city: string
  state: string
  zipCode: string
  placeID: string
  coordinateLat: string
  coordinateLng: string
}

export const formAddressValidationSchema = yup.object().shape({
  name: yup.string().required('This field is required').min(3).max(20),
  ...Validation.address,
})

type Props = {
  type?: 'new' | 'edit'
  onSearch?: (searched: boolean) => void
}

const FormAddress = ({ type }: Props) => {
  const [searched, setSearched] = React.useState<boolean>(type === 'edit')
  const [initialPrimary, setInitialPrimary] = React.useState('') // needed to avoid 'watch'
  const { register, formState, setValue, getValues } = useFormContext()
  const { errors } = formState
  const errorBeforeAutoComplete =
    !searched &&
    (errors.address || errors.city || errors.state || errors.zipCode)

  React.useEffect(() => {
    const address = getValues('address')
    if (address) {
      setSearched(true)
      setInitialPrimary(address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <input type="hidden" name="address" ref={register} />
      <input type="hidden" name="city" ref={register} />
      <input type="hidden" name="state" ref={register} />
      <input type="hidden" name="placeID" ref={register} />
      <input type="hidden" name="coordinateLat" ref={register} />
      <input type="hidden" name="coordinateLng" ref={register} />

      {/* Address */}
      <div className="py-2" data-testid="location-autocomplete">
        <LocationAutocomplete
          type="address"
          onSelect={({ parsed, coordinates, suggestion }) => {
            const { address, city, state } = parsed

            setSearched(true)
            setValue('address', address, { shouldDirty: true })
            setValue('city', city, { shouldDirty: true })
            setValue('state', state, { shouldDirty: true })
            setValue('placeID', suggestion.placeID, { shouldDirty: true })
            setValue('coordinateLat', coordinates?.lat, { shouldDirty: true })
            setValue('coordinateLng', coordinates?.lng, { shouldDirty: true })
          }}
          label="Address *"
          name="address-autocomplete"
          includeCoordinates
          initialValue={initialPrimary}
        />
      </div>

      {errorBeforeAutoComplete && (
        <ErrorMessage>Address is required</ErrorMessage>
      )}

      <div className={searched ? 'block' : 'hidden'}>
        <div className="py-2 grid grid-cols-2 gap-2">
          {/* Address Secondary */}
          <div data-testid="addres-secondary">
            <FormField
              type="text"
              name="addressSecondary"
              label="Suite, Apt, etc"
              register={register}
            />
          </div>

          {/* Zip Code */}
          <div data-testid="zip-code">
            <FormField
              type="tel"
              name="zipCode"
              label="Zip Code *"
              register={register}
              error={errors.zipCode?.message}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default FormAddress
