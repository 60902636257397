import FormField from 'components/FormField'
import { useModalScreen } from 'components/ModalScreen'
import { Control } from 'react-hook-form'
import PrivacyPolicy from 'screens/PrivacyPolicy'
import TermsAndConditions from 'screens/TermsAndConditions'

type Props = {
  name: string
  control: Control
}

const TermsAndConditionsField = ({ name, control }: Props) => {
  const modal = useModalScreen()
  const { register, formStateRef } = control
  const { errors } = formStateRef.current

  return (
    <>
      <FormField
        type="checkbox"
        name={name}
        id={name}
        register={register}
        label={
          <>
            I have read and agree to the{' '}
            <span
              className="text-primary"
              onClick={() => {
                modal.open({
                  header: 'Terms & Conditions',
                  body: <TermsAndConditions />,
                })
              }}
            >
              Terms &amp; Conditions
            </span>{' '}
            and the{' '}
            <span
              className="text-primary"
              onClick={() => {
                modal.open({
                  header: 'Privacy Policy',
                  body: <PrivacyPolicy />,
                })
              }}
            >
              Privacy Policy
            </span>
          </>
        }
        error={errors[name]?.message}
        data-testid="checkbox-terms-and-conditions"
      />
    </>
  )
}

export default TermsAndConditionsField
