import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'

import MultiPicture, {
  MultiPictureUploadContext,
} from 'components/MultiPictureUpload'
import { useFormCarousel } from 'forms/FormCarousel/context'
import LiveSupportButton from 'components/LiveSupportButton'

const Picture = () => {
  const { next, initialValues } = useFormCarousel()

  return (
    <Container topBottomSpace className="flex flex-col h-full">
      <div className="text-center">
        <div className="heading-1 text-primary">
          Please upload a some pics
          <br />
          for your studio
        </div>
      </div>

      <div className="py-5">
        <MultiPictureUploadContext
          limit={6}
          initialPictures={initialValues?.photos || []}
        >
          <MultiPicture
            onDone={async (newPhotos, setIsDirty) => {
              setIsDirty(false)
              next({
                photos: newPhotos.map((x) => x.url!),
              })
            }}
          >
            {(trigger, { loading }) => (
              <div className="pt-6">
                <Button
                  type="button"
                  $fluid
                  loading={loading}
                  onClick={() => {
                    trigger()
                  }}
                >
                  Get Started
                </Button>
              </div>
            )}
          </MultiPicture>
        </MultiPictureUploadContext>

        <LiveSupportButton />
      </div>
    </Container>
  )
}

export default Picture
