import React from 'react'
import { Link, useLocation } from '@reach/router'
import { useRoutes } from 'Router'
import { toolbarWithTo } from 'utils/menu'
import { Plugins } from '@capacitor/core'
import isBrowser from 'utils/isBrowser'

const { Network } = Plugins

const Toolbar = () => {
  const { pathname } = useLocation()
  const routes = useRoutes()
  const [hasInternet, setHasInternet] = React.useState(true)

  React.useEffect(() => {
    if (!isBrowser()) {
      ;(async () => {
        await Network.requestPermissions?.()

        Network.getStatus().then((status) => {
          setHasInternet(status.connected)
        })

        Network.addListener('networkStatusChange', (status) => {
          setHasInternet(status.connected)
        })
      })()
    }

    return () => {
      if (!isBrowser()) {
        Network.removeAllListeners()
      }
    }
  }, [])

  return (
    <footer className="safe-area-pb bg-white border-t border-gray-200">
      <ul className="grid grid-flow-col shadow-xs">
        {toolbarWithTo(routes).map(
          ({ to, Icon, IconActive, topSpace }: any) => {
            const isActive = pathname === to
            const IconComponent = isActive ? IconActive : Icon

            return (
              <li key={to} className="text-center p-2">
                <Link
                  to={to}
                  className="inline-flex h-full justify-center items-center p-2"
                  onClick={(event) => {
                    if (isActive) {
                      event.preventDefault()
                    }
                  }}
                >
                  <IconComponent
                    width="27"
                    className="block fill-primary"
                    style={{
                      marginTop: topSpace,
                    }}
                  />
                </Link>
              </li>
            )
          },
        )}
      </ul>

      {!hasInternet && (
        <div className="animate-pulse text-center text-red-500">
          Your device is not connected to Internet
        </div>
      )}
    </footer>
  )
}

export default Toolbar
