import cc from 'classcat'

export type ItemId = string

type Item = {
  id: ItemId
  text: string
}

type Props = {
  items: Item[]
  onChange: (id: ItemId) => void
  activeTab?: ItemId
  disabled?: boolean
  $size?: 'sm' | 'md'
  $rootClassName?: string
}

const Tabs = ({
  items,
  onChange,
  activeTab,
  disabled,
  $size,
  $rootClassName,
}: Props) => (
  <ul className={cc(['grid grid-flow-col bg-white', $rootClassName])}>
    {items.map(({ id, text }: any) => {
      const isActive = id === activeTab

      return (
        <li
          key={id}
          role="button"
          className={cc([
            'text-center text-primary cursor-pointer',
            {
              'border-transparent': !isActive,
              'border-primary': isActive,
              'text-gray-500': disabled && !isActive,
            },
          ])}
          data-testid={`tab-${id}`}
          onClick={() => {
            if (!disabled && id !== activeTab) {
              onChange(id)
            }
          }}
        >
          <span
            className={cc([
              'inline-flex items-center heading-3 border-b-2 h-full',
              {
                'p-3': $size === 'sm',
                'p-5': $size === 'md',
                'border-transparent': !isActive,
                'border-primary': isActive,
                'text-gray-500': disabled && !isActive,
              },
            ])}
          >
            {text}
          </span>
        </li>
      )
    })}
  </ul>
)

Tabs.defaultProps = {
  $size: 'sm',
}

export default Tabs
