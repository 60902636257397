import AddBankAccount from './AddBankAccount'
import AddPaymentMethod from './AddPaymentMethod'
import ListSources from './ListSources'

const ListBankAccountsAndCards = ({ data }: any) => {
  const { cards, bankAccounts } = data
  const showSeparator = cards && bankAccounts

  return (
    <>
      {showSeparator && (
        <h2 className="heading-2 text-primary pb-1">Bank Accounts</h2>
      )}
      {bankAccounts && (
        <>
          {bankAccounts.length > 0 ? (
            <ListSources type="bankAccount" data={bankAccounts} />
          ) : (
            <p className="pb-2">
              There are no bank accounts yet. Let's add one!
            </p>
          )}
          <div className="pt-3">
            <AddBankAccount />
          </div>
        </>
      )}

      {showSeparator && <hr className="my-10 border-gray-400" />}

      {showSeparator && (
        <h2 className="heading-2 text-primary pb-2">Debit Cards</h2>
      )}
      {cards && (
        <>
          {cards.length > 0 ? (
            <ListSources type="card" data={cards} />
          ) : (
            <p className="pb-2">There are no debit cards yet. Let's add one!</p>
          )}
          <div className="pt-3">
            <AddPaymentMethod />
          </div>
        </>
      )}
    </>
  )
}

export default ListBankAccountsAndCards
