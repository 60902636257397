import cc from 'classcat'
import moment from 'moment'
import getPersonName from 'utils/getPersonName'
import locationToString from 'utils/locationToString'
import Container from 'components/Container'
import CollapsableContent from 'components/CollapsableContent'
import ServerErrorMessage from 'components/ServerErrorMessage'
import PicturesCarousel from './PicturesCarousel'
import ClassTabs from './Tabs'
import ReviewsButton from 'components/ReviewsButton'
import { DB_TIME_FORMAT } from 'globalConstants'
import {
  friendlyUTCToLocalDate,
  friendlyLocalDate,
  fromUntil,
  utcDayToLocalDay,
  utcDateOrDayToLocalDate,
} from 'utils/date'
import { withContext, withDateTime } from './classContext'
import SectionList from 'components/SectionList'
import SafetyGuidelinesList from 'components/SafetyGuidelinesList'
import { useAlert } from 'components/AlertModal'
import SafetyIcon from 'components/SafetyIcon'
import { useState, useEffect } from 'react'
import useClassAlreadyBooked from 'hooks/useClassAlreadyBooked'
import { Class_Date_Time } from 'generated/graphql'
import trackClassTime from 'utils/trackClassTime'
import ms from 'ms'
import { useAuth } from 'stores/auth.store'

const Info = withContext(
  withDateTime(({ clazz, dateTime: selectedDateTime }) => {
    const alert = useAlert()
    const { account } = useAuth()
    const [dateTime, setDateTime] = useState(selectedDateTime)
    const {
      name,
      online,
      description,
      dates_times,
      level,
      setting,
      yogaType,
      safetyGuidelines,
      maxCapacity,
    } = clazz
    const isOwner = clazz.owner.id === account.id

    useEffect(() => {
      setDateTime(selectedDateTime)
    }, [selectedDateTime])

    if (!dateTime) {
      return (
        <Container topBottomSpace>
          <ServerErrorMessage>
            Could not locate the selected date and time for this Class
          </ServerErrorMessage>
        </Container>
      )
    }

    return (
      <>
        {/* Header */}
        <PicturesCarousel
          pictures={clazz.picture ? [{ id: clazz.id, url: clazz.picture }] : []}
        />
        <ClassTabs />

        {/* Body */}
        <div className="flex-col pt-2 flex-grow">
          {/* ALREADY BOOKED? */}
          {!isOwner && <AlreadyBooked dateTime={dateTime} />}

          <SectionList $oddClassName="bg-white">
            {/* SUMMARY */}
            <Container>
              <div className="flex-col space-y-1">
                <div className="heading-1 text-primary">{name}</div>

                {online ? (
                  <p className="text-primary heading-3">Virtual class</p>
                ) : (
                  <p>{clazz.location?.name}</p>
                )}
                <p>
                  {moment(dateTime.start, DB_TIME_FORMAT).format('hh:mm')} -{' '}
                  {moment(dateTime.end, DB_TIME_FORMAT).format('hh:mm A')} -{' '}
                  {friendlyLocalDate(
                    utcDateOrDayToLocalDate({
                      date: dateTime.date,
                      day: dateTime.day,
                      start: dateTime.start,
                    }),
                  )}
                </p>
                <ReviewsButton type="class" id={clazz.id} />
              </div>
            </Container>

            {/* DETAILS */}
            <Container>
              <SectionList $lines>
                {/* ABOUT THE CLASS */}
                <div className="flex-col">
                  <div className="heading-2 text-primary">About the class</div>
                  <div className="pt-1">{description}</div>
                  <div className="pt-3">
                    <ul>
                      <li>
                        <span className="heading-3 text-primary">Level:</span>{' '}
                        {level}
                      </li>
                      <li>
                        <span className="heading-3 text-primary">Setting:</span>{' '}
                        {online ? 'virtual' : setting}
                      </li>
                      <li>
                        <span className="heading-3 text-primary">
                          Yoga type:
                        </span>{' '}
                        {yogaType}
                      </li>
                      <li>
                        <span className="heading-3 text-primary">
                          Max Capacity:
                        </span>{' '}
                        {maxCapacity}
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Location (map) */}
                {!online && !!clazz.location && (
                  <div className="flex-col">
                    <div className="heading-2 text-primary">Location</div>
                    <a
                      href={`https://www.google.com/maps/place/${locationToString(
                        clazz.location,
                      )}`}
                      className="pt-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {locationToString(clazz.location)}
                    </a>
                    <img
                      src={[
                        'https://maps.googleapis.com/maps/api/staticmap',
                        `?key=${
                          process.env
                            .REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_KEY || ''
                        }`,
                        `&center=${locationToString(clazz.location)}`,
                        '&zoom=13',
                        '&size=300x120',
                        '&maptype=roadmap',
                        `&markers=size:mid|color:red|${locationToString(
                          clazz.location,
                        )}`,
                      ].join('')}
                      alt="map thumb"
                      className="block md:hidden w-full mt-3"
                    />
                    <img
                      src={[
                        'https://maps.googleapis.com/maps/api/staticmap',
                        `?key=${
                          process.env
                            .REACT_APP_GOOGLE_PLACES_AUTOCOMPLETE_KEY || ''
                        }`,
                        `&center=${locationToString(clazz.location)}`,
                        // '&zoom=13',
                        '&zoom=15',
                        // '&size=300x120',
                        '&size=1200x250',
                        '&maptype=roadmap',
                        `&markers=size:mid|color:red|${locationToString(
                          clazz.location,
                        )}`,
                      ].join('')}
                      alt="map thumb"
                      className="hidden md:block w-full mt-3"
                    />
                  </div>
                )}

                {!online && safetyGuidelines.length > 0 && (
                  <div className="flex-col">
                    <div className="heading-2 text-primary">
                      Safety Guidelines
                    </div>
                    <ul className="pt-2 -mx-2 flex items-center max-w-full flex-wrap">
                      {safetyGuidelines
                        .map(({ safety_guideline }) => safety_guideline)
                        .map(({ id }) => (
                          <li key={`safety-guideline-${id}`} className="p-2">
                            <SafetyIcon id={id} className="w-6" />
                          </li>
                        ))}
                    </ul>
                    <button
                      type="button"
                      className="body-3 py-4 pr-4 text-gray-700"
                      onClick={() => {
                        alert.send({
                          $type: 'info',
                          title: 'Safety Guidelines',
                          body: (
                            <div className="space-y-2">
                              <p className="pb-8">
                                Predefined safety guidelines
                              </p>
                              <div className="text-left">
                                <SafetyGuidelinesList
                                  list={safetyGuidelines.map(
                                    ({ safety_guideline }) => safety_guideline,
                                  )}
                                />
                              </div>
                            </div>
                          ),
                          buttons: [
                            {
                              children: 'Close',
                            },
                          ],
                        })
                      }}
                    >
                      Learn More
                    </button>
                  </div>
                )}

                {/* Cancelation Policy */}
                <div className="flex-col">
                  <div className="heading-2 text-primary">
                    Cancelation Policy
                  </div>
                  <div className="pt-1">
                    Cancellations made 24 hours or more before the beginning of
                    the class will receive a 100% refund. Anything after that
                    will result in a 50% penalty fee.
                  </div>
                </div>
              </SectionList>
            </Container>
          </SectionList>

          {/* OTHER CLASS TIMES */}
          {dates_times.length > 1 && (
            <Container xSpace={false} ySpace={false}>
              <CollapsableContent label="Other class times">
                {dates_times.map((dt, i) => {
                  const isTheOneOpened = dateTime.id === dt.id

                  return (
                    <div
                      className={cc([
                        'bg-white p-4',
                        {
                          'mt-4': i > 0,
                          'opacity-60': isTheOneOpened,
                        },
                      ])}
                      key={`other-times-${i}`}
                      onClick={() => {
                        if (!isTheOneOpened) {
                          setDateTime(dt)
                        }
                      }}
                    >
                      <div className="text-purple heading-3">
                        {dt.day != null
                          ? moment()
                              .day(utcDayToLocalDay(dt.day, dt.start))
                              .format('dddd[s]')
                              .toUpperCase()
                          : friendlyUTCToLocalDate(dt.date, dt.start)}
                        <div>{fromUntil(dt.start, dt.end).join(' - ')}</div>
                      </div>
                      <hr className="border border-gray-300 my-2" />
                      {dt.teacher && (
                        <p className="text-primary body-2">
                          {getPersonName(dt.teacher.person)}
                        </p>
                      )}
                    </div>
                  )
                })}
              </CollapsableContent>
            </Container>
          )}
        </div>
      </>
    )
  }),
)

export default Info

const AlreadyBooked = ({ dateTime }: { dateTime: Class_Date_Time }) => {
  const {
    loading,
    isAlreadyBooked,
    startTimestamp,
    endTimestamp,
    bookTime,
  } = useClassAlreadyBooked(dateTime)
  const [trackedTime, setTrackedTime] = useState<string | null>(null)

  useEffect(() => {
    if (isAlreadyBooked && startTimestamp && endTimestamp) {
      setTrackedTime(trackClassTime(startTimestamp, endTimestamp))

      const timer = setInterval(() => {
        setTrackedTime(trackClassTime(startTimestamp, endTimestamp))
      }, ms('15s'))

      return () => {
        clearInterval(timer)
      }
    }
  }, [isAlreadyBooked])

  if (loading) {
    return null
  }

  if (isAlreadyBooked) {
    return (
      <Container>
        <div className="border border-dashed border-gray-500 bg-white mt-4 p-3 space-y-2">
          <p>
            You booked this class <strong>{moment(bookTime).calendar()}</strong>
          </p>
          {trackedTime && (
            <p className="text-primary animate-pulse">{trackedTime}</p>
          )}
        </div>
      </Container>
    )
  }

  return null
}
