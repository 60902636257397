import { useState } from 'react'

const useToggle = (
  initialValue = false,
): [boolean, () => void, (newState: boolean) => void] => {
  const [state, setState] = useState(initialValue)

  return [
    state,
    () => {
      setState(!state)
    },
    setState,
  ]
}

export default useToggle
