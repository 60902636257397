import React from 'react'
import Button from 'components/Button'
import FormField, { ErrorMessage, FormGroup } from 'components/FormField'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useToast } from 'components/ToastMessage'
import { useUpdateClassEventMeetingLinkMutation } from 'generated/graphql'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

type Props = {
  eventID: string
  meetingLink?: string | null
}

const validationSchema = yup.object().shape({
  link: yup.string().url().required(),
})

const OnlineMeetingLink = ({ eventID, meetingLink }: Props) => {
  const toast = useToast()
  const [currentMeetingLink, setCurrentMeetingLink] = React.useState<
    string | null
  >(meetingLink || null)
  const { errors, handleSubmit, formState, register, reset } = useForm({
    defaultValues: {
      link: '',
    },
    resolver: yupResolver(validationSchema),
  })
  const [mutate, { error }] = useUpdateClassEventMeetingLinkMutation({
    onCompleted: ({ update_class_event_by_pk }) => {
      // TODO: Update cache
      setCurrentMeetingLink(update_class_event_by_pk!.meeting_link!)
      reset()
      toast.notify({
        type: 'success',
        message: 'The Virtual Link has been saved!',
      })
    },
  })
  const { isSubmitting } = formState

  return (
    <>
      {currentMeetingLink ? (
        <p className="text-center">
          This is the current link for your Virtual Class:{' '}
          <span className="text-primary underline truncate inline-block max-w-full">
            {currentMeetingLink}
          </span>
        </p>
      ) : (
        <p className="text-center">
          Please provide the Virtual Link at least 15 minutes before the class
          starts.
        </p>
      )}

      <form
        onSubmit={handleSubmit(async ({ link }) => {
          await mutate({
            variables: {
              id: eventID,
              newMeetingLink: link,
            },
          })
        })}
      >
        <FormGroup>
          <FormField
            name="link"
            type="url"
            placeholder="Paste virtual link here"
            error={errors.link?.message}
            register={register}
          />

          {error && <ErrorMessage>{error.message}</ErrorMessage>}

          <Button $fluid loading={isSubmitting}>
            {currentMeetingLink
              ? 'Save new link & Notify attendants'
              : 'Send link to attendants'}
          </Button>
        </FormGroup>

        <SpaceForKeyboard />
      </form>
    </>
  )
}

export default OnlineMeetingLink
