import React from 'react'
import moment from 'moment'
import minTwoDigitsNumber from 'utils/minTwoDigitsNumber'
import FormField, {
  DropdownOption,
  ErrorMessage,
  Label,
} from 'components/FormField'
import { useFormContext } from 'react-hook-form'
import { LEGAL_PERSON_AGE } from 'globalConstants'

type Props = {
  name: string
  label?: string
  isBig?: boolean
}

const BirthdayField = ({ name, label, isBig }: Props) => {
  const { errors, getValues, setValue, register, control } = useFormContext()
  const setBirthday = (type: 'month' | 'day' | 'year') => (
    newValue: string,
  ) => {
    const birthday = getValues()[name]
    const parts = birthday.split('-')

    let index = -1
    switch (type) {
      case 'year': {
        index = 0
        break
      }
      case 'month': {
        index = 1
        break
      }
      case 'day': {
        index = 2
        break
      }
    }

    parts[index] =
      type === 'year' ? newValue : minTwoDigitsNumber(Number(newValue))

    setValue(name, parts.join('-'))
  }

  return (
    <>
      <input type="hidden" name={name} ref={register} />
      {label && <Label>{label}</Label>}
      <div className="grid grid-cols-3 gap-2">
        <FormField
          type="dropdown"
          placeholder="Month"
          name="birthdayMonth"
          onChange={setBirthday('month')}
          isBig={isBig}
          control={control}
          data-testid="input-birthday-month"
        >
          {moment.months().map((month, i) => (
            <DropdownOption key={`birthday-month-${i}`} value={i + 1}>
              {month}
            </DropdownOption>
          ))}
        </FormField>
        <FormField
          type="number"
          placeholder="Day"
          name="birthdayDay"
          onChange={setBirthday('day')}
          isBig={isBig}
          register={register}
          data-testid="input-birthday-day"
        />
        <FormField
          type="dropdown"
          placeholder="Year"
          name="birthdayYear"
          onChange={setBirthday('year')}
          isBig={isBig}
          control={control}
          data-testid="input-birthday-year"
        >
          {new Array(90)
            .fill(moment().subtract(LEGAL_PERSON_AGE, 'years').year())
            .map((year, index) => year - index)
            .map((year) => (
              <DropdownOption key={`birthday-year-${year}`} value={year}>
                {year.toString()}
              </DropdownOption>
            ))}
        </FormField>
      </div>

      {errors?.birthday?.message && (
        <ErrorMessage>{errors?.birthday?.message}</ErrorMessage>
      )}
    </>
  )
}

export default BirthdayField
