import Button from 'components/Button'
import FormField, { FormGroup } from 'components/FormField'
import { Fragment } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { difference, dropRight, uniqBy } from 'lodash/fp'
import { ReactComponent as IconTrashCan } from 'assets/icons/trash-can.svg'
import Container from 'components/Container'
import {
  useAccountCertificationsQuery,
  AccountCertificationsQuery,
  useUpdateAccountCertificationMutation,
} from 'generated/graphql'
import Loading from 'components/Loading'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { useAuth } from 'stores/auth.store'

const CERTIFICATES = [
  'RYT® 200',
  'RYT® 500',
  'E-RYT® 200',
  'E-RYT® 500',
  'E-RYT® 200, RYT® 500',
  'RCYT®',
  'RPYT®',
]

type Props = {
  onComplete?: () => void
}

type FormValues = {
  certifications: {
    name: string
  }[]
}

const Certifications = ({ onComplete }: Props) => {
  const { account } = useAuth()
  const { data, loading, error } = useAccountCertificationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      accountID: account.id,
    },
  })

  if (loading) {
    return (
      <Container topBottomSpace>
        <Loading />
      </Container>
    )
  }

  if (error) {
    return <Container topBottomSpace>{error.message}</Container>
  }

  return (
    <Form onComplete={onComplete} initialState={data?.account_certification} />
  )
}

const validationSchema = yup.object().shape({
  certifications: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Certificate is required'),
    }),
  ),
})

const Form = ({
  onComplete,
  initialState,
}: Props & {
  initialState?: AccountCertificationsQuery['account_certification']
}) => {
  const [
    updateCertifications,
    updateState,
  ] = useUpdateAccountCertificationMutation({
    onCompleted: () => {
      onComplete?.()
    },
  })
  const { control, register, formState, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      certifications: initialState ? uniqBy('name', initialState) : [],
    },
    resolver: yupResolver(validationSchema),
  })
  const certifications = useFieldArray({
    control,
    name: 'certifications',
  })
  const { errors, isDirty, isSubmitting } = formState

  return (
    <Container topBottomSpace>
      <form
        onSubmit={handleSubmit(async (values) => {
          await updateCertifications({
            variables: {
              certifications: values.certifications.map(({ name }) => ({
                name,
              })),
            },
          })
        })}
      >
        {certifications.fields.length === 0 && (
          <div className="py-2">
            Start adding certificates by click on the "Add Certificate" button
          </div>
        )}

        {
          certifications.fields.map((field, index) => (
            <Fragment key={field.id}>
              <div className="flex items-center">
                <div className="flex-grow pr-4">
                  <FormGroup>
                    <FormField
                      type="dropdown"
                      placeholder="Select a certificate"
                      name={`certifications[${index}].name`}
                      error={errors.certifications?.[index]?.name?.message}
                      register={register}
                      defaultValue={field.name}
                      control={control}
                    >
                      {difference(
                        CERTIFICATES,
                        dropRight(
                          certifications.fields.length - index,
                          certifications.fields,
                        ).map((x: any) => x.name),
                      ).map((certificate) => (
                        <option value={certificate} key={certificate}>
                          {certificate}
                        </option>
                      ))}
                    </FormField>
                  </FormGroup>
                </div>
                <button
                  type="button"
                  className="p-2 text-center"
                  onClick={() => {
                    certifications.remove(index)
                  }}
                >
                  <IconTrashCan className="inline-block w-5 fill-red-500" />
                  <span className="block body-3 text-red-500 pt-1">Remove</span>
                </button>
              </div>

              {certifications.fields.length - 1 > index && (
                <hr className="border-gray-500 my-6" />
              )}
            </Fragment>
          )) as any
        }

        <FormGroup>
          {certifications.fields.length < CERTIFICATES.length && (
            <Button
              $fluid
              $type="secondary"
              type="button"
              onClick={() => {
                certifications.append({
                  name: '',
                })
              }}
              className="mt-6"
              disabled={isSubmitting || updateState.loading}
            >
              Add certificate
            </Button>
          )}

          <Button
            $fluid
            disabled={!isDirty}
            loading={isSubmitting || updateState.loading}
          >
            Save Changes
          </Button>
        </FormGroup>
      </form>

      <SpaceForKeyboard $size="lg" />
    </Container>
  )
}

export default Certifications
