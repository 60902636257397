import React from 'react'
import cc from 'classcat'
import ms from 'ms'
import Loading, { Size } from 'components/Loading'
import Button from 'components/Button'
import Container from 'components/Container'

type Block = {
  $size: Size
  subtext?: string
  zIndex?: number
  cancelTimer?: number
}

type LBlockProps = Block & {
  onClose: () => void
}

type ContextValue = {
  open: (block?: Block) => void
  close: () => void
}

const Context = React.createContext<ContextValue>({
  open: () => {
    throw new Error('Cannot use Loading Block outside of LoadingBlock')
  },
  close: () => {
    throw new Error('Cannot use Loading Block outside of LoadingBlock')
  },
})

type LBlockProviderProps = {
  children: any
}

export const LoadingBlockProvider = ({ children }: LBlockProviderProps) => {
  const [state, setState] = React.useState<Block | null>(null)

  const open = (
    block: Block = {
      $size: 'md',
    },
  ) => {
    setState(block)
  }

  const close = () => {
    setState(null)
  }

  return (
    <Context.Provider value={{ open, close }}>
      {children}
      {state && <LoadingBlock {...state} onClose={() => close()} />}
    </Context.Provider>
  )
}

export const useLoadingBlock = () => React.useContext(Context)

const LoadingBlock = ({
  $size,
  subtext,
  onClose,
  zIndex,
  cancelTimer,
}: LBlockProps) => {
  const [showButton, setShow] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, cancelTimer || ms('10s'))

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div
      data-testid="lblock"
      className={cc([
        'bg-white w-screen h-screen fixed top-0 flex items-center justify-center bg-opacity-90',
        zIndex && `z-${zIndex}`,
      ])}
    >
      <Container className="grid grid-rows-3 text-center">
        <Loading $size={$size} />
        {subtext && <p className="heading-3">{subtext}</p>}
        {showButton && (
          <Button type="button" $type="primary" $fluid onClick={onClose}>
            Cancel
          </Button>
        )}
      </Container>
    </div>
  )
}

LoadingBlock.defaultProps = {
  zIndex: 993,
}

export default LoadingBlock
