import { $ } from 'moneysafe'
import { $$, subtractPercent } from 'moneysafe/ledger'
import GenericQueryRender from 'components/GenericQueryRender'
import DefaultAvatar from 'assets/user-avatar.png'
import { AttendingToClassQuery, Class_Cost_Type_Enum } from 'generated/graphql'
import { QUERY_ATTENDING_TO_CLASS, QUERY_CLASS_PRICE } from 'graphql/queries'
import BackgroundImage from 'components/BackgroundImage'
import Container from 'components/Container'
import { FEE_SERVICE_IN_PERCENTAGE } from 'globalConstants'
import OnlineMeetingLink from './OnlineMeetingLink'

const calculateTotal = (data: AttendingToClassQuery['class_book']): number =>
  data.length > 0
    ? $$(...data.map(({ amount_paid }) => $(amount_paid))).toNumber()
    : 0

const calculateEarnings = (total: number): number => {
  return $$($(total), subtractPercent(FEE_SERVICE_IN_PERCENTAGE)).toNumber()
}

type Props = {
  eventID: string
  meetingLink?: string | null
  date: string
  online: boolean
  start: string
  classID: string
  isPast: boolean
}

const AttendingStudents = ({
  eventID,
  meetingLink,
  classID,
  online,
  isPast,
}: Props) => {
  return (
    <GenericQueryRender
      query={QUERY_ATTENDING_TO_CLASS}
      fetchPolicy="cache-and-network"
      dataAccess="class_book"
      variables={{
        classEventID: eventID,
      }}
      NoResults={() => (
        <>
          <Container topBottomSpace className="text-center text-gray-700">
            {isPast
              ? 'Nobody booked this class 😔, but do not worry! yogis will book your class next time!'
              : 'Nobody is attending to this class yet.'}
          </Container>
          {!isPast && online && (
            <Container topBottomSpace>
              <OnlineMeetingLink eventID={eventID} meetingLink={meetingLink} />
            </Container>
          )}
        </>
      )}
      Success={({ data }) => {
        const totalPayment = calculateTotal(
          data as AttendingToClassQuery['class_book'],
        )
        const totalEarnings = calculateEarnings(totalPayment)

        return (
          <>
            {!isPast && online && (
              <Container topBottomSpace>
                <OnlineMeetingLink
                  eventID={eventID}
                  meetingLink={meetingLink}
                />
              </Container>
            )}

            {/* Attendants */}
            <Container topBottomSpace>
              <ul>
                {(data as AttendingToClassQuery['class_book']).map(
                  ({ attendant }, index) => (
                    <li
                      key={`attending-${index}`}
                      className="flex items-center px-3 py-4"
                    >
                      <BackgroundImage
                        image={attendant.person?.picture || DefaultAvatar}
                        width={50}
                        height={50}
                        className="rounded-full mr-4"
                      />

                      <span className="heading-2 text-primary">
                        {attendant.person?.firstName}{' '}
                        {attendant.person?.lastName}
                      </span>
                    </li>
                  ),
                )}
              </ul>
            </Container>

            {/* Total $$$ */}
            <Container xSpace={false} className="bg-gray-100 text-primary mb-8">
              <ul className="py-2">
                <GenericQueryRender
                  query={QUERY_CLASS_PRICE}
                  dataAccess="class_by_pk"
                  variables={{
                    classID,
                  }}
                  Success={({ data }) => (
                    <li className="flex justify-between px-8 py-3">
                      <span className="heading-3">
                        {data.type === Class_Cost_Type_Enum.Donation
                          ? 'Min. Donation per class'
                          : 'Class price'}
                      </span>
                      <span className="heading-3">${data.price}</span>
                    </li>
                  )}
                  Failure={undefined}
                  NoResults={undefined}
                  Loading={undefined}
                />

                <li className="flex justify-between px-8 py-3">
                  <span className="heading-3">Paid by students</span>
                  <span className="heading-3">${totalPayment}</span>
                </li>
                <li className="flex justify-between px-8 py-3">
                  <span className="heading-3">Ohmunity™ Fee</span>
                  <span className="heading-3">
                    {FEE_SERVICE_IN_PERCENTAGE}%
                  </span>
                </li>
              </ul>
              <ul>
                <li className="flex justify-between px-8 py-5 bg-nude">
                  <span className="heading-2">Your total earnings</span>
                  <span className="number-1">${totalEarnings}</span>
                </li>
              </ul>
            </Container>
          </>
        )
      }}
    />
  )
}

export default AttendingStudents
