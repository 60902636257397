import Loading from 'components/Loading'

type Props = {
  title: string
  url: string
}

const ExternalPage = ({ title, url }: Props) => {
  return (
    <>
      <Loading />
      <iframe
        src={url}
        title={title}
        className="absolute inset-0 w-full h-full"
      />
    </>
  )
}

export default ExternalPage
