import { loader } from 'graphql.macro'

export const QUERY_CLASS_BY_ID = loader('./classById.graphql')

export const QUERY_YOGA_TYPES = loader('./yogaTypes.graphql')

export const QUERY_STUDIO_TEACHERS = loader('./studioTeachers.graphql')

export const QUERY_COUNT_TEACHERS = loader('./countTeachers.graphql')

export const QUERY_TEACHERS = loader('./teachers.graphql')

export const QUERY_NOTIFICATIONS = loader('./notifications.graphql')

export const QUERY_TEACHER_IS_TEACHING_CLASSES = loader(
  './teacherIsTeachingClasses.graphql',
)

export const QUERY_LOCATIONS_BY_ACCOUNT = loader('./locationsByAccount.graphql')

export const QUERY_CREATE_CLASS_REQUIRED_DATA = loader(
  './createClassRequiredData.graphql',
)

export const QUERY_SEARCH_CLASSES = loader('./searchClasses.graphql')

export const QUERY_SEARCH_HISTORY = loader('./searchingHistory.graphql')

export const QUERY_SIGNUP_CHECK = loader('./signupCheck.graphql')

export const QUERY_CLASS_REVIEWS = loader('./classReviews.graphql')

export const QUERY_TEACHER_REVIEWS = loader('./teacherReviews.graphql')

export const QUERY_STUDIO_PICTURES = loader('./studioPictures.graphql')

export const QUERY_STUDIO_INFO = loader('./studioInfo.graphql')

export const QUERY_STUDIO_REVIEWS = loader('./studioReviews.graphql')

export const QUERY_TOTAL_STUDIO_REVIEWS = loader('./studioTotalReviews.graphql')

export const QUERY_TOTAL_CLASS_REVIEWS = loader('./classTotalReviews.graphql')

export const QUERY_TOTAL_TEACHER_REVIEWS = loader(
  './teacherTotalReviews.graphql',
)

export const QUERY_SIGNUP_CHECK_EMAIL = loader('./signupCheckEmail.graphql')

export const QUERY_SIGNUP_CHECK_PHONE_NUMBER = loader(
  './signupCheckPhoneNumber.graphql',
)

export const QUERY_MY_BOOKED_CLASSES = loader('./myBookedClasses.graphql')

export const QUERY_INSPIRATIONAL_READS = loader('./inspirationalReads.graphql')

export const QUERY_ATTENDING_TO_CLASS = loader('./attendingToClass.graphql')

export const QUERY_CLASS_PRICE = loader('./classPrice.graphql')

export const QUERY_INSURANCE_DOCUMENT = loader('./insuranceDocument.graphql')

export const QUERY_FIND_CLASS_EVENT = loader('./findClassEvent.graphql')

export const QUERY_ACCOUNT_PROFILE = loader('./AccountProfile.graphql')
