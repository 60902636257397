import { createContext, useContext } from 'react'
import { Class, CreateClassRequiredDataQuery } from 'generated/graphql'
import { ApolloError, useQuery } from '@apollo/client'
import { QUERY_CREATE_CLASS_REQUIRED_DATA } from 'graphql/queries'
import { useAuth } from 'stores/auth.store'
import useLogger from 'hooks/useLogger'
import Container from 'components/Container'
import ServerErrorMessage from 'components/ServerErrorMessage'
import Loading from 'components/Loading'

type Options = {
  locations: CreateClassRequiredDataQuery['location']
  yogaTypes: CreateClassRequiredDataQuery['yoga_type']
  settings: CreateClassRequiredDataQuery['class_setting']
  levels: CreateClassRequiredDataQuery['class_level']
  safetyGuidelines: CreateClassRequiredDataQuery['safety_guideline']
}

type Ctx = {
  clazz?: Class
  options?: Options
}

export const Context = createContext<Ctx>({})

export const useManageClassContext = () => useContext(Context)

const useRequiredData = (): {
  loading: boolean
  error?: ApolloError | null
  data?: Options
} => {
  const { account } = useAuth()
  const requiredState = useQuery(QUERY_CREATE_CLASS_REQUIRED_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: {
      accountID: account.id,
    },
  })
  const { loading, error, data } = requiredState

  return {
    loading,
    error,
    data: data
      ? {
          locations: data.location,
          yogaTypes: data.yoga_type,
          settings: data.class_setting,
          levels: data.class_level,
          safetyGuidelines: data.safety_guideline,
        }
      : undefined,
  }
}

export const ManageProvider = ({
  children,
  clazz,
}: {
  children: any
  clazz?: Class
}) => {
  const { log } = useLogger()
  const { loading, error, data } = useRequiredData()

  if (error) {
    log('error', 'Fetching Required Data for Manage Class', { error })

    return (
      <Container topBottomSpace>
        <ServerErrorMessage>
          Something went wrong while loading required data to Manage a Class.
          Please try again or Contact Support.
        </ServerErrorMessage>
      </Container>
    )
  }

  if (loading) {
    return <Loading context="General required data" />
  }

  return (
    <Context.Provider value={{ clazz, options: data }}>
      {children}
    </Context.Provider>
  )
}
