import React from 'react'
import Container from 'components/Container'
import BookAndPay from 'screens/BookAndPay' // import { GoogleMap, withGoogleMap } from 'react-google-maps'
import { QUERY_CLASS_BY_ID } from 'graphql/queries'
import {
  Class,
  Class_Date_Time,
  DeleteClassDocument,
  DeleteClassMutation,
} from 'generated/graphql'
import { useAuth } from 'stores/auth.store'
import ClassInfo from './ClassInfo'
import StudioInfo from './StudioInfo'
import TeacherInfo from './TeacherInfo'
import GenericQueryRender from 'components/GenericQueryRender'
import patternMatching from 'utils/patternMatching'
import { Provider, withContext, withDateTime } from './classContext'
import { TabsProvider, useTabs } from './Tabs'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import useLogger from 'hooks/useLogger'
import { useAlert } from 'components/AlertModal'
import { useModalScreen, withModalScreen } from 'components/ModalScreen'
import ManageClass from 'screens/ManageClass'
import Button from 'components/Button'
import useClassAlreadyBooked from 'hooks/useClassAlreadyBooked'
import { CancelBookingTrigger } from './CancelBooking'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useClient } from 'urql'
import { useToast } from 'components/ToastMessage'
import { useParams } from '@reach/router'
import NotFound from 'screens/NotFound'
import { isWebapp } from 'utils/env'
import { navigate } from 'clients/navigation.client'

type Props = {
  classId: string
  dateTimeID: string
  preloadedClass?: Class
  day?: number
}

const ClassDetailsScreen = (props: Props) => {
  const { classId, preloadedClass, ...rest } = props

  if (preloadedClass) {
    return (
      <ClassDetailsWithPreloadedClass
        {...props}
        preloadedClass={preloadedClass}
      />
    )
  }

  return (
    <GenericQueryRender
      query={QUERY_CLASS_BY_ID}
      variables={{
        classId,
      }}
      dataAccess="class_by_pk"
      additionalProps={rest}
      Failure={({ error }) => (
        <Container topBottomSpace>
          {error?.message || error || GENERIC_ERROR_MESSAGE}
        </Container>
      )}
      Success={({ data }) => {
        return (
          <ClassDetailsWithPreloadedClass
            classId={classId}
            preloadedClass={data}
            {...rest}
          />
        )
      }}
    />
  )
}

export default ClassDetailsScreen

export const ClassDetailsScreenFromRoute = () => {
  const { classID, dateTimeID } = useParams() || {}

  if (!classID) {
    return <NotFound />
  }

  return (
    <Container xSpace={false}>
      <div className="shadow md:my-4">
        <ClassDetailsScreen classId={classID} dateTimeID={dateTimeID} />
      </div>
    </Container>
  )
}

type PropsWithPreloadedClass = Props & {
  preloadedClass: Class
}

const ClassDetailsWithPreloadedClass = ({
  classId,
  preloadedClass,
  dateTimeID,
  ...rest
}: PropsWithPreloadedClass) => {
  const { log } = useLogger()
  const alert = useAlert()
  const [dateTime, setDateTime] = React.useState(
    preloadedClass &&
      preloadedClass.dates_times.find((dt) => dt.id === dateTimeID),
  )
  const [clazz, setClazzState] = React.useState(preloadedClass)

  const setClazz = (newClazz: Class) => {
    const newSelectedDateTime = newClazz.dates_times.find(
      (dt) => dt.id === dateTime?.id,
    )

    if (!newSelectedDateTime) {
      setDateTime(newClazz.dates_times[0])
    } else {
      setDateTime(newSelectedDateTime)
    }

    setClazzState(newClazz)
  }

  React.useEffect(() => {
    if (!dateTime) {
      log(
        'error',
        `Date Time "${dateTimeID}" does not exist in class "${preloadedClass.id}"`,
      )

      alert.send({
        body:
          'The selected date and time for this class is not longer available. A default date and time will bee selected, you can change it at any time.',
        buttons: [
          {
            children: 'Close',
            onClick: () => {
              setDateTime(preloadedClass.dates_times[0])
            },
          },
        ],
      })
    }
  }, [dateTime])

  if (!dateTime) {
    return null
  }

  return (
    <Provider
      value={{
        ...rest,
        classId,
        clazz,
        setClazz,
        dateTimeID: dateTime.id,
      }}
    >
      <TabsProvider>
        <ClassDetails />
      </TabsProvider>
    </Provider>
  )
}

const ClassDetails = withContext(
  withDateTime((props) => {
    const { account, canAssistToClass } = useAuth()
    const [tabActive] = useTabs()
    const isOwner = props.clazz.owner.id === account.id

    return (
      <>
        {patternMatching([
          ['info', <ClassInfo key="class-info" />],
          ['studio', <StudioInfo key="studio" />],
          ['teacher', <TeacherInfo key="teacher-info" />],
        ])(tabActive)}

        {(canAssistToClass() || isOwner) && (
          <div className="bg-white">
            <Container className="py-5 block safe-area-mb">
              {isOwner ? (
                <div className="grid gap-2 md:grid-cols-2">
                  <EditClassButton
                    clazz={props.clazz}
                    onEdit={(newClazz) => {
                      props.setClazz(newClazz)
                    }}
                  />
                  <DeleteClassButton clazz={props.clazz} />
                </div>
              ) : (
                <MaybeBookAndPay {...props} />
              )}
            </Container>
          </div>
        )}
      </>
    )
  }),
)

// -------------------------

type MaybeBookAndPayProps = {
  clazz: Class
  dateTime: Class_Date_Time
}

const MaybeBookAndPay = ({ clazz, dateTime }: MaybeBookAndPayProps) => {
  const {
    loading,
    isAlreadyBooked,
    classBookID,
    startTimestamp,
  } = useClassAlreadyBooked(dateTime)

  if (loading) {
    return <div className="bg-gray-500 w-full h-14 animate-pulse" />
  }

  if (isAlreadyBooked) {
    return (
      <CancelBookingTrigger
        classBookID={classBookID!}
        startTimestamp={startTimestamp!}
        dateTime={dateTime}
      />
    )
  }

  return <BookAndPay clazz={clazz} dateTime={dateTime} />
}

const EditClassButton = withModalScreen<{
  clazz: Class
  onEdit: (newClazz: Class) => void
}>(({ clazz, onEdit }) => {
  const modal = useModalScreen()
  const alert = useAlert()

  return (
    <Button
      $fluid={true}
      $size={'md'}
      data-testid="button-edit-class"
      onClick={() => {
        modal.open({
          body: (
            <ManageClass
              clazz={clazz}
              onSuccess={(newClazz) => {
                modal.close()
                onEdit(newClazz)
              }}
              onCancel={() => {
                alert.send({
                  body: 'You will lose any changes made.',
                  buttons: [
                    {
                      children: 'Yes, close',
                      onClick: () => {
                        modal.close()
                      },
                    },
                    {
                      $type: 'tertiary',
                      children: 'Cancel',
                    },
                  ],
                })
              }}
            />
          ),
        })
      }}
    >
      Edit
    </Button>
  )
})

const DeleteClassButton = ({
  clazz,
}: {
  clazz: Class
  onDelete?: (newClazz: Class) => void
}) => {
  const client = useClient()
  const loadingBlock = useLoadingBlock()
  const modal = useModalScreen()
  const toast = useToast()
  const alert = useAlert()

  return (
    <Button
      $fluid={true}
      $size={'md'}
      $type="danger"
      data-testid="button-delete-class"
      onClick={() => {
        alert.send({
          $type: 'danger',
          body: 'All bookings will be cancelled instantaneously!',
          buttons: [
            {
              children: 'Yes, delete class',
              onClick: async () => {
                // modal.close()
                loadingBlock.open({
                  $size: 'md',
                  subtext: 'Deleting class...',
                })

                try {
                  const response = await client
                    .mutation<DeleteClassMutation>(DeleteClassDocument, {
                      id: clazz.id,
                    })
                    .toPromise()

                  if (response.error) {
                    toast.notify({
                      type: 'failure',
                      message: response.error.message,
                    })
                  } else if (response.data) {
                    modal.close()
                    toast.notify({
                      type: 'success',
                      message: `The class "${clazz.name}" has been deleted.`,
                    })

                    if (isWebapp()) {
                      navigate('/myClasses')
                    }
                  } else {
                    toast.notify({
                      type: 'failure',
                      message: GENERIC_ERROR_MESSAGE,
                    })
                  }
                } catch (err) {
                  toast.notify({
                    type: 'failure',
                    message: err.message,
                  })
                }

                loadingBlock.close()
              },
            },
            {
              $type: 'tertiary',
              children: 'No, cancel',
            },
          ],
        })
      }}
    >
      Delete
    </Button>
  )
}
