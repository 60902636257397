import { pick, flow, identity, difference } from 'lodash/fp'
import FormCarousel, { FormCarouselSlide } from 'forms/FormCarousel'
import General from './General'
import DateAndTime from './DateAndTime'
import useLogger from 'hooks/useLogger'
import {
  Class,
  Class_Recurrence_Enum,
  DeleteClassDateTimesDocument,
  UpdateClassDateTimesDocument,
  UpdateClassGeneralDocument,
  UpdateClassSafetyGuidelinesDocument,
} from 'generated/graphql'
import { useApolloClient } from '@apollo/client'
import { DateTime, GeneralValues } from './types'
import { CREATE_CLASS } from 'graphql/mutations'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useToast } from 'components/ToastMessage'
import { useAlert } from 'components/AlertModal'
import { ManageProvider, useManageClassContext } from './context'
import {
  classifyDateTimeChanges,
  classToFormInitialValues,
  hasGeneralChanged,
  localDateTimeToUTC,
  removeDeletedClasses,
  updateAndAddClasses,
} from './helpers'
import combineQuery from 'graphql-combine-query'

/*
<SpaceForKeyboard /> not needed
*/

type FormValues = {
  general: GeneralValues
  dateAndTime: {
    type: Class_Recurrence_Enum
    dateTime: DateTime[]
  }
}

type Props = {
  clazz?: Class
  onSuccess: (newClazz: Class) => void
  onCancel?: () => void
}

const ManageClassWithProvider = (props: Props) => {
  return (
    <ManageProvider clazz={props.clazz}>
      <ManageClass {...props} />
    </ManageProvider>
  )
}

export default ManageClassWithProvider

const ManageClass = ({ clazz, onSuccess, onCancel }: Props) => {
  const alert = useAlert()
  const toast = useToast()
  const client = useApolloClient()
  const loadingBlock = useLoadingBlock()
  const { log } = useLogger()
  const { options } = useManageClassContext()

  return (
    <FormCarousel
      onSubmit={async ({ general, dateAndTime }: FormValues) => {
        loadingBlock.open()

        try {
          const parsedGeneral = {
            ...pick(
              [
                'costType',
                'online',
                'yogaType',
                'level',
                'name',
                'description',
                'picture',
                'maxCapacity',
              ],
              general,
            ),
            price: general.price ? parseInt(general.price, 10) : 0,
            setting: general.setting || undefined,
            location_id: general.location_id || undefined,
          }

          // Converts local date and time selection to UTC
          dateAndTime.dateTime = localDateTimeToUTC(dateAndTime.dateTime)

          // Create
          if (!clazz) {
            const variables = {
              object: {
                ...parsedGeneral,
                recurrence: dateAndTime.type,
                dates_times: {
                  data: dateAndTime.dateTime.map(
                    pick([
                      dateAndTime.type === Class_Recurrence_Enum.OneTime
                        ? 'date'
                        : 'day',
                      'start',
                      'end',
                      'teacher_id',
                    ]),
                  ),
                },
                safetyGuidelines:
                  general.safetyGuidelines?.length > 0
                    ? {
                        data: general.safetyGuidelines.map(
                          (safety_guideline_id) => ({
                            safety_guideline_id,
                          }),
                        ),
                      }
                    : undefined,
              },
            }

            const response = await client.mutate({
              mutation: CREATE_CLASS,
              variables,
            })

            if (response.errors) {
              toast.notify({
                type: 'failure',
                message: response.errors[0].message,
              })
              log('error', 'Create Class', {
                error: response.errors[0],
              })
            } else {
              toast.notify({
                type: 'success',
                message: `Your "${general.name}" class has been successfuly created.`,
              })
              onSuccess({
                ...response.data,
                ...parsedGeneral,
                recurrence: dateAndTime.type,
              })
            }

            loadingBlock.close()
          } else {
            // Update
            const generalChanged = hasGeneralChanged(clazz, general, [
              'safetyGuidelines',
            ])
            const dateTimeUpdates = classifyDateTimeChanges(clazz, dateAndTime)

            const generalUpdates = generalChanged
              ? {
                  ...parsedGeneral,
                  recurrence: dateTimeUpdates.type,
                }
              : clazz.recurrence !== dateTimeUpdates.type
              ? {
                  recurrence: dateTimeUpdates.type,
                }
              : {}
            const datesTimesToAddOrUpdate = [
              ...dateTimeUpdates.dateTime.added,
              ...dateTimeUpdates.dateTime.updated,
            ].map((x) => ({
              ...x,
              class_id: clazz.id,
            }))

            const datesTimesToRemove = dateTimeUpdates.dateTime.removed
              .map(({ id }) => id)
              .filter((id) => id)
            const toBeRemoved = dateTimeUpdates.dateTime.removed.length

            const haveSafetyGuidelinesChanged =
              difference(
                general.safetyGuidelines,
                clazz.safetyGuidelines.map((s) => s.safety_guideline_id),
              ).length > 0 ||
              general.safetyGuidelines.length !== clazz.safetyGuidelines.length

            const update = async () => {
              const dateTimeChanged =
                dateTimeUpdates.dateTime.added.length > 0 ||
                dateTimeUpdates.dateTime.removed.length > 0 ||
                dateTimeUpdates.dateTime.updated.length > 0

              // Changes were detected!
              if (
                generalChanged ||
                dateTimeChanged ||
                haveSafetyGuidelinesChanged
              ) {
                let updateMutation = combineQuery('UpdateClass')

                if (generalChanged) {
                  updateMutation = updateMutation.add(
                    UpdateClassGeneralDocument,
                    {
                      id: clazz.id,
                      general: generalUpdates,
                    },
                  ) as any
                }

                if (haveSafetyGuidelinesChanged) {
                  updateMutation = updateMutation.add(
                    UpdateClassSafetyGuidelinesDocument,
                    {
                      classID: clazz.id,
                      safetyGuidelines: general.safetyGuidelines.map((s) => ({
                        class_id: clazz.id,
                        safety_guideline_id: s,
                      })),
                    },
                  ) as any
                }

                if (datesTimesToAddOrUpdate.length > 0) {
                  updateMutation = updateMutation.add(
                    UpdateClassDateTimesDocument,
                    {
                      dateTimeAddOrUpdate: datesTimesToAddOrUpdate,
                    },
                  ) as any
                }

                if (toBeRemoved > 0) {
                  updateMutation = updateMutation.add(
                    DeleteClassDateTimesDocument,
                    {
                      dateTimeDelete: {
                        _or: datesTimesToRemove.map((id) => ({
                          id: { _eq: id },
                        })),
                      },
                    },
                  ) as any
                }

                try {
                  const response = await client.mutate({
                    mutation: (updateMutation as any).document,
                    variables: (updateMutation as any).variables,
                  })

                  if (response.errors) {
                    toast.notify({
                      type: 'failure',
                      message: response.errors[0].message,
                    })
                    log('error', 'Update Class', {
                      error: response.errors[0],
                    })
                  } else {
                    toast.notify({
                      type: 'success',
                      message: `The class ${general.name} has been updated!`,
                    })

                    onSuccess({
                      ...clazz,
                      ...generalUpdates,
                      ...(haveSafetyGuidelinesChanged
                        ? {
                            safetyGuidelines:
                              (options?.safetyGuidelines
                                .filter((x) =>
                                  general.safetyGuidelines.includes(x.id),
                                )
                                .map((x) => ({
                                  safety_guideline: x,
                                })) as any) || [],
                          }
                        : {}),
                      ...(response.data.delete_class_date_time ||
                      response.data.insert_class_date_time
                        ? {
                            dates_times: flow(
                              response.data.delete_class_date_time
                                ? removeDeletedClasses(
                                    response.data.delete_class_date_time.returning.map(
                                      ({ id }: any) => id,
                                    ),
                                  )
                                : identity,
                              response.data.insert_class_date_time
                                ? updateAndAddClasses(
                                    response.data.insert_class_date_time,
                                  )
                                : identity,
                            )(clazz.dates_times),
                          }
                        : {}),
                    })
                  }
                } catch (error) {
                  toast.notify({
                    type: 'failure',
                    message: error.message,
                  })
                  log('error', 'Update Class', {
                    error,
                  })
                }
              } else {
                onSuccess(clazz)
              }

              loadingBlock.close()
            }

            if (toBeRemoved > 0) {
              alert.send({
                body: `There ${
                  toBeRemoved === 1 ? 'is' : 'are'
                } ${toBeRemoved} ${
                  toBeRemoved === 1 ? 'date and time' : 'dates and times'
                } that ${
                  toBeRemoved === 1 ? 'is' : 'are'
                } being removed from this class. All the bookings related to ${
                  toBeRemoved === 1 ? 'this time' : 'these times'
                } will be cancelled.`,
                buttons: [
                  {
                    children: 'Yes, delete!',
                    onClick: () => {
                      update()
                    },
                  },
                  {
                    $type: 'tertiary',
                    children: 'Cancel',
                  },
                ],
              })
            } else {
              update()
            }
          }
        } catch (error) {
          log('error', 'Create Class', { error })
          toast.notify({
            type: 'failure',
            message: error.message,
          })
          loadingBlock.close()
        }
      }}
      onCancel={onCancel}
      $cancelType="close"
      $headerBg="bg-white"
      initialValues={clazz ? classToFormInitialValues(clazz) : undefined}
    >
      <FormCarouselSlide formName="general">
        <General />
      </FormCarouselSlide>
      <FormCarouselSlide formName="dateAndTime">
        <DateAndTime />
      </FormCarouselSlide>
    </FormCarousel>
  )
}
