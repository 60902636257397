import React, { createContext, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { Account } from 'types'
import { ACCOUNT } from 'config'
import {
  isStudent,
  isTeacher,
  isStudio,
  isUnkown,
  isAnonymous,
  isEntity,
  isPerson,
  canAssistToClass,
  canTeachAClass,
} from 'utils/account'
import { AuthData, useGlobalProvider } from './global.store'
import {
  Class_Level_Enum,
  Class_Setting_Enum,
  Yoga_Type_Enum,
} from 'generated/graphql'
import { AuthPayload } from 'stores/global.store'

export interface ContextInterface {
  auth: AuthData | null
  account: Account
  updateAccount: (partialNewAccount: Partial<Account>) => void
  login: (authPayload: AuthPayload) => void
  logout: () => void
  isAuthenticated: () => boolean
}

export const Context = createContext<ContextInterface>({
  auth: null,
  account: ACCOUNT.defaultData,
  updateAccount: () => {
    throw new Error('Missing "updateAccount" implementation')
  },
  login: () => {
    throw new Error('Missing "login" implementation')
  },
  logout: () => {
    throw new Error('Missing "logout" implementation')
  },
  isAuthenticated: () => {
    throw new Error('Missing "isAuthenticated" implementation')
  },
})

export const Provider = ({ children }: any) => {
  const {
    auth,
    setAuth,
    account,
    setAccount,
    resetAuthAndAccount,
    setPreferences,
  } = useGlobalProvider()

  if (process.env.NODE_ENV !== 'production') {
    console.debug('--auth', auth)
    console.debug('--account', account)
  }

  const isAuthenticated = (): boolean => {
    if (!auth) {
      resetAuthAndAccount()
      return false
    }

    const { expiresAt } = auth
    const isExpired = new Date().getTime() > expiresAt

    if (isExpired) {
      resetAuthAndAccount()
      return false
    }

    return !isExpired
  }

  const isAuthAndAccountValid = (): boolean => {
    // One does not have data and the other has
    if ((!auth && account) || (auth && !account)) {
      return false
    }

    // Auth exists without token
    if (!auth?.token) {
      return false
    }

    // Missing account important info
    if (
      account &&
      account.type !== 'anonymous' &&
      (!account.id ||
        !account.type ||
        !account.profile?.id ||
        !account.profile?.email)
    ) {
      return false
    }

    return true
  }

  const isAuthValid = isAuthAndAccountValid()

  useEffect(() => {
    if (!isAuthValid) {
      resetAuthAndAccount()
    }
  }, [isAuthValid])

  return (
    <Context.Provider
      value={{
        auth,
        account,
        updateAccount: (partialAccount: Partial<Account>) => {
          const newAccount = {
            ...account,
            ...partialAccount,
          }
          setAccount(newAccount)
          Sentry.setContext('account', newAccount)
        },
        login: async (authPayload: AuthPayload) => {
          const { token, expiresAt, account } = authPayload
          const newAuth = {
            token,
            expiresAt,
          }
          const newAccount = account

          Sentry.setContext('auth', newAuth)
          Sentry.setContext('account', newAccount)

          setAuth(newAuth)
          setAccount(newAccount)

          if (account.preferences) {
            setPreferences({
              yogaTypes: account.preferences.yogaTypes.split(
                ',',
              ) as Yoga_Type_Enum[],
              levels: account.preferences.levels.split(
                ',',
              ) as Class_Level_Enum[],
              settings: account.preferences.settings.split(
                ',',
              ) as Class_Setting_Enum[],
            })
          }
        },
        logout: () => {
          resetAuthAndAccount()
          Sentry.setContext('auth', null)
          Sentry.setContext('account', ACCOUNT.defaultData)
        },
        isAuthenticated: () => {
          const isAuthed = !!auth && isAuthenticated()

          return isAuthed
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

export interface UseAuth extends ContextInterface {
  isStudent: () => boolean
  isTeacher: () => boolean
  isStudio: () => boolean
  isUnkown: () => boolean
  isAnonymous: () => boolean
  isEntity: () => boolean
  isPerson: () => boolean
  canAssistToClass: () => boolean
  canTeachAClass: () => boolean
}

export const useAuth = (): UseAuth => {
  const data = React.useContext(Context)

  return {
    ...data,
    isStudent: () => (data.account ? isStudent(data.account.type) : false),
    isTeacher: () => (data.account ? isTeacher(data.account.type) : false),
    isStudio: () => (data.account ? isStudio(data.account.type) : false),
    isUnkown: () => (data.account ? isUnkown(data.account.type) : false),
    isAnonymous: () => (data.account ? isAnonymous(data.account.type) : false),
    isEntity: () => (data.account ? isEntity(data.account.type) : false),
    isPerson: () => (data.account ? isPerson(data.account.type) : false),
    canAssistToClass: () =>
      data.account ? canAssistToClass(data.account.type) : false,
    canTeachAClass: () =>
      data.account ? canTeachAClass(data.account.type) : false,
  }
}

// NOTE: This is intented to be used for Testing only
// RUNNING THIS COMPONENT IN THE APP WILL CAUSE ISSUES!
// Only run it on stories and tests

// interface ProviderProps {
//   children: any
//   mocks?: {
//     auth?: AuthData
//     account?: Account
//   }
// }

// export const MockedProvider = ({ children, mocks }: ProviderProps) => {
//   React.useMemo(() => {
//     if (mocks?.auth) {
//       AuthStorage.set(mocks.auth)
//     } else {
//       AuthStorage.remove()
//     }

//     if (mocks?.account) {
//       AccountStorage.set(mocks.account)
//     } else {
//       AccountStorage.reset()
//     }
//   }, [mocks])

//   return <Provider>{children}</Provider>
// }
