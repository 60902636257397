import { Plugins, CameraResultType } from '@capacitor/core'
import { useAlert } from 'components/AlertModal'
import useLogger from './useLogger'

const { Camera } = Plugins

export type PhotoBase64 = {
  base64String: string
  format: string
}

type Options = {
  width?: number
  height?: number
  autoHandleReject?: boolean
}

const useCamera = (): {
  getPhoto: (options?: Options) => Promise<PhotoBase64>
} => {
  const alert = useAlert()
  const { log } = useLogger()

  const getPhoto = (
    { width, height, autoHandleReject }: Options = { autoHandleReject: true },
  ): Promise<PhotoBase64> =>
    new Promise((resolve, reject) => {
      Camera.getPhoto({
        quality: 90,
        allowEditing: false, // Note: does not work on Android: https://github.com/ionic-team/capacitor/issues/2695 & https://github.com/ionic-team/capacitor/issues/2089
        resultType: CameraResultType.Base64,
        width,
        height,
      })
        .then((result) => {
          if (result.base64String) {
            resolve({
              format: result.format,
              base64String: result.base64String,
            })
          } else {
            const error = new Error('Missing Base64 data')
            reject(error)
            log('error', 'Get Photo from useCamera', {
              error,
            })
          }
        })
        .catch((err) => {
          // iOS - permissiones are denied
          if (err.message === 'User denied access to photos') {
            alert.send({
              $type: 'danger',
              title: 'Cannot access',
              body: (
                <>
                  Ohmunity™ does not have access.
                  <br />
                  <br />
                  Please go to <strong>Settings</strong>, then find{' '}
                  <strong>"Ohmunity"</strong> (probably at the end of list),
                  click on it, and enable access to <strong>Camera</strong> and{' '}
                  <strong>Photos</strong>
                </>
              ),
              buttons: [
                {
                  children: 'Close',
                },
              ],
            })
          } else if (err.message !== 'User cancelled photos app') {
            if (autoHandleReject) {
              alert.send({
                $type: 'danger',
                title: 'Unable to select picture',
                body: err.message,
                buttons: [
                  {
                    children: 'Close',
                  },
                ],
              })
            } else {
              reject(err.message)
            }

            log('error', 'Get Photo from useCamera', {
              error: err,
            })
          }
        })
    })

  return {
    getPhoto,
  }
}

export default useCamera
