import { GenericSubscriptionRender } from 'components/GenericQueryRender'
import { useAuth } from 'stores/auth.store'
import Container from 'components/Container'
import Button from 'components/Button'
import TeacherCard from 'components/TeacherCard'
import { useApolloClient } from '@apollo/client'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { SUBSCRIPTION_STUDIO_TEACHERS } from 'graphql/subscriptions'
import DeletableList from 'components/DeletableList'
import StackNavigation, {
  makeContext,
  StackComponentProps,
} from 'components/StackNavigation'
import { QUERY_TEACHER_IS_TEACHING_CLASSES } from 'graphql/queries'
import { DELETE_TEACHER_BY_PK } from 'graphql/mutations'
import { Teacher } from 'generated/graphql'
import { useToast } from 'components/ToastMessage'
import { useAlert } from 'components/AlertModal'
import FormStudioTeacher from 'forms/FormStudioTeacher'

/*
<SpaceForKeyboard /> not needed
*/

const Form = ({ data: teacher, back }: StackComponentProps<Teacher>) => {
  return (
    <Container topBottomSpace>
      {/* Form */}
      <FormStudioTeacher
        onAdded={() => {
          back?.()
        }}
        onUpdated={() => {
          back?.()
        }}
        teacherID={teacher?.id}
        personID={teacher?.person.id}
        initialValues={{
          firstName: teacher?.person.firstName,
          lastName: teacher?.person.lastName,
          email: teacher?.person.email,
          picture: teacher?.person.picture,
          phoneNumber: teacher?.person.phoneNumber,
          bio: teacher?.bio,
          cover_picture: teacher?.cover_picture,
        }}
      />

      <div className="py-2">
        <Button
          type="button"
          $type="secondary"
          $fluid
          onClick={() => {
            if (window.confirm('Are you sure you can to cancel?')) {
              back?.()
            }
          }}
        >
          Cancel
        </Button>
      </div>
    </Container>
  )
}

const List = ({ navigate }: any) => {
  const { account } = useAuth()
  const { query, mutate } = useApolloClient()
  const { notify } = useToast()
  const alert = useAlert()

  const deleteTeacher = async (teacherID: string) => {
    try {
      // verify teacher
      const verifyResult = await query({
        query: QUERY_TEACHER_IS_TEACHING_CLASSES,
        variables: {
          teacherID,
        },
      })

      // TODO: Maybe we do not need this if graphql throws a constraint error
      if (verifyResult.data.class_date_time_aggregate.aggregate.count > 0) {
        notify({
          type: 'failure',
          message:
            'The teacher is assigned to future classes. In order to remove the teacher, you need to assign a new teacher to its classes',
        })
      } else {
        await mutate({
          mutation: DELETE_TEACHER_BY_PK,
          variables: {
            teacherID,
          },
        })

        notify({
          type: 'success',
          message: 'The teacher has been deleted successfuly.',
        })
      }
    } catch (err) {
      console.error(err)

      notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
    }
  }

  return (
    <GenericSubscriptionRender
      subscription={SUBSCRIPTION_STUDIO_TEACHERS}
      variables={{
        accountID: account.id,
      }}
      dataAccess="teacher"
      NoResults={() => (
        <Container topBottomSpace>
          No teachers yet
          <div className="py-3">
            <Button
              type="button"
              $type="primary"
              $fluid
              onClick={() => {
                navigate({
                  title: 'Add Teacher',
                  Component: Form,
                })
              }}
            >
              Add Teacher
            </Button>
          </div>
        </Container>
      )}
      Success={({ data }: any) => {
        return (
          <Container topBottomSpace>
            <DeletableList
              $type="card"
              context="teachers"
              onDelete={async (index) => {
                alert.send({
                  title: 'Are you sure you want to delete the teacher?',
                  buttons: [
                    {
                      $type: 'primary',
                      children: 'Delete',
                      onClick: async () => {
                        await deleteTeacher(data[index].id)
                      },
                    },
                    {
                      $type: 'tertiary',
                      children: 'Cancel',
                    },
                  ],
                })
              }}
            >
              {data.map((teacher: Teacher) => (
                <div
                  key={`teacher-${teacher.id}`}
                  role="button"
                  onClick={() => {
                    navigate({
                      title: `Update ${teacher.person.firstName} ${teacher.person.lastName}`,
                      Component: Form,
                      data: teacher,
                    })
                  }}
                >
                  <TeacherCard teacherID={teacher.id} person={teacher.person} />
                </div>
              ))}
            </DeletableList>

            <div className="pt-6">
              <Button
                type="button"
                $type="primary"
                $fluid
                onClick={() => {
                  navigate({
                    title: 'Add Teacher',
                    Component: Form,
                  })
                }}
              >
                Add Teacher
              </Button>
            </div>
          </Container>
        )
      }}
    />
  )
}

const context = makeContext()

const StudioTeachers = () => {
  return <StackNavigation Context={context} Component={List} />
}

export default StudioTeachers
