import { useEffect } from 'react'
import cc from 'classcat'
import { CSSTransition } from 'react-transition-group'

import styles from './index.module.scss'

export const ANIMATION_DURATION = 300

type ModalProps = {
  isOpen: boolean
  toggle: () => void
  children?: React.ReactNode
  LazyChildren?: any
  $contentType?: 'full-screen' | 'content-base'
  $modalClassName?: string
}

const Modal = ({
  isOpen,
  toggle,
  children,
  LazyChildren,
  $contentType,
  $modalClassName,
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.querySelector('body')?.classList.add('overflow-hidden')
    } else {
      document.querySelector('body')?.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <CSSTransition in={isOpen} timeout={300} classNames={styles} unmountOnExit>
      {() => (
        <div className={cc([styles.root, 'z-900'])}>
          <div
            className={styles.overlay}
            onClick={() => {
              if ($contentType === 'content-base') {
                toggle()
              }
            }}
          />
          <div
            className={cc([
              styles.content,
              {
                [styles.contentFullScreen]: $contentType === 'full-screen',
                [styles.contentContentBase]: $contentType === 'content-base',
              },
              (!$modalClassName || !$modalClassName.includes('bg-')) &&
                'bg-white',
              $modalClassName,
            ])}
          >
            {LazyChildren ? (
              <LazyChildren isOpen={isOpen} toggle={toggle} />
            ) : (
              children
            )}
          </div>
        </div>
      )}
    </CSSTransition>
  )
}

Modal.defaultProps = {
  $contentType: 'full-screen',
}

export default Modal
