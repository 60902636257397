// TODO: Make it a utility (BE also uses it)

function patternMatching<K = unknown, R = unknown>(
  cases: Array<[K, R]>,
): (v: K) => R | undefined
function patternMatching<K = unknown, R = unknown>(
  cases: Array<[K, R]>,
  fallback: R,
): (v: K) => R
function patternMatching<K = unknown, R = unknown>(
  cases: Array<[K, R]>,
  fallback?: R,
) {
  return (value: K) => {
    for (const [$case, $return] of cases) {
      if ($case === value) {
        return $return
      }
    }

    if (fallback) {
      return fallback as R
    } else {
      return undefined
    }
  }
}

export default patternMatching
