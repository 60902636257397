import React from 'react'
import Container from 'components/Container'
import NotLoggedIn from 'components/NotLoggedIn'
import Loading from 'components/Loading'
import ClassDetailsScreen from 'screens/ClassDetails'
import ClassCard from 'components/ClassCard'
import NothingYet from 'components/NothingYet'
import { useAuth } from 'stores/auth.store'
import { useSubscription } from '@apollo/client'
import { useModalScreen } from 'components/ModalScreen'
import { Class, FavoriteClassesSubSubscription } from 'generated/graphql'
import { SUBSCRIPTION_FAVORITE_CLASSES } from 'graphql/subscriptions'
import { navigate } from 'clients/navigation.client'
import useMode from 'hooks/useMode'

const ListClasses = () => {
  const [mode] = useMode()
  const { open, close } = useModalScreen()
  const {
    loading,
    error,
    data,
  } = useSubscription<FavoriteClassesSubSubscription>(
    SUBSCRIPTION_FAVORITE_CLASSES,
  )

  if (loading) {
    return <Loading context="Favorite Classes" />
  }

  if (error) {
    console.error('ERROR!', error)
    return <div>Error</div>
  }

  if (data?.favorite_class && data.favorite_class.length > 0) {
    return (
      <ul>
        {data.favorite_class.map(
          ({ class: clazz, class_date_time_id }, i: number) => (
            <li
              key={`class-card-${clazz.id}-${class_date_time_id}`}
              className="py-2"
            >
              <ClassCard
                clazz={clazz as Class}
                onClick={(clazz) => {
                  open({
                    header: 'Class Details',
                    body: (
                      <ClassDetailsScreen
                        preloadedClass={clazz}
                        classId={clazz.id}
                        dateTimeID={class_date_time_id}
                      />
                    ),
                  })
                }}
                dateTimeID={class_date_time_id}
                to={`/class/${clazz.id}/${class_date_time_id}`}
              />
            </li>
          ),
        )}
      </ul>
    )
  }

  return (
    <NothingYet
      title="No favorites yet."
      description="When you see a class you love, add it to your favorites."
      ctas={
        mode !== 'teach'
          ? [
              {
                text: 'Find a Class',
                onClick: () => {
                  navigate('/search')
                  close()
                },
              },
            ]
          : undefined
      }
    />
  )
}

const Favorites = () => {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated()) {
    return (
      <Container className="flex flex-col justify-center h-full">
        <NotLoggedIn />
      </Container>
    )
  }

  return (
    <Container topBottomSpace className="safe-area-mt">
      <ListClasses />
    </Container>
  )
}

export default Favorites
