import Container from 'components/Container'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { useState, useEffect, useCallback } from 'react'
import { TripaneerResponse } from 'generated/tripaneer_interfaces'
import jsonp from 'jsonp'
import ms from 'ms'
import cc from 'classcat'
import Button from 'components/Button'
import { useModalScreen, withModalScreen } from 'components/ModalScreen'
import { useForm } from 'react-hook-form'
import Switch from 'components/Switch'
// import Accordion from 'components/Accordion'
import FormField, { DropdownOption } from 'components/FormField'
// import { ReactComponent as IconX } from 'assets/symbols/x.svg'
// import { ReactComponent as IconCarrot } from 'assets/symbols/carrot.svg'
// import cc from 'classcat'
import { take } from 'lodash/fp'
import useToggle from 'hooks/useToggle'
import EventCard from 'components/EventCard'
import buildQueryParams from 'utils/buildQueryParams'
import {
  TRIPANEER_RETREATS_AFFILIATE_ID,
  TRIPANEER_TEACHER_TRAINING_AFFILIATE_ID,
} from 'globalConstants'
import useAffiliateProgram from 'hooks/useAffiliateProgram'
import { isWebapp } from 'utils/env'

const URL_RETREATS = `https://www.bookyogaretreats.com/json?`
const URL_TEACHER_TRAINING = `https://www.bookyogateachertraining.com/json?`
const LIMIT = 12
const STYLES_LIMIT = 5
const START_PAGE = 1

type Filters = {
  page: number
  online_offer?: boolean
  d?: string // destination
  duration_option?: string[]
  s?: string[] // yoga types/styles
  sorting: string
}

type AffiliateType = 'retreats' | 'teacher-training'

const makeRequest = (
  type: AffiliateType,
  params?: Filters,
): Promise<TripaneerResponse> =>
  new Promise((resolve, reject) => {
    const queryParams = params ? buildQueryParams(params) : ''

    const url =
      (type === 'retreats' ? URL_RETREATS : URL_TEACHER_TRAINING) + queryParams

    // NOTE: Can't use 'fetch' due to cors restrictions, this solves it because 'callback' param is supported by the API
    jsonp(
      url,
      {
        name: '__bookretreats',
        timeout: ms('10s'),
      },
      (err, data) => {
        if (err) {
          reject(err.message)
        } else {
          resolve(data)
        }
      },
    )
  })

type Props = {
  type: AffiliateType
}

const AffiliateTripaneer = ({ type }: Props) => {
  const modal = useModalScreen()
  const [data, setData] = useState<TripaneerResponse['results'] | null>(null)
  const [fetching, setFetching] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [filterSettings, setFilterSettings] = useState<
    TripaneerResponse['filter'] | null
  >()
  const [page, setPage] = useState(START_PAGE)
  const [filters, setFilters] = useState<Filters>({
    page,
    sorting: 'recommended',
  })
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    const hasPageChanged = page !== filters.page

    setFetching(true)

    if (!hasPageChanged) {
      setData(null)
    }

    makeRequest(type, filters)
      .then((data) => {
        setFetching(false)
        setFilterSettings(data.filter)
        setTotal(data.matchCount)

        if (hasPageChanged) {
          setData((currentData) => {
            const newData = [...(currentData || []), ...(data.results || [])]

            if (newData.length > data.matchCount) {
              return take(data.matchCount, newData)
            } else {
              return newData
            }
          })
          setPage(filters.page)
        } else if (!data.results) {
          setData(null)
        } else {
          setData(
            data.results.length > data.matchCount
              ? take(data.matchCount, data.results)
              : data.results,
          )
        }
      })
      .catch((err) => {
        setError(err)
        setFetching(false)
      })
  }, [filters])

  const onLoadMore = useCallback(() => {
    setFilters((filter) => ({
      ...filter,
      page: filter.page + 1,
    }))
  }, [])

  return (
    <>
      {filterSettings && (
        <Container xSpace={isWebapp()}>
          <div
            className={cc([
              'flex items-center justify-between p-5 bg-white shadow bg-opacity-95',
              {
                'z-9 sticky top-0': !isWebapp(),
              },
            ])}
          >
            <div className="heading-3">{total} results</div>
            <button
              className="body-2 text-primary"
              onClick={() => {
                modal.open({
                  header: 'Retreats Filters',
                  body: (
                    <FiltersModal
                      type={type}
                      settings={filterSettings}
                      currentFilters={filters}
                      onFilter={(newFilters: Filters) => {
                        setFilters((filter) => ({
                          ...filter,
                          ...newFilters,
                          page: START_PAGE,
                        }))
                        modal.close()
                      }}
                    />
                  ),
                })
              }}
            >
              Sort {`&`} Filter
            </button>
          </div>
        </Container>
      )}

      <Container topBottomSpace>
        {data && (
          <ListResults
            data={data!}
            onLoadMore={onLoadMore}
            isFetching={fetching}
            type={type}
          />
        )}

        {fetching && (
          <div className="py-2">
            <Loading />
          </div>
        )}

        {error && <ServerErrorMessage>{error}</ServerErrorMessage>}
      </Container>
    </>
  )
}

export default withModalScreen(AffiliateTripaneer)

type ListResultsProps = {
  data: TripaneerResponse['results']
  onLoadMore: () => void
  isFetching: boolean
  type: AffiliateType
}

const ListResults = ({
  data,
  onLoadMore,
  isFetching,
  type,
}: ListResultsProps) => {
  const affiliate = useAffiliateProgram({
    type,
    source: {
      name: 'Tripanner',
      website: 'https://www.tripaneer.com/',
    },
  })

  if (!data) {
    return <div>No results :(</div>
  }

  return (
    <>
      <ul className="grid gap-8 sm:grid-cols-2">
        {data.map((retreat, index) => (
          <li key={`${index}-retreat-${retreat.id}`}>
            <EventCard
              data={{
                id: retreat.id,
                title: retreat.title,
                subtitle: retreat.includedMeals,
                images: retreat.images
                  ? retreat.images.map((image) => image.medium)
                  : [],
                spokenLanguages: retreat.spokenLanguages || [],
                reviews: {
                  score: 5 * (retreat.avgReviewScore / 100),
                  total: retreat.reviewNr,
                },
                duration: retreat.durationInDaysTranslated,
                availability: retreat.availabilityShowcard,
                pricePerTotalPeople: retreat.minPriceNrPersonsTranslated,
                price: Math.round(retreat.price.amount / 100),
              }}
              onClick={() => {
                const url = [
                  retreat.href,
                  '?aid=',
                  type === 'retreats'
                    ? TRIPANEER_RETREATS_AFFILIATE_ID
                    : TRIPANEER_TEACHER_TRAINING_AFFILIATE_ID,
                ].join('')

                affiliate.open(retreat.title, url)
              }}
            />
          </li>
        ))}
      </ul>

      {!isFetching && data.length % LIMIT === 0 && (
        <div className="pt-8">
          <Button
            $fluid
            onClick={() => {
              onLoadMore()
            }}
          >
            Load more
          </Button>
        </div>
      )}
    </>
  )
}

type FiltersModalProps = {
  type: AffiliateType
  settings: TripaneerResponse['filter']
  currentFilters: Filters
  onFilter: (newFilters: Filters) => void
}

const FiltersModal = ({
  type,
  settings,
  currentFilters,
  onFilter,
}: FiltersModalProps) => {
  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      sorting: currentFilters.sorting,
      online: !!currentFilters.online_offer,
      destination: currentFilters.d || undefined,
      duration: currentFilters.duration_option || [],
      styles: currentFilters.s || [],
    },
  })
  // const watchDestination = watch('destination')
  const currentStyles: string[] = currentFilters.s || []
  const styles = [
    ...currentStyles.map((current) =>
      settings.styles!.find((style) => style.id === parseInt(current, 10)),
    ),
    ...settings.styles!.filter(
      (style) => !currentStyles.includes(style.id.toString()),
    ),
  ].filter(Boolean) as NonNullable<TripaneerResponse['filter']['styles']>
  const [showAllStyles, toggleShowAllStyles] = useToggle(
    currentStyles.length > STYLES_LIMIT,
  )

  return (
    <Container topBottomSpace>
      <form
        className="grid gap-3"
        onSubmit={handleSubmit((values) => {
          onFilter({
            ...currentFilters,
            sorting: values.sorting,
            online_offer: values.online,
            d: values.destination || undefined,
            duration_option:
              values.duration.length > 0 ? values.duration : undefined,
            s: values.styles.length > 0 ? values.styles : undefined,
          })
        })}
      >
        {/* Sorting */}
        <div>
          <FormField
            type="dropdown"
            name="sorting"
            register={register}
            control={control}
            label="Sort by"
            placeholder="Select a value"
          >
            <DropdownOption value="recommended">Recommended</DropdownOption>
            <DropdownOption value="popularity">Popularity</DropdownOption>
            <DropdownOption value="duration_asc">
              Duration short to long
            </DropdownOption>
            <DropdownOption value="duration_desc">
              Duration long to short
            </DropdownOption>
            <DropdownOption value="price_asc">Price low to high</DropdownOption>
            <DropdownOption value="price_desc">
              Price high to low
            </DropdownOption>
            <DropdownOption value="price_per_day_asc">
              Price per day low to high
            </DropdownOption>
            <DropdownOption value="price_per_day_desc">
              Price per day high to low
            </DropdownOption>
            <DropdownOption value="review_score">Review score</DropdownOption>
          </FormField>
        </div>

        {/* Online */}
        {type === 'teacher-training' && (
          <div className="flex justify-between items-center">
            <div>Online Experiences</div>
            <Switch name="online" control={control} />
          </div>
        )}

        {/* {settings.destinations && (
          <>
            <div className="heading-2 text-primary">Destinations</div>
            {watchDestination && (
              <div className="flex items-center justify-between">
                <div>
                  Selected:{' '}
                  <span className="body-medium-1">
                    {settings.destinations!.find(
                      (d) => d.slug == watchDestination,
                    )?.name || 'N/A'}
                  </span>
                </div>
                <button
                  type="button"
                  className="flex items-center heading-3"
                  onClick={() => {
                    setValue('destination', '')
                  }}
                >
                  <IconX className="w-2 mr-1" />
                  <span>Reset</span>
                </button>
              </div>
            )}
            <input type="hidden" name="destination" ref={register} />
            <ul>
              {settings.destinations
                .filter(
                  (destination) =>
                    !destination.parentIDs ||
                    destination.parentIDs.length === 0,
                )
                .map((destination) => (
                  <li
                    key={`destination-${destination.id}`}
                    className="bg-white border-b border-gray-400"
                  >
                    <Accordion
                      Button={({ onClick, isOpen }) => (
                        <button
                          type="button"
                          onClick={onClick}
                          className="flex items-center justify-between p-3 w-full text-left active:bg-gray-100"
                        >
                          <span>{destination.name}</span>
                          <IconCarrot
                            className={cc([
                              'w-3 transform transition',
                              {
                                'rotate-180': isOpen,
                              },
                            ])}
                          />
                        </button>
                      )}
                      Body={({ onClick }) => (
                        <ul className="bg-white pb-4">
                          {settings
                            .destinations!.filter((d) =>
                              d.parentIDs?.includes(destination.id),
                            )
                            .map((destination) => (
                              <li key={`subdestination-${destination.id}`}>
                                <button
                                  type="button"
                                  className={cc([
                                    'block w-full text-left py-2 pl-6 pr-2',
                                    {
                                      'active:bg-gray-100':
                                        watchDestination != destination.slug,
                                      'bg-primary text-white':
                                        watchDestination == destination.slug,
                                    },
                                  ])}
                                  onClick={() => {
                                    setValue('destination', destination.slug)
                                    onClick()
                                  }}
                                >
                                  {destination.name}
                                </button>
                              </li>
                            ))}
                        </ul>
                      )}
                    />
                  </li>
                ))}
            </ul>
          </>
        )} */}

        <div>
          <div className="heading-2 text-primary">Duration</div>
          <ul className="py-1">
            {[
              {
                id: '1',
                label: '2 days',
              },
              {
                id: '2',
                label: 'From 3 to 7 days',
              },
              {
                id: '3',
                label: 'From 1 to 2 weeks',
              },
              {
                id: '4',
                label: 'More than 2 weeks',
              },
            ].map(({ id, label }) => (
              <li key={`duration-${id}`}>
                <FormField
                  id={`duration-${id}`}
                  name="duration"
                  register={register}
                  type="checkbox"
                  value={id}
                  label={label}
                />
              </li>
            ))}
          </ul>
        </div>

        <div>
          <div className="heading-2 text-primary">Styles ({styles.length})</div>
          <ul>
            {(showAllStyles ? styles : take(STYLES_LIMIT, styles)).map(
              ({ id, title }) => (
                <li key={id}>
                  <FormField
                    id={`style-${id}`}
                    name="styles"
                    register={register}
                    type="checkbox"
                    value={id.toString()}
                    label={title}
                  />
                </li>
              ),
            )}
          </ul>
          <Button
            type="button"
            $type="link"
            $size="sm"
            onClick={() => toggleShowAllStyles()}
          >
            {showAllStyles ? 'Show less' : 'Show All'}
          </Button>
        </div>

        <Button $fluid>Apply Filters</Button>
      </form>
    </Container>
  )
}

/*
<SpaceForKeyboard /> not needed in this file
*/
