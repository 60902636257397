import { WithNotOptimizedForWebapp } from 'components/NotOptimizedForWebapp'
import useMode from 'hooks/useMode'
import React from 'react'
import { useAuth } from 'stores/auth.store'
import StudentClasses from './StudentClasses'
import StudioClasses from './StudioClasses'

const MyClasses = () => {
  const { isStudent, isStudio, isTeacher } = useAuth()
  const [mode] = useMode()

  if (isStudent() || (isTeacher() && mode === 'practice')) {
    return <StudentClasses />
  }

  if (isStudio() || (isTeacher() && mode === 'teach')) {
    return <StudioClasses />
  }

  return null
}

export default WithNotOptimizedForWebapp(MyClasses)
