import { capitalize, lowerCase, upperCase } from 'lodash/fp'
import { useAlert } from 'components/AlertModal'
import { Plugins } from '@capacitor/core'

const { Browser } = Plugins

type Opts = {
  source: {
    name: string
    website: string
  }
  type: string
}

const useAffiliateProgram = ({ type, source }: Opts) => {
  const alert = useAlert()
  const key = `AFFILIATE_PROGRAM_${upperCase(source.name)}_${type}_AGREE`

  const open = (title: string, url: string) => {
    if (!localStorage.getItem(key)) {
      alert.send({
        title: 'Partnership',
        body: (
          <div className="space-y-2">
            <p>
              <strong>Ohmunity</strong> is associated with{' '}
              <a
                href={source.website}
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                {source.name}
              </a>{' '}
              to offer {capitalize(lowerCase(type))}.
            </p>
            <p>
              Bookings, Payments, and Customer Support for these events are
              handled by {source.name}.
            </p>
            <p>
              Ohmunity <span className="underline">does not</span> your private
              information with Tripanner, such as Personal information or Credit
              Card information
            </p>
          </div>
        ),
        buttons: [
          {
            children: 'I understand',
            onClick: () => {
              localStorage.setItem(key, '1')
              Browser.open({
                url,
                windowName: title,
                presentationStyle: 'popover',
              })
            },
          },
          {
            $type: 'tertiary',
            children: 'Cancel',
          },
        ],
      })
    } else {
      Browser.open({
        url,
        windowName: title,
        presentationStyle: 'popover',
      })
    }
  }

  return { open }
}

export default useAffiliateProgram
