import Container from 'components/Container'
import FormField, { FormGroup } from 'components/FormField'
import GenericQueryRender from 'components/GenericQueryRender'
import { useForm } from 'react-hook-form'
import {
  AccountSocialMediaDocument,
  AccountSocialMediaQuery,
  Social_Media_Type_Enum,
  useInsertUpdateAccountSocialMediaMutation,
} from 'generated/graphql'
import { useAuth } from 'stores/auth.store'
import Button from 'components/Button'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { yupResolver } from '@hookform/resolvers'
import ServerErrorMessage from 'components/ServerErrorMessage'
import SpaceForKeyboard from 'components/SpaceForKeyboard'

type InitialValues = {
  facebook: string
  instagram: string
  twitter: string
  tiktok: string
  website: string
}

const dataToInitialValues = (
  data: AccountSocialMediaQuery['account_social_media'],
): InitialValues => {
  const obj: Record<string, string> = {
    facebook: '',
    instagram: '',
    twitter: '',
    tiktok: '',
    website: '',
  }

  data.forEach((socialMedia) => {
    obj[socialMedia.type] = socialMedia.url || ''
  })

  return obj as InitialValues
}

type Props = {
  onComplete?: () => void
}

const SocialMedia = (props: Props) => {
  const { account } = useAuth()

  return (
    <Container topBottomSpace>
      <GenericQueryRender
        fetchPolicy="cache-and-network"
        query={AccountSocialMediaDocument}
        variables={{
          accountID: account.id,
        }}
        dataAccess="account_social_media"
        Success={({ data }) => (
          <Form {...props} initialValues={dataToInitialValues(data)} />
        )}
      />
    </Container>
  )
}

type FormProps = Props & {
  initialValues?: InitialValues
}

const validationSchema = yup.object().shape({
  facebook: Validation.url,
  instagram: Validation.url,
  twitter: Validation.url,
  tiktok: Validation.url,
  website: Validation.url,
})

const Form = ({ initialValues, onComplete }: FormProps) => {
  const { register, formState, handleSubmit, watch } = useForm({
    defaultValues: {
      facebook: initialValues?.facebook || '',
      instagram: initialValues?.instagram || '',
      twitter: initialValues?.twitter || '',
      tiktok: initialValues?.tiktok || '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { isDirty, errors } = formState
  const [
    insertUpdate,
    insertUpdateState,
  ] = useInsertUpdateAccountSocialMediaMutation({
    onCompleted: (data) => {
      if (data.insert_account_social_media) {
        onComplete?.()
      }
    },
  })
  const watchFacebook = watch('facebook')
  const watchInstagram = watch('instagram')
  const watchTwitter = watch('twitter')
  const watchTiktok = watch('tiktok')

  return (
    <>
      <p className="text-center p-2 bg-white mb-10 border border-primary border-dashed">
        Only enter the username for each platform.
      </p>
      <form
        onSubmit={handleSubmit(async (values) => {
          await insertUpdate({
            variables: {
              objects: [
                {
                  type: Social_Media_Type_Enum.Facebook,
                  url: values.facebook || null,
                },
                {
                  type: Social_Media_Type_Enum.Instagram,
                  url: values.instagram || null,
                },
                {
                  type: Social_Media_Type_Enum.Twitter,
                  url: values.twitter || null,
                },
                {
                  type: Social_Media_Type_Enum.Tiktok,
                  url: values.tiktok || null,
                },
              ],
            },
          })
        })}
      >
        <FormGroup>
          <FormField
            type="text"
            name="facebook"
            label="Facebook"
            register={register}
            error={errors.facebook?.message}
            sublabel={`facebook.com/${watchFacebook}`}
          />
          <FormField
            type="text"
            name="instagram"
            label="Instagram"
            register={register}
            error={errors.instagram?.message}
            sublabel={`instagram.com/${watchInstagram}`}
          />
          <FormField
            type="text"
            name="twitter"
            label="Twitter"
            register={register}
            error={errors.twitter?.message}
            sublabel={`twitter.com/${watchTwitter}`}
          />
          <FormField
            type="text"
            name="tiktok"
            label="Tiktok"
            register={register}
            error={errors.tiktok?.message}
            sublabel={`tiktok.com/${watchTiktok}`}
          />

          {insertUpdateState.error && (
            <ServerErrorMessage>
              {insertUpdateState.error.message}
            </ServerErrorMessage>
          )}

          <Button $fluid disabled={!isDirty || insertUpdateState.loading}>
            Save
          </Button>
        </FormGroup>
      </form>

      <SpaceForKeyboard $size="lg" />
    </>
  )
}

export default SocialMedia
