import cc from 'classcat'
import { ReactComponent as IconMask } from 'assets/icons/mask.svg'
import { ReactComponent as IconVentilation } from 'assets/icons/ventilation.svg'
import { ReactComponent as IconSanitation } from 'assets/icons/sanitation.svg'
import { ReactComponent as IconContactless } from 'assets/icons/contactless.svg'
import { ReactComponent as IconVaccine } from 'assets/icons/vaccine.svg'
import { ReactComponent as IconLimitedCapacity } from 'assets/icons/limited-capacity.svg'
import { ReactComponent as IconLimitedAmenities } from 'assets/icons/limited-amenities.svg'
import { ReactComponent as IconSocialDistance } from 'assets/icons/social-distance.svg'
import { ReactComponent as IconTemperature } from 'assets/icons/temperature.svg'

const ICONS = [
  ['mask_wearing_policies', IconMask],
  ['ventilation_system', IconVentilation],
  ['extra_sanitation', IconSanitation],
  ['contactless_check_in', IconContactless],
  ['vaccination_policies', IconVaccine],
  ['limited_capacity', IconLimitedCapacity],
  ['limited_amenity_access', IconLimitedAmenities],
  ['social_distancing_measures', IconSocialDistance],
  ['temperature_checks', IconTemperature],
]

/*
contactless_check_in
Please book your class through the app ahead of time.

extra_sanitation
Studio is cleaned thoroughly between classes. Hand sanitizer and disinfectant wipes are also provided throughout the studio.

limited_amenity_access
Some amenties are not available at this time,please check class description for details.

limited_capacity
Studio is operating at a certain capacity at this time, please check class description for details.

mask_wearing_policies
Masks are required at all times.

social_distancing_measures
Mats will be placed 6 ft apart, to adhere to social distancing guidelines

temperature_checks
Students will be greeted with mandatory temperature checks

vaccination_policies
Masks are required unless proof of vaccination.

ventilation_system
Studio follows current ventilation guidelines, please check class description for details.
*/

type Props = {
  id: string
  className?: string
}

const SafetyIcon = ({ id, className }: Props) => {
  const iconComponent = ICONS.find(([safetyID]) => safetyID === id)

  if (!iconComponent) {
    return null
  }

  const [, Icon] = iconComponent

  return <Icon className={cc(['w-4', className])} />
}

export default SafetyIcon
