import { Class, Account_Role_Enum } from 'generated/graphql'

export const isStudio = (type: Account_Role_Enum) => {
  return type === Account_Role_Enum.Studio
}

export const isStudent = (type: Account_Role_Enum) => {
  return type === Account_Role_Enum.Student
}

export const isTeacher = (type: Account_Role_Enum) => {
  return type === Account_Role_Enum.Teacher
}

export const isUnkown = (type: Account_Role_Enum) => {
  return type === Account_Role_Enum.Unknown
}

export const isAnonymous = (type: Account_Role_Enum) => {
  return type === Account_Role_Enum.Anonymous
}

export const isEntity = isStudio

export const isPerson = (type: Account_Role_Enum) => {
  return isStudent(type) || isTeacher(type)
}

export const canAssistToClass = (type: Account_Role_Enum) => {
  return isPerson(type) || isAnonymous(type)
}

export const canTeachAClass = (type: Account_Role_Enum) => {
  return isEntity(type) || isTeacher(type)
}

export const isClassOwner = (accountId: string, clazz: Class) =>
  clazz.account_id === accountId
