import React from 'react'
import cc from 'classcat'
import {
  SwipeableList,
  SwipeableListItem,
} from '@sandstreamdev/react-swipeable-list'

type Props = {
  children: React.ReactNode
  onDelete: (index: number) => void
  context?: string
  $type: 'card' | 'list'
  $itemBgClass?: string
}

const DeletableList = ({
  children,
  onDelete,
  context,
  $type,
  $itemBgClass,
}: Props) => {
  return (
    <div
      className={cc({
        '-mx-4': $type === 'card',
      })}
    >
      <SwipeableList>
        {React.Children.map(children, (child, index) => (
          <div
            className={cc([
              'bg-red-500',
              { 'm-4 shadow-lg': $type === 'card' },
            ])}
          >
            <SwipeableListItem
              key={`${context}-${index}`}
              swipeLeft={{
                content: <div className="text-white p-3">DELETE</div>,
                action: async () => {
                  await onDelete(index)
                },
              }}
            >
              <div
                className={cc([
                  'w-full',
                  $itemBgClass,
                  {
                    'border-t border-gray-500': index > 0 && $type === 'list',
                    'py-5': $type === 'list',
                  },
                ])}
                data-testid="deleteable-list"
              >
                {child}
              </div>
            </SwipeableListItem>
          </div>
        ))}
      </SwipeableList>
    </div>
  )
}

DeletableList.defaultProps = {
  context: 'deletable-list',
  $itemBgClass: 'bg-white',
}

export default DeletableList
