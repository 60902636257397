import React from 'react'
import { ReactComponent as LogoSymbol } from 'assets/logo-symbol.svg'
import Button from 'components/Button'
import Container from 'components/Container'
import FormLogin from 'forms/FormLogin'
import useSignupNavigation from 'hooks/useSignupNavigation'
import { RouteComponentProps } from '@reach/router'
import { ReactComponent as IconClose } from 'assets/symbols/x.svg'
import { ReactComponent as IconAvatar } from 'assets/icons/avatar-filled.svg'
import { ReactComponent as IconTeacher } from 'assets/icons/teacher-filled.svg'
import { ReactComponent as IconHouse } from 'assets/icons/house-filled.svg'
import { useGlobalProvider } from 'stores/global.store'
import { navigate } from 'clients/navigation.client'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import LiveSupportButton from 'components/LiveSupportButton'
import { isWebapp } from 'utils/env'

type CircleSelectorProps = {
  Icon: any
  onClick: any
  children: any
  ['data-testid']: string
}

const CircleSelector = ({
  Icon,
  onClick,
  children,
  'data-testid': dataTestID,
}: CircleSelectorProps) => {
  return (
    <button
      className="heading-3 text-primary text-center bg-white shadow-lg rounded-full my-1 mx-3"
      style={{
        width: 140,
        height: 140,
      }}
      onClick={() => {
        onClick()
      }}
      data-testid={dataTestID}
    >
      <Icon width={35} className="inline-block fill-primary" />
      <span className="block pt-1">{children}</span>
    </button>
  )
}

const Welcome = (_props: RouteComponentProps) => {
  const [showLogin, setShowLogin] = React.useState(false)
  const { preferences } = useGlobalProvider()
  const { signupNavigate } = useSignupNavigation()

  return (
    <div className="relative h-full safe-area-pt safe-area-pb">
      <Container
        topBottomSpace
        className="flex flex-col h-full justify-between bg-center bg-cover"
        $size={isWebapp() ? 'compact' : 'wide'}
      >
        {/* TOP */}
        <div className="text-center space-y-1">
          {!isWebapp() && (
            <LogoSymbol width="70" height="70" className="inline-block mb-3" />
          )}
          <div className="heading-1 text-primary">Ready to join?</div>
        </div>

        {/* CENTER */}
        {!showLogin && (
          <div
            className="py-8 text-center m-auto w-full"
            style={{
              maxWidth: 330,
            }}
          >
            <CircleSelector
              Icon={IconAvatar}
              onClick={() => {
                signupNavigate('/student', {
                  state: {
                    disableAnimation: true,
                  },
                })
              }}
              data-testid="button-yogi"
            >
              Yogi
            </CircleSelector>

            <div className="flex justify-between">
              <CircleSelector
                Icon={IconTeacher}
                onClick={() => {
                  signupNavigate('/teacher', {
                    state: {
                      disableAnimation: true,
                    },
                  })
                }}
                data-testid="button-teacher"
              >
                Teacher
              </CircleSelector>
              <CircleSelector
                Icon={IconHouse}
                onClick={() => {
                  signupNavigate('/studio', {
                    state: {
                      disableAnimation: true,
                    },
                  })
                }}
                data-testid="button-studio"
              >
                Studio
              </CircleSelector>
            </div>
          </div>
        )}

        {/* BOTTOM */}
        {showLogin ? (
          <div>
            <FormLogin
              onLoginCancel={() => {
                setShowLogin(false)
              }}
              $hasLargeFields
              VerifyWrapper={({ children }: any) => (
                <div className="fixed inset-0 flex items-center bg-gray-200">
                  <Container topBottomSpace>{children}</Container>
                </div>
              )}
            />
            <SpaceForKeyboard />
          </div>
        ) : (
          <div className="space-y-5">
            {(isWebapp() || !preferences) && (
              <>
                <div className="text-center heading-2 text-primary">
                  Already have an account?
                </div>
                <Button
                  $fluid
                  onClick={() => {
                    setShowLogin(true)
                  }}
                >
                  Login
                </Button>
              </>
            )}
          </div>
        )}

        <LiveSupportButton />
      </Container>

      {!isWebapp() && preferences && (
        <button
          className="absolute top-0 left-0 p-4 safe-area-mt"
          onClick={() => {
            navigate('/account')
          }}
        >
          <IconClose width={20} className="fill-primary" />
        </button>
      )}
    </div>
  )
}

export default Welcome
