import moment from 'moment'
import BackgroundImage from 'components/BackgroundImage'
import DefaultAvatar from 'assets/user-avatar.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import GenericQueryRender from 'components/GenericQueryRender'
import { QUERY_INSPIRATIONAL_READS } from 'graphql/queries'
import { InspirationalReadsQuery } from 'generated/graphql'
import { Plugins } from '@capacitor/core'
import { useLoadingBlock } from 'components/LoadingBlock'
import womanDarkBg from 'assets/photos/woman-dark-bg.png'

const { Browser } = Plugins

const InspirationalReads = () => {
  return (
    <div className="py-4">
      <GenericQueryRender
        query={QUERY_INSPIRATIONAL_READS}
        fetchPolicy="cache-and-network"
        dataAccess="blog"
        NoResults={() => null}
        Success={({ data }) => (
          <>
            <h2 className="heading-2 pb-4 text-green">Inspirational Reads</h2>
            <div className="-ml-10">
              <Swiper
                slidesPerView="auto"
                centeredSlides
                spaceBetween={15}
                className="-m-3 p-3"
              >
                {data.map(
                  (result: InspirationalReadsQuery['blog'][0], i: number) => (
                    <SwiperSlide key={`inspirational-${i}`} className="w-4/5">
                      <Card {...result} />
                    </SwiperSlide>
                  ),
                )}
              </Swiper>
            </div>
          </>
        )}
      />
    </div>
  )
}

const Card = ({
  url,
  picture,
  title,
  authorName,
  authorPicture,
  published_at,
  minsToRead,
}: InspirationalReadsQuery['blog'][0]) => {
  const loadingBlock = useLoadingBlock()

  return (
    <div
      className="bg-white shadow-md h-full"
      onClick={async () => {
        loadingBlock.open()

        await Browser.open({
          url,
          windowName: 'Inspirational Read',
          // toolbarColor?: string;
          presentationStyle: 'popover',
        })

        setTimeout(() => {
          loadingBlock.close()
        }, 1000)
      }}
    >
      <BackgroundImage image={picture || womanDarkBg} height={125} />
      <div className="p-4">
        <div
          className="heading-2 text-primary line-clamp-2"
          style={{
            minHeight: 45,
          }}
        >
          {title}
        </div>
        <div className="flex flex-row items-center mt-6">
          <BackgroundImage
            image={authorPicture || DefaultAvatar}
            width={45}
            height={45}
            className="rounded-full mr-3"
          />
          <div className="grow">
            <div className="heading-2 text-primary">{authorName}</div>
            <div className="body-2 text-gray-700">
              {moment.utc(published_at).local().calendar()}
              {minsToRead > 0 && (
                <>
                  {' - '}
                  <span className="text-gray-500">{minsToRead}min read</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InspirationalReads
