import { useLocation } from '@reach/router'
import { match } from '@reach/router/lib/utils'
import Redirect from 'components/Redirect'

type Props = {
  from: string
  to: string
}

const RedirectPage = ({ from, to }: Props) => {
  const { pathname } = useLocation()
  const m = match(from, pathname)

  if (!m) {
    return null
  }

  const toWithParams = Object.keys(m?.params || {}).reduce(
    (value, key) => value.replace(`:${key}`, m.params[key]),
    to,
  )

  return <Redirect to={toWithParams} />
}

export default RedirectPage
