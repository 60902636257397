import React from 'react'
import cc from 'classcat'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { LEFT, RIGHT, useSwipeable } from 'react-swipeable'
import Header from './Header'
import Context, { useFormCarousel as $useFormCarousel } from './context'
import stylesAnimationNext from './animationNext.module.scss'
import stylesAnimationPrev from './animationPrev.module.scss'
import stylesBackgroundImage from './animationBgImage.module.scss'
import BackgroundImage from 'components/BackgroundImage'
import useKeyboardListener from 'hooks/useKeyboardListener'

const ANIMATION_TIME_OUT = 500
const ANIMATION_BG_TIME_OUT = 900

export const useFormCarousel = $useFormCarousel

type FormCarouselSlideProps = {
  children: React.ReactElement
  canSkip?: boolean
  formName?: string
  bgImage?: string
  bgFaded?: boolean
  bgClass?: string
  bgFadedOnKeyboard?: boolean
}

// NOTE: Used to get data from it from root
export const FormCarouselSlide = ({ children }: FormCarouselSlideProps) =>
  children

FormCarouselSlide.defaultProps = {
  bgFaded: true,
}

type FormValues = Record<number, Record<string, any>>

type FormCarouselProps = {
  children: React.ReactElement[]
  initialValues?: FormValues
  onSubmit: (values: any) => void
  onCancel?: () => void
  $cancelType: 'back' | 'close'
  $headerBg?: string
  CustomHeader?: React.FC<CustomHeaderProps>
  swipeable?: boolean
  $className?: string
}

export type CustomHeaderProps = {
  current: number
  canSkip?: boolean
  next: (slideFormValues?: Record<string, any>) => void
  previous: () => void
}

const FormCarousel = ({
  children,
  onSubmit,
  onCancel,
  $cancelType,
  $headerBg,
  initialValues,
  CustomHeader,
  swipeable,
  $className,
}: FormCarouselProps) => {
  const [current, setCurrent] = React.useState(0)
  const [direction, setDirection] = React.useState<'left' | 'right'>('left')
  const [formValues, setFormValues] = React.useState<FormValues>(
    initialValues || {},
  )
  const [transitionInProgress, setTransitionInProgress] = React.useState(false)
  const isKeyboardOnScreen = useKeyboardListener()

  // TODO: Investiga if we can validate it with TS
  // NOTE: Does NOT work with prod build
  // children.forEach((child) => {
  //   if (typeof child.type === 'function') {
  //     if (child.type.name !== 'FormCarouselSlide') {
  //       throw new Error(
  //         `Only FormCarouselSlide component can be use as child of FormCarousel`,
  //       )
  //     }
  //   } else {
  //     throw new Error(
  //       `Only FormCarouselSlide component can be use as child of FormCarousel`,
  //     )
  //   }
  // })

  const slide = children[current]
  const total = children.length
  const nextSlide = children[current + 1]

  React.useEffect(() => {
    if (nextSlide && nextSlide.props.bgImage) {
      const img = new Image()

      img.src = nextSlide.props.bgImage
    }
  }, [current])

  const previous = () => {
    if (!transitionInProgress && current > 0) {
      setDirection('right')
      setTransitionInProgress(true)
      setTimeout(() => {
        setCurrent((current) => current - 1)
      })
      setTimeout(() => {
        setTransitionInProgress(false)
      }, ANIMATION_TIME_OUT)
    }
  }

  const next = (slideFormValues?: Record<string, any>) => {
    if (!transitionInProgress) {
      const { formName } = slide.props
      const newFormValues = slideFormValues
        ? {
            ...formValues,
            [formName || current]: slideFormValues,
          }
        : {
            ...formValues,
          }

      if (current === children.length - 1) {
        onSubmit(
          formName
            ? newFormValues
            : Object.values(newFormValues).reduce(
                (acc, obj) => ({ ...acc, ...obj }),
                {},
              ),
        )
      } else {
        setDirection('left')
        setTransitionInProgress(true)
        setTimeout(() => {
          setCurrent((current) => current + 1)
        })
        setTimeout(() => {
          setTransitionInProgress(false)
        }, ANIMATION_TIME_OUT)
      }

      setFormValues(newFormValues)
    }
  }

  const swipeableHandlers = useSwipeable({
    onSwiped: (eventData) => {
      switch (eventData.dir) {
        case LEFT: {
          next()
          break
        }
        case RIGHT: {
          previous()
          break
        }
      }
    },
  })

  return (
    <Context.Provider
      value={{
        current,
        total,
        previous,
        next,
        initialValues: formValues[slide.props.formName || current],
        isKeyboardOnScreen,
      }}
    >
      <div
        className={cc([
          'relative flex flex-col h-full bg-center bg-cover safe-area-pb',
          $className,
        ])}
        {...(swipeable ? swipeableHandlers : {})}
      >
        <TransitionGroup>
          <CSSTransition
            key={current}
            classNames={stylesBackgroundImage}
            timeout={ANIMATION_BG_TIME_OUT}
            appear
          >
            {slide.props.bgImage ? (
              <BackgroundImage
                image={slide.props.bgImage}
                className={cc([
                  'absolute inset-0',
                  !(slide.props.bgFadedOnKeyboard && isKeyboardOnScreen) &&
                    slide.props.bgFaded &&
                    'opacity-80',
                  slide.props.bgFadedOnKeyboard &&
                    isKeyboardOnScreen &&
                    'opacity-30',
                  slide.props.bgClass,
                ])}
                loadHandler={false}
              />
            ) : (
              <div />
            )}
          </CSSTransition>
        </TransitionGroup>

        {CustomHeader ? (
          <CustomHeader
            canSkip={slide.props.canSkip}
            next={next}
            previous={previous}
            current={current}
          />
        ) : (
          <Header
            canSkip={slide.props.canSkip}
            onCancel={onCancel}
            $cancelType={$cancelType}
            $rootClassName={['safe-area-pt', $headerBg]
              .filter(Boolean)
              .join(' ')}
          />
        )}

        <TransitionGroup className="flex-grow relative overflow-hidden">
          <CSSTransition
            key={current}
            classNames={
              direction === 'left' ? stylesAnimationNext : stylesAnimationPrev
            }
            timeout={ANIMATION_TIME_OUT}
            appear
          >
            <div className="absolute inset-0 overflow-auto">{slide}</div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </Context.Provider>
  )
}

FormCarousel.defaultProps = {
  $cancelType: 'back',
}

export default FormCarousel
