import React from 'react'
import { capitalize, mapValues, join } from 'lodash/fp'
import Button from 'components/Button'
import FormLogin from 'forms/FormLogin'
import { useAuth } from 'stores/auth.store'
import { navigate } from 'clients/navigation.client'
import FormSimplePersonSignup from 'forms/FormSimplePersonSignup'
import { useMutation } from '@apollo/client'
import { CREATE_STUDENT_ACCOUNT } from 'graphql/mutations'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'
import {
  useGlobalProvider,
  _INTERNAL_getStoredPreferences,
} from 'stores/global.store'
import { onlyNumbers } from 'utils/regex'
import { getDeviceTimezone } from 'utils/timezone'

/*
<SpaceForKeyboard /> not needed, used in FormSimplePersonSignup
*/

type Props = {
  inlineSignup?: boolean
}

const InlineSignup = ({ switchToLogin }: { switchToLogin: () => void }) => {
  const { log, logUnhandledCase } = useLogger()
  const { login } = useAuth()
  const toast = useToast()
  const { preferences } = useGlobalProvider()
  const [createStudent, state] = useMutation(CREATE_STUDENT_ACCOUNT, {
    onCompleted: (data) => {
      const { token, expiresAt, account } = data?.signupStudent || {}

      if (!token || !expiresAt || !account) {
        logUnhandledCase('Account Has Been Created :: Some data is missing', {
          token,
          expiresAt,
          account,
        })

        toast.notify({
          type: 'info',
          message:
            'The account has been create but we could not log you in. Please login manually.',
        })

        switchToLogin()
      } else {
        login({
          token,
          expiresAt,
          account,
        })
      }
    },
    onError: (error) => {
      log('error', 'Student Signup', { error })
      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
    },
  })

  return (
    <FormSimplePersonSignup
      onSubmit={async (values) => {
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          birthday,
          picture,
          termsAndConditions,
        } = values

        try {
          const result = await createStudent({
            variables: {
              profile: {
                firstName: capitalize(firstName),
                lastName: capitalize(lastName),
                email: email.toLowerCase(),
                phoneNumber: onlyNumbers(phoneNumber),
                picture,
                birthday,
              },
              // NOTE: we only 'setPreferences' if the user decided to create the account later
              preferences: mapValues(
                join(','),
                preferences ||
                  _INTERNAL_getStoredPreferences() || {
                    yogaTypes: [],
                    settings: [],
                    levels: [],
                  },
              ),
              termsAndConditions,
              timezone: getDeviceTimezone(),
            },
          })

          if (result.errors) {
            toast.notify({
              type: 'failure',
              message: result.errors[0].message,
            })
            log('error', 'Student Inline Signup', {
              errors: result.errors,
              values,
            })
          }
        } catch (error) {
          log('error', 'Student Inline Signup', { error, values })
          toast.notify({
            type: 'failure',
            message: GENERIC_ERROR_MESSAGE,
          })
        }
      }}
      state={state}
    />
  )
}

const NotLoggedIn = ({ inlineSignup }: Props) => {
  const { isAuthenticated } = useAuth()
  const [view, setView] = React.useState<'default' | 'login' | 'signup'>(
    'default',
  )

  if (isAuthenticated()) {
    return (
      <div>
        Cannot render this Component because the account is already logged in
      </div>
    )
  }

  if (view === 'login') {
    return (
      <FormLogin
        onLoginCancel={() => {
          setView('default')
        }}
      />
    )
  }

  if (inlineSignup && view === 'signup') {
    return (
      <InlineSignup
        switchToLogin={() => {
          setView('login')
        }}
      />
    )
  }

  return (
    <>
      {/* LOGIN */}
      <div className="text-center py-5">
        <p className="heading-3 text-primary pb-2">Already a member?</p>
        <Button
          onClick={() => {
            setView('login')
          }}
          $fluid
          data-testid="button-login"
        >
          Login
        </Button>
      </div>

      {/* SIGNUP */}
      <div className="text-center py-5">
        <p className="heading-3 text-primary pb-2">Not registered yet?</p>
        <Button
          onClick={() => {
            if (inlineSignup) {
              setView('signup')
            } else {
              navigate('/signup')
            }
          }}
          $fluid
          data-testid="button-sign-up"
        >
          Sign up
        </Button>
      </div>
    </>
  )
}

export default NotLoggedIn
