type Value = string | number | boolean

type Params = Record<string, Value | Value[]>

const buildQueryParams = (params: Params) =>
  Object.keys(params)
    .map((key) => [key, params[key as keyof Params]])
    .filter(([, value]) => value != null)
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((v) => [key, v].join('=')).join('&')
        : [key, value].join('='),
    )
    .join('&')

export default buildQueryParams
