import cc from 'classcat'

type Props = {
  topBottomSpace?: boolean
  xSpace?: boolean
  ySpace?: boolean
  topSpace?: boolean
  rightSpace?: boolean
  bottomSpace?: boolean
  leftSpace?: boolean
  className?: string
  [key: string]: any
  ['data-testid']?: string
  $size?: 'wide' | 'compact' | 'wider'
}

const Container = ({
  className,
  topBottomSpace,
  xSpace,
  ySpace,
  topSpace,
  rightSpace,
  bottomSpace,
  leftSpace,
  ['data-testid']: dataTestID,
  $size,
  ...props
}: Props) => {
  return (
    <div
      data-testid={dataTestID}
      className={cc([
        // 'w-screen',
        'w-full mx-auto',
        {
          'py-8': topBottomSpace || ySpace,
          'px-5': xSpace,
          'pt-5': topSpace,
          'pb-5': bottomSpace,
          'pl-8': leftSpace,
          'pr-8': rightSpace,
          'max-w-2xl': $size === 'wide',
          'max-w-lg': $size === 'compact',
          'max-w-4xl': $size === 'wider',
        },
        className,
      ])}
      {...props}
    />
  )
}

Container.defaultProps = {
  xSpace: true,
  'data-testid': 'container',
  $size: 'wide',
}

export default Container
