import React from 'react'
import getPersonName from 'utils/getPersonName'
import InfoBox from 'components/InfoBox'
import Container from 'components/Container'
import GenericQueryRender from 'components/GenericQueryRender'
import CompanyLegalInformation, { Values as CompanyValues } from './Company'
import IndividualLegalInformation, {
  Values as IndividualValues,
} from './Individual'
import FormField, { DropdownOption, FormGroup } from 'components/FormField'
import { omit } from 'lodash/fp'
import { useAuth } from 'stores/auth.store'
import {
  LegalInformationDocument,
  LegalInformationQuery,
  GetBirthdayDocument,
} from 'generated/graphql'
import { addressStringify } from 'utils/address'
import { ReactComponent as IconMail } from 'assets/icons/mail.svg'
import { Entity, Person } from 'types'
import { useApolloClient } from '@apollo/client'
import { useForm } from 'react-hook-form'

/*
<SpaceForKeyboard /> not needed
*/

const LegalInformation = () => {
  return (
    <Container topBottomSpace>
      <p className="p-3 bg-white border border-dashed border-primary mb-8 text-center body-2">
        This information is required to promote classes.
        <br />
        It is 100% confidential and completely safe.
        <br />
        Data is stored in our{' '}
        <a
          className="text-primary underline"
          href="https://stripe.com/docs/security/stripe"
          target="_blank"
          rel="noreferrer"
        >
          highly secure partner, Stripe
        </a>
        .
      </p>

      <GenericQueryRender
        query={LegalInformationDocument}
        fetchPolicy="network-only"
        dataAccess="legalInformation"
        Success={LegalInformationWithData as any}
      />
    </Container>
  )
}

const LegalInformationWithData = ({
  data,
}: {
  data?: LegalInformationQuery['legalInformation']
}) => {
  const client = useApolloClient()
  const { account, isEntity } = useAuth()

  if (data && data.type) {
    return <Summary data={data} />
  }

  if (isEntity()) {
    return (
      <CompanyLegalInformation
        onAdded={(values: CompanyValues) => {
          client.writeQuery({
            query: LegalInformationDocument,
            data: {
              legalInformation: {
                ...data,
                type: 'company',
                address: values.address,
                company: omit('address', values),
              },
            },
          })
        }}
        initialValues={{
          name: (account.profile as Entity).name,
        }}
      />
    )
  } else {
    return (
      <GenericQueryRender
        query={GetBirthdayDocument}
        dataAccess="profile_person_by_pk"
        variables={{
          id: account.profile?.id,
        }}
        Success={LegalInformationWithBirthday as any}
      />
    )
  }
}

const LegalInformationWithBirthday = ({ data }: { data: any }) => {
  const client = useApolloClient()
  const { account, isEntity } = useAuth()
  const [type, setType] = React.useState<'individual' | 'company'>(
    data?.type === 'company' || isEntity() ? 'company' : 'individual',
  )
  const { control } = useForm({
    defaultValues: {
      type,
    },
  })

  const onAdded = (values: CompanyValues | IndividualValues) => {
    client.writeQuery({
      query: LegalInformationDocument,
      data: {
        legalInformation: {
          ...data,
          type,
          address: values.address,
          individual: type === 'individual' ? omit('address', values) : null,
          company: type === 'company' ? omit('address', values) : null,
        },
      },
    })
  }

  return (
    <>
      <FormGroup>
        <FormField
          type="dropdown"
          name="type"
          value={type}
          label="Account Type"
          control={control}
          onChange={(value) => {
            setType(value as any)
          }}
          placeholder="Account Type"
        >
          <DropdownOption value="individual">Individual</DropdownOption>
          <DropdownOption value="company">Company</DropdownOption>
        </FormField>
      </FormGroup>

      {type === 'individual' ? (
        <IndividualLegalInformation
          onAdded={onAdded}
          initialValues={{
            firstName: (account.profile as Person).firstName,
            lastName: (account.profile as Person).lastName,
            birthday: data?.birthday,
          }}
        />
      ) : (
        <CompanyLegalInformation onAdded={onAdded} />
      )}
    </>
  )
}

const Summary = ({
  data,
}: {
  data: LegalInformationQuery['legalInformation']
}) => {
  console.log('--data', data)
  const { account, isEntity } = useAuth()
  const rows =
    data?.type === 'individual'
      ? [
          {
            label: 'Account Type',
            value: data?.type,
          },
          {
            label: 'First Name',
            value: data?.individual?.firstName,
          },
          {
            label: 'Last Name',
            value: data?.individual?.lastName,
          },
          {
            label: 'Date of Birth',
            value: data?.individual?.birthday,
          },
          {
            label: 'Last 4 SSN',
            value: data?.individual?.last4ssn,
          },
          {
            label: 'Address',
            value: addressStringify({
              address: data?.address?.primary || '',
              addressSecondary: data?.address?.secondary || '',
              city: data?.address?.city || '',
              zipCode: data?.address?.zipCode || '',
              state: data?.address?.state || '',
            } as any),
          },
        ]
      : [
          {
            label: 'Account Type',
            value: data?.type,
          },
          {
            label: 'Name',
            value: data?.company?.name,
          },
          {
            label: 'Tax ID',
            value: '**-*******',
          },
          {
            label: 'Address',
            value: addressStringify({
              address: data?.address?.primary || '',
              addressSecondary: data?.address?.secondary || '',
              city: data?.address?.city || '',
              zipCode: data?.address?.zipCode || '',
              state: data?.address?.state || '',
            } as any),
          },
        ]

  return (
    <>
      <ul>
        {rows.map(({ label, value }, i) => (
          <li
            key={`summary-row-${i}`}
            className="flex justify-between items-center py-3 border-b border-gray-400"
          >
            <span className="heading-3 text-primary mr-4">{label}</span>
            <span className="truncate">{value}</span>
          </li>
        ))}
      </ul>

      <div className="mt-8">
        <InfoBox Icon={IconMail}>
          Need to change the information? Please contact us{' '}
          <a
            className="text-primary underline"
            href={`mailto:hello@ohmunity.com&subject=Change Legal Information: ${
              isEntity()
                ? (account.profile as Entity).name
                : getPersonName(account.profile as Person)
            }&body=Hi Ohmunity TM,\n\nI would like to change my legal information.`}
          >
            hello@ohmunity.com
          </a>
        </InfoBox>
      </div>
    </>
  )
}

export default LegalInformation
