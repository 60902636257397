import React from 'react'
import cc from 'classcat'
import BackgroundImage from 'components/BackgroundImage'
import { ReactComponent as IconX } from 'assets/symbols/x-filled.svg'

type Props = {
  image: string
  onRemove: () => void
  disabled?: boolean
  active?: boolean
}

const Selected = ({ image, onRemove, disabled, active }: Props) => (
  <div
    className={cc([
      'relative h-full p-2',
      {
        'opacity-60': disabled,
      },
    ])}
  >
    <BackgroundImage image={image} width="100%" height="100%" />
    {active && (
      <div className="absolute top-0 right-0 bottom-0 left-0 border-4 border-primary m-2" />
    )}

    {/* NOTE: Temporary fix for the X icons. The X is transparent and we need to make it white with this hack */}
    <div
      className="absolute bg-white"
      style={{
        top: 3,
        right: 3,
        width: 24,
        height: 24,
        borderRadius: 9,
      }}
    />
    <div
      className="absolute top-0 right-0"
      onClick={() => {
        if (!disabled) {
          onRemove()
        }
      }}
    >
      <IconX width={30} className="fill-primary" />
    </div>
  </div>
)

export default Selected
