import * as yup from 'yup'
import FormField, { FormGroup } from 'components/FormField'
import BirthdayField from 'formFields/BirthdayField'
import PhoneNumberField from 'formFields/PhoneNumberField'
import Button from 'components/Button'
import { yupResolver } from '@hookform/resolvers'
import { FormProvider, useForm } from 'react-hook-form'
import { MutationResult, useApolloClient } from '@apollo/client'
import * as Validation from 'utils/formFieldValidations'
import ServerErrorMessage from 'components/ServerErrorMessage'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'

type Props = {
  onSubmit: (values: any) => void
  state: MutationResult
}

const FormSimplePersonSignup = ({ onSubmit, state }: Props) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    email: Validation.uniqueEmail(client),
    phoneNumber: Validation.uniquePhoneNumber(client),
    birthday: Validation.birthday,
    termsAndConditions: Validation.termsAndConditions,
  })
  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      birthday: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
  })
  const { control, errors, register, handleSubmit, formState } = formMethods
  const { isSubmitting } = formState

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Type */}
        <FormGroup>
          {/* TODO: Enable as soon as we support Teachers */}
          {/* <div className="heading-3">
          <div className="pb-1">I am signing up as a</div>
          <div className="flex">
            <FormField type="radio" name="type" label="Yogi" id="type-yogi" />
            <div className="pl-8">
              <FormField
                type="radio"
                name="type"
                label="Teacher"
                id="type-teacher"
              />
            </div>
          </div>
        </div> */}

          {/* First Name */}
          <FormField
            type="text"
            name="firstName"
            placeholder="First Name *"
            register={register}
            error={errors?.firstName?.message}
          />

          {/* Last Name */}
          <FormField
            type="text"
            name="lastName"
            placeholder="Last Name *"
            register={register}
            error={errors?.lastName?.message}
          />

          {/* Email */}
          <FormField
            type="text"
            name="email"
            placeholder="Email *"
            register={register}
            error={errors?.email?.message}
          />

          {/* Phone Number */}
          <PhoneNumberField name="phoneNumber" control={control} />

          {/* Birthday */}
          <BirthdayField name="birthday" />

          <TermsAndConditionsField
            name="termsAndConditions"
            control={control}
          />
        </FormGroup>

        {state.error && (
          <ServerErrorMessage>{state.error.message}</ServerErrorMessage>
        )}

        <div className="pt-4">
          <Button $fluid loading={isSubmitting || state.loading}>
            Create account and Book
          </Button>
        </div>

        <SpaceForKeyboard />
      </form>
    </FormProvider>
  )
}

export default FormSimplePersonSignup
