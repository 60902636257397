import React from 'react'
import patternMatching from 'utils/patternMatching'
import Tabs from 'components/Tabs'
import Container from 'components/Container'
import { useAlert } from 'components/AlertModal'
import { Class_Recurrence_Enum } from 'generated/graphql'
import TimeForm from './TimeForm'
import { useFormCarousel } from 'forms/FormCarousel'

const TABS = [
  {
    id: Class_Recurrence_Enum.Recurring,
    text: 'Recurring',
  },
  {
    id: Class_Recurrence_Enum.OneTime,
    text: 'One Time',
  },
]

const tabToDateTimeType = patternMatching<string, Class_Recurrence_Enum>(
  [
    ['one-time', Class_Recurrence_Enum.OneTime],
    ['recurring', Class_Recurrence_Enum.Recurring],
  ],
  Class_Recurrence_Enum.OneTime,
)

type ContentProps = {
  currentTab: Class_Recurrence_Enum
  setCurrentTab: (type: Class_Recurrence_Enum) => void
}

const DateTimeContent = ({ currentTab, setCurrentTab }: ContentProps) => {
  const alert = useAlert()
  const { initialValues } = useFormCarousel()

  return (
    <>
      <div
        className="sticky top-0 z-10"
        style={{
          // TODO: Not sure why the Tabs' position is jumping 1px, creating a gap in some scroll positions. This temp solves the problem
          top: -1,
        }}
      >
        <Tabs
          items={TABS}
          onChange={(id) => {
            const from = TABS.find((obj) => obj.id === currentTab)?.text

            alert.send({
              title: 'Are you sure?',
              body: (
                <>
                  A class can either be {TABS.map((x) => x.text).join(' or ')}.
                  <br />
                  If you switch now, you will loose the data entered in {from}.
                  {initialValues ? (
                    <p className="pt-4 text-red-500">
                      This action will cancel all bookings related to this class
                      and date and time. Instead, we recommend to edit the date
                      and time.
                    </p>
                  ) : null}
                </>
              ),
              buttons: [
                {
                  $type: 'primary',
                  children: 'Yes, continue',
                  onClick: () => {
                    setCurrentTab(tabToDateTimeType(id))
                  },
                },
                {
                  $type: 'tertiary',
                  children: 'Cancel',
                },
              ],
            })
          }}
          activeTab={currentTab}
        />
      </div>

      <Container topBottomSpace>
        <TimeForm type={currentTab} />
      </Container>
    </>
  )
}

const DateAndTime = () => {
  const { initialValues } = useFormCarousel()
  const [currentTab, setCurrentTab] = React.useState(
    initialValues?.type
      ? TABS.find(({ id }) => id === initialValues.type)?.id || TABS[0].id
      : TABS[0].id,
  )

  return (
    <DateTimeContent currentTab={currentTab} setCurrentTab={setCurrentTab} />
  )
}

export default DateAndTime
