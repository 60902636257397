import React from 'react'
import Modal from 'components/Modal'
import Page, { PageHeaderProps } from 'components/Page'
import { ReactComponent as IconClose } from 'assets/symbols/x.svg'

// TODO: This modal acts as Global modal as well, it should be break down into two different utilities

export type ModalScreenClose = () => void

export const makeContext = () =>
  React.createContext<{
    isOpen: boolean
    toggle: () => void
    setIsOpen: (state: boolean) => void
    open: () => void
    setHeader: () => void
    setBody: () => void
  }>({
    isOpen: false,
    toggle: () => undefined,
    setIsOpen: () => undefined,
    open: () => undefined,
    setHeader: () => undefined,
    setBody: () => undefined,
  })

const ModalScreenContext = makeContext()

export const useModalScreen = (
  context = ModalScreenContext,
): {
  isOpen: boolean
  open: ({
    header,
    body,
  }: {
    header?: string | PageHeaderProps
    body: any
  }) => void
  setHeader: (header: string | PageHeaderProps) => void
  setBody: (body: any) => void
  close: ModalScreenClose
} => {
  const { isOpen, open, setHeader, setBody, setIsOpen } = React.useContext(
    context,
  )

  return {
    isOpen,
    open,
    setHeader,
    setBody,
    close: () => {
      setIsOpen(false)
    },
  }
}

export const CloseButton = ({ close }: { close: ModalScreenClose }) => (
  <button
    className="block p-2"
    onClick={close}
    data-testid="modal-screen-close"
  >
    <IconClose width={18} />
  </button>
)

export const simpleHeader = (title: string, close: ModalScreenClose) => ({
  children: title,
  before: <CloseButton close={close} />,
})

export const ModalScreenProvider = ({
  children,
  context,
}: {
  children: any
  context?: any
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [header, setHeader] = React.useState<PageHeaderProps | null>(null)
  const [body, setBody] = React.useState('N/A')

  const toggle = () => {
    setIsOpen((state) => !state)
  }
  const open = ({
    header,
    body,
  }: {
    header?: string | PageHeaderProps
    body: any
  }) => {
    if (typeof header === 'string') {
      setHeader(simpleHeader(header, toggle))
    } else {
      setHeader(header || null)
    }

    setBody(body)
    setIsOpen(true)
  }

  const Context = context || ModalScreenContext

  return (
    <Context.Provider
      value={{
        isOpen,
        setIsOpen,
        toggle,
        open,
        setHeader: (header: PageHeaderProps) => {
          setHeader(header)
        },
        setBody: (body: any) => {
          setBody(body)
        },
      }}
    >
      {children}
      {typeof body === 'function' ? (
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          LazyChildren={() => (
            <Page>
              {header && <Page.Header {...header} />}
              <Page.Content>{body}</Page.Content>
            </Page>
          )}
        />
      ) : (
        <Modal isOpen={isOpen} toggle={toggle}>
          <Page>
            {header && <Page.Header {...header} />}
            <Page.Content>{body}</Page.Content>
          </Page>
        </Modal>
      )}
    </Context.Provider>
  )
}

export function withModalScreen<T = unknown>(Component: React.FC<T>) {
  return (props: T) => (
    <ModalScreenProvider>
      <Component {...props} />
    </ModalScreenProvider>
  )
}
