import React from 'react'
import useLogger from 'hooks/useLogger'
import patternMatching from 'utils/patternMatching'
import CallToAction, { CTA } from 'components/CallToAction'
import { RouteComponentProps } from '@reach/router'
import { useAuth } from 'stores/auth.store'
import { isEntity } from 'utils/account'
import { ReactComponent as LogoSymbol } from 'assets/logo-symbol-flat.svg'
import { useGlobalProvider } from 'stores/global.store'
import { Account_Role_Enum } from 'generated/graphql'

const AccountCreated = ({ navigate, location }: RouteComponentProps) => {
  const { login } = useAuth()
  const { logUnhandledCase } = useLogger()
  const { refreshPreferences } = useGlobalProvider()
  const { result, forceRefreshPreferences } = location?.state || ({} as any)
  const { token, expiresAt, account } = result || {}

  if (!token || !expiresAt || !account) {
    logUnhandledCase('Account Has Been Created :: Some data is missing', {
      token,
      expiresAt,
      account,
    })

    navigate?.('/')

    return null
  }

  const onClick = (to: string) => () => {
    login({
      token,
      expiresAt,
      account,
    })

    if (forceRefreshPreferences) {
      refreshPreferences()
    }

    // The setTimeout is needed so we wait until 'logic' takes effect
    // (sets the token and refreshes the client, so the user is considered logged in)
    setTimeout(() => {
      navigate?.(to, {
        state: {
          openCreateClass: true,
        },
      })
    })
  }

  return (
    <div className="z-9 fixed top-0 right-0 bottom-0 left-0">
      <CallToAction
        $color="green"
        Icon={LogoSymbol}
        title={`Welcome ${
          isEntity(account.type)
            ? account.profile.name
            : account.profile.firstName
        }`}
        description={patternMatching<Account_Role_Enum, string>(
          [
            [
              Account_Role_Enum.Student,
              'Now you can begin searching for classes!',
            ],
            [
              Account_Role_Enum.Teacher,
              'Now you can begin your Ohmunity™ journey',
            ],
            [Account_Role_Enum.Studio, ''],
          ],
          '',
        )(account.type)}
        ctas={patternMatching<Account_Role_Enum, CTA[]>(
          [
            [
              Account_Role_Enum.Student,
              [
                {
                  $type: 'primary',
                  text: <>Explore Ohmunity™</>,
                  onClick: onClick('/'),
                  dataTestId: 'button-explore-ohmunity',
                },
              ],
            ],
            [
              Account_Role_Enum.Teacher,
              [
                {
                  $type: 'primary',
                  text: <>Get Started</>,
                  onClick: onClick('/'),
                  dataTestId: 'button-get-started',
                },
                // {
                //   $type: 'secondary',
                //   text: 'Create your first Class',
                //   onClick: onClick('/myClasses'),
                // },
              ],
            ],
            [
              Account_Role_Enum.Studio,
              [
                {
                  $type: 'primary',
                  text: <>Explore Ohmunity™</>,
                  onClick: onClick('/'),
                  dataTestId: 'button-explore-ohmunity',
                },
              ],
            ],
          ],
          [],
        )(account.type)}
      />
    </div>
  )
}

export default AccountCreated
