import { createClient, Provider, dedupExchange, fetchExchange } from 'urql'
import { useGlobalProvider } from 'stores/global.store'
import { cacheExchange } from '@urql/exchange-graphcache'
import { AccountStatusDocument } from 'generated/graphql'
import { GRAPHQL_HTTP_URL, INACTIVE_ACCOUNT_KEY } from 'globalConstants'

const GraphqlClientProvider = ({ children }: any) => {
  const { auth, account } = useGlobalProvider()

  const cache = cacheExchange({
    updates: {
      Mutation: {
        createIndividualAccount: (_result, _args, cache) => {
          // When the Individual Legal Infomation has been completed, we mark "legal information" missing code as completed.
          cache.updateQuery(
            {
              query: AccountStatusDocument,
            },
            (data) => {
              if (
                data &&
                data.accountStatus &&
                Array.isArray(data.accountStatus)
              ) {
                data.accountStatus = data.accountStatus.map((status: any) =>
                  status.id === INACTIVE_ACCOUNT_KEY.legalInformation
                    ? {
                        ...status,
                        completed: true,
                      }
                    : status,
                )
              }

              return data
            },
          )
        },
        createCompanyAccount: (_result, _args, cache) => {
          // When the Individual Legal Infomation has been completed, we mark "legal information" missing code as completed.
          cache.updateQuery(
            {
              query: AccountStatusDocument,
            },
            (data) => {
              if (
                data &&
                data.accountStatus &&
                Array.isArray(data.accountStatus)
              ) {
                data.accountStatus = data.accountStatus.map((status: any) =>
                  status.id === INACTIVE_ACCOUNT_KEY.legalInformation
                    ? {
                        ...status,
                        completed: true,
                      }
                    : status,
                )
              }

              return data
            },
          )
        },
      },
    },
  })

  const client = createClient({
    url: GRAPHQL_HTTP_URL,
    fetchOptions: () => {
      if (auth && account) {
        return {
          headers: {
            authorization: `Bearer ${auth?.token}`,
          },
        }
      }

      return {}
    },
    exchanges: [dedupExchange, cache, fetchExchange],
  })

  // TODO: Handle errors + jwt expiration

  return <Provider value={client}>{children}</Provider>
}

export default GraphqlClientProvider
