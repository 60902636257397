import React from 'react'
import useLogger from './useLogger'
import useIsMounted from './useIsMounted'
import { useApolloClient } from '@apollo/client'
import { useAuth } from 'stores/auth.store'
import { gql } from 'graphql.macro'
import { OneSignal } from '@ionic-native/onesignal'
import isBrowser from 'utils/isBrowser'
import { captureMessage, Severity } from '@sentry/react'

// const { PushNotifications } = Plugins

const getToken = async (): Promise<string | null> => {
  const response = await OneSignal.getPermissionSubscriptionState()

  return response.subscriptionStatus.pushToken || null
}

const usePushNotification = () => {
  const client = useApolloClient()
  const mounted = useIsMounted()
  const { account, updateAccount } = useAuth()
  const { log } = useLogger()

  // Handles One Signal init & listeners
  React.useEffect(() => {
    if (!isBrowser() && process.env.REACT_APP_ONESIGNAL) {
      OneSignal.setLogLevel({
        logLevel: 6,
        visualLevel: 0,
      })

      OneSignal.startInit(process.env.REACT_APP_ONESIGNAL)

      OneSignal.iOSSettings({
        kOSSettingsKeyAutoPrompt: true,
        kOSSettingsKeyInAppLaunchURL: false,
      })
      OneSignal.enableSound(true)
      OneSignal.enableVibrate(true)

      OneSignal.handleNotificationOpened().subscribe(
        (notification) => {
          captureMessage('Push Notification Opened', {
            level: Severity.Debug,
            tags: {
              functionality: 'push-notification',
            },
            extra: {
              notification: JSON.stringify(notification),
              account,
            },
          })
        },
        (error) => {
          log('error', 'Push Notification Opened', {
            error,
          })
        },
      )

      OneSignal.handleNotificationReceived().subscribe(
        (notification) => {
          captureMessage('Push Notification Received', {
            level: Severity.Debug,
            tags: {
              functionality: 'push-notification',
            },
            extra: {
              notification: JSON.stringify(notification),
              account,
            },
          })
        },
        (error) => {
          log('error', 'Push Notification Received', {
            error,
          })
        },
      )

      OneSignal.handleInAppMessageClicked().subscribe(
        (notification) => {
          captureMessage('Push Notification Clicked', {
            level: Severity.Debug,
            tags: {
              functionality: 'push-notification',
            },
            extra: {
              notification: JSON.stringify(notification),
              account,
            },
          })
        },
        (error) => {
          log('error', 'Push Notification Clicked', {
            error,
          })
        },
      )

      OneSignal.registerForPushNotifications()
      OneSignal.endInit()
    }
  }, [])

  const updateToken = async (token: string): Promise<{ success: boolean }> => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation UpdateAccountPushNotificationsToken(
            $accountID: uuid!
            $token: String!
          ) {
            update_account_by_pk(
              pk_columns: { id: $accountID }
              _set: { push_token: $token }
            ) {
              id
            }
          }
        `,
        variables: {
          accountID: account.id,
          token,
        },
      })

      if (response.errors) {
        log('error', 'Update Push Notification Token', {
          errors: response.errors,
        })

        return { success: false }
      }

      return { success: true }
    } catch (error) {
      log('error', 'Update Push Notification Token', {
        error,
      })

      return { success: false }
    }
  }

  React.useEffect(() => {
    Promise.resolve()
      .then(async () => {
        if (!isBrowser() && account.type !== 'anonymous') {
          const token = await getToken()

          OneSignal.setExternalUserId(account.id)

          if (token && token !== account.push_token) {
            const { success } = await updateToken(token)

            if (success) {
              captureMessage('Push Notification Updated Account', {
                level: Severity.Debug,
                tags: {
                  functionality: 'push-notification',
                },
                extra: {
                  account: {
                    ...account,
                    push_token: token,
                  },
                },
              })
            }

            if (success && mounted.current) {
              updateAccount({
                ...account,
                push_token: token,
              })
            }
          }
        }
      })
      .catch((error) => {
        if (error) {
          log('error', 'Push Notification Token', {
            error,
          })
        }
      })
  }, [account.type])
}

export default usePushNotification
