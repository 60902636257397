import { useState, useEffect } from 'react'
import ms from 'ms'
import cc from 'classnames'
import moment from 'moment'
import Container from 'components/Container'
import GenericQueryRender from 'components/GenericQueryRender'
import Badge from 'components/Badge'
import Tabs from 'components/Tabs'
import ClassDetailsScreen from 'screens/ClassDetails'
import Button from 'components/Button'
import NothingYet from 'components/NothingYet'
import { QUERY_MY_BOOKED_CLASSES } from 'graphql/queries'
import { MyBookedClassesQuery } from 'generated/graphql'
import { useModalScreen } from 'components/ModalScreen'
import {
  friendlyLocalDate,
  fromUntil,
  timestampToLocalDate,
  timestampToLocalTime,
} from 'utils/date'
import { useAuth } from 'stores/auth.store'
import trackClassTime from 'utils/trackClassTime'

const TABS = [
  {
    id: 'current',
    text: 'Current',
  },
  {
    id: 'past',
    text: 'Past classes',
  },
  {
    id: 'cancelled',
    text: 'Cancelled',
  },
]

const StudentClasses = () => {
  const modal = useModalScreen()
  const { account } = useAuth()
  const [tab, setTab] = useState<string>(TABS[0].id)

  if (process.env.REACT_APP_STAGE === 'alpha-1') {
    return (
      <Container topBottomSpace className="flex flex-col h-full justify-center">
        <h1 className="heading-1 text-primary">Coming Soon</h1>

        <p className="pt-6 pb-8">
          Soon you will be able to find and book classes here in the app!
        </p>
      </Container>
    )
  }

  return (
    <div className="flex flex-col h-full safe-area-pt">
      <Tabs
        items={TABS}
        onChange={(newTab) => {
          setTab(newTab)
        }}
        activeTab={tab}
        $size="md"
      />

      <GenericQueryRender
        fetchPolicy="cache-and-network"
        query={QUERY_MY_BOOKED_CLASSES}
        variables={{
          where: {
            buyer_account_id: {
              _eq: account.id,
            },
            ...(tab === 'cancelled'
              ? {
                  class_event_id: {
                    _is_null: true,
                  },
                }
              : {
                  class_event: {
                    end_timestamp: {
                      [tab === 'current'
                        ? '_gt'
                        : '_lte']: moment.utc().toISOString(),
                    },
                  },
                }),
          },
        }}
        dataAccess="class_book"
        NoResults={() => (
          <div className="h-full">
            <NothingYet
              title="The best beginning is today."
              description={
                <>
                  Find and book a class you like to begin your
                  <br />
                  Ohmunity™ yoga journey.
                </>
              }
            />
          </div>
        )}
        Success={({ data }) => {
          return (
            <Container topBottomSpace className="h-full">
              <ul>
                {(data as MyBookedClassesQuery['class_book']).map(
                  (bookedClass, index) => (
                    <li key={`booked-class-${index}`} className="py-4">
                      <Card tab={tab} {...bookedClass} />
                      {tab === 'past' && (
                        <Button
                          $fluid
                          onClick={() => {
                            modal.open({
                              header: 'Class Details',
                              body: (
                                <ClassDetailsScreen
                                  classId={bookedClass.class_id}
                                  dateTimeID={bookedClass.class_date_time_id}
                                />
                              ),
                            })
                          }}
                        >
                          Book again
                        </Button>
                      )}
                    </li>
                  ),
                )}
              </ul>
            </Container>
          )
        }}
      />
    </div>
  )
}

export default StudentClasses

type CardProps = {
  tab: string
} & MyBookedClassesQuery['class_book'][0]

const Card = ({
  tab,
  class_id,
  class_date_time_id,
  recorded_data,
}: CardProps) => {
  const modal = useModalScreen()
  const {
    start_timestamp,
    end_timestamp,
    className,
    teacherName,
    setting,
    online,
  } = recorded_data
  const date = timestampToLocalDate(start_timestamp)
  const start = timestampToLocalTime(start_timestamp)
  const end = timestampToLocalTime(end_timestamp)
  const [trackedClass, setTrackedClass] = useState(
    tab === 'current' ? trackClassTime(start_timestamp, end_timestamp) : null,
  )

  useEffect(() => {
    const timer = setInterval(() => {
      if (tab === 'current') {
        setTrackedClass(trackClassTime(start_timestamp, end_timestamp))
      }
    }, ms('15s'))

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div className="relative">
      <div
        className={cc([
          'relative grid gap-3 grid-cols-2 h-32 shadow-lg bg-white p-3 content-between',
          {
            'border-2 border-primary': trackedClass,
            'active:bg-gray-100': tab === 'current',
          },
        ])}
        onClick={() => {
          if (tab === 'current') {
            modal.open({
              header: 'Class Details',
              body: (
                <ClassDetailsScreen
                  classId={class_id}
                  dateTimeID={class_date_time_id}
                />
              ),
            })
          }
        }}
      >
        {/* Date & Time */}
        <div className="body-2 text-gray-700">
          {friendlyLocalDate(date)}
          <br />
          {fromUntil(start, end).join(' - ')}
        </div>

        {/* Virtual flag */}
        <div className="text-right">{online && <Badge>Virtual</Badge>}</div>

        {/* Class Info */}
        <div>
          {className && (
            <div className="heading-3 text-primary">{className}</div>
          )}
          {teacherName && (
            <div className="body-2 text-gray-700">w/ {teacherName}</div>
          )}
        </div>

        {/* Setting */}
        {setting && (
          <div className="flex justify-end items-end heading-3 text-primary body-2">
            {setting || ''}
          </div>
        )}
      </div>

      {trackedClass && (
        <div className="animate-pulse absolute top-full bg-primary text-white p-1 w-full body-3 text-center">
          {trackedClass}
        </div>
      )}
    </div>
  )
}
