import React from 'react'
import Button from 'components/Button'
import FormField from 'components/FormField'
import ServerErrorMessage from 'components/ServerErrorMessage'
import VerificationCode from 'components/VerificationCode'
import { useAuth } from 'stores/auth.store'
import { useForm } from 'react-hook-form'
import { useToast } from 'components/ToastMessage'
import stateUnion, { MergedStates } from 'utils/stateUnion'
import {
  Account_Role_Enum,
  useLoginMutation,
  useLoginVerifyMutation,
} from 'generated/graphql'
import { onlyNumbers } from 'utils/regex'

const Login = ({
  onSubmit,
  onCancel,
  state,
  $hasLargeFields,
}: {
  onSubmit: (values: any) => void
  onCancel: () => void
  state: MergedStates
  $hasLargeFields?: boolean
}) => {
  const { handleSubmit, register } = useForm({
    defaultValues: {
      emailOrPhoneNumber: '',
    },
  })

  return (
    <form
      onSubmit={handleSubmit(({ emailOrPhoneNumber }) =>
        onSubmit(emailOrPhoneNumber),
      )}
    >
      {state.errors.length > 0 && (
        <ServerErrorMessage>{state.errors[0].message}</ServerErrorMessage>
      )}
      <div className="py-4">
        <FormField
          type="text"
          name="emailOrPhoneNumber"
          placeholder={$hasLargeFields ? 'Email or Phone Number' : undefined}
          label={!$hasLargeFields ? 'Email or Phone Number' : undefined}
          required
          isBig={$hasLargeFields}
          register={register}
          data-testid="input-email-or-phone"
        />
      </div>
      <Button
        type="submit"
        $fluid
        loading={state.loading}
        data-testid="button-login"
      >
        Login
      </Button>
      <Button
        type="button"
        $fluid
        onClick={() => onCancel()}
        $type="tertiary"
        disabled={state.loading}
        data-testid="button-cancel"
      >
        Cancel
      </Button>
    </form>
  )
}

type Props = {
  onLoginCancel: () => void
  $hasLargeFields?: boolean
  VerifyWrapper: React.FC
  LoginWrapper: React.FC
}

const FormLogin = ({
  onLoginCancel,
  $hasLargeFields,
  VerifyWrapper,
  LoginWrapper,
}: Props) => {
  const { isAuthenticated } = useAuth()
  const [screen, setScreen] = React.useState<'login' | 'verify'>('login')
  const [sentTo, setSentTo] = React.useState<string>('')
  const [expiresIn, setExpiresIn] = React.useState<number>(1000 * 10)
  const [mutateLogin, loginState] = useLoginMutation()
  const [mutateVerify, verifyState] = useLoginVerifyMutation()
  const { login } = useAuth()
  const toast = useToast()
  const state = stateUnion([loginState, verifyState])

  if (isAuthenticated()) {
    return (
      <div>
        Cannot render this Component because the account is already logged in
      </div>
    )
  }

  const sendVerificationCode = (
    onSuccess: (to: string, expiresIn: number) => void,
  ) => (emailOrPhoneNumber: string) => {
    const isEmail = emailOrPhoneNumber.includes('@')
    const parsedEmailOrPhoneNumber = isEmail
      ? emailOrPhoneNumber.trim().toLowerCase()
      : onlyNumbers(emailOrPhoneNumber)

    mutateLogin({
      variables: isEmail
        ? {
            email: parsedEmailOrPhoneNumber,
          }
        : {
            phoneNumber: parsedEmailOrPhoneNumber,
          },
    })
      .then((response) => {
        onSuccess(parsedEmailOrPhoneNumber, response.data?.login!.expiresIn!)
      })
      .catch((err) => {
        console.error('error', err)
      })
  }

  if (screen === 'verify') {
    return (
      <VerifyWrapper>
        <VerificationCode
          sentTo={sentTo}
          expiresIn={expiresIn}
          onExpired={() => {
            setScreen('login')
          }}
          onSubmit={(code) => {
            mutateVerify({
              variables: {
                code,
              },
            })
              .then((response: any) => {
                const { account, token, expiresAt } = response.data.loginVerify

                if (
                  [
                    Account_Role_Enum.Student,
                    Account_Role_Enum.Teacher,
                    Account_Role_Enum.Studio,
                  ].includes(account.type)
                ) {
                  login({
                    account,
                    token,
                    expiresAt,
                  })
                } else {
                  toast.notify({
                    type: 'failure',
                    message: 'This account is not allowed.',
                  })
                }
              })
              .catch((err) => {
                console.error('error', err)
              })
          }}
          onResend={() => {
            sendVerificationCode((to) => {
              toast.notify({
                type: 'success',
                message: `The new code has been sent to "${to}"`,
              })
            })(sentTo)
          }}
          $hasLargeFields={$hasLargeFields}
          state={state}
        />
      </VerifyWrapper>
    )
  } else {
    return (
      <LoginWrapper>
        <Login
          onSubmit={sendVerificationCode((to, expiresIn) => {
            setSentTo(to)
            setExpiresIn(Date.now() + expiresIn)
            setScreen('verify')
          })}
          onCancel={onLoginCancel}
          $hasLargeFields={$hasLargeFields}
          state={state}
        />
      </LoginWrapper>
    )
  }
}

FormLogin.defaultProps = {
  VerifyWrapper: React.Fragment,
  LoginWrapper: React.Fragment,
}

export default FormLogin
