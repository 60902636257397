import { NavigateOptions } from '@reach/router'
import { navigate } from 'clients/navigation.client'
import { useAuth } from 'stores/auth.store'
import { useGlobalProvider } from 'stores/global.store'
import { isWebapp } from 'utils/env'

const useNavigation = () => {
  const { preferences } = useGlobalProvider()
  const { isAuthenticated } = useAuth()

  return {
    signupNavigate: (path: string, opts?: NavigateOptions<{}>) => {
      if (isWebapp() || preferences || isAuthenticated()) {
        navigate(`/signup${path}`, opts)
      } else {
        navigate(path, opts)
      }
    },
  }
}

export default useNavigation
