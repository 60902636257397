import moment from 'moment'
import cc from 'classcat'
import useLogger from 'hooks/useLogger'
import getPersonName from 'utils/getPersonName'
import Button from 'components/Button'
import Container from 'components/Container'
import FileUploader from 'components/FileUploader'
import ServerErrorMessage from 'components/ServerErrorMessage'
import GenericQueryRender from 'components/GenericQueryRender'
import { ReactComponent as IconHeadphones } from 'assets/icons/headphones.svg'
import { useAuth } from 'stores/auth.store'
import { Person } from 'types'
import { useLoadingBlock } from 'components/LoadingBlock'
import { QUERY_INSURANCE_DOCUMENT } from 'graphql/queries'
import { useApolloClient } from '@apollo/client'
import { useAlert } from 'components/AlertModal'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useToast } from 'components/ToastMessage'
import { pick } from 'lodash/fp'
import { ReactComponent as IconCheckmark } from 'assets/icons/check-circle.svg'
import { ReactComponent as IconX } from 'assets/symbols/x.svg'
import { ReactComponent as IconRecent } from 'assets/icons/recent.svg'
import {
  InsuranceDocumentQuery,
  UploadInsuranceDocumentDocument,
  useDeleteInsuranceDocumentMutation,
} from 'generated/graphql'
import { Plugins } from '@capacitor/core'
import InfoBox from 'components/InfoBox'

const isExpired = (expiresAt: string) =>
  expiresAt && moment().diff(expiresAt) > 0

const CannotUpload = () => {
  const { account } = useAuth()

  return (
    <div className="mt-8">
      <InfoBox Icon={IconHeadphones}>
        Having trouble uploading the document? Do you have it in another format?{' '}
        <br /> Please send it to{' '}
        <a
          className="text-primary underline"
          href={`mailto:hello@ohmunity.com&subject=Proof of Insurance: ${getPersonName(
            account.profile as Person,
          )}&body=Hi Ohmunity™,\n\nI'm having trouble uploading my proof of insurance. Attaching the file in this email.`}
        >
          hello@ohmunity.com
        </a>
      </InfoBox>
    </div>
  )
}

const UploadNewDocument = () => {
  const client = useApolloClient()
  const loadingBlock = useLoadingBlock()
  const alert = useAlert()
  const toast = useToast()
  const { log } = useLogger()
  const { account } = useAuth()

  return (
    <>
      <FileUploader
        mimeType=".pdf,application/pdf,image/*"
        note="Ony Images and PDF files are supported."
        onSelect={async ({ base64String, mime }) => {
          loadingBlock.open({
            $size: 'md',
            subtext: 'Uploading document...',
          })

          try {
            const response = await client.mutate({
              mutation: UploadInsuranceDocumentDocument,
              variables: {
                base64String,
                type: mime,
              },
            })

            if (response.errors) {
              const error = response.errors[0]

              log('error', 'Upload Insurance Document', {
                error,
              })
              alert.send({
                $type: 'danger',
                body: error.message || GENERIC_ERROR_MESSAGE,
                buttons: [
                  {
                    children: 'Close',
                  },
                ],
              })
            } else {
              toast.notify({
                type: 'success',
                message:
                  'The document has been upload and the team will review it soon!',
              })

              // Safe because we made the query before (always)
              const cachedInsuranceDocs = client.readQuery({
                query: QUERY_INSURANCE_DOCUMENT,
              })

              client.writeQuery({
                query: QUERY_INSURANCE_DOCUMENT,
                variables: {
                  accountID: account.id,
                },
                data: {
                  insurance_document: [
                    ...(cachedInsuranceDocs
                      ? cachedInsuranceDocs.insurance_document || []
                      : []),
                    pick(
                      [
                        'id',
                        'name',
                        'file_url',
                        'verified',
                        'invalid_reason',
                        'updated_at',
                        'expires_at',
                      ],
                      response.data.uploadInsuraceDocument,
                    ),
                  ],
                },
              })
            }
          } catch (error) {
            log('error', 'Upload Insurance Document', {
              error,
            })
            alert.send({
              $type: 'danger',
              body: error.message || GENERIC_ERROR_MESSAGE,
              buttons: [
                {
                  children: 'Close',
                },
              ],
            })
          }

          loadingBlock.close()
        }}
      />

      <CannotUpload />
    </>
  )
}

const Status = ({
  invalid_reason,
  verified,
  expires_at,
}: Pick<
  InsuranceDocumentQuery['insurance_document'][0],
  'invalid_reason' | 'verified' | 'expires_at'
>) => {
  if (invalid_reason) {
    return (
      <>
        <IconX width="15" height="15" className="fill-red-600 mr-2" />
        <div className="text-red-600">{invalid_reason}</div>
      </>
    )
  }

  if (verified && isExpired(expires_at)) {
    return (
      <>
        <IconRecent width="15" height="15" className="fill-red-500 mr-2" />
        <div className="text-red-500">Expired</div>
      </>
    )
  }

  if (verified) {
    return (
      <>
        <IconCheckmark width="15" height="15" className="fill-primary mr-2" />
        <div className="text-primary">Verified</div>
      </>
    )
  }

  return (
    <>
      <IconRecent width="15" height="15" className="fill-yellow-800 mr-2" />
      <div className="text-yellow-800">Ohmunity is verifying your document</div>
    </>
  )
}

const InsuranceCard = ({
  id,
  name,
  invalid_reason,
  verified,
  expires_at,
  file_url,
}: InsuranceDocumentQuery['insurance_document'][0]) => {
  const client = useApolloClient()
  const alert = useAlert()
  const [remove, { error, loading }] = useDeleteInsuranceDocumentMutation({
    variables: {
      id,
    },
    onCompleted: () => {
      const cached = client.readQuery({
        query: QUERY_INSURANCE_DOCUMENT,
      })

      client.writeQuery({
        query: QUERY_INSURANCE_DOCUMENT,
        data: {
          insurance_document: cached.insurance_document.filter(
            (c: any) => c.id !== id,
          ),
        },
      })
    },
  })

  return (
    <>
      <div
        className={cc([
          'relative bg-white shadow p-3',
          {
            'opacity-75': loading,
          },
        ])}
      >
        <div className="flex">
          <div className="truncate">ID~{name}</div>
        </div>
        <div className="flex items-center body-3 text-gray-700 justify-between">
          <div className="flex body-3 py-3">
            <Status {...{ invalid_reason, verified, expires_at }} />
          </div>
          <div>
            {expires_at && (
              <>
                <span className="underline">Expires</span>{' '}
                {moment(expires_at).calendar()}
              </>
            )}
          </div>
        </div>

        <Button
          $size="sm"
          $type="primary"
          onClick={() => {
            Plugins.Browser.open({
              url: file_url,
              windowName: 'Insurance Document',
              presentationStyle: 'popover',
            })
          }}
        >
          View Document
        </Button>

        {(!verified || isExpired(expires_at)) && (
          <button
            className="absolute top-0 right-0 bg-red-500 p-2 rounded-full"
            style={{
              top: -10,
              right: -8,
            }}
            onClick={() => {
              if (!loading) {
                alert.send({
                  title: 'Are you sure?',
                  body:
                    'You are about to delete an Insurance Document. This action might inactive your account.',
                  buttons: [
                    {
                      children: 'Continue',
                      onClick: () => {
                        remove()
                      },
                    },
                    {
                      $type: 'tertiary',
                      children: 'Cancel',
                    },
                  ],
                })
              }
            }}
          >
            <IconX width="15" className="fill-white" />
          </button>
        )}
      </div>

      {error && <ServerErrorMessage>{error.message}</ServerErrorMessage>}
    </>
  )
}

const TeachingDocuments = () => {
  return (
    <Container topBottomSpace>
      <p className="pb-8">
        We require proof of teaching permissions from all our Teachers to
        guarantee safety to all participants.
      </p>

      <div className="py-4">
        <div className="heading-3 text-primary pb-2">Proof of Insurance</div>
        <GenericQueryRender
          query={QUERY_INSURANCE_DOCUMENT}
          dataAccess="insurance_document"
          Success={({ data }) => {
            const arr = data as InsuranceDocumentQuery['insurance_document']

            return (
              <>
                {arr && arr.length > 0 && (
                  <ul>
                    {arr.map((data, i) => (
                      <li className="py-3" key={`insurance-document-card-${i}`}>
                        <InsuranceCard {...data} />
                      </li>
                    ))}
                  </ul>
                )}

                {!arr.some(({ verified }) => !verified) && (
                  <>
                    {arr.length > 0 && (
                      <div className="heading-3 text-primary pt-8 pb-2">
                        Update or Change your Insurance Document
                      </div>
                    )}

                    <UploadNewDocument />
                  </>
                )}
              </>
            )
          }}
        />
      </div>
    </Container>
  )
}

export default TeachingDocuments
