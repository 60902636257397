import jsonp from 'jsonp'
import ms from 'ms'
import { FC, useEffect } from 'react'
import makeCancelable from 'utils/makeCancelable'

const SupportedCountry = () => {
  useEffect(() => {
    const request = makeCancelable(
      new Promise((resolve, reject) => {
        jsonp(
          'http://ipinfo.io',
          {
            name: '__current_location_data',
            timeout: ms('10s'),
          },
          (err, data) => {
            if (err) {
              reject(err.message)
            } else {
              resolve(data)
            }
          },
        )
      }),
    )

    request.promise
      .then((data) => {
        console.log('--data', data)
      })
      .catch()

    return () => {
      request.cancel()
    }
  }, [])

  return null
}

export default SupportedCountry

export function WithSupportedCountry<P = unknown>(Component: FC<P>) {
  return (props: P) => {
    return (
      <>
        <SupportedCountry />
        <Component {...props} />
      </>
    )
  }
}
