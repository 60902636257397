import StackNavigation from 'components/StackNavigation'
import Container from 'components/Container'
import GenericQueryRender from 'components/GenericQueryRender'
import { useAuth } from 'stores/auth.store'
import StackContext from './context'
import ListBankAccountsAndCards from './ListBankAccountsAndCards'
import {
  AccountStatusDocument,
  BillingSourcesDocument,
} from 'generated/graphql'
import useLogger from 'hooks/useLogger'
import { useQuery } from 'urql'
import { INACTIVE_ACCOUNT_KEY } from 'globalConstants'
import Button from 'components/Button'
import { useModalScreen } from 'components/ModalScreen'
import sleep from 'utils/sleep'
import { ANIMATION_DURATION } from 'components/Modal'
import LegalInformation from 'screens/LegalInformation'

const Billing = () => {
  const modal = useModalScreen()
  const { log } = useLogger()
  const [{ fetching, data, error }] = useQuery({
    query: AccountStatusDocument,
    requestPolicy: 'cache-and-network',
  })

  if (error) {
    log('error', 'Account Status at Billing Screen', { error })
  }

  if (fetching || error) {
    return null
  }

  if (
    data &&
    data?.accountStatus.length > 0 &&
    data?.accountStatus.some(
      (x: any) =>
        x.id === INACTIVE_ACCOUNT_KEY.legalInformation && !x.completed,
    )
  ) {
    return (
      <Container topBottomSpace>
        <p className="pb-8 text-red-500 text-center">
          Cannot manage Bank Accounts until the Legal Information is submitted.
        </p>
        <Button
          $fluid
          $type="secondary"
          onClick={async () => {
            if (modal.isOpen) {
              modal.close()
              await sleep(ANIMATION_DURATION + 100)
            }

            modal.open({
              header: 'Legal Information',
              body: <LegalInformation />,
            })
          }}
        >
          Submit Legal Information now
        </Button>
      </Container>
    )
  }

  return <VerifiedBillingScreen />
}

const VerifiedBillingScreen = () => {
  return (
    <StackNavigation
      Context={StackContext}
      Component={BillingWithStackNavigation}
    />
  )
}

const BillingWithStackNavigation = () => {
  const { isAuthenticated, canTeachAClass } = useAuth()

  if (!isAuthenticated()) {
    return <div>You need to sign-in first</div>
  }

  return (
    <Container topBottomSpace>
      <GenericQueryRender
        query={BillingSourcesDocument}
        dataAccess="billingSources"
        fetchPolicy="network-only"
        variables={{
          withCards: true,
          withBankAccounts: canTeachAClass(),
        }}
        Success={ListBankAccountsAndCards}
      />
    </Container>
  )
}

export default Billing
