import ms from 'ms'
import {
  split,
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { navigate } from './navigation.client'
import { useGlobalProvider } from 'stores/global.store'
import { GRAPHQL_HTTP_URL, GRAPHQL_WS_URL } from 'globalConstants'

const ApolloClientProvider = ({ children }: any) => {
  const { auth, account } = useGlobalProvider()
  const headers =
    auth && account ? { Authorization: `Bearer ${auth?.token}` } : {}

  const wsLink = new WebSocketLink({
    uri: GRAPHQL_WS_URL,
    options: {
      reconnect: true,
      minTimeout: ms('3s'),
      timeout: ms('10s'),
      connectionParams: () => ({
        headers,
      }),
      connectionCallback: (error, result) => {
        if (error) {
          console.error('--WEBSOCKET', error)
        } else {
          console.debug('--WEBSOCKET', result)
        }
      },
    },
  })

  const httpLink = new HttpLink({
    uri: GRAPHQL_HTTP_URL,
    headers: auth && account ? { Authorization: `Bearer ${auth?.token}` } : {},
  })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
  )

  const logoutLink = onError((error) => {
    console.error('APOLLO ERROR!', JSON.stringify(error))

    if (
      error.networkError?.message.includes('JWTExpired') ||
      error.graphQLErrors?.some(
        ({ extensions, message }) =>
          extensions?.code === 'invalid-jwt' || message.includes('JWTExpired'),
      )
    ) {
      navigate('/logout')
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([logoutLink, splitLink]),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloClientProvider
