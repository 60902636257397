import Button from 'components/Button'
import { useStack } from 'components/StackNavigation'
import Container from 'components/Container'
import { useApolloClient } from '@apollo/client'
import { useAuth } from 'stores/auth.store'
import FormAddBankAccount from 'forms/FormAddBankAccount'
import useActionMutation from 'hooks/useActionMutation'
import StackContext from './context'
import {
  AccountStatus,
  AccountStatusDocument,
  AddBankAccountDocument,
  BillingSourcesDocument,
} from 'generated/graphql'
import { INACTIVE_ACCOUNT_KEY } from 'globalConstants'

/*
<SpaceForKeyboard /> not needed, used in FormAddBankAccount
*/

const AddBankAccount = () => {
  const client = useApolloClient()
  const { navigate, back } = useStack(StackContext)
  const { canTeachAClass } = useAuth()
  const actionMutation = useActionMutation()

  return (
    <Button
      type="button"
      $type="primary"
      $fluid
      onClick={() => {
        navigate({
          title: 'Add a Bank Account',
          // eslint-disable-next-line react/display-name
          Component: () => (
            <Container topBottomSpace>
              <FormAddBankAccount
                onSubmit={async (variables) => {
                  await actionMutation({
                    mutation: {
                      mutation: AddBankAccountDocument,
                      variables,
                    },
                    errorContext: 'Add Bank Account',
                    onSuccess: (newBankAccount: any) => {
                      const cached = client.readQuery({
                        query: BillingSourcesDocument,
                        variables: {
                          withCards: true,
                          withBankAccounts: canTeachAClass(),
                        },
                      })

                      client.writeQuery({
                        query: BillingSourcesDocument,
                        variables: {
                          withCards: true,
                          withBankAccounts: canTeachAClass(),
                        },
                        data: {
                          billingSources: {
                            ...cached.billingSources,
                            bankAccounts: [
                              ...cached.billingSources.bankAccounts,
                              newBankAccount.addBankAccount,
                            ],
                          },
                        },
                      })

                      const cachedAccountStatus = client.readQuery({
                        query: AccountStatusDocument,
                      })

                      if (
                        cachedAccountStatus &&
                        cachedAccountStatus.accountStatus &&
                        cachedAccountStatus.accountStatus.length > 0
                      ) {
                        client.writeQuery({
                          query: AccountStatusDocument,
                          data: {
                            accountStatus: (cachedAccountStatus.accountStatus as AccountStatus[]).map(
                              (status) => {
                                if (
                                  status.id ===
                                  INACTIVE_ACCOUNT_KEY.externalAccount
                                ) {
                                  return {
                                    ...status,
                                    completed: true,
                                  }
                                } else {
                                  return status
                                }
                              },
                            ),
                          },
                        })
                      }

                      back()
                    },
                    autoNotifySuccess: {
                      text: 'The Bank Account has been added successfuly.',
                    },
                  })
                }}
              />
            </Container>
          ),
        })
      }}
    >
      + Add a Bank Account
    </Button>
  )
}

export default AddBankAccount
