import React from 'react'
import cc from 'classcat'
import Badge from 'components/Badge'
import Container from 'components/Container'
import GenericQueryRender from 'components/GenericQueryRender'
import AttendingStudents from 'screens/AttentingStudents'
import { useModalScreen } from 'components/ModalScreen'
import { BookedClassesDocument, BookedClassesQuery } from 'generated/graphql'
import { friendlyLocalDate, fromUntil } from 'utils/date'
import Button from 'components/Button'
import NothingYet from 'components/NothingYet'
import Tabs from 'components/Tabs'
import moment from 'moment'
import { DB_DATE_FORMAT, DB_TIME_FORMAT } from 'globalConstants'
import getPersonName from 'utils/getPersonName'
import { useAuth } from 'stores/auth.store'
import { WithNotOptimizedForWebapp } from 'components/NotOptimizedForWebapp'

const BookedClasses = () => {
  const LIMIT = 10
  const { account } = useAuth()
  // 'false' because https://ohmunity.atlassian.net/browse/MA-172
  const [canLoadMore, setCanLoadMore] = React.useState(false)
  const [offset, setOffset] = React.useState(0)
  const [currentTab, setCurrentTab] = React.useState('upcoming')
  const endTimeISO = moment.utc().toISOString()
  const isUpcoming = currentTab === 'upcoming'
  const where = {
    class: {
      account_id: {
        _eq: account.id,
      },
    },
    end_timestamp: isUpcoming
      ? {
          _gte: endTimeISO,
          _lte: moment.utc(endTimeISO).add(7, 'days').toISOString(),
        }
      : {
          _lte: endTimeISO,
        },
  }

  return (
    <>
      <Tabs
        items={[
          {
            id: 'upcoming',
            text: 'Upcoming',
          },
          {
            id: 'past',
            text: 'Past',
          },
        ]}
        onChange={(newTab) => {
          setCurrentTab(newTab)
        }}
        activeTab={currentTab}
        $rootClassName="safe-area-pt"
      />

      <Container topBottomSpace className="h-full">
        <GenericQueryRender
          fetchPolicy="cache-and-network"
          query={BookedClassesDocument}
          dataAccess="class_event"
          variables={{
            limit: LIMIT,
            offset,
            where,
          }}
          NoResults={() => (
            <NothingYet
              title={
                isUpcoming
                  ? 'No booked classes yet.'
                  : 'No past booked classes.'
              }
              description={"Don't worry, that will change very soon."}
            />
          )}
          Success={({ data, fetchMore }) => (
            <>
              <ul>
                {(data as BookedClassesQuery['class_event']).map(
                  (bookedClasses, i) => (
                    <li className="py-3" key={`booked-card-${i}`}>
                      <Card data={bookedClasses} isUpcoming={isUpcoming} />
                    </li>
                  ),
                )}
              </ul>
              {canLoadMore && (
                <Button
                  $fluid
                  className="mt-4"
                  onClick={() => {
                    fetchMore?.({
                      query: BookedClassesDocument,
                      variables: {
                        limit: LIMIT,
                        offset: offset + LIMIT,
                        where,
                      },
                    }).then((result) => {
                      if (
                        !result.data ||
                        result.data.class_book_event.length < LIMIT
                      ) {
                        setCanLoadMore(false)
                      }

                      setOffset((offset) => offset + LIMIT)
                    })
                  }}
                >
                  Load more
                </Button>
              )}
            </>
          )}
        />
      </Container>
    </>
  )
}

export default WithNotOptimizedForWebapp(BookedClasses)

const Card = ({
  data,
  isUpcoming,
}: {
  data: BookedClassesQuery['class_event'][0]
  isUpcoming: boolean
}) => {
  const modal = useModalScreen()
  const {
    id,
    start_timestamp,
    end_timestamp,
    meeting_link,
    class_date_time,
  } = data
  const { name, online, setting } = data.class
  const attending = data.class_books_aggregate.aggregate?.count || 0
  const date = moment.utc(start_timestamp).local().format(DB_DATE_FORMAT)
  const start = moment.utc(start_timestamp).local().format(DB_TIME_FORMAT)
  const end = moment.utc(end_timestamp).local().format(DB_TIME_FORMAT)

  return (
    <div
      className={cc([
        'grid gap-3 grid-cols-2 shadow-lg bg-white p-3 content-between',
        {
          'active:bg-gray-100': attending > 0,
        },
      ])}
      role="button"
      style={{
        height: 150,
      }}
      onClick={() => {
        modal.open({
          header: `${isUpcoming ? 'Attending' : 'Attended'} Students`,
          body: (
            <AttendingStudents
              eventID={id}
              meetingLink={meeting_link}
              classID={data.class.id}
              online={online}
              date={date}
              start={start}
              isPast={moment().isAfter(end_timestamp)}
            />
          ),
        })
      }}
    >
      <div>
        <div className="heading-2 text-primary">{name}</div>
        <div className="body-2 text-gray-700">
          {getPersonName(class_date_time.teacher.person)}
        </div>
      </div>
      <div className="text-right">
        <Badge>
          {attending} {`${isUpcoming ? 'attending' : 'attended'}`}
        </Badge>
      </div>
      <div className="body-2 text-gray-700">
        {friendlyLocalDate(date)}
        <br />
        {fromUntil(start, end).join(' - ')}
      </div>
      <div className="flex justify-end items-end heading-3 text-primary">
        {online ? 'Virtual' : setting}
      </div>
    </div>
  )
}
