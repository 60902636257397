import { ReactElement, Children } from 'react'
import FormGroupTitle, {
  Props as FormGroupTitleProps,
} from 'components/FormGroupTitle'

/*
<SpaceForKeyboard /> not needed in this file
*/

type Props = {
  children:
    | ReactElement
    | (ReactElement | null | '' | undefined | false | void | 0)[]
} & Partial<FormGroupTitleProps>

const FormGroup = ({ children, title, subtitle }: Props) => {
  return (
    <>
      {title && <FormGroupTitle title={title} subtitle={subtitle} />}
      {Children.count(children) > 1 ? (
        Children.map(children, (child) =>
          child ? <div className="py-3">{child}</div> : null,
        )
      ) : (
        <div className="py-3">{children}</div>
      )}
    </>
  )
}

export default FormGroup
