import Button from 'components/Button'
import FormField, { FormGroup } from 'components/FormField'
import { Fragment } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  capitalize,
  difference,
  dropRight,
  join,
  kebabCase,
  map,
  split,
  uniqBy,
} from 'lodash/fp'
import { ReactComponent as IconTrashCan } from 'assets/icons/trash-can.svg'
import Container from 'components/Container'
import {
  useAccountYogaTypesQuery,
  AccountYogaTypesQuery,
  useUpdateAccountYogaTypesMutation,
  Yoga_Type_Enum,
} from 'generated/graphql'
import Loading from 'components/Loading'
import { flow } from 'lodash'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { useAuth } from 'stores/auth.store'

const YOGA_TYPES = Object.values(Yoga_Type_Enum).filter((x) => x !== 'other')

type Props = {
  onComplete?: () => void
}

type FormValues = {
  yogaTypes: {
    type: string
    fallback: string
  }[]
}

const YogaStyles = ({ onComplete }: Props) => {
  const { account } = useAuth()
  const { data, loading, error } = useAccountYogaTypesQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      accountID: account.id,
    },
  })

  if (loading) {
    return (
      <Container topBottomSpace>
        <Loading />
      </Container>
    )
  }

  if (error) {
    return <Container topBottomSpace>{error.message}</Container>
  }

  return <Form onComplete={onComplete} initialState={data?.account_yoga_type} />
}

const validationSchema = yup.object().shape({
  yogaTypes: yup.array().of(
    yup.object().shape({
      type: yup.string().required('a yoga type is required'),
      fallback: yup.string().when('type', {
        is: 'other',
        then: yup.string().required('a yoga type is required'),
        otherwise: yup.string().notRequired(),
      }),
    }),
  ),
})

const Form = ({
  onComplete,
  initialState,
}: Props & {
  initialState?: AccountYogaTypesQuery['account_yoga_type']
}) => {
  const [updateYogaTypes, updateState] = useUpdateAccountYogaTypesMutation({
    onCompleted: () => {
      onComplete?.()
    },
  })
  const {
    control,
    register,
    formState,
    handleSubmit,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      yogaTypes: initialState
        ? uniqBy('type', initialState).map(({ type, fallback }) => ({
            type,
            fallback: fallback || '',
          }))
        : [],
    },
    resolver: yupResolver(validationSchema),
  })
  const yogaTypes = useFieldArray({
    control,
    name: 'yogaTypes',
  })
  const { errors, isDirty, isSubmitting } = formState
  const watchYogaTypes = watch('yogaTypes')

  return (
    <Container topBottomSpace>
      <form
        onSubmit={handleSubmit(async (values) => {
          await updateYogaTypes({
            variables: {
              yogaTypes: values.yogaTypes.map(({ type, fallback }) => ({
                type: type as Yoga_Type_Enum,
                fallback,
              })),
            },
          })
        })}
      >
        {yogaTypes.fields.length === 0 && (
          <div className="py-2">
            Start adding yoga types by click on the "Add Yoga Type" button
          </div>
        )}

        {
          yogaTypes.fields.map((field, index) => (
            <Fragment key={field.id}>
              <div className="flex items-center">
                <div className="flex-grow pr-4">
                  <FormGroup>
                    <FormField
                      type="dropdown"
                      placeholder="Select a yoga type"
                      name={`yogaTypes[${index}].type`}
                      error={errors.yogaTypes?.[index]?.type?.message}
                      register={register}
                      defaultValue={field.type}
                      control={control}
                    >
                      <option value="">Select a type</option>
                      {difference(
                        YOGA_TYPES,
                        dropRight(
                          yogaTypes.fields.length - index,
                          yogaTypes.fields,
                        ).map((x: any) => x.value),
                      ).map((yogaType) => (
                        <option
                          value={yogaType}
                          key={[yogaType, index].join('-')}
                        >
                          {flow(
                            kebabCase,
                            split('-'),
                            map(capitalize),
                            join(' '),
                          )(yogaType)}
                        </option>
                      ))}
                      <option value="">---------</option>
                      <option value="other">Other</option>
                    </FormField>

                    {watchYogaTypes[index]?.type === 'other' && (
                      <FormField
                        type="text"
                        placeholder="Yoga type name"
                        error={errors.yogaTypes?.[index]?.fallback?.message}
                        name={`yogaTypes.${index}.fallback`}
                        register={register}
                        defaultValue={field.fallback}
                      />
                    )}
                  </FormGroup>
                </div>
                <button
                  type="button"
                  className="p-2 text-center"
                  onClick={() => {
                    yogaTypes.remove(index)
                  }}
                >
                  <IconTrashCan className="inline-block w-5 fill-red-500" />
                  <span className="block body-3 text-red-500 pt-1">Remove</span>
                </button>
              </div>

              {yogaTypes.fields.length - 1 > index && (
                <hr className="border-gray-500 my-6" />
              )}
            </Fragment>
          )) as any
        }

        <FormGroup>
          {yogaTypes.fields.length < 5 && (
            <Button
              $fluid
              $type="secondary"
              type="button"
              onClick={() => {
                yogaTypes.append({
                  type: '',
                  fallback: '',
                })
              }}
              className="mt-6"
            >
              Add Yoga Type
            </Button>
          )}

          <Button
            $fluid
            disabled={!isDirty}
            loading={isSubmitting || updateState.loading}
          >
            Save Changes
          </Button>
        </FormGroup>
      </form>

      <SpaceForKeyboard $size="lg" />
    </Container>
  )
}

export default YogaStyles
