import React from 'react'

type Props = {
  value: number
}

const ProgressBar = ({ value }: Props) => {
  return (
    <div className="relative" style={{ height: 4 }}>
      <div className="w-full h-full bg-gray-400" />
      <div
        data-testid="bar"
        className="absolute top-0 left-0 h-full bg-primary transition-all duration-200 ease-in-out"
        style={{
          width: `${value}%`,
        }}
      />
    </div>
  )
}

ProgressBar.defaultProps = {
  value: 0,
}

export default ProgressBar
