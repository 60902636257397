import Container from 'components/Container'
import Button from 'components/Button'
import FormLocation from 'forms/FormLocation'
import { StackComponentProps } from 'components/StackNavigation'
import { QUERY_LOCATIONS_BY_ACCOUNT } from 'graphql/queries'
import { useAuth } from 'stores/auth.store'
import { useApolloClient } from '@apollo/client'
import { useToast } from 'components/ToastMessage'
import { Location, LocationsByAccountQuery } from 'generated/graphql'
import { ANIMATION_DURATION } from 'components/Modal'

/*
<SpaceForKeyboard /> not needed
*/

const Form = ({ data: location, back }: StackComponentProps<Location>) => {
  const client = useApolloClient()
  const { account } = useAuth()
  const { notify } = useToast()

  return (
    <Container topBottomSpace>
      <FormLocation
        onSubmit={(newValues) => {
          const isUpdate = !!location
          const queried = client.readQuery<LocationsByAccountQuery>({
            query: QUERY_LOCATIONS_BY_ACCOUNT,
            variables: {
              accountID: account.id,
            },
          })

          client.writeQuery({
            query: QUERY_LOCATIONS_BY_ACCOUNT,
            variables: {
              accountID: account.id,
            },
            data: {
              location: isUpdate
                ? queried?.location.map((queriedLocation) =>
                    queriedLocation.id === location?.id
                      ? newValues
                      : queriedLocation,
                  )
                : [...(queried?.location || []), newValues],
            },
          })

          // Closing Stack Modal
          back?.()

          // Trigger Toast to notify about the change
          setTimeout(() => {
            notify({
              type: 'success',
              message: `The location "${
                location?.name || newValues.name
              }" has been ${isUpdate ? 'succesfully modified' : 'added'}`,
            })
          }, ANIMATION_DURATION + 200)
        }}
        location={location}
      />

      <Button
        data-testid="cancel-button"
        type="button"
        $type="secondary"
        $fluid
        onClick={() => {
          if (window.confirm('Are you sure you can to cancel?')) {
            back?.()
          }
        }}
      >
        Cancel
      </Button>
    </Container>
  )
}

export default Form
