import React from 'react'
import cc from 'classcat'
import useIsMounted from 'hooks/useIsMounted'
import Modal from 'components/Modal'
import FormField from 'components/FormField'
import Content from 'components/Container'
import Page from 'components/Page'
import List from 'components/List'
import { Plugins } from '@capacitor/core'
import { ReactComponent as IconSearch } from 'assets/icons/pin.svg'
import { gpsLookup } from 'utils/geolocationLookup'
import LocationAutocomplete, {
  SelectedData,
  parseSuggestion,
} from 'components/LocationAutocomplete'
import { QUERY_SEARCH_HISTORY } from 'graphql/queries'
import {
  UPDATE_SEARCH_HISTORY,
  CREATE_SEARCH_HISTORY_ONE,
} from 'graphql/mutations'
import { useAuth } from 'stores/auth.store'
import { useApolloClient } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { FindLocationOutput, PlaceCoordinatesDocument } from 'generated/graphql'
import { ReactComponent as Compass } from 'assets/icons/compass.svg'
import { ReactComponent as Recent } from 'assets/icons/recent.svg'
import { take } from 'lodash/fp'
import { useToast } from 'components/ToastMessage'
import { ReactComponent as Globe } from 'assets/icons/globe.svg'
import useLogger from 'hooks/useLogger'

/*
<SpaceForKeyboard /> not needed in this file
*/

export type VirtualClassOrLocation = {
  virtualClassEverywhere: boolean
  place?: SelectedData
}

export const getStoredLocation = () => localStorage.getItem('currentLocation')

const setStoredLocation = (data: VirtualClassOrLocation) => {
  localStorage.setItem('currentLocation', JSON.stringify(data))
}

export const getCurrentLocation = async (
  locate: boolean,
): Promise<VirtualClassOrLocation> => {
  const stored = getStoredLocation()

  if (stored && !locate) {
    return JSON.parse(stored)
  }

  const position = await Plugins.Geolocation.getCurrentPosition()

  const { coords } = position
  const { latitude, longitude } = coords
  const result = gpsLookup(latitude, longitude)

  if (result) {
    const data = {
      virtualClassEverywhere: false,
      place: {
        suggestion: {
          placeID: 'locateme',
          description: result.city
            ? `${result.city}, ${result.state_abbr} ${result.zipcode}`
            : `${result.state}, ${result.zipcode}`,
          terms: [] as any, // TODO: Because of https://github.com/hasura/graphql-engine/issues/6856
        },
        parsed: {
          country: result.country as string,
          state: result.state as string,
          city: result.city as string,
          address: '',
        },
        coordinates: {
          lat: latitude,
          lng: longitude,
        },
      },
    }

    setStoredLocation(data)

    return data
  } else {
    throw new Error('location not found')
  }
}

type AutocompleteModalProps = {
  isOpen: boolean
  toggle: () => any
  onSelect: (data: VirtualClassOrLocation) => void
}

type SearchingHistory = {
  placeID: string
  searching: string
}

const AutocompleteModal = ({
  isOpen,
  toggle,
  onSelect,
}: AutocompleteModalProps) => {
  const isMounted = useIsMounted()
  const { isAuthenticated, canAssistToClass } = useAuth()
  const auth = isAuthenticated() && canAssistToClass()
  const [coordinatesUser, setCoordinatesUser] = React.useState<string | null>(
    null,
  )
  const { notify } = useToast()
  const client = useApolloClient()
  const [locating, setLocating] = React.useState(false)
  const { log } = useLogger()

  const orderHistory = async (
    history: SearchingHistory[],
    suggestion: FindLocationOutput,
  ) => {
    const newHistory = history.filter(
      (newH: SearchingHistory) => newH.placeID !== suggestion.placeID && newH,
    )

    const historyUpdate = await client.mutate({
      mutation: UPDATE_SEARCH_HISTORY,
      variables: {
        placeID: suggestion.placeID,
      },
    })

    newHistory.unshift(historyUpdate.data.update_searching_history.returning[0])
    client.writeQuery({
      query: QUERY_SEARCH_HISTORY,
      data: {
        searching_history: newHistory,
      },
    })
  }

  const addToHistory = async (suggestion: FindLocationOutput) => {
    const { searching_history } = await client.readQuery({
      query: QUERY_SEARCH_HISTORY,
    })

    if (coordinatesUser) setCoordinatesUser(null)

    const alreadyStored = (searching_history as SearchingHistory[]).some(
      (h) => h.placeID === suggestion.placeID,
    )

    if (!alreadyStored) {
      await client.mutate({
        mutation: CREATE_SEARCH_HISTORY_ONE,
        variables: {
          placeID: suggestion.placeID,
          searching: suggestion.description,
        },
      })

      await client.writeQuery({
        query: QUERY_SEARCH_HISTORY,
        data: {
          searching_history: take(
            10,
            [
              {
                placeID: suggestion.placeID,
                searching: suggestion.description,
              },
            ].concat(searching_history),
          ),
        },
      })
    } else {
      orderHistory(searching_history, suggestion)
    }
  }

  const searchingHistory = async (
    suggestion: FindLocationOutput,
    history: SearchingHistory[],
  ) => {
    try {
      const result = await client.query({
        query: PlaceCoordinatesDocument,
        fetchPolicy: 'network-only',
        variables: {
          placeID: suggestion.placeID,
        },
      })

      if (result.error) {
        // TODO: Display Toast
      } else {
        if (coordinatesUser) setCoordinatesUser(null)

        onSelect({
          virtualClassEverywhere: false,
          place: {
            suggestion,
            parsed: parseSuggestion(suggestion),
            coordinates: {
              lat: result.data.placeCoordinates.lat,
              lng: result.data.placeCoordinates.lng,
            },
          },
        })

        orderHistory(history, suggestion)
      }
    } catch (err) {
      console.error(err)
      // TODO: Display Toast
    }
  }

  const locateMe = async () => {
    setLocating(true)

    try {
      const coordinates = await getCurrentLocation(true)

      setCoordinatesUser(coordinates.place?.suggestion.description || null)
      onSelect(coordinates)
    } catch (error) {
      log('error', 'Locate Me', {
        error,
      })
      notify({
        type: 'failure',
        message: (
          <>
            Ohmunity™ does not have permission to access to the device’s
            location
          </>
        ),
      })
    }

    setLocating(false)
  }

  React.useEffect(() => {
    const stored: string | null = getStoredLocation()

    if (!stored) {
      return
    } else {
      const locate = JSON.parse(stored)

      if (
        isMounted.current &&
        locate?.place?.suggestion?.placeID === 'locateme'
      ) {
        setCoordinatesUser(locate.place.suggestion.description)
      }
    }
  }, [])

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Page>
        <Page.Content>
          <Content topBottomSpace className="safe-area-mt">
            <LocationAutocomplete
              type="regions"
              onSelect={(select) => {
                if (auth) {
                  addToHistory(select.suggestion)
                }

                onSelect({
                  virtualClassEverywhere: false,
                  place: select,
                })
              }}
              name="search"
              showIcon
              placeholder="City, State, or Zip Code"
              includeCoordinates
              $listClassName="pl-9 -mt-4"
              inputRender={(input) => (
                <>
                  <div className="flex mb-4">
                    <div className="flex-grow">{input}</div>
                    <button
                      type="button"
                      onClick={toggle}
                      className="heading-3 text-gray-700 pl-2 pr-1 ml-2 active:bg-gray-100"
                    >
                      Cancel
                    </button>
                  </div>

                  <button
                    type="button"
                    onClick={() => {
                      onSelect({
                        virtualClassEverywhere: true,
                      })
                    }}
                    className={cc([
                      'flex items-center w-full pl-5 py-3 active:bg-gray-100 body-1',
                    ])}
                  >
                    <Globe width={20} className="-ml-5 mr-4" />
                    Virtual Classes Everywhere
                  </button>

                  <button
                    type="button"
                    onClick={locateMe}
                    className={cc([
                      'flex items-center w-full pl-5 py-2 active:bg-gray-100 body-1',
                      {
                        'heading-2 text-primary': !coordinatesUser,
                      },
                    ])}
                  >
                    <Compass width={20} className="-ml-5 mr-4" />
                    {locating ? 'Locating...' : coordinatesUser ?? 'Locate Me'}
                  </button>

                  {(auth || input.props?.value) && (
                    <div className="pl-9 pt-2 pb-3">
                      <hr className="border-gray-400" />
                    </div>
                  )}

                  {auth && (
                    <Query query={QUERY_SEARCH_HISTORY}>
                      {({ data }: any) => (
                        <>
                          {!input.props?.value && (
                            <>
                              <div className="flex items-center px-4">
                                <Recent width={20} className="-ml-4 mr-4" />
                                <p className="text-primary heading-2 uppercase">
                                  Recent Searches
                                </p>
                              </div>
                              <div className="pl-9">
                                <List>
                                  {data?.searching_history.map(
                                    (search: SearchingHistory) => (
                                      <List.Item
                                        key={search.placeID}
                                        onClick={() =>
                                          searchingHistory(
                                            {
                                              description: search.searching,
                                              placeID: search.placeID,
                                              terms: [] as any,
                                            },
                                            data.searching_history,
                                          )
                                        }
                                      >
                                        <span className="flex items-center justify-between">
                                          <span>{search.searching}</span>
                                        </span>
                                      </List.Item>
                                    ),
                                  )}
                                </List>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Query>
                  )}
                </>
              )}
            />
          </Content>
        </Page.Content>
      </Page>
    </Modal>
  )
}

type Props = {
  onSearch: (data: VirtualClassOrLocation) => void
  includeStoredLocation?: boolean
  onLoading?: (state: boolean) => void
  disabled?: boolean
}

const SearchLocation = ({
  onSearch,
  includeStoredLocation,
  onLoading,
  disabled,
}: Props) => {
  const DEFAULT_PLACEHOLDER = 'City, State, or Zip Code'
  const isMounted = useIsMounted()
  const [placeholder, setPlaceholder] = React.useState(DEFAULT_PLACEHOLDER)
  const [selected, setSelected] = React.useState<
    VirtualClassOrLocation | undefined
  >(undefined)
  const [isOpen, setIsOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { notify } = useToast()
  const { log } = useLogger()

  React.useEffect(() => {
    if (isMounted.current) {
      onLoading?.(loading)
    }
  }, [loading, onLoading])

  React.useEffect(() => {
    setPlaceholder('Retrieving location...')

    const timer = setTimeout(() => {
      if (isMounted.current) {
        setPlaceholder('Location not found, please enter manually')
        setLoading(false)
      }
    }, 1000 * 5)

    getCurrentLocation(false)
      .then((storedSelection) => {
        clearTimeout(timer)

        if (isMounted.current) {
          setPlaceholder(DEFAULT_PLACEHOLDER)
          setSelected(storedSelection)
          setLoading(false)

          if (includeStoredLocation) {
            onSearch(storedSelection)
          }
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setPlaceholder('Location not found, please enter manually')
          setLoading(false)
          notify({
            type: 'failure',
            message: (
              <>
                Ohmunity™ does not have permission to access to the device’s
                location
              </>
            ),
          })
          log('error', 'Geolocation', {
            error,
          })
        }
      })
  }, [includeStoredLocation])

  return (
    <>
      <div>
        <FormField
          type="text"
          name="search-location"
          readOnly
          Icon={IconSearch}
          placeholder={placeholder}
          value={selected?.place?.suggestion.description}
          onFocus={() => {
            if (!loading) {
              setIsOpen(true)
            }
          }}
          disabled={disabled || loading}
        />
      </div>
      <AutocompleteModal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
        onSelect={(selected) => {
          setSelected(selected)
          setIsOpen(false)
          setStoredLocation(selected)
          onSearch(selected)
        }}
      />
    </>
  )
}

export default SearchLocation
