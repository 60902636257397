import React from 'react'

import { ReactComponent as CCamex } from 'assets/icons/cc/amex.svg'
import { ReactComponent as CCvisa } from 'assets/icons/cc/visa.svg'
import { ReactComponent as CCmastercard } from 'assets/icons/cc/mastercard.svg'
import { ReactComponent as CCunknown } from 'assets/icons/cc/unknown.svg'

interface Props {
  brand: string
  $size: 'sm'
  className?: string
}

const CreditCardIcon = ({ brand, className }: Props): React.ReactElement => {
  const width = 35

  switch (brand.toLowerCase()) {
    case 'american express':
    case 'americanexpress':
    case 'amex': {
      return <CCamex className={className} width={width} />
    }

    case 'visa':
      return <CCvisa className={className} width={width} />

    case 'master':
    case 'mastercard':
    case 'master card':
      return <CCmastercard className={className} width={width} />

    default:
      return <CCunknown className={className} width={width} />
  }
}

CreditCardIcon.defaultProps = {
  $size: 'sm',
}

export default CreditCardIcon
