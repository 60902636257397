import { Children, Fragment, isValidElement, ReactNode } from 'react'
import cc from 'classcat'

type Props = {
  children: ReactNode
  $lines?: boolean | string
  $evenClassName?: string
  $oddClassName?: string
  $spaceSize?: 'sm' | 'md' | 'lg' | 'xl'
}

const SectionList = ({
  children,
  $spaceSize,
  $lines,
  $evenClassName,
  $oddClassName,
}: Props) => {
  const validChildren = Children.toArray(children).filter((child) => !!child)

  if (validChildren.length === 0) {
    return null
  }

  return (
    <ul
      className={cc([
        !$oddClassName &&
          !$evenClassName && {
            'space-y-4': $spaceSize === 'sm',
            'space-y-6': $spaceSize === 'md',
            'space-y-10': $spaceSize === 'lg',
            'space-y-16': $spaceSize === 'xl',
          },
      ])}
    >
      {validChildren.map((child, i) => (
        <Fragment key={`section-${isValidElement(child) ? child.key : i}`}>
          {$lines && i > 0 && (
            <li>
              <hr
                className={
                  typeof $lines === 'string' ? $lines : 'border-gray-400'
                }
              />
            </li>
          )}
          <li
            className={cc([
              $evenClassName && {
                [$evenClassName]: i % 2 === 0,
              },
              $oddClassName && {
                [$oddClassName]: i % 2 !== 0,
              },
              ($oddClassName || $evenClassName) && {
                'py-4': $spaceSize === 'sm',
                'py-6': $spaceSize === 'md',
                'py-10': $spaceSize === 'lg',
                'py-16': $spaceSize === 'xl',
              },
            ])}
          >
            {child}
          </li>
        </Fragment>
      ))}
    </ul>
  )
}

SectionList.defaultProps = {
  $spaceSize: 'md',
}

export default SectionList
