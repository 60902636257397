import React from 'react'
import { flow, pick, mapValues } from 'lodash/fp'
import FormCarousel, { FormCarouselSlide } from 'forms/FormCarousel'
import backWoman from 'assets/photos/signup/back-woman.jpg'
import YogaTypes from './YogaTypes'
import Settings from './Settings'
import Levels from './Levels'
import Finish from './Finish'
import {
  useGlobalProvider,
  _INTERNAL_setStoredPreferences,
} from 'stores/global.store'
import useSignupNavigation from 'hooks/useSignupNavigation'

/*
<SpaceForKeyboard /> not needed
*/

const WelcomeStudent = (_props: any) => {
  const { preferences, setPreferences } = useGlobalProvider()
  const { signupNavigate } = useSignupNavigation()

  React.useEffect(() => {
    if (preferences) {
      signupNavigate('/student')
    }
  }, [preferences])

  if (preferences) {
    return null
  }

  return (
    <FormCarousel
      onSubmit={(values) => {
        const cleanPreferences = flow(
          pick(['yogaTypes', 'levels', 'settings']),
          mapValues((str: string) => str.split(',')),
        )({
          yogaTypes: '',
          levels: '',
          settings: '',
          ...values,
        })

        if (values.next === 'explore') {
          setPreferences(cleanPreferences)
        } else {
          _INTERNAL_setStoredPreferences(cleanPreferences)
          signupNavigate('/student')
        }
      }}
      onCancel={() => {
        signupNavigate('/')
      }}
    >
      <FormCarouselSlide canSkip>
        <YogaTypes />
      </FormCarouselSlide>
      <FormCarouselSlide canSkip>
        <Settings />
      </FormCarouselSlide>
      <FormCarouselSlide canSkip>
        <Levels />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={backWoman}>
        <Finish />
      </FormCarouselSlide>
    </FormCarousel>
  )
}

export default WelcomeStudent
