import React from 'react'
import cc from 'classcat'
import useMode from 'hooks/useMode'

const MODES = [
  {
    id: 'teach' as 'teach',
    text: 'I want to teach',
  },
  {
    id: 'practice' as 'practice',
    text: 'I want to practice',
  },
]

const ModeSwitch = () => {
  const [currentMode, setMode] = useMode()

  return (
    <ul className="grid grid-cols-2 border-b-2 border-primary">
      {MODES.map((mode, index) => (
        <li key={`mode-${mode.id}-${index}`} className="text-center py-3 px-5">
          <button
            onClick={() => {
              setMode(mode.id)
            }}
            className={cc([
              'w-full p-2 rounded-full heading-4',
              {
                'bg-white text-primary shadow-md': currentMode === mode.id,
                'text-gray-700': currentMode !== mode.id,
              },
            ])}
            data-testid={`i-want-to-${mode.id}`}
          >
            {mode.text}
          </button>
        </li>
      ))}
    </ul>
  )
}

export default ModeSwitch
