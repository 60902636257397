import React from 'react'

type Props = {
  children: React.ReactNode
}

const Badge = ({ children }: Props) => (
  <div
    className="inline-block heading-4 text-white
bg-primary text-center rounded-full py-1 px-3"
  >
    {children}
  </div>
)

export default Badge
