import locations from 'assets/data/locations.json'
import haversine from 'haversine'
import _ from 'lodash'

// TODO: This file was found online but does not work very well with all the address. We need to find a better one

const MEMO: any = {}

const find = _.partial(_.find, locations)
const filter = _.partial(_.filter as any, locations)

export const zipLookup = (zipcode: any) => {
  zipcode = _.padStart(zipcode, 5, '0')

  return find({
    zipcode,
  } as any)
}

export const gpsLookup = (latitude: number, longitude: any) => {
  if (MEMO[`${latitude},${longitude}`]) {
    return MEMO[`${latitude},${longitude}`]
  }

  let minDistance = Infinity // simulate infinity
  let minLocation: any = {}

  const start = {
    latitude: latitude,
    longitude: longitude,
  }

  for (let i = 0; i < locations.length; i++) {
    const distance = haversine(start, locations[i] as any)

    if (distance < minDistance) {
      minLocation = locations[i]
      minDistance = distance
    }
  }

  minLocation.distance = minDistance

  MEMO[`${latitude},${longitude}`] = minLocation

  return minLocation
}

export const findByState = (state: any) => {
  state = state.toUpperCase()
  return filter({
    state_abbr: state,
  })
}

export const findByCityAndState = (city: string, state: string) => {
  state = state.toUpperCase()
  city = city.toUpperCase()

  const cities = filter({
    state_abbr: state,
  })

  return _.find(cities as any, (c: any) => {
    return c.city.toUpperCase() === city
  })
}
