import { flow, pick, mapValues, lowerCase } from 'lodash/fp'
import { useAuth } from 'stores/auth.store'
import Container from 'components/Container'
import { useMutation } from '@apollo/client'
import { Entity, Person } from 'types'
import useLogger from 'hooks/useLogger'
import FormPerson from 'forms/FormPerson'
import {
  StudioInfoDocument,
  StudioInfoQuery,
  TeacherInfoByPersonIdDocument,
  TeacherInfoByPersonIdQuery,
  UpdateProfileEntityDocument,
  UpdateProfilePersonDocument,
  UpdateProfileTeacherDocument,
} from 'generated/graphql'
import GenericQueryRender from 'components/GenericQueryRender'
import FormEntity from 'forms/FormEntity'
import { onlyNumbers } from 'utils/regex'
import FormTeacher from 'forms/FormTeacher'

/*
<SpaceForKeyboard /> not needed, used in FormPerson, FormEntity
*/

type Props = {
  onComplete?: () => void
}

const General = ({ onComplete }: Props) => {
  const { isEntity, isTeacher, isPerson } = useAuth()

  return (
    <Container topBottomSpace>
      {isEntity() && <EditEntity onComplete={onComplete} />}
      {isPerson() && isTeacher() && <EditTeacher onComplete={onComplete} />}
      {isPerson() && !isTeacher() && <EditStudent onComplete={onComplete} />}
    </Container>
  )
}

const EditEntity = ({ onComplete }: Props) => {
  const { log } = useLogger()
  const { account, updateAccount } = useAuth()
  const { profile } = account
  const [updateProfileEntity, { loading, error }] = useMutation(
    UpdateProfileEntityDocument,
  )

  if (!profile) {
    return <div>Not logged in</div>
  }

  return (
    <GenericQueryRender
      query={StudioInfoDocument}
      variables={{
        id: account.id,
      }}
      dataAccess="account_by_pk"
      Success={({ data }: { data: StudioInfoQuery['account_by_pk'] }) => (
        <FormEntity
          buttonText="Save"
          onSubmit={async (values: any) => {
            const variables = {
              id: profile.id,
              set: {
                ...pick(['name', 'bio', 'email'], values),
                phoneNumber: onlyNumbers(values.phoneNumber),
                contactPhoneNumber: onlyNumbers(values.contactPhoneNumber),
              },
              accountID: account.id,
              accountSet: flow(
                pick(['username']),
                mapValues(lowerCase),
                mapValues((v) => v.replace(/[\W_]+/g, '')),
                mapValues((v) => v || null),
              )(values),
            }

            await updateProfileEntity({
              variables,
              update: (_, { errors, data }) => {
                if (errors) {
                  log('error', 'Profile Entity', {
                    errors,
                  })
                } else {
                  updateAccount({
                    username: data.update_account_by_pk?.username || null,
                    profile: {
                      ...profile,
                      ...data.update_profile_entity_by_pk,
                    },
                  })
                }
              },
            })

            onComplete?.()
          }}
          state={{ loading, error }}
          initialValues={{
            name: (profile as Entity)?.name || '',
            email: profile.email || '',
            phoneNumber: profile.phoneNumber || '',
            bio: data?.entity?.bio || '',
            username: account.username || '',
            contactPhoneNumber: data?.entity?.contactPhoneNumber || '',
          }}
          canEditPicture={false}
          canEditEmail
        />
      )}
    />
  )
}

const EditTeacher = ({ onComplete }: Props) => {
  const { log } = useLogger()
  const { account, updateAccount } = useAuth()
  const { profile } = account
  const [updateProfileTeacher, { loading, error }] = useMutation(
    UpdateProfileTeacherDocument,
  )

  if (!profile) {
    return <div>Not logged in</div>
  }

  return (
    <>
      <GenericQueryRender
        query={TeacherInfoByPersonIdDocument}
        variables={{
          personID: profile.id,
        }}
        dataAccess="teacher[0]"
        Success={({
          data,
        }: {
          data: TeacherInfoByPersonIdQuery['teacher'][0]
        }) => (
          <FormTeacher
            buttonText="Save"
            onSubmit={async (values: any) => {
              await updateProfileTeacher({
                variables: {
                  personID: profile.id,
                  teacherID: data.id,
                  setPerson: {
                    ...pick(['firstName', 'lastName', 'email'], values),
                    phoneNumber: onlyNumbers(values.phoneNumber),
                    contactPhoneNumber: onlyNumbers(values.contactPhoneNumber),
                  },
                  setTeacher: pick(['bio', 'cover_picture'], values),
                  accountID: account.id,
                  accountSet: flow(
                    pick(['username']),
                    mapValues(lowerCase),
                    mapValues((v) => v.replace(/[\W_]+/g, '')),
                    mapValues((v) => v || null),
                  )(values),
                },
                update: (_, { errors, data }) => {
                  if (errors) {
                    log('error', 'Profile Teacher', {
                      errors,
                    })
                  } else {
                    updateAccount({
                      username: data.update_account_by_pk?.username || null,
                      profile: {
                        ...profile,
                        ...data.update_profile_person_by_pk,
                      },
                    })
                  }
                },
              })

              onComplete?.()
            }}
            state={{ loading, error }}
            initialValues={{
              firstName: (profile as Person)?.firstName || '',
              lastName: (profile as Person)?.lastName || '',
              email: profile.email || '',
              phoneNumber: profile.phoneNumber || '',
              bio: data?.bio || '',
              username: account.username || '',
              cover_picture: data?.cover_picture || '',
              contactPhoneNumber: data.person.contactPhoneNumber || '',
            }}
          />
        )}
      />
    </>
  )
}

const EditStudent = ({ onComplete }: Props) => {
  const { log } = useLogger()
  const { account, updateAccount } = useAuth()
  const { profile } = account
  const [updateProfileStudent, { loading, error }] = useMutation(
    UpdateProfilePersonDocument,
  )

  if (!profile) {
    return <div>Not logged in</div>
  }

  return (
    <FormPerson
      buttonText="Save"
      onSubmit={async (values: any) => {
        await updateProfileStudent({
          variables: {
            id: profile.id,
            set: {
              ...pick(['firstName', 'lastName', 'email'], values),
              phoneNumber: onlyNumbers(values.phoneNumber),
            },
            accountID: account.id,
            accountSet: flow(
              pick(['username']),
              mapValues(lowerCase),
              mapValues((v) => v.replace(/[\W_]+/g, '')),
              mapValues((v) => v || null),
            )(values),
          },
          update: (_, { errors, data }) => {
            if (errors) {
              log('error', 'Profile Student', {
                errors,
              })
            } else {
              updateAccount({
                username: data.update_account_by_pk?.username || null,
                profile: {
                  ...profile,
                  ...data.update_profile_person_by_pk,
                },
              })
            }
          },
        })

        onComplete?.()
      }}
      state={{ loading, error }}
      initialValues={{
        firstName: (profile as Person)?.firstName || '',
        lastName: (profile as Person)?.lastName || '',
        email: profile.email || '',
        phoneNumber: profile.phoneNumber || '',
        username: account.username || '',
      }}
      canEditEmail
      canEditUsername
    />
  )
}

export default General
