import React from 'react'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import Button from 'components/Button'
import Container from 'components/Container'
import BirthdayField from 'formFields/BirthdayField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'

const validationSchema = yup.object().shape({
  birthday: Validation.birthday,
})

const Birthday = () => {
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const formMethods = useForm({
    defaultValues: {
      birthday: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, formState } = formMethods
  const { dirtyFields, isValid } = formState

  const canContinue =
    (dirtyFields.birthdayMonth &&
      dirtyFields.birthdayDay &&
      dirtyFields.birthdayYear) ||
    isValid

  return (
    <Container topBottomSpace className="flex flex-col h-full justify-between">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">
          What is your date of birth?
        </div>
      </div>

      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit((values) => {
            next(values)
          })}
        >
          {/* Birthday */}
          <div className="py-3">
            <BirthdayField name="birthday" isBig />
          </div>

          <div className="pt-4">
            <Button $fluid disabled={!canContinue}>
              Next
            </Button>
          </div>

          {isKeyboardOnScreen && (
            <SpaceForKeyboard $size="lg" autoControlled={false} />
          )}
        </form>
      </FormProvider>
    </Container>
  )
}

export default Birthday
