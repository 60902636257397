import React from 'react'
import CallToAction, { CTA } from 'components/CallToAction'
import { ReactComponent as YogaMountain } from 'assets/illustrations/mountain.svg'
import { ReactComponent as YogaChildPose } from 'assets/illustrations/childs-pose.svg'

type Props = {
  type: 'not-found' | 'error'
  title: string
  description?: React.ReactNode
  ctas?: CTA[]
}

const NothingYet = ({ type, title, description, ctas }: Props) => {
  return (
    <CallToAction
      Icon={type === 'not-found' ? YogaMountain : YogaChildPose}
      iconStyle={
        type === 'not-found'
          ? { width: 'w-60', heigth: 'h-56', margin: '-mb-6' }
          : { width: 'w-48', heigth: 'h-32', margin: '-mb-12' }
      }
      $color="light"
      title={title}
      description={description}
      ctas={ctas}
    />
  )
}

NothingYet.defaultProps = {
  type: 'not-found',
}

export default NothingYet
