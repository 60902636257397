import React from 'react'
import { capitalize } from 'lodash/fp'
import FormCarousel, { FormCarouselSlide } from 'forms/FormCarousel'
import Info from './Info'
import Pictures from './Pictures'
import { useMutation, useApolloClient } from '@apollo/client'
import { CREATE_STUDIO_ACCOUNT } from 'graphql/mutations'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useAlert } from 'components/AlertModal'
import useSignupNavigation from '../../../hooks/useSignupNavigation'
import { onlyNumbers } from 'utils/regex'
import { getDeviceTimezone } from 'utils/timezone'
import { isWebapp } from 'utils/env'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import Button from 'components/Button'
import PhoneNumberField from 'formFields/PhoneNumberField'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import FormField, { FormGroup } from 'components/FormField'
import { yupResolver } from '@hookform/resolvers'
import { FormProvider, useForm } from 'react-hook-form'
import Container from 'components/Container'
import FormInlineGroup from 'components/FormInlineGroup'
import { WithWebBackLayout } from 'components/WebBackLayout'

/*
<SpaceForKeyboard /> not needed
*/

const WelcomeStudio = (_props: any) => {
  const { log } = useLogger()
  const toast = useToast()
  const loadingBlock = useLoadingBlock()
  const alert = useAlert()
  const [mutate] = useMutation(CREATE_STUDIO_ACCOUNT)
  const { signupNavigate } = useSignupNavigation()

  const onSubmit = async (values: any) => {
    const { name, email, phoneNumber, bio, photos, termsAndConditions } = values

    loadingBlock.open()

    try {
      const result = await mutate({
        variables: {
          profile: {
            name: capitalize(name),
            email: email.trim().toLowerCase(),
            phoneNumber: onlyNumbers(phoneNumber),
            bio,
          },
          photos: values?.photos ? photos : [],
          termsAndConditions,
          timezone: getDeviceTimezone(),
        },
      })

      if (result.errors) {
        toast.notify({
          type: 'failure',
          message: result.errors[0].message,
        })
      } else {
        signupNavigate('/done', {
          state: {
            disableAnimation: true,
            result: result?.data?.signupStudio,
            forceRefreshPreferences: true,
          },
        })
      }

      loadingBlock.close()
    } catch (error) {
      log('error', 'Studio Signup', { error, values })
      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
      loadingBlock.close()
    }
  }

  if (isWebapp()) {
    return <SimpleForm onSubmit={onSubmit} />
  }

  return (
    <FormCarousel
      onSubmit={onSubmit}
      onCancel={() => {
        alert.send({
          body: 'All information you have already entered will be lost.',
          buttons: [
            {
              children: 'Continue',
              onClick: () => {
                signupNavigate('/')
              },
            },
            {
              $type: 'tertiary',
              children: 'Cancel',
            },
          ],
        })
      }}
    >
      <FormCarouselSlide>
        <Info />
      </FormCarouselSlide>
      <FormCarouselSlide canSkip>
        <Pictures />
      </FormCarouselSlide>
    </FormCarousel>
  )
}

export default WithWebBackLayout<any>(WelcomeStudio)

type SimpleFormProps = {
  onSubmit: (values: any) => void
}

const SimpleForm = ({ onSubmit }: SimpleFormProps) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    name: Validation.entityName,
    email: Validation.uniqueEmail(client),
    phoneNumber: Validation.uniquePhoneNumber(client),
    bio: Validation.longText,
    termsAndConditions: yup
      .boolean()
      .required()
      .test(
        'isChecked',
        'You need to read and agree on our Terms and Conditions.',
        (value) => value === true,
      ),
  })
  const formMethods = useForm({
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
      bio: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, register, errors, formState, control } = formMethods
  const { dirtyFields, isValid } = formState
  const canContinue = isValid || Object.keys(dirtyFields).length > 0

  return (
    <Container>
      <h1 className="heading-1 text-center pt-4 pb-8 text-primary">
        Welcome Studio
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <FormGroup>
            {/* Name */}
            <FormField
              type="text"
              name="name"
              label="Studio name *"
              register={register}
              error={errors?.name?.message}
            />

            <FormInlineGroup>
              {/* Email */}
              <div>
                <FormField
                  type="text"
                  name="email"
                  label="Email *"
                  register={register}
                  error={errors?.email?.message}
                />
              </div>

              {/* Phone Number */}
              <div>
                <PhoneNumberField
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="XXX XXX XXXX"
                  control={control}
                />
              </div>
            </FormInlineGroup>

            <FormTextWithCounter
              name="bio"
              label="Write an introduction for your studio *"
              $inputClassName="h-56"
            />

            <TermsAndConditionsField
              name="termsAndConditions"
              control={control}
            />
          </FormGroup>
        </FormProvider>

        <div className="pt-4">
          <Button $fluid disabled={!canContinue}>
            Sign up
          </Button>
        </div>
      </form>
    </Container>
  )
}
