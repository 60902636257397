import { loader } from 'graphql.macro'

export const CREATE_STUDENT_ACCOUNT = loader('./createStudentAccount.graphql')

export const CREATE_STUDIO_ACCOUNT = loader('./createStudioAccount.graphql')

export const CREATE_NOTIFICATIONS = loader('./createNotifications.graphql')

export const DELETE_NOTIFICATIONS = loader('./deleteNotifications.graphql')

export const DELETE_TEACHER_BY_PK = loader('./deleteTeacherByPK.graphql')

export const CREATE_STUDIO_TEACHER = loader('./createStudioTeacher.graphql')

export const CREATE_LOCATION = loader('./createLocation.graphql')

export const UPDATE_LOCATION_BY_PK = loader('./updateLocationByPK.graphql')

export const DELETE_LOCATION_BY_PK = loader('./deleteLocationByPK.graphql')

export const CREATE_CLASS = loader('./createClass.graphql')

export const CREATE_SEARCH_HISTORY_ONE = loader(
  './createSearchHistoryOne.graphql',
)

export const UPDATE_SEARCH_HISTORY = loader('./updateSearchHistory.graphql')

export const UPLOAD_PICTURE = loader('./uploadPicture.graphql')

export const UPDATE_DEFAULT_SOURCE = loader('./updateDefaultSource.graphql')

export const CREATE_FAVORITE_CLASS = loader('./createFavoriteClass.graphql')

export const DELETE_FAVORITE_CLASS = loader('./deleteFavoriteClass.graphql')
