import { useEffect, useState } from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Loading from 'components/Loading'
import { useToast } from 'components/ToastMessage'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'
import { useRoutes } from 'Router'
import { useAuth } from 'stores/auth.store'

const ComponentList = [
  {
    title: 'Loading',
    Render: () => (
      <>
        <div>default</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading />
        </div>

        <div>xs</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading $size="xs" />
        </div>

        <div>sm</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading $size="sm" />
        </div>

        <div>md</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading $size="md" />
        </div>

        <div>lg</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading $size="lg" />
        </div>

        <div>with context</div>
        <div
          className="inline-block"
          style={{
            border: 'dashed 1px red',
          }}
        >
          <Loading>Loading...</Loading>
        </div>
      </>
    ),
  },
  {
    title: 'Button',
    Render: () => {
      const [state, setState] = useState(true)

      useEffect(() => {
        const interval = setInterval(() => {
          setState((current) => !current)
        }, 1500)

        return () => {
          clearInterval(interval)
        }
      }, [])

      return (
        <ul className="space-y-2">
          <li>
            <Button $fluid>Hello che</Button>
          </li>
          <li>
            <Button $fluid>
              <Plus width={18} className="inline-block fill-white" /> With Icon
            </Button>
          </li>
          <li>
            <Button>
              <Plus width={18} className="inline-block fill-white" /> With Icon
            </Button>
          </li>
          <li className="flex space-x-2">
            <div>
              <Button $size="sm">Hello che</Button>
            </div>
            <div>
              <Button $size="sm" loading>
                Hello che
              </Button>
            </div>
          </li>
          <li className="flex space-x-2">
            <div>
              <Button $size="md">Hello che</Button>
            </div>
            <div>
              <Button $size="md" loading>
                Hello che
              </Button>
            </div>
          </li>
          <li className="flex space-x-2">
            <Button $size="sm" $roundedSquare>
              Hello che
            </Button>
            <Button $size="sm" $roundedSquare loading>
              Hello che
            </Button>
          </li>
          <li className="flex space-x-2">
            <Button $size="md" $roundedSquare>
              Hello che
            </Button>
            <Button $size="md" $roundedSquare loading>
              Hello che
            </Button>
          </li>
          <li>
            <Button $fluid $size="sm" loading={state}>
              Hello che
            </Button>
          </li>
          <li>
            <Button $fluid loading={state}>
              Hello che
            </Button>
          </li>
        </ul>
      )
    },
  },
  {
    title: 'Toast',
    Render: () => {
      const toast = useToast()

      return (
        <ul className="space-y-2">
          <li>
            <Button
              onClick={() => {
                toast.notify({
                  type: 'info',
                  message: `Bacon ipsum dolor amet cupim sirloin t-bone meatloaf chuck. Tenderloin turkey spare ribs, ball tip andouille biltong doner frankfurter strip steak.`,
                })
              }}
            >
              Info
            </Button>
          </li>
          <li>
            <Button
              onClick={() => {
                toast.notify({
                  type: 'success',
                  message: `Bacon ipsum dolor amet cupim sirloin t-bone meatloaf chuck. Tenderloin turkey spare ribs, ball tip andouille biltong doner frankfurter strip steak.`,
                })
              }}
            >
              Success
            </Button>
          </li>
          <li>
            <Button
              onClick={() => {
                toast.notify({
                  type: 'warning',
                  message: `Bacon ipsum dolor amet cupim sirloin t-bone meatloaf chuck. Tenderloin turkey spare ribs, ball tip andouille biltong doner frankfurter strip steak.`,
                })
              }}
            >
              Warning
            </Button>
          </li>
          <li>
            <Button
              onClick={() => {
                toast.notify({
                  type: 'failure',
                  message: `Bacon ipsum dolor amet cupim sirloin t-bone meatloaf chuck. Tenderloin turkey spare ribs, ball tip andouille biltong doner frankfurter strip steak.`,
                })
              }}
            >
              Failure
            </Button>
          </li>
        </ul>
      )
    },
  },
]

const Admin = () => {
  const { account } = useAuth()
  const routes = useRoutes()

  return (
    <Container topBottomSpace>
      {/* Routes */}
      <div className="py-6">
        <h1 className="heading-2 text-primary pb-2">
          {routes.length} routes as "{account.type}"
        </h1>

        <ul>
          {routes.map((route) => (
            <li key={`route-${route.id}-${route.path || ''}`}>{route.path}</li>
          ))}
        </ul>
      </div>

      {/* components */}
      <ul>
        {ComponentList.map(({ title, Render }, index) => (
          <li key={`component-${index}`} className={index > 0 ? 'pt-8' : ''}>
            <h2 className="font-title heading-1 text-primary pb-2">{title}</h2>

            <div
              className="p-2"
              style={{
                border: 'solid 1px blue',
              }}
            >
              <Render />
            </div>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Admin
