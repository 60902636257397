import FormField, { Props as FormFieldProps } from 'components/FormField'
import { useFormContext } from 'react-hook-form'
import { LONG_TEXT_BOUNDARIES } from 'globalConstants'

const getTextColor = (length: number) => {
  const RED = 'text-red-500'
  const GREEN = 'text-green'
  const YELLOW = 'text-yellow-600'

  if (length < LONG_TEXT_BOUNDARIES.MIN || length > LONG_TEXT_BOUNDARIES.MAX) {
    return RED
  }

  if ((length * 100) / LONG_TEXT_BOUNDARIES.MAX <= 85) {
    return GREEN
  }

  return YELLOW
}

type Props = Omit<FormFieldProps, 'type'> & {
  name: string
}

const FormTextWithCounter = (props: Props) => {
  const { name } = props
  const { watch, errors, register } = useFormContext()
  const watchLength = watch(name).length

  return (
    <>
      <FormField
        {...props}
        type="longtext"
        register={register}
        error={errors[name]?.message}
      />
      <div className="flex body-2 mt-1 justify-between">
        <div className="body-2">
          (min {LONG_TEXT_BOUNDARIES.MIN} / max {LONG_TEXT_BOUNDARIES.MAX}{' '}
          characters)
        </div>
        <div>
          {watchLength > 0 && (
            <span className={getTextColor(watchLength)}>
              Total {watchLength}
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default FormTextWithCounter
