import cc from 'classcat'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'
import FilePicker, { MimeType } from 'utils/FilePicker'
import { useToast } from 'components/ToastMessage'
import useLogger from 'hooks/useLogger'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'

type EmptyProps = {
  onClick: () => void
  innerRef?: any
  disabled?: boolean
}

const Empty = ({ innerRef, onClick, disabled }: EmptyProps) => (
  <button
    ref={innerRef}
    type="button"
    onClick={() => {
      if (!disabled) {
        onClick()
      }
    }}
    className={cc([
      'flex items-center justify-center bg-white border-2 border-dashed border-gray-400',
      {
        'opacity-60': disabled,
      },
    ])}
    style={{ width: '100%', height: 160, fontSize: '2em' }}
  >
    <Plus width="20" height="20" className="fill-primary" />
  </button>
)

type Props = {
  mimeType: MimeType
  onSelect: ({
    base64String,
    mime,
  }: {
    base64String: string
    mime: string
  }) => void
  label?: string
  note?: string
}

const FileUploader = ({ onSelect, mimeType, label, note }: Props) => {
  const toast = useToast()
  const { log } = useLogger()

  return (
    <>
      {label && <div className="heading-3 text-primary pb-2">{label}</div>}
      <Empty
        onClick={async () => {
          try {
            const response = await FilePicker(mimeType)
            onSelect(response)
          } catch (error) {
            log('error', 'File Picker', {
              error,
            })

            toast.notify({
              type: 'failure',
              message: GENERIC_ERROR_MESSAGE,
            })
          }
        }}
      />
      {note && <p className="body-3 text-gray-700 pt-2">{note}</p>}
    </>
  )
}

export default FileUploader
