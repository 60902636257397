import React from 'react'
import cc from 'classcat'
import Button, { Type } from 'components/Button'
import Container from 'components/Container'
import patternMatching from 'utils/patternMatching'

export type CTA = {
  text: React.ReactNode
  onClick: () => void
  $type?: Type
  dataTestId?: string
}

type Color = 'white' | 'green' | 'red' | 'purple' | 'light'

type Props = {
  Icon: any // TODO: Need SVG import component type
  iconStyle?: { width: string; heigth: string; margin?: string }
  title: React.ReactNode
  description: React.ReactNode
  ctas?: CTA[]
  $color: Color
}

const typeToBgColor = patternMatching<Color, string>([
  ['light', ''],
  ['white', 'bg-white'],
  ['green', 'bg-green'],
  ['red', 'bg-red-600'],
  ['purple', 'bg-primary'],
])

const typeToTextColor = patternMatching<Color, string>([
  ['light', 'text-primary'],
  ['white', 'text-primary'],
  ['green', 'text-white'],
  ['red', 'text-white'],
  ['purple', 'text-white'],
])

const typeToFillColor = patternMatching<Color, string>([
  ['light', 'fill-primary'],
  ['white', 'fill-primary'],
  ['green', 'fill-white'],
  ['red', 'fill-white'],
  ['purple', 'fill-white'],
])

const CallToAction = ({
  Icon,
  iconStyle,
  title,
  description,
  ctas,
  $color,
}: Props) => {
  const bgColor = typeToBgColor($color)
  const textColor = typeToTextColor($color)
  const fillColor = typeToFillColor($color)

  return (
    <div
      className={cc([
        'text-center h-full flex justify-center items-center',
        bgColor,
        textColor,
      ])}
    >
      <Container topBottomSpace>
        {/* Icon */}
        <div className="flex justify-center">
          <Icon
            className={cc([
              'w-16 h-16',
              fillColor,
              iconStyle?.width,
              iconStyle?.heigth,
              iconStyle?.margin,
            ])}
          />
        </div>

        <div className="py-8">
          {/* Title */}
          <div className="heading-1">{title}</div>

          {/* Description */}
          <div className="pt-2">{description}</div>
        </div>

        {/* CTAS */}
        <ul>
          {ctas?.map(({ text, dataTestId, ...rest }, i) => (
            <li key={`cta-${i}`} className="py-2">
              <Button
                type="button"
                $fluid
                $inverted={$color !== 'white' && $color !== 'light'}
                {...rest}
                data-testid={dataTestId || 'button-call-to-action'}
              >
                {text}
              </Button>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

CallToAction.defaultProps = {
  $color: 'white',
}

export default CallToAction
