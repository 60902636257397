// import { history } from 'clients/navigation.client'
import { Link } from '@reach/router'
import { useModalScreen } from 'components/ModalScreen'
import { WithNotOptimizedForWebapp } from 'components/NotOptimizedForWebapp'
import Classes from 'screens/Classes'
// import FindTeachersStudios from 'screens/FindTeachersStudios'
import Retreats from 'screens/Retreats'
import TeacherTraining from 'screens/TeacherTraining'
import { isWebapp } from 'utils/env'
// import { isWebapp } from 'utils/env'

const Search = () => {
  const modal = useModalScreen()

  const OPTIONS = [
    {
      id: 'classes',
      label: 'Classes',
      Component: Classes,
      to: '/search/classes',
      onClick: () => {
        modal.open({
          body: <Classes closeModal={modal.close} />,
        })
      },
    },
    {
      id: 'retreats',
      label: 'Retreats',
      to: '/search/retreats',
      onClick: () => {
        modal.open({
          header: 'Retreats',
          body: <Retreats />,
        })
      },
    },
    {
      id: 'teacher-training',
      label: 'Teacher Training',
      to: '/search/teacher-training',
      onClick: () => {
        modal.open({
          header: 'Teacher Training',
          body: <TeacherTraining />,
        })
      },
    },
    // {
    //   id: 'find-teachers-studios',
    //   label: 'Find Teachers & Studios',
    //   onClick: () => {
    //     if (isWebapp()) {
    //       history.navigate('/search/teachers-studios')
    //     } else {
    //       modal.open({
    //         header: 'Find Teachers & Studios',
    //         body: <FindTeachersStudios />,
    //       })
    //     }
    //   },
    // },
  ]

  return (
    <div className="flex items-center justify-center h-full">
      <div>
        <h1 className="heading-1 text-primary pb-8">
          What are you looking for?
        </h1>
        <ul className="grid gap-6">
          {OPTIONS.map(({ id, label, to, onClick }) => {
            const className =
              'block w-full px-4 py-6 bg-white shadow-lg heading-2 text-center text-primary active:bg-gray-100'
            return (
              <li key={id}>
                {isWebapp() ? (
                  <Link to={to || '/'} className={className}>
                    {label}
                  </Link>
                ) : (
                  <button className={className} onClick={onClick}>
                    {label}
                  </button>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default WithNotOptimizedForWebapp(Search)
