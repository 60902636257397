import { Safety_Guideline } from 'generated/graphql'
import { Fragment } from 'react'
import SafetyIcon from 'components/SafetyIcon'

type Props = {
  list: Pick<Safety_Guideline, 'id' | 'label' | 'description'>[]
}

const SafetyGuidelinesList = ({ list }: Props) => {
  return (
    <ul className="space-y-6">
      {list.map(({ id, label, description }) => (
        <Fragment key={`safety-guideline-list-item_${id}`}>
          <li>
            <span className="flex items-center">
              <SafetyIcon id={id} className="w-4 mr-2" />
              <span className="heading-3">{label}</span>
            </span>
            <span className="block pt-2 text-gray-600">{description}</span>
          </li>
          <li>
            <hr className="border-gray-400" />
          </li>
        </Fragment>
      ))}
    </ul>
  )
}

export default SafetyGuidelinesList
