import { Link } from '@reach/router'
import { isWebapp } from 'utils/env'

type Props = {
  to?: string
  children: any
  onClick: () => void
  className?: string
  style?: any
}

const CrossLink = ({ onClick, to, ...props }: Props) => {
  return (
    <Link
      data-testid="cross-link"
      {...props}
      to={to || ''}
      onClick={(event) => {
        if (!to || !isWebapp()) {
          event.preventDefault()
          onClick()
        }
      }}
    />
  )
}

export default CrossLink
