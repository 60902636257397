import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { FormGroup, Label } from 'components/FormField'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import Button from 'components/Button'
import { ApolloError, useApolloClient } from '@apollo/client'
import ServerErrorMessage from 'components/ServerErrorMessage'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import * as Validation from 'utils/formFieldValidations'
import PhoneNumberField from 'formFields/PhoneNumberField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import MultiPictureUpload, {
  MultiPictureUploadContext,
} from 'components/MultiPictureUpload'

type InitialValues = {
  firstName?: string
  lastName?: string
  username?: string
  email?: string
  picture?: string | null
  phoneNumber?: string | null
  bio?: string
  cover_picture?: string | null
  contactPhoneNumber?: string | null
}

interface Props {
  buttonText: string
  onSubmit: (values: InitialValues) => void
  state: {
    loading?: boolean
    error?: ApolloError | undefined
  }
  initialValues: InitialValues
}

const FormTeacher = ({ buttonText, onSubmit, state, initialValues }: Props) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    phoneNumber: Validation.uniquePhoneNumber(
      client,
      initialValues.phoneNumber,
    ),
    username: Validation.username(client, initialValues.username),
    email: Validation.uniqueEmail(client, initialValues.email),
    picture: yup.string().optional(),
    bio: Validation.longText,
    cover_picture: yup.string().optional(),
    contactPhoneNumber: Validation.phoneNumber,
  })
  const formMethods = useForm({
    defaultValues: {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      username: initialValues.username || '',
      email: initialValues.email || '',
      picture: initialValues.picture || '',
      phoneNumber: initialValues.phoneNumber || '',
      bio: initialValues.bio || '',
      cover_picture: initialValues.cover_picture || '',
      contactPhoneNumber: initialValues.contactPhoneNumber || '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, errors, control } = formMethods
  const [disableButton, setDisableButton] = useState(false)

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup title="Private" subtitle="login and notifications purposes">
          {/* Email */}
          <FormField
            type="text"
            label="Email"
            name="email"
            register={register}
            error={errors?.email?.message}
          />

          {/* Phone Number */}
          <PhoneNumberField
            label="Phone Number"
            name="phoneNumber"
            control={control}
          />
        </FormGroup>

        <hr className="border-gray-400 my-6" />

        <FormGroup title="Public">
          {/* First Name */}
          <FormField
            type="text"
            label="First Name"
            name="firstName"
            register={register}
            error={errors?.firstName?.message}
          />

          {/* Last Name */}
          <FormField
            type="text"
            label="Last Name"
            name="lastName"
            register={register}
            error={errors?.lastName?.message}
          />

          {/* Username */}
          <FormField
            type="text"
            label="Username"
            name="username"
            register={register}
            error={errors?.username?.message}
          />

          <PhoneNumberField
            label="Public Contact Phone Number"
            name="contactPhoneNumber"
            control={control}
            sublabel="This is for your Students to contact you for emergencies/problems."
          />

          <FormTextWithCounter name="bio" label="Bio" $inputClassName="h-56" />

          {/* PICTURE */}
          <div className="py-4">
            <Label>Cover Picture</Label>
            <input type="hidden" name="cover_picture" ref={register} />
            <MultiPictureUploadContext
              limit={1}
              initialPictures={
                initialValues?.cover_picture
                  ? [initialValues.cover_picture]
                  : undefined
              }
              onChange={(change) => {
                setDisableButton(change)
              }}
            >
              <MultiPictureUpload
                onDone={(value) => {
                  const picture = value.map((x) => x.url)[0]

                  formMethods.setValue('cover_picture', picture)
                  setDisableButton(false)
                }}
              />
            </MultiPictureUploadContext>
          </div>
        </FormGroup>

        {/* ERROR */}
        {state.error && (
          <ServerErrorMessage>{state.error.message}</ServerErrorMessage>
        )}

        {/* CREATE */}
        <div className="pt-6">
          <Button
            type="submit"
            $type="primary"
            $fluid
            loading={state.loading}
            disabled={disableButton}
          >
            {buttonText}
          </Button>
        </div>

        <SpaceForKeyboard $size="lg" />
      </form>
    </FormProvider>
  )
}

FormTeacher.defaultProps = {
  buttonText: 'Submit',
}

export default FormTeacher
