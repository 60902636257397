import Container from 'components/Container'
import { withContext } from './classContext'
import PicturesCarousel from './PicturesCarousel'
import ClassTabs from './Tabs'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { StudioInfoDocument, StudioInfoQuery } from 'generated/graphql'
import { useQuery } from 'urql'

const StudioInfo = withContext(({ clazz }) => {
  // NOTE: Can't use GenericQueryRender because of 'PicturesCarousel'
  const [{ fetching, data, error }] = useQuery<StudioInfoQuery>({
    query: StudioInfoDocument,
    variables: {
      id: clazz.account_id,
    },
  })

  return (
    <>
      {/* Header */}
      <PicturesCarousel
        pictures={data?.account_by_pk ? data.account_by_pk.studio_pictures : []}
        loading={fetching}
      />
      <ClassTabs />

      {/* Body */}
      <Container xSpace topSpace className="flex-grow">
        {fetching ? (
          <Loading />
        ) : error ? (
          <ServerErrorMessage>{error.message}</ServerErrorMessage>
        ) : (
          <>
            <h1 className="heading-1 text-primary pb-2">
              {data?.account_by_pk?.entity?.name}
            </h1>
            <p>{data?.account_by_pk?.entity?.bio}</p>
          </>
        )}
      </Container>
    </>
  )
})

export default StudioInfo
