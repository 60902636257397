import { createHistory, createMemorySource } from '@reach/router'
import isBrowser from 'utils/isBrowser'

export const history = createHistory(
  isBrowser() ? (window as any) : createMemorySource('/'),
)

// TODO: Document why this is needed

export const navigate = history.navigate
