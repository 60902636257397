import React from 'react'

type Props = {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  children: React.ReactNode
}

const InfoBox = ({ Icon, children }: Props) => (
  <div className="flex bg-white p-4">
    <div className="pr-4 pt-1">
      <Icon width="20" className="fill-primary" />
    </div>
    <p>{children}</p>
  </div>
)

export default InfoBox
