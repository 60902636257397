import { Control } from 'react-hook-form'
import FormField, { Props as FormFieldProps } from 'components/FormField'

type Props = {
  name: string
  control: Control
} & Omit<FormFieldProps, 'type'>

const PhoneNumberField = (props: Props) => (
  <FormField
    {...props}
    type="mask"
    prefix="+1"
    maskChar="X"
    mask="999 999 9999"
    error={props.control.formStateRef.current.errors?.[props.name]?.message}
    inputMode="numeric"
    data-testid="input-phone-number"
  />
)

export default PhoneNumberField
