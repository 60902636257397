import React from 'react'
import cc from 'classcat'
import moment from 'moment'

import styles from './index.module.scss'
import { Control, Controller } from 'react-hook-form'

const TOTAL_DAYS = 7
const DAYS = new Array(TOTAL_DAYS).fill(0).map((_v, i) => i)

const initialState = (initialDays: number[]) =>
  new Array(TOTAL_DAYS).fill(null).map((_v, i) => initialDays.includes(i))

interface SelectorProps {
  value?: string
  onChange?: (activeDays: string) => void
}

const Selector = ({ value, onChange }: SelectorProps) => {
  const m = moment()

  const [activeDays, setActiveDays] = React.useState(
    initialState(
      value && value.length > 0
        ? value.split(',').map((v) => parseInt(v, 10))
        : [],
    ),
  )

  const setDay = (dayNumber: number) => {
    const newState = [...activeDays]

    newState[dayNumber] = !newState[dayNumber]

    setActiveDays(newState)

    if (onChange) {
      onChange(
        newState
          .map((v, i) => (v ? i : -1))
          .filter((v) => v >= 0)
          .join(','),
      )
    }
  }

  return (
    <ul className="flex justify-between">
      {DAYS.map((dayNumber) => {
        const text = m.day(dayNumber).format('dd')[0]
        const isActive = activeDays[dayNumber]

        return (
          <li
            key={`days-selector-day-${dayNumber}`}
            className="text-center"
            data-testid={`day-${dayNumber}`}
          >
            <button
              type="button"
              className={cc([
                styles.button,
                'inline-flex justify-center items-center border-2',
                {
                  'border-gray-500 text-gray-500': !isActive,
                  'bg-primary text-white': isActive,
                },
              ])}
              onClick={() => {
                setDay(dayNumber)
              }}
            >
              {text}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

type Props = {
  name: string
  control: Control
  defaultValue?: string
}

const DaysSelector = ({ name, control, defaultValue }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <Selector value={value || defaultValue} onChange={onChange} />
      )}
    />
  )
}

export default DaysSelector
