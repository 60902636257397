/*
File is called "globalConstants" and not "constants" because there's a package
called "constants", and importing it by "import {} from 'constants'", as alias,
imports the npm package instead of the file
*/
import moment from 'moment'
import { TimeOfTheDay } from 'types'

export const GRAPHQL_HTTP_URL = process.env.REACT_APP_GRAPHQL_URL as string
export const GRAPHQL_WS_URL = process.env.REACT_APP_GRAPHQL_WS_URL as string

export const SHORT_DOMAIN = 'ohm.link'

// SHARED with BE

export const DB_DATE_FORMAT = 'YYYY-MM-DD'

export const DB_TIME_FORMAT = 'HH:mm:ssZZ'

export const DB_TIME_FORMAT_WITHOUT_TZ = 'HH:mm:ss'

export const SELECT_TIME_FORMAT = 'HH:mm'

export const INACTIVE_ACCOUNT_KEY = {
  account: 'INACTIVE_ACCOUNT',
  accountVerification: 'UNVERIFIED_ACCOUNT',
  company: 'MISSING_COMPANY',
  address: 'MISSING_COMPANY_ADDRESS',
  businessProfile: 'MISSING_BUSINESS_PROFILE',
  termsAndConditions: 'MISSING_TERMS_AND_CONDITIONS',
  externalAccount: 'MISSING_EXTERNAL_ACCOUNTS',
  representative: 'MISSING_REPRESENTATIVE',
  owners: 'MISSING_OWNERS',
  individual: 'MISSING_INDIVIDUAL',
  teachingDocuments: 'MISSING_TEACHING_DOCUMENTS',
  legalInformation: 'MISSING_LEGAL_INFORMATION',
}

export const FRIENDLY_TIME_FORMAT = 'hh:mmA'

export const FRIENDLY_DATE_FORMAT_CALENDAR = {
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameDay: '[Today], MMM DD',
  nextDay: '[Tomorrow], MMM DD',
  nextWeek: 'dddd, MMM DD',
  sameElse: 'dddd, MMM DD',
}

export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please try again. If the error persists, please contact support.'

export const USA_STATES = [
  { name: 'Alabama', abrv: 'AL' },
  { name: 'Alaska', abrv: 'AK' },
  { name: 'Arizona', abrv: 'AZ' },
  { name: 'Arkansas', abrv: 'AR' },
  { name: 'California', abrv: 'CA' },
  { name: 'Colorado', abrv: 'CO' },
  { name: 'Connecticut', abrv: 'CT' },
  { name: 'Delaware', abrv: 'DE' },
  { name: 'Florida', abrv: 'FL' },
  { name: 'Georgia', abrv: 'GA' },
  { name: 'Hawaii', abrv: 'HI' },
  { name: 'Idaho', abrv: 'ID' },
  { name: 'Illinois', abrv: 'IL' },
  { name: 'Indiana', abrv: 'IN' },
  { name: 'Iowa', abrv: 'IA' },
  { name: 'Kansas', abrv: 'KS' },
  { name: 'Kentucky', abrv: 'KY' },
  { name: 'Louisiana', abrv: 'LA' },
  { name: 'Maine', abrv: 'ME' },
  { name: 'Maryland', abrv: 'MD' },
  { name: 'Massachusetts', abrv: 'MA' },
  { name: 'Michigan', abrv: 'MI' },
  { name: 'Minnesota', abrv: 'MN' },
  { name: 'Mississippi', abrv: 'MS' },
  { name: 'Missouri', abrv: 'MO' },
  { name: 'Montana', abrv: 'MT' },
  { name: 'Nebraska', abrv: 'NE' },
  { name: 'Nevada', abrv: 'NV' },
  { name: 'New Hampshire', abrv: 'NH' },
  { name: 'New Jersey', abrv: 'NJ' },
  { name: 'New Mexico', abrv: 'NM' },
  { name: 'New York', abrv: 'NY' },
  { name: 'North Carolina', abrv: 'NC' },
  { name: 'North Dakota', abrv: 'ND' },
  { name: 'Ohio', abrv: 'OH' },
  { name: 'Oklahoma', abrv: 'OK' },
  { name: 'Oregon', abrv: 'OR' },
  { name: 'Pennsylvania', abrv: 'PA' },
  { name: 'Rhode Island', abrv: 'RI' },
  { name: 'South Carolina', abrv: 'SC' },
  { name: 'South Dakota', abrv: 'SD' },
  { name: 'Tennessee', abrv: 'TN' },
  { name: 'Texas', abrv: 'TX' },
  { name: 'Utah', abrv: 'UT' },
  { name: 'Vermont', abrv: 'VT' },
  { name: 'Virginia', abrv: 'VA' },
  { name: 'Washington', abrv: 'WA' },
  { name: 'West Virginia', abrv: 'WV' },
  { name: 'Wisconsin', abrv: 'WI' },
  { name: 'Wyoming', abrv: 'WY' },
]

export const LEGAL_PERSON_AGE = 18

export const SUPPORT_PAGE_URL = 'https://ohmunity.tawk.help/'

export const TAWK_ID = '6084df5d5eb20e09cf364d16/1f43gh1r4'

export const MASK_CREDIT_CARD = {
  amex: '9999-999999-99999',
  other: '9999-9999-9999-9999',
}

export const REGEX_IS_AMEX_CARD = /^3[47]/

export const CREDIT_CARD_LENGTH = {
  amex: 15,
  other: 16,
}

export const CLASS_LEVELS = [
  { id: 'beginner', text: 'Beginner' },
  { id: 'intermediate', text: 'Intermediate' },
  { id: 'advanced', text: 'Advanced' },
]

export const CLASS_SETTINGS = [
  { id: 'outdoor', text: 'Outdoor' },
  { id: 'in-studio', text: 'In Studio' },
]

export const TIME_OF_THE_DAY_RANGE: {
  [key in TimeOfTheDay]: [string, string]
} = {
  morning: [
    moment('04:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
    moment('12:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
  ],
  afternoon: [
    moment('12:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
    moment('18:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
  ],
  evening: [
    moment('18:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
    moment('04:00:00', 'HH:mm:ss').utc().format(DB_TIME_FORMAT),
  ],
}

export const SEARCH_RESULTS_LIMIT = 10

export const SEARCH_CLASS_DISTANCE_IN_MILES = 10

export const PREFERENCES_STORE_KEY = 'preferences'

export const LONG_TEXT_BOUNDARIES = {
  MIN: 20,
  MAX: 200,
}

export const FEE_VIRTUAL = 1

export const FEE_SERVICE_IN_PERCENTAGE = 10

export const CLASS_BOOK_CANCEL_LIMIT_TIME_IN_HOURS = 24

export const APP_CRASHED_MESSAGE = `The App just crashed 😔. Please contact us via email for more information: hello@ohmunity.com`

export const TRIPANEER_RETREATS_AFFILIATE_ID = 6883
export const TRIPANEER_TEACHER_TRAINING_AFFILIATE_ID = 6896

export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/ohmunity/id1552797145'
export const GOOGLE_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.ohmunity.app'
