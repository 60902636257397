import { Values as SortFilterValues } from 'screens/SortAndFilter'

/**
 * Converts 'sortBy' tuple to Hasura filter
 * @param sortBy
 */
const sortToOrderBy = (
  sortBy?: SortFilterValues['sortBy'],
): Record<string, any> | undefined => {
  if (!sortBy) {
    return undefined
  }

  const [field, direction] = sortBy

  switch (field) {
    case 'price': {
      return {
        price: direction,
      }
    }
    case 'rating': {
      return {
        reviews_aggregate: {
          avg: {
            value: direction,
          },
        },
      }
    }
  }
}

export default sortToOrderBy
