import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import * as yup from 'yup'
import Explore from 'screens/Explore'
import Signup from 'screens/Signup'
import Logout from 'screens/Logout'
import Search from 'screens/Search'
import Favorites from 'screens/Favorites'
import Account from 'screens/Account'
import NotFound from 'screens/NotFound'
import MyClasses from 'screens/MyClasses'
import Toolbar from 'components/Toolbar'
import Page from 'components/Page'
import styles from './Router.module.scss'
import { useAuth } from 'stores/auth.store'
import { Location, Router as ReachRouter, useLocation } from '@reach/router'
import { match } from '@reach/router/lib/utils'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import {
  Account_Role_Enum,
  AgreeToTermsDocument,
  GetTermsDocument,
  GetTermsQuery,
} from 'generated/graphql'
import { useGlobalProvider } from 'stores/global.store'
import { OhmRoute } from 'types'
import BookedClasses from 'screens/BookedClasses'
import useMode from 'hooks/useMode'
import Admin from 'screens/Admin'
import makeStorage from 'utils/makeStorage'
import Intro from 'screens/Intro'
import useAnalytics from 'hooks/useAnalytics'
import { APP_CRASHED_MESSAGE } from 'globalConstants'
import { useClient, useQuery } from 'urql'
import { useAlert } from 'components/AlertModal'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import { FormGroup } from 'components/FormField'
import Button from 'components/Button'
import useLogger from 'hooks/useLogger'
import { isWebapp } from 'utils/env'
import { WithNotOptimizedForWebapp } from 'components/NotOptimizedForWebapp'
import WebHeader from 'components/WebHeader'
import EditProfile from 'screens/EditProfile'
import Notifications from 'screens/Notifications'
import Billing from 'screens/Billing'
import StudioPictures from 'screens/StudioPictures'
import StudioTeachers from 'screens/StudioTeachers'
import LegalInformation from 'screens/LegalInformation'
import Locations from 'screens/Locations'
import { WithWebBackLayout } from 'components/WebBackLayout'
import { ClassDetailsScreenFromRoute } from 'screens/ClassDetails'
import FindTeachersStudios from 'screens/FindTeachersStudios'
import UserProfile from 'screens/UserProfile'
import Classes from 'screens/Classes'
import Retreats from 'screens/Retreats'
import TeacherTraining from 'screens/TeacherTraining'
import RedirectPage from 'screens/RedirectPage'

const notFoundRoute = {
  id: 'not-found',
  name: 'Not Found',
  default: true,
  Component: NotFound,
}

const DEFAULT_APP_ROUTES: OhmRoute[] = [
  {
    id: 'logout',
    name: 'Logout',
    path: '/logout',
    Component: Logout,
  },
  {
    id: 'explore',
    name: 'Explore',
    path: '/',
    Component: WithNotOptimizedForWebapp(Explore),
  },
  {
    id: 'account',
    name: 'Account',
    path: '/account',
    Component: Account,
  },
  {
    id: 'classDetails',
    name: 'Class Details',
    path: '/class/:classID/:dateTimeID',
    Component: ClassDetailsScreenFromRoute,
  },
  {
    id: 'explore-detail',
    name: 'Explore X',
    path: '/explore/:subpage',
    Component: Explore,
  },
  {
    id: 'admin',
    name: 'Admin',
    path: '/admin',
    Component: Admin,
  },
  {
    id: 'user-profile',
    name: 'User Profile',
    path: '/u/:username', // DEPRECATED
    Component: () => <RedirectPage from="/u/:username" to="/:username" />,
  },
  {
    id: 'search-teachers-studios',
    name: 'Find Teachers & Studios',
    path: '/search/teachers-studios',
    Component: WithWebBackLayout(FindTeachersStudios),
  },
  {
    id: 'search-classes',
    name: 'Classes',
    path: '/search/classes',
    Component: WithWebBackLayout(Classes) as any,
  },
  {
    id: 'search-retreats',
    name: 'Retreats',
    path: '/search/retreats',
    Component: WithWebBackLayout(Retreats),
  },
  {
    id: 'search-teacher-training',
    name: 'Teacher Training',
    path: '/search/teacher-training',
    Component: WithWebBackLayout(TeacherTraining),
  },
]

const ANONYMOUS_ROUTES: OhmRoute[] = [
  {
    id: 'signup',
    name: 'Signup',
    path: '/signup/*',
    Component: Signup,
    hideToolbar: isWebapp(),
  },
]

const WELCOME_ROUTES: OhmRoute[] = [
  {
    id: 'welcome',
    name: 'Welcome',
    path: '/*',
    Component: Signup,
    hideToolbar: isWebapp(),
  },
  notFoundRoute,
]

const INTRO_ROUTES: OhmRoute[] = [
  {
    id: 'welcome',
    name: 'Welcome',
    path: '/',
    Component: Intro,
    hideToolbar: true,
  },
  notFoundRoute,
]

const WEBAPP_LOGGEDIN_ROUTES: OhmRoute[] = [
  {
    id: 'manageProfile',
    name: 'Manange Profile',
    path: '/account/manage-profile',
    Component: WithWebBackLayout(EditProfile),
  },
  {
    id: 'locations',
    name: 'Locations',
    path: '/account/locations',
    Component: WithWebBackLayout(Locations),
  },
  {
    id: 'legalInformation',
    name: 'Legal Information',
    path: '/account/legal-information',
    Component: WithWebBackLayout(LegalInformation),
  },
  {
    id: 'billing',
    name: 'Billing',
    path: '/account/billing',
    Component: WithWebBackLayout(Billing),
  },
  {
    id: 'notifications',
    name: 'Notifications',
    path: '/account/notifications',
    Component: WithWebBackLayout(Notifications as any) as any,
  },
]

export const useRoutes = (): OhmRoute[] => {
  const { isAuthenticated, account } = useAuth()
  const [mode] = useMode()
  const ROUTES = isAuthenticated()
    ? [
        ...DEFAULT_APP_ROUTES,
        ...(isWebapp() ? WEBAPP_LOGGEDIN_ROUTES : []),
        {
          id: 'user-profile',
          name: 'User Profile',
          path: '/:username',
          Component: UserProfile,
        },
        {
          id: 'not-found',
          name: 'Not Found',
          default: true,
          Component: NotFound,
        },
      ]
    : [
        ...DEFAULT_APP_ROUTES,
        ...ANONYMOUS_ROUTES,
        {
          id: 'user-profile',
          name: 'User Profile',
          path: '/:username',
          Component: UserProfile,
          hideWebHeader: true,
        },
        {
          id: 'not-found',
          name: 'Not Found',
          default: true,
          Component: NotFound,
        },
      ]

  switch (account.type) {
    case Account_Role_Enum.Student: {
      return [
        ...(process.env.REACT_APP_STAGE === 'alpha-1'
          ? []
          : [
              {
                id: 'search',
                name: 'Studio Classes',
                path: '/search',
                Component: Search,
              },
            ]),
        {
          id: 'myClasses',
          name: 'My Classes',
          path: '/myClasses',
          Component: MyClasses,
        },
        {
          id: 'favorites',
          name: 'Favorites',
          path: '/account/favorites',
          Component: WithWebBackLayout(Favorites),
        },
        ...ROUTES,
      ]
    }
    case Account_Role_Enum.Teacher: {
      if (mode === 'teach') {
        return [
          {
            id: 'bookedClasses',
            name: 'Booked Classes',
            path: '/bookedClasses',
            Component: BookedClasses,
          },
          {
            id: 'myClasses',
            name: 'My Classes',
            path: '/myClasses',
            Component: MyClasses,
          },
          ...ROUTES,
        ]
      }

      return [
        ...(process.env.REACT_APP_STAGE === 'alpha-1'
          ? []
          : [
              {
                id: 'search',
                name: 'Studio Classes',
                path: '/search',
                Component: Search,
              },
            ]),
        {
          id: 'myClasses',
          name: 'My Classes',
          path: '/myClasses',
          Component: MyClasses,
        },
        {
          id: 'favorites',
          name: 'Favorites',
          path: '/favorites',
          Component: Favorites,
        },
        ...ROUTES,
      ]
    }
    case Account_Role_Enum.Studio: {
      return [
        {
          id: 'bookedClasses',
          name: 'Booked Classes',
          path: '/bookedClasses',
          Component: BookedClasses,
        },
        {
          id: 'myClasses',
          name: 'My Classes',
          path: '/myClasses',
          Component: MyClasses,
        },
        {
          id: 'studioPhotos',
          name: 'Studio Photos',
          path: '/account/studio-photos',
          Component: WithWebBackLayout(StudioPictures),
        },
        {
          id: 'teachers',
          name: 'Teachers',
          path: '/account/teachers',
          Component: WithWebBackLayout(StudioTeachers),
        },
        ...ROUTES,
      ]
    }
    default:
      return [
        ...(process.env.REACT_APP_STAGE === 'alpha-1'
          ? []
          : [
              {
                id: 'search',
                name: 'Studio Classes',
                path: '/search',
                Component: Search,
              },
            ]),
        ...ROUTES,
      ]
  }
}

export const arrayToJSX = (arr: any) =>
  arr.map(({ Component, name, ...props }: any) => (
    <Component key={name} {...props} />
  ))

const Identity = ({ children }: any) => children

const FadeTransitionRouter = ({ children }: any) => (
  <Location>
    {({ location }) => {
      if (location.state && (location.state as any).disableAnimation) {
        return (
          <ReachRouter
            location={location}
            primary={false}
            component={isWebapp() ? Identity : Page.Content}
          >
            {children}
          </ReachRouter>
        )
      }

      return (
        <SwitchTransition>
          <CSSTransition
            key={location.key}
            classNames={styles}
            timeout={100}
            appear
          >
            <ReachRouter
              location={location}
              primary={false}
              component={isWebapp() ? Identity : Page.Content}
            >
              {children}
            </ReachRouter>
          </CSSTransition>
        </SwitchTransition>
      )
    }}
  </Location>
)

const AppRouter = Sentry.withErrorBoundary(
  () => {
    const routes = useRoutes()
    const { pathname } = useLocation()
    const { isAuthenticated } = useAuth()
    const shouldRenderHeader = routes.some(
      (x) => x.path && match(x.path, pathname) && !x.hideWebHeader,
    )

    useEffect(() => {
      const body = document.querySelector('body')

      if (body) {
        if (shouldRenderHeader) {
          body.style.paddingTop = '90px'
        } else {
          body.style.paddingTop = '0px'
        }
      }
    }, [shouldRenderHeader])

    if (isWebapp()) {
      return (
        <>
          {shouldRenderHeader && <WebHeader />}
          <FadeTransitionRouter>{arrayToJSX(routes)}</FadeTransitionRouter>
        </>
      )
    }

    return (
      <Page>
        <FadeTransitionRouter>{arrayToJSX(routes)}</FadeTransitionRouter>

        {routes.some(
          (x) => x.path && match(x.path, pathname) && !x.hideToolbar,
        ) && (
          <Page.Footer>
            <Toolbar />
          </Page.Footer>
        )}

        {isAuthenticated() && <CheckTermsAndConditions />}
      </Page>
    )
  },
  {
    fallback: APP_CRASHED_MESSAGE,
    beforeCapture: (scope) => {
      scope.setExtra('component', 'AppRouter')
    },
  },
)

const CheckTermsAndConditions = () => {
  const alertModal = useAlert()
  const [state] = useQuery<GetTermsQuery>({
    query: GetTermsDocument,
    requestPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (state.data && !state.data.terms.agreed) {
      alertModal.send({
        title: 'Terms have changed.',
        body: <AcceptTermsAndConditions dismiss={alertModal.dismiss} />,
        buttons: [],
      })
    }
  }, [state.data])

  return null
}

const AcceptTermsAndConditions = ({ dismiss }: { dismiss: () => void }) => {
  const client = useClient()
  const { log } = useLogger()
  const validationSchema = yup.object().shape({
    termsAndConditions: yup
      .boolean()
      .required()
      .test(
        'isChecked',
        'You need to read and agree on our Terms and Conditions.',
        (value) => value === true,
      ),
  })
  const formMethods = useForm({
    defaultValues: {
      termsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, control, formState } = formMethods
  const { isSubmitting } = formState

  return (
    <form
      onSubmit={handleSubmit(async (values) => {
        try {
          const response = await client
            .mutation(AgreeToTermsDocument)
            .toPromise()

          if (response.error || !response.data) {
            log('error', 'Agree to New Terms', {
              error: response.error,
              data: response.data,
            })
          }
        } catch (error) {
          log('error', 'Agree to New Terms', {
            error,
          })
        }

        dismiss()
      })}
    >
      <FormGroup>
        <p>
          Our Terms and Conditions and Privacy Policy have changed. Please take
          a moment to read them.
        </p>

        <div className="text-left">
          <TermsAndConditionsField
            control={control}
            name="termsAndConditions"
          />
        </div>

        <Button $fluid loading={isSubmitting}>
          Accept
        </Button>
      </FormGroup>
    </form>
  )
}

const introStorage = makeStorage('intro-seen')

const WelcomeRouter = Sentry.withErrorBoundary(
  () => {
    return (
      <FadeTransitionRouter>
        {arrayToJSX(introStorage.get() ? WELCOME_ROUTES : INTRO_ROUTES)}
      </FadeTransitionRouter>
    )
  },
  {
    fallback: APP_CRASHED_MESSAGE,
    beforeCapture: (scope) => {
      scope.setExtra('component', 'WelcomeRouter')
    },
  },
)

const Router = () => {
  const { pathname } = useLocation()
  const { preferences } = useGlobalProvider()
  const { isAuthenticated } = useAuth()

  useAnalytics()

  // Forcing start from top
  useEffect(
    () =>
      document.getElementById('scrollable-content')?.scrollTo({
        top: 0,
        left: 0,
      }),
    [pathname],
  )

  if (isWebapp() || preferences || isAuthenticated()) {
    return <AppRouter />
  } else {
    return <WelcomeRouter />
  }
}

export default Sentry.withErrorBoundary(Router, {
  fallback: APP_CRASHED_MESSAGE,
  beforeCapture: (scope) => {
    scope.setExtra('component', 'Router')
  },
})

/*
<SpaceForKeyboard /> not needed in this file
*/
