import getPersonName from 'utils/getPersonName'
import Container from 'components/Container'
import InspirationalReads from 'components/InspirationalReads'
import ModeSwitch from 'components/ModeSwitch'
import ThingsTodo from 'components/ThingsTodo'
import { useAuth } from 'stores/auth.store'
import {
  AccountStatusDocument,
  Account_Role_Enum,
  DailyTipDocument,
} from 'generated/graphql'
import { Entity, Person } from 'types'
import { useQuery } from 'urql'
import useLogger from 'hooks/useLogger'
import GenericQueryRender from 'components/GenericQueryRender'
import patternMatching from 'utils/patternMatching'
import { Swiper, SwiperSlide } from 'swiper/react'
import { sortBy } from 'lodash/fp'
import lottieSwipeLeftJson from 'assets/lottie/7635-swipe-left.json'
import Lottie from 'components/Lottie'
import useMode from 'hooks/useMode'
import { useState } from 'react'
import { navigate } from 'clients/navigation.client'

const Explore = () => {
  const { isAuthenticated, isStudent, isStudio, isTeacher, account } = useAuth()
  const [helloClicks, setHelloClicks] = useState(0)

  return (
    <>
      <div className="safe-area-pt" />
      {isTeacher() && <ModeSwitch />}
      <Container topBottomSpace>
        <h1
          className="heading-1 text-primary"
          onClick={() => {
            if (helloClicks === 3) {
              navigate('/admin')
            } else if (
              account.profile?.email &&
              account.profile.email.endsWith('@ohmunity.com')
            ) {
              setHelloClicks((count) => count + 1)
            }
          }}
        >
          Hello{' '}
          {isAuthenticated()
            ? isStudio()
              ? (account.profile as Entity).name
              : getPersonName(account.profile as Person)
            : ''}
        </h1>

        <div className="pb-6">
          <p className="py-2">Welcome to Ohmunity™</p>
        </div>

        {!isAuthenticated() ? (
          <Anonymous />
        ) : isStudent() || isTeacher() ? (
          <Student />
        ) : isStudio() ? (
          <Studio />
        ) : null}

        <div className="pt-4">
          <DailyTip />
        </div>

        <div className="pt-4">
          <InspirationalReads />
        </div>
      </Container>
    </>
  )
}

export default Explore

const Student = () => {
  return (
    <div>
      <MissingThings />
    </div>
  )
}

const Studio = () => {
  return (
    <div>
      <MissingThings />
    </div>
  )
}

const Anonymous = () => <div />

const MissingThings = () => {
  const { log } = useLogger()
  const [{ fetching, data, error }] = useQuery({
    query: AccountStatusDocument,
    requestPolicy: 'cache-and-network',
  })

  if (error) {
    log('error', 'Account Status at Explore', { error })
  }

  if (fetching || error) {
    return null
  }

  if (
    data &&
    data?.accountStatus.length > 0 &&
    data?.accountStatus.some((x: any) => !x?.completed)
  ) {
    return (
      <div className="bg-gray-300 py-8 px-4">
        <h2 className="heading-2 text-primary pb-4">
          Just a few more steps...
        </h2>
        <ThingsTodo things={data.accountStatus} />
        <p className="pt-6 opacity-60">
          But do not worry, you can still use most of the features!
        </p>
      </div>
    )
  }

  return null
}

const DailyTip = () => {
  const { account } = useAuth()
  const [mode] = useMode()
  const goodFor = patternMatching<string, Account_Role_Enum>(
    [
      ['teach', Account_Role_Enum.Teacher],
      ['practice', Account_Role_Enum.Student],
    ],
    account.type,
  )(mode)

  return (
    <GenericQueryRender
      query={DailyTipDocument}
      variables={{
        goodFor: patternMatching(
          [
            [Account_Role_Enum.Student, 'student'],
            [Account_Role_Enum.Teacher, 'teacher'],
            [Account_Role_Enum.Studio, 'studio'],
          ],
          'student',
        )(goodFor),
      }}
      dataAccess="tip_current_by_pk.tip.sources"
      Loading={() => null}
      Success={({ data }) => {
        if (!data || data.length === 0) {
          return null
        }

        return (
          <>
            <h2 className="heading-2 pb-4 text-green">Today's Tip</h2>
            <div className="relative">
              <Swiper slidesPerView="auto" centeredSlides className="-ml-10">
                {sortBy('order', data).map(({ source }, i: number) => (
                  <SwiperSlide key={`tip-${i}`} className="w-4/5">
                    <img src={source} alt={`Tip ${i}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="z-2 absolute bg-white -right-2 -bottom-2 rounded-full shadow">
                <Lottie json={lottieSwipeLeftJson} width={45} />
              </div>
            </div>
          </>
        )
      }}
    />
  )
}
