import React from 'react'
import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import defaultBuilding from 'assets/placeholders/building.jpg'
import getPicture from 'utils/getPicture'
import Container from 'components/Container'
import Button from 'components/Button'
import DeletableList from 'components/DeletableList'
import RoundedPicture from 'components/RoundedPicture'
import CollapsableContent from 'components/CollapsableContent'
import Form from './Form'
import { Navigate } from 'components/StackNavigation'
import { LocationsByAccountQuery } from 'generated/graphql'
import { useApolloClient } from '@apollo/client'
import { DELETE_LOCATION_BY_PK } from 'graphql/mutations'
import { useToast } from 'components/ToastMessage'
import { QUERY_LOCATIONS_BY_ACCOUNT } from 'graphql/queries'
import { useAuth } from 'stores/auth.store'
import { useAlert } from 'components/AlertModal'

type LocationQuery = LocationsByAccountQuery['location']

const groupLocationsByCity = (
  locations: LocationQuery,
): Array<[string, LocationQuery]> =>
  flow(groupBy('city'), (byCity: Record<string, LocationQuery>) =>
    Object.keys(byCity).reduce<Array<[string, LocationQuery]>>((arr, city) => {
      const locations = byCity[city]
      const label = `${locations[0].state}, ${city}`

      return [...arr, [label, locations]]
    }, []),
  )(locations)

type Props = {
  data: LocationQuery
  navigate?: Navigate
}

const List = ({ data, navigate }: Props) => {
  const client = useApolloClient()
  const { notify } = useToast()
  const { account, isAuthenticated } = useAuth()
  const alert = useAlert()
  const locationsByCity = groupLocationsByCity(data)
  const [deleteErrorIndex, setDeleteErrorIndex] = React.useState<
    [number, number] | null
  >(null)

  if (!isAuthenticated()) {
    // TODO: We need a better way to handle this
    return <Container topBottomSpace>You need to login</Container>
  }

  return (
    <>
      {locationsByCity.map(([label, locations], i) => {
        const hasMultipleCities = locationsByCity.length > 1
        const InnerWrapper = hasMultipleCities
          ? CollapsableContent
          : React.Fragment

        return (
          <div
            key={`${i}-${label}`}
            className={hasMultipleCities ? 'py-1' : ''}
            data-testid={`location-list-${i}`}
          >
            <InnerWrapper label={label} noInnerSpace>
              <Container>
                <DeletableList
                  $type="card"
                  context="locations"
                  onDelete={async (index) => {
                    alert.send({
                      body: `The location "${locations[index].name}" is about to be deleted.`,
                      buttons: [
                        {
                          children: 'Delete',
                          onClick: async () => {
                            try {
                              await client.mutate({
                                mutation: DELETE_LOCATION_BY_PK,
                                variables: {
                                  locationID: locations[index].id,
                                },
                              })

                              const queried = client.readQuery<LocationsByAccountQuery>(
                                {
                                  query: QUERY_LOCATIONS_BY_ACCOUNT,
                                  variables: {
                                    accountID: account.id,
                                  },
                                },
                              )
                              const updatedLocations = [
                                ...(queried?.location || []),
                              ]
                              const indexInLocations = updatedLocations.findIndex(
                                (location) =>
                                  location.id === locations[index].id,
                              )

                              updatedLocations.splice(indexInLocations, 1)

                              client.writeQuery({
                                query: QUERY_LOCATIONS_BY_ACCOUNT,
                                variables: {
                                  accountID: account.id,
                                },
                                data: {
                                  location: updatedLocations,
                                },
                              })
                            } catch (err) {
                              if (
                                err.graphQLErrors?.some(
                                  (er: any) =>
                                    er?.extensions?.code ===
                                    'constraint-violation',
                                )
                              ) {
                                notify({
                                  type: 'failure',
                                  title: 'The location cannot be deleted',
                                  message:
                                    'A current class in this location is in your schedule. Revisit classes and delete.',
                                  preventAutoClose: true,
                                  onClose: () => {
                                    setDeleteErrorIndex(null)
                                  },
                                })
                                setDeleteErrorIndex([i, index])
                              }
                            }
                          },
                        },
                        {
                          $type: 'tertiary',
                          children: 'Cancel',
                        },
                      ],
                    })
                  }}
                >
                  {locations.map((location, ii) => {
                    const markAsRed =
                      deleteErrorIndex &&
                      deleteErrorIndex[0] === i &&
                      deleteErrorIndex[1] === ii

                    return (
                      <button
                        key={`location-${location.id}`}
                        onClick={() => {
                          navigate?.({
                            title: `Update ${location.name}`,
                            Component: Form,
                            data: location,
                          })
                        }}
                        className="w-full flex items-center p-3 relative"
                        data-testid={`${ii}-${location.name}`}
                      >
                        <RoundedPicture
                          image={getPicture(location.picture, defaultBuilding)}
                          size={100}
                        />
                        <span
                          className="text-left ml-4"
                          style={{
                            lineHeight: '22px',
                          }}
                        >
                          <span className="block heading-2 text-primary">
                            {location.name}
                          </span>
                          <span className="block">
                            {[location.address, location.addressSecondary]
                              .filter(Boolean)
                              .join(', ')}
                          </span>
                          <span className="block">{location.zipCode}</span>
                        </span>
                        {markAsRed && (
                          <div className="absolute top-0 right-0 bottom-0 left-0 bg-red-600 bg-opacity-25" />
                        )}
                      </button>
                    )
                  })}
                </DeletableList>
              </Container>
            </InnerWrapper>
          </div>
        )
      })}

      <Container topBottomSpace data-testid="add-location">
        <Button
          type="button"
          $type="primary"
          $fluid
          onClick={() => {
            navigate?.({
              title: 'Add Location',
              Component: Form,
            })
          }}
        >
          Add Location
        </Button>
      </Container>
    </>
  )
}

export default List
