import { MutationOptions, useApolloClient } from '@apollo/client'
import { useAlert } from 'components/AlertModal'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useLoadingBlock } from 'components/LoadingBlock'
import useLogger from './useLogger'

const useActionMutation = () => {
  const client = useApolloClient()
  const { log } = useLogger()
  const alert = useAlert()
  const { notify } = useToast()
  const loadingBlock = useLoadingBlock()

  async function mutate<T = unknown, TVariables = unknown>({
    mutation,
    errorContext,
    onSuccess,
    hideOriginalError,
    hideLoadingBlock,
    autoNotifySuccess,
  }: {
    mutation: MutationOptions<T, TVariables>
    errorContext: string
    onSuccess?: (data: T) => void
    hideOriginalError?: boolean
    hideLoadingBlock?: boolean
    autoNotifySuccess?: {
      text: string
    }
  }): Promise<void> {
    if (!hideLoadingBlock) {
      loadingBlock.open()
    }

    try {
      const result = await client.mutate(mutation)

      if (result.errors) {
        log('error', errorContext, {
          error: result.errors,
          from: 'result',
        })

        alert.send({
          $type: 'danger',
          body: !hideOriginalError
            ? result.errors[0].message
            : GENERIC_ERROR_MESSAGE,
          buttons: [
            {
              children: 'Close',
            },
          ],
        })
      } else {
        if (onSuccess) {
          await onSuccess(result.data!)
        }

        if (autoNotifySuccess) {
          notify({
            type: 'success',
            message: autoNotifySuccess.text,
          })
        }
      }
    } catch (error) {
      log('error', errorContext, {
        error,
        from: 'catch',
      })
      alert.send({
        $type: 'danger',
        body: !hideOriginalError ? error.message : GENERIC_ERROR_MESSAGE,
        buttons: [
          {
            children: 'Close',
          },
        ],
      })
    }

    if (!hideLoadingBlock) {
      loadingBlock.close()
    }
  }

  return mutate
}

export default useActionMutation
