import cc from 'classcat'
import FormCarousel, {
  CustomHeaderProps,
  FormCarouselSlide,
  useFormCarousel,
} from 'forms/FormCarousel'
import introAfricanWoman from 'assets/intro/african-american-woman.jpg'
import introDaneWetton from 'assets/intro/dane-wetton.jpg'
import introPexelsCottonbro from 'assets/intro/pexels-cottonbro.jpg'
import introSeyiAriyo from 'assets/intro/seyi-ariyo.jpg'
import { ReactComponent as ArrowBack } from 'assets/symbols/arrow-back.svg'
import styles from './index.module.scss'
import Button from 'components/Button'
import makeStorage from 'utils/makeStorage'
import React, { useEffect, useState } from 'react'
import Signup from 'screens/Signup'
import useIsMounted from 'hooks/useIsMounted'
import ms from 'ms'
import { ReactComponent as IconArrow } from 'assets/icons/arrow-left.svg'

/*
<SpaceForKeyboard /> not needed
*/

const introStorage = makeStorage('intro-seen')

const withDelayedOverlay = (Component: React.FC) => (props: any) => {
  const [show, setShow] = useState(false)
  const [opacity, setOpacity] = useState(0)
  const isMounted = useIsMounted()
  const { next } = useFormCarousel()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted.current) {
        setShow(true)
      }
    }, ms('20s'))

    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    if (isMounted.current) {
      setTimeout(() => {
        setOpacity(show ? 1 : 0)
      })
    }
  }, [show])

  return (
    <>
      <Component {...props} />
      {show && (
        <div
          className="absolute inset-0 bg-purple text-white flex flex-col items-center justify-center heading-2 bg-opacity-90 transition duration-500 ease-in-out p-4 text-center"
          style={{
            opacity,
          }}
          onClick={() => {
            setShow(false)
          }}
        >
          Use the Top Navigation to continue
          <div className="py-10">- OR -</div>
          <Button
            $fluid
            $inverted
            onClick={() => {
              if (props.onClick) {
                props.onClick()
              } else {
                next()
              }
            }}
          >
            Click here to continue{' '}
            <IconArrow
              className="inline-block fill-black"
              style={{ height: 10, transform: 'rotate(180deg)' }}
            />
          </Button>
        </div>
      )}
    </>
  )
}

const Intro = () => {
  const [seen, setSeen] = useState(false)

  const introSeen = () => {
    setSeen(true)
    introStorage.set(true)
  }

  if (seen) {
    return <Signup />
  }

  return (
    <FormCarousel
      CustomHeader={({ current, next }: CustomHeaderProps) => (
        <div className="z-1 flex justify-between safe-area-pt">
          <button
            className={cc([
              'heading-3 p-3',
              {
                'text-white': current > 0,
                'text-gray-700': current === 0,
              },
            ])}
            onClick={() => {
              introSeen()
            }}
          >
            Skip all
          </button>
          <button
            className="heading-3 py-3 pr-3 pl-10"
            onClick={() => {
              next()
            }}
          >
            <ArrowBack
              height={20}
              className={cc([
                'transform rotate-180',
                {
                  'fill-white': current > 0,
                  'fill-gray-700': current === 0,
                },
              ])}
            />
          </button>
        </div>
      )}
      onSubmit={() => {
        introSeen()
      }}
      swipeable
    >
      <FormCarouselSlide
        bgImage={introAfricanWoman}
        bgFaded={false}
        bgClass={['bg-no-repeat', styles.bgPosition].join(' ')}
      >
        <WelcomeToOhmunity />
      </FormCarouselSlide>

      <FormCarouselSlide
        bgImage={introPexelsCottonbro}
        bgFaded={false}
        bgClass={['bg-no-repeat', styles.bgPosition].join(' ')}
      >
        <YogisAndYoginis />
      </FormCarouselSlide>

      <FormCarouselSlide
        bgImage={introSeyiAriyo}
        bgFaded={false}
        bgClass={['bg-no-repeat', styles.bgPosition].join(' ')}
      >
        <TeachersAndStudios />
      </FormCarouselSlide>

      <FormCarouselSlide
        bgImage={introDaneWetton}
        bgFaded={false}
        bgClass={['bg-no-repeat', styles.bgPosition].join(' ')}
      >
        <GetStarted
          onClick={() => {
            introSeen()
          }}
        />
      </FormCarouselSlide>
    </FormCarousel>
  )
}

const WelcomeToOhmunity = withDelayedOverlay(() => (
  <div className="h-full flex flex-col justify-between">
    <div
      className="text-center pt-2 pb-4"
      style={{
        backgroundColor: 'rgba(245, 246, 248, .7)',
      }}
    >
      <h1 className="leading-10 font-title text-green" style={{ fontSize: 24 }}>
        Welcome to
      </h1>
      <h2 className="leading-10 font-title text-green" style={{ fontSize: 40 }}>
        Ohmunity
        <sup
          style={{
            fontSize: '50%',
          }}
        >
          ™
        </sup>
      </h2>
    </div>
    <div
      className={cc([
        styles.box,
        'flex  items-center bg-white heading-1 text-purple p-4 text-center justify-center',
      ])}
    >
      Your new digital Yoga community
    </div>
  </div>
))

const YogisAndYoginis = withDelayedOverlay(() => (
  <div className="w-full h-full flex items-end">
    <div
      className={cc([
        styles.box,
        'w-full flex flex-col items-center justify-center bg-white heading-1 p-4 text-center',
      ])}
    >
      <div className="bg-green rounded-full text-white heading-4 py-1 px-2">
        Yogis / Yoginis
      </div>
      <p className="pt-4 text-green" style={{ maxWidth: 250 }}>
        Join unlimited virtual and in-person classes
      </p>
    </div>
  </div>
))

const TeachersAndStudios = withDelayedOverlay(() => (
  <div className="w-full h-full flex items-end">
    <div
      className={cc([
        styles.box,
        'w-full flex flex-col items-center justify-center bg-white heading-1 p-4 text-center',
      ])}
    >
      <div className="bg-purple rounded-full text-white heading-4 py-1 px-2">
        Teachers / Studios
      </div>
      <p className="pt-4 text-purple" style={{ maxWidth: 250 }}>
        Expand your network by being discoverable
      </p>
    </div>
  </div>
))

const GetStarted = ({ onClick }: { onClick: () => void }) => (
  <div className="w-full h-full flex items-end">
    <div
      className={cc([
        styles.box,
        'w-full flex flex-col items-center justify-center bg-white heading-1 p-4 text-center',
      ])}
    >
      <p className="w-full text-green pb-6">Begin your jouney today</p>
      <Button
        $fluid
        onClick={() => {
          onClick()
        }}
      >
        Get started
      </Button>
    </div>
  </div>
)

export default Intro
