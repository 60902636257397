import React from 'react'
import FormField, { DropdownOption, FormGroup } from 'components/FormField'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import useLogger from 'hooks/useLogger'
import ServerErrorMessage from 'components/ServerErrorMessage'
import Button from 'components/Button'
import * as Validation from 'utils/formFieldValidations'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import Note from 'components/Note'
import { onlyNumbers } from 'utils/regex'

interface Props {
  onSubmit: (bankData: {
    name: string
    type: 'individual' | 'company'
    number: string
    routing: string
  }) => void
  initialName?: string
}

const FormAddBankAccount = ({ onSubmit, initialName }: Props) => {
  const { log } = useLogger()
  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      name: initialName || '',
      type: '',
      number: '',
      routing: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().min(3).max(30).required('This field is required'),
        type: yup.string().required(),
        number: Validation.bankAccountNumber,
        routing: Validation.bankRouting,
      }),
    ),
  })
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)

  return (
    <>
      <div className="pb-8">
        <Note>We currenly accept only Banks from United States in USD.</Note>
      </div>
      <form
        onSubmit={handleSubmit(async ({ name, type, number, routing }) => {
          setLoading(true)
          setError(null)

          try {
            await onSubmit({
              name,
              type: type === 'individual' ? 'individual' : 'company',
              number: onlyNumbers(number),
              routing: onlyNumbers(routing),
            })
          } catch (error) {
            log('error', 'Form Add Payment Method "onSubmit"', {
              error,
            })
            setError(
              'Something went wrong. Please verify the card information entered',
            )
          }

          setLoading(false)
        })}
        id="form-add-payment-method"
      >
        <FormGroup>
          <FormField
            type="dropdown"
            label="Type *"
            name="type"
            control={control}
            error={errors?.type?.message}
            disabled={loading}
            placeholder="Select account holder type"
          >
            <DropdownOption value="individual">Individual</DropdownOption>
            <DropdownOption value="company">Company</DropdownOption>
          </FormField>

          {/* Cardholder Name */}
          <FormField
            type="text"
            label="Full Name *"
            name="name"
            placeholder="Your full name"
            register={register}
            error={errors?.name?.message}
            disabled={loading}
          />

          {/* Account Number */}
          <FormField
            type="number"
            label="Account Number *"
            name="number"
            register={register}
            error={errors?.number?.message}
            disabled={loading}
          />

          {/* Routing Number */}
          <FormField
            type="number"
            label="Routing Number *"
            name="routing"
            register={register}
            error={errors?.routing?.message}
            disabled={loading}
          />
        </FormGroup>

        {error && (
          <div className="pt-4">
            <ServerErrorMessage>{error}</ServerErrorMessage>
          </div>
        )}

        <div className="pt-4">
          <Button type="submit" $fluid loading={loading}>
            Add
          </Button>
        </div>

        <SpaceForKeyboard />
      </form>
    </>
  )
}

export default FormAddBankAccount
