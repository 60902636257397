import React from 'react'

const Context = React.createContext<{
  current: number
  total: number
  previous: () => void
  next: (values?: any) => void
  initialValues?: any
  isKeyboardOnScreen: boolean
}>({
  current: 0,
  total: 0,
  previous: () => {
    throw new Error('Must be consumed inside FormCarousel Provider')
  },
  next: () => {
    throw new Error('Must be consumed inside FormCarousel Provider')
  },
  isKeyboardOnScreen: false,
})

export const useFormCarousel = () => React.useContext(Context)

export default Context
