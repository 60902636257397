import Container from 'components/Container'
import Tabs from 'components/Tabs'
import { Account_Role_Enum } from 'generated/graphql'
import React, { useContext } from 'react'
import { useClassContext } from './classContext'

const TABS_STUDIO = [
  {
    id: 'info',
    text: 'Class',
  },
  {
    id: 'studio',
    text: 'Studio',
  },
  {
    id: 'teacher',
    text: 'Teacher',
  },
]
const TABS_TEACHER = [
  {
    id: 'info',
    text: 'Class',
  },
  {
    id: 'teacher',
    text: 'Teacher',
  },
]

const Context = React.createContext<[string, (id: string) => void]>([
  TABS_STUDIO[0].id,
  () => {},
])

export const useTabs = () => useContext(Context)

export const TabsProvider = ({ children }: { children: any }) => {
  const [state, setState] = React.useState<string>(TABS_STUDIO[0].id)

  return (
    <Context.Provider
      value={[
        state,
        (id) => {
          setState(id)
        },
      ]}
    >
      {children}
    </Context.Provider>
  )
}

const ClassTabs = () => {
  const [tabActive, setTabActive] = useContext(Context)
  const clazzContext = useClassContext()

  return (
    <div className="bg-white w-full pt-4 text-primary">
      <Container>
        <Tabs
          items={
            clazzContext?.clazz.owner.type === Account_Role_Enum.Teacher
              ? TABS_TEACHER
              : TABS_STUDIO
          }
          onChange={(id) => {
            setTabActive(id)
          }}
          activeTab={tabActive}
        />
      </Container>
    </div>
  )
}

export default ClassTabs
