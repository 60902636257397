import React from 'react'
import Container from 'components/Container'
import Button from 'components/Button'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'
import { FormProvider, useForm } from 'react-hook-form'

const Bio = () => {
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const validationSchema = yup.object().shape({
    bio: Validation.longText,
    fullPicture: yup.string().optional(),
  })
  const formMethods = useForm({
    defaultValues: {
      bio: '',
      fullPicture: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, formState } = formMethods
  const { isSubmitting } = formState

  return (
    <FormProvider {...formMethods}>
      <Container
        topBottomSpace
        className="flex flex-col min-h-full justify-between"
      >
        <div className="h-14 text-center">
          <div className="heading-1 text-primary">
            Tell us more about yourself
          </div>
        </div>

        <form
          onSubmit={handleSubmit((values) => {
            next(values)
          })}
        >
          <FormTextWithCounter
            name="bio"
            placeholder="Start here"
            $inputClassName="h-48"
          />

          <div className="pt-4">
            <Button $fluid type="submit" loading={isSubmitting}>
              Next
            </Button>
          </div>
        </form>
      </Container>

      {isKeyboardOnScreen && (
        <SpaceForKeyboard $size="lg" autoControlled={false} />
      )}
    </FormProvider>
  )
}

export default Bio
