import { capitalize } from 'lodash/fp'
import FormCarousel, { FormCarouselSlide } from 'forms/FormCarousel'
import japa from 'assets/photos/signup/japa.jpg'
import couple from 'assets/photos/signup/couple.jpg'
import girlMeditating from 'assets/photos/signup/girl-meditating.jpg'
import teacher from 'assets/photos/signup/teacher.jpg'
import FirstAndLast from '../Reusable/FirstAndLast'
import Email from '../Reusable/Email'
import PhoneNumber from '../Reusable/PhoneNumber'
import Birthday from '../Reusable/Birthday'
import useLogger from 'hooks/useLogger'
import useSignupNavigation from 'hooks/useSignupNavigation'
import Bio from './Bio'
import Picture from '../Reusable/Picture'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useAlert } from 'components/AlertModal'
import { useCreateTeacherAccountMutation } from 'generated/graphql'
import { onlyNumbers } from 'utils/regex'
import { getDeviceTimezone } from 'utils/timezone'
import { isWebapp } from 'utils/env'
import Container from 'components/Container'
import FormField from 'components/FormField'
import Button from 'components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import FormGroup from 'components/FormGroup'
import FormInlineGroup from 'components/FormInlineGroup'
import { useApolloClient } from '@apollo/client'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import PhoneNumberField from 'formFields/PhoneNumberField'
import BirthdayField from 'formFields/BirthdayField'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import { WithWebBackLayout } from 'components/WebBackLayout'

/*
<SpaceForKeyboard /> not needed
*/

const SignupTeacher = (_props: any) => {
  const { log } = useLogger()
  const toast = useToast()
  const loadingBlock = useLoadingBlock()
  const alert = useAlert()
  const [signupTeacher] = useCreateTeacherAccountMutation()
  const { signupNavigate } = useSignupNavigation()

  const onSubmit = async (values: any) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      birthday,
      picture,
      termsAndConditions,
      bio,
    } = values

    loadingBlock.open()

    try {
      const result = await signupTeacher({
        variables: {
          termsAndConditions,
          object: {
            person: {
              firstName: capitalize(firstName),
              lastName: capitalize(lastName),
              email: email.trim().toLowerCase(),
              phoneNumber: onlyNumbers(phoneNumber),
              picture,
              birthday,
            },
            teacher: {
              bio,
            },
            account: {
              username: '',
              timezone: getDeviceTimezone(),
            },
          },
        },
      })

      if (result.errors) {
        toast.notify({
          type: 'failure',
          message: result.errors[0].message,
        })
      } else {
        signupNavigate('/done', {
          state: {
            disableAnimation: true,
            result: result?.data?.signupTeacher,
          },
        })
      }

      loadingBlock.close()
    } catch (error) {
      log('error', 'Teacher Signup', { error, values })
      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
      loadingBlock.close()
    }
  }

  if (isWebapp()) {
    return <SimpleForm onSubmit={onSubmit} />
  }

  return (
    <FormCarousel
      onSubmit={onSubmit}
      onCancel={() => {
        alert.send({
          body: 'All information you have already entered will be lost.',
          buttons: [
            {
              children: 'Continue',
              onClick: () => {
                signupNavigate('/')
              },
            },
            {
              $type: 'tertiary',
              children: 'Cancel',
            },
          ],
        })
      }}
    >
      <FormCarouselSlide bgImage={girlMeditating} bgFadedOnKeyboard>
        <FirstAndLast />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={teacher} bgFadedOnKeyboard>
        <Email />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={japa} canSkip bgFadedOnKeyboard>
        <PhoneNumber />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={couple} bgFadedOnKeyboard>
        <Birthday />
      </FormCarouselSlide>
      <FormCarouselSlide>
        <Bio />
      </FormCarouselSlide>
      <FormCarouselSlide canSkip>
        <Picture />
      </FormCarouselSlide>
    </FormCarousel>
  )
}

export default WithWebBackLayout<any>(SignupTeacher)

type SimpleFormProps = {
  onSubmit: (values: any) => void
}

const SimpleForm = ({ onSubmit }: SimpleFormProps) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    email: Validation.uniqueEmail(client),
    phoneNumber: Validation.uniquePhoneNumber(client),
    birthday: Validation.birthday,
    termsAndConditions: Validation.termsAndConditions,
    bio: Validation.longText,
    fullPicture: yup.string().optional(),
  })
  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      birthday: '',
      bio: '',
      fullPicture: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, register, errors, formState, control } = formMethods
  const { dirtyFields, isValid } = formState
  const canContinue = isValid || Object.keys(dirtyFields).length > 0

  return (
    <Container>
      <h1 className="heading-1 text-center pt-4 pb-8 text-primary">
        Welcome Teacher
      </h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formMethods}>
          <FormGroup>
            <FormInlineGroup>
              {/* First Name */}
              <div>
                <FormField
                  type="text"
                  name="firstName"
                  placeholder="First Name *"
                  register={register}
                  error={errors?.firstName?.message}
                />
              </div>

              {/* Last Name */}
              <div>
                <FormField
                  type="text"
                  name="lastName"
                  placeholder="Last Name *"
                  register={register}
                  error={errors?.lastName?.message}
                />
              </div>
            </FormInlineGroup>

            <FormInlineGroup>
              {/* Email */}
              <div>
                <FormField
                  type="email"
                  name="email"
                  placeholder="Email *"
                  register={register}
                  error={errors?.email?.message}
                />
              </div>

              {/* Phone Number */}
              <div>
                <PhoneNumberField name="phoneNumber" control={control} />
              </div>
            </FormInlineGroup>

            {/* Birthday */}
            <BirthdayField name="birthday" label="Date of Birth" />

            {/* Bio */}
            <FormTextWithCounter
              name="bio"
              placeholder="Introduce yourself, what type of yoga you teach, your certifications, and more."
              $inputClassName="h-48"
              label="Bio"
            />

            <TermsAndConditionsField
              name="termsAndConditions"
              control={control}
            />
          </FormGroup>
        </FormProvider>

        <div className="pt-4">
          <Button $fluid disabled={!canContinue}>
            Sign up
          </Button>
        </div>
      </form>
    </Container>
  )
}
