import React from 'react'
import differenceBy from 'lodash/differenceBy'
import GenericQueryRender from 'components/GenericQueryRender'
import { QUERY_STUDIO_PICTURES } from 'graphql/queries'
import MultiPictureUpload, {
  MultiPictureUploadContext,
} from 'components/MultiPictureUpload'
import Container from 'components/Container'
import Button from 'components/Button'
import { gql, useApolloClient } from '@apollo/client'
import { useToast } from 'components/ToastMessage'
import { StudioPicturesQuery } from 'generated/graphql'

type UploaderProps = {
  data: StudioPicturesQuery['studio_picture']
}

const Uploader = ({ data }: UploaderProps) => {
  const client = useApolloClient()
  const [fetching, setFetching] = React.useState(false)
  const { notify } = useToast()
  // Need a state to mutate 'initialPictures' after we mutate
  const [initialPictures, setInitialPictures] = React.useState(data)

  return (
    <MultiPictureUploadContext
      limit={6}
      initialPictures={initialPictures.map(({ url }: any) => url)}
    >
      <MultiPictureUpload
        onDone={async (pictures, setIsDirty) => {
          const hasErrors = pictures.some((picture) => picture.error)
          const orderedPictures = pictures.map((picture, index) => ({
            ...picture,
            order: index,
          }))

          if (hasErrors) {
            notify({
              type: 'failure',
              message:
                'Cannot save changes because one or more pictures have an error',
            })
          } else {
            setFetching(true)

            const picsToAdd = differenceBy(orderedPictures, data, 'url')
            const picsToRemove = differenceBy(data, orderedPictures, 'url')
            const picsToUpdate = data
              .filter(
                (pic) =>
                  !picsToAdd.some((pa) => pa.url === pic.url) &&
                  !picsToRemove.some((pa) => pa.url === pic.url),
              )
              .map((pic) => ({
                ...pic,
                order:
                  orderedPictures.find((x) => x.url === pic.url)?.order ?? 0,
              }))

            try {
              const response = await client.mutate({
                mutation: gql`
                mutation CreateAndDeleteStudioPictures(
                    ${
                      picsToAdd.length > 0 || picsToUpdate.length > 0
                        ? `$add: [studio_picture_insert_input!]!`
                        : ''
                    }
                    ${
                      picsToRemove.length > 0
                        ? `$remove: studio_picture_bool_exp!`
                        : ''
                    }
                    
                  ) {
                    ${
                      picsToAdd.length > 0 || picsToUpdate.length > 0
                        ? `
                          insert_studio_picture(objects: $add,
                          
                          on_conflict: {
                            constraint: studio_picture_pkey,
                            update_columns: [order]
                          }
                          
                          ) {
                            returning {
                              id
                              url
                            }
                          }
                          `
                        : ''
                    }
                    ${
                      picsToRemove.length > 0
                        ? `
                          delete_studio_picture(where: $remove) {
                            returning {
                              id
                              url
                            }
                          }
                          `
                        : ''
                    }
                  }
                `,
                variables: {
                  add:
                    picsToAdd.length > 0 || picsToUpdate.length > 0
                      ? [
                          ...picsToAdd,
                          ...picsToUpdate,
                        ].map(({ id, url, order }: any) => ({ id, url, order }))
                      : undefined,
                  remove:
                    picsToRemove.length > 0
                      ? {
                          _or: picsToRemove.map(({ id }: any) => ({
                            id: { _eq: id },
                          })),
                        }
                      : undefined,
                },
              })

              if (response.errors) {
                response.errors.forEach((err) => {
                  notify({
                    type: 'failure',
                    message: err.message,
                  })
                })
              } else {
                notify({
                  type: 'info',
                  message: 'Photos have been updated.',
                })

                const cached = client.readQuery({
                  query: QUERY_STUDIO_PICTURES,
                })

                const newCache = orderedPictures.map((pic) => ({
                  order: pic.order,
                  id:
                    cached.studio_picture.find((c: any) => c.url === pic.url)
                      ?.id ||
                    response.data.insert_studio_picture.returning.find(
                      (i: any) => i.url === pic.url,
                    )?.id,
                  url: pic.url!,
                }))

                client.writeQuery({
                  query: QUERY_STUDIO_PICTURES,
                  data: {
                    studio_picture: newCache,
                  },
                })

                setInitialPictures(newCache)
              }

              setFetching(false)
              setIsDirty(false)
            } catch (err) {
              notify({
                type: 'failure',
                message: err.message,
              })
              setFetching(false)
            }
          }
        }}
      >
        {(trigger, { isDirty, loading }) => (
          <div className="pt-6">
            <Button
              type="button"
              $type="primary"
              $fluid
              loading={loading || fetching}
              onClick={() => trigger()}
              disabled={!isDirty}
            >
              Save
            </Button>
          </div>
        )}
      </MultiPictureUpload>
    </MultiPictureUploadContext>
  )
}

const StudioPictures = () => {
  return (
    <Container topBottomSpace>
      <GenericQueryRender
        query={QUERY_STUDIO_PICTURES}
        dataAccess="studio_picture"
        Success={Uploader}
      />
    </Container>
  )
}

export default StudioPictures
