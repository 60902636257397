import { ReactElement } from 'react'

type Props = {
  children: (ReactElement | null | false | true | undefined | '')[]
  $oddClassName: string
  $evenClassName: string
}

const Sections = ({ children, $evenClassName, $oddClassName }: Props) => {
  const elements = children.filter(Boolean)

  return (
    <>
      {elements.map((element, index) => (
        <section
          key={`section-${index}`}
          className={index % 2 === 0 ? $evenClassName : $oddClassName}
        >
          {element}
        </section>
      ))}
    </>
  )
}

export default Sections
