import React from 'react'
import useCamera, { PhotoBase64 } from 'hooks/useCamera'
import RoundedPicture from 'components/RoundedPicture'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'
import { ApolloError, useApolloClient } from '@apollo/client'
import { UPLOAD_PICTURE } from 'graphql/mutations'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import patternMatching from 'utils/patternMatching'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'

type Props = {
  size: number
  image: string | null | undefined
  onUpload: (picture: string) => void
}

const TeacherPicture = ({ size, image, onUpload }: Props) => {
  const { mutate } = useApolloClient()
  const { getPhoto } = useCamera()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<ApolloError | null>()
  const [picture, setPicture] = React.useState(image)
  const { notify } = useToast()
  const { log } = useLogger()

  const uploadPicture = async ({ format, base64String }: PhotoBase64) => {
    setLoading(true)

    try {
      const { data } = await mutate({
        mutation: UPLOAD_PICTURE,
        variables: {
          format,
          base64String,
        },
      })

      setLoading(false)
      setPicture(data.uploadPicture.url)
      onUpload(data.uploadPicture.url)
    } catch (err) {
      setLoading(false)
      setError(err)
      log('error', 'profile picture upload', {
        error: err.message,
      })
      notify({
        type: 'failure',
        message: 'Something went wrong',
      })
    }
  }

  if (loading) {
    return (
      <div
        className="inline-flex flex-col justify-center items-center bg-white"
        style={{
          width: size,
          height: size,
          borderRadius: size,
        }}
      >
        <Loading>Uploading...</Loading>
      </div>
    )
  }

  if (!!picture) {
    return (
      <>
        <div
          className="relative inline-block"
          onClick={() => {
            getPhoto().then(uploadPicture)
          }}
          data-testid="profile-picture-upload"
        >
          <RoundedPicture image={picture} size={size} />

          <div
            className="absolute inline-flex justify-center items-center bg-primary"
            style={{
              width: 35,
              height: 35,
              borderRadius: 35,
              bottom: -7,
              left: '50%',
              marginLeft: 20,
            }}
          >
            <IconCamera width={17} className="fill-white" />
          </div>
        </div>
        {error && (
          <div className="pt-2">
            <ServerErrorMessage>
              {patternMatching<string, string>(
                [['request entity too large', 'The picture is too large']],
                error.message,
              )(error.message)}
            </ServerErrorMessage>
          </div>
        )}
      </>
    )
  }

  // No picture yet
  return (
    <>
      <div
        className="inline-flex justify-center items-center bg-primary"
        style={{
          width: size,
          height: size,
          borderRadius: size,
        }}
        onClick={() => {
          getPhoto().then(uploadPicture)
        }}
        data-testid="profile-picture-not-upload"
      >
        <IconCamera width={25} className="fill-white" />
      </div>
      {error && (
        <div className="pt-2">
          <ServerErrorMessage>
            {patternMatching<string, string>(
              [['request entity too large', 'The picture is too large']],
              error.message,
            )(error.message)}
          </ServerErrorMessage>
        </div>
      )}
    </>
  )
}

export default TeacherPicture
