import { navigate } from 'clients/navigation.client'
import React from 'react'

type Props = {
  to: string
}

const Redirect = ({ to }: Props) => {
  React.useEffect(() => {
    navigate(to)
  }, [to])

  return null
}

export default Redirect
