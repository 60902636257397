import winston from 'winston'
import Transport from 'winston-transport'
import * as Sentry from '@sentry/react'

class SimpleConsoleTransport extends Transport {
  log = (info: any, callback: any) => {
    this.emit('logged', info)

    const { level, message, ...rest } = info

    const handler = (console as any)[level]

    handler(
      JSON.stringify({
        level,
        message,
        ...rest,
      }),
    )

    callback()
  }
}

class SentryTransport extends Transport {
  log(info: any, callback: any) {
    if (process.env.REACT_APP_SENTRY_DSN) {
      const {
        level,
        message,
        errorException,
        account,
        authenticated,
        ...rest
      } = info

      try {
        Sentry.captureMessage(message, {
          level,
          extra: {
            account,
            processEnv: process.env,
            ...rest,
          },
          user: authenticated
            ? {
                id: account.id,
                email: account.profile.email,
                username: account.profile.email,
              }
            : undefined,
          tags: {
            authenticated: !!authenticated,
            env: process.env.REACT_APP_ENV || 'n/a',
            stage: process.env.REACT_APP_STAGE || 'n/a',
          },
        })

        if (errorException) {
          Sentry.captureException(errorException)
        }
      } catch (err) {
        console.error('Sentry log', err)
      }
    }

    callback()
  }
}

const logger = winston.createLogger({
  transports: [new SimpleConsoleTransport(), new SentryTransport(Sentry)],
})

export default logger
