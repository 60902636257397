import { useEffect } from 'react'
import { BranchIo } from '@ionic-native/branch-io'
import { captureMessage, Severity } from '@sentry/react'

const useBranchIO = () => {
  useEffect(() => {
    const handleBranch = (context?: string) => {
      if (BranchIo && BranchIo.initSession) {
        BranchIo.initSession()
          .then((data) => {
            // read depp link data on click
            // do something with the deep link
            console.debug('-- branch init', data)

            captureMessage('BranchIO', {
              level: Severity.Debug,
              extra: {
                data,
                context,
              },
            })
          })
          .catch((error) => {
            console.error('-- branchio error', error)
            captureMessage('BranchIO Error', {
              level: Severity.Error,
              extra: {
                error,
              },
            })
          })
      } else {
        console.debug('-- branchio not available')
        captureMessage('BranchIO Not Available', {
          level: Severity.Debug,
        })
      }
    }

    const onDeviceReady = () => {
      handleBranch('on device ready')
    }

    const onResume = () => {
      handleBranch('on resume')
    }

    document.addEventListener('deviceready', onDeviceReady, false)
    document.addEventListener('resume', onResume, false)

    return () => {
      document.removeEventListener('deviceready', onDeviceReady)
      document.removeEventListener('resume', onResume)
    }
  }, [])
}

export default useBranchIO
