import React from 'react'
import cc from 'classcat'
import { debounce } from 'lodash/fp'
import { ReactComponent as IconSearch } from 'assets/icons/magnifying-glass.svg'
import FormField from 'components/FormField'
import List from 'components/List'
import Loading, { LoadingSpinner } from 'components/Loading'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import {
  FindLocationDocument,
  FindLocationOutput,
  PlaceCoordinatesDocument,
} from 'generated/graphql'
import { ApolloError, useApolloClient } from '@apollo/client'
import ServerErrorMessage from 'components/ServerErrorMessage'

/*
<SpaceForKeyboard /> not needed in this file
*/

export type ParsedSuggestion = {
  address: string
  city: string
  state: string
  country: string
}

export type SelectedData = {
  suggestion: FindLocationOutput
  parsed: ParsedSuggestion
  coordinates?: {
    lat: number
    lng: number
  }
}

export const parseSuggestion = (
  suggestion: FindLocationOutput,
): ParsedSuggestion => {
  const [country, state, city, ...address] = [
    ...(Array.isArray(suggestion.terms) // TODO: Because of https://github.com/hasura/graphql-engine/issues/6856
      ? suggestion.terms
      : [suggestion.terms]),
  ].reverse()

  return {
    country: country || '',
    state: state || '',
    city: city || '',
    address: address.reverse().join(' '),
  }
}

interface Props {
  onSelect: (data: SelectedData) => void
  name: string
  type: 'address' | 'regions'
  initialValue: string
  showIcon?: boolean
  placeholder?: string
  label?: string
  includeCoordinates?: boolean
  inputRender?: (input: React.ReactElement) => React.ReactNode
  $listClassName?: string
}

const LocationAutocomplete = ({
  name,
  type,
  showIcon,
  placeholder,
  label,
  onSelect,
  includeCoordinates,
  inputRender,
  initialValue,
  $listClassName,
}: Props) => {
  const client = useApolloClient()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<ApolloError | null>(null)
  const [value, setValue] = React.useState(initialValue)
  const [hasSearched, setHasSearched] = React.useState(false)
  const [selecting, setSelecting] = React.useState(false)
  const [suggestions, setSuggestions] = React.useState<FindLocationOutput[]>([])

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const changeSearchFor = debounce(500, (value) => {
    if (value) {
      setLoading(true)
      client
        .query({
          query: FindLocationDocument,
          fetchPolicy: 'network-only',
          variables: {
            input: value,
            type,
          },
        })
        .then((result) => {
          if (result.error) {
            // TODO: Display Toast
            setError(result.error)
          } else {
            setHasSearched(true)
            setSuggestions(result.data.findLocation)
            setError(null)
          }
          setLoading(false)
        })
        .catch((err) => {
          console.error(err)
          // TODO: Display Toast
          setError(err.message)
          setLoading(false)
        })
    }

    if (!value) setSuggestions([])
  })
  const delayedChange = React.useCallback(changeSearchFor, [])

  const inputField = (
    <FormField
      data-testid="location-autocomplete"
      type="text"
      name={name}
      Icon={showIcon && IconSearch}
      onChange={(value) => {
        setValue(value)
        delayedChange(value)
      }}
      value={value}
      placeholder={placeholder}
      label={label}
    />
  )

  const suggestionSearching = async (suggestion: FindLocationOutput) => {
    // This is just to avoid some code duplication
    const sets = () => {
      setValue(suggestion.description)
      setSuggestions([])
      setHasSearched(false)
    }

    if (includeCoordinates) {
      try {
        const result = await client.query({
          query: PlaceCoordinatesDocument,
          fetchPolicy: 'network-only',
          variables: {
            placeID: suggestion.placeID,
          },
        })

        if (result.error) {
          // TODO: Display Toast
        } else {
          onSelect({
            suggestion,
            parsed: parseSuggestion(suggestion),
            coordinates: {
              lat: result.data.placeCoordinates.lat,
              lng: result.data.placeCoordinates.lng,
            },
          })
          sets()

          setSelecting(false)
        }
      } catch (err) {
        console.error(err)
        // TODO: Display Toast
        setSelecting(false)
      }
    } else {
      onSelect({
        suggestion,
        parsed: parseSuggestion(suggestion),
      })
      sets()
    }
  }

  return (
    <>
      {inputRender ? inputRender(inputField) : inputField}
      {error && (
        <div className="py-2">
          <ServerErrorMessage>{GENERIC_ERROR_MESSAGE}</ServerErrorMessage>
        </div>
      )}
      {loading && <Loading context="Location Autocomplete" />}
      {suggestions.length > 0 && (
        <div
          className={cc(['py-2', $listClassName])}
          data-testid="location-autocomplete-suggestion"
        >
          <List>
            {suggestions.map((suggestion, index) => (
              <List.Item
                data-testid={`suggestion-${index}`}
                key={suggestion.placeID}
              >
                <button
                  onClick={() => suggestionSearching(suggestion)}
                  className="w-full"
                >
                  <span className="flex items-center justify-between">
                    <span
                      className={cc([
                        {
                          'text-gray-500': selecting,
                        },
                      ])}
                    >
                      {suggestion.description}
                    </span>
                    {selecting && <LoadingSpinner $size="sm" />}
                  </span>
                </button>
              </List.Item>
            ))}
          </List>
        </div>
      )}
      {!loading && hasSearched && suggestions.length === 0 && value && (
        <div className="py-2">No results</div>
      )}
    </>
  )
}

LocationAutocomplete.defaultProps = {
  initialValue: '',
}

export default LocationAutocomplete
