import {
  CreateStudioTeacherMutation,
  UpdateStudioProfileTeacherDocument,
  UpdateStudioProfileTeacherMutation,
} from 'generated/graphql'
import { useApolloClient, useMutation } from '@apollo/client'
import { omit, pick } from 'lodash/fp'
import { CREATE_STUDIO_TEACHER } from 'graphql/mutations'
import { onlyNumbers } from 'utils/regex'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import FormField, { FormGroup, Label } from 'components/FormField'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import Button from 'components/Button'
import ServerErrorMessage from 'components/ServerErrorMessage'
import FormTextWithCounter from 'forms/FromTextWithCounter'
import * as Validation from 'utils/formFieldValidations'
import PhoneNumberField from 'formFields/PhoneNumberField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import TeacherPicture from 'components/TeacherPictureToStudio'
import MultiPictureUpload, {
  MultiPictureUploadContext,
} from 'components/MultiPictureUpload'

type InitialValues = {
  firstName?: string
  lastName?: string
  email?: string
  picture?: string | null
  phoneNumber?: string | null
  bio?: string
  cover_picture?: string | null
}

type Props = {
  onAdded?: (result: CreateStudioTeacherMutation['insert_teacher_one']) => void
  onUpdated?: (result: {
    teacher: UpdateStudioProfileTeacherMutation['update_teacher_by_pk']
    person: UpdateStudioProfileTeacherMutation['update_profile_person_by_pk']
  }) => void
  personID?: string
  teacherID?: string
  initialValues?: InitialValues
}

const FormStudioTeacher = ({
  onAdded,
  onUpdated,
  personID,
  teacherID,
  initialValues,
}: Props) => {
  const isUpdate = personID && teacherID
  const [mutate, { loading, error }] = useMutation(
    isUpdate ? UpdateStudioProfileTeacherDocument : CREATE_STUDIO_TEACHER,
  )
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    phoneNumber: Validation.uniquePhoneNumber(
      client,
      initialValues?.phoneNumber,
    ),
    email: Validation.uniqueEmail(client, initialValues?.email),
    picture: yup.string().optional(),
    bio: Validation.longText,
    cover_picture: yup.string().optional(),
  })
  const formMethods = useForm({
    defaultValues: {
      firstName: initialValues?.firstName || '',
      lastName: initialValues?.lastName || '',
      email: initialValues?.email || '',
      picture: initialValues?.picture || '',
      phoneNumber: initialValues?.phoneNumber || '',
      bio: initialValues?.bio || '',
      cover_picture: initialValues?.cover_picture || '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, errors, control, setValue } = formMethods
  const [disableButton, setDisableButton] = useState(false)

  const onSubmit = async (values: InitialValues) => {
    try {
      const result = await mutate({
        variables: isUpdate
          ? {
              personID,
              setPerson: omit(['bio', 'cover_picture'], values),
              teacherID,
              setTeacher: pick(['bio', 'cover_picture'], values),
            }
          : {
              object: {
                ...pick(['bio', 'cover_picture'], values),
                person: {
                  data: {
                    ...pick(
                      ['firstName', 'lastName', 'email', 'picture'],
                      values,
                    ),
                    phoneNumber: onlyNumbers(values.phoneNumber),
                  },
                },
              },
            },
      })

      if (!isUpdate && onAdded) {
        onAdded(result.data.insert_teacher_one)
      }

      if (isUpdate && onUpdated) {
        onUpdated({
          teacher: result.data.update_teacher_by_pk,
          person: result.data.update_profile_person_by_pk,
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <div className="text-center">
              <input hidden name="picture" ref={register} />
              <TeacherPicture
                image={initialValues?.picture}
                size={120}
                onUpload={(pic) => {
                  setValue('picture', pic)
                }}
              />
            </div>

            {/* First Name */}
            <FormField
              type="text"
              label="First Name"
              name="firstName"
              register={register}
              error={errors?.firstName?.message}
            />

            {/* Last Name */}
            <FormField
              type="text"
              label="Last Name"
              name="lastName"
              register={register}
              error={errors?.lastName?.message}
            />

            {/* Email */}
            <FormField
              type="text"
              label="Email"
              name="email"
              register={register}
              error={errors?.email?.message}
            />

            {/* Phone Number */}
            <PhoneNumberField
              label="Phone Number"
              name="phoneNumber"
              control={control}
            />

            <FormTextWithCounter
              name="bio"
              label="Bio"
              $inputClassName="h-56"
            />

            {/* PICTURE */}
            <div className="py-4">
              <Label>Cover Picture</Label>
              <input type="hidden" name="cover_picture" ref={register} />
              <MultiPictureUploadContext
                limit={1}
                initialPictures={
                  initialValues?.cover_picture
                    ? [initialValues.cover_picture]
                    : undefined
                }
                onChange={(change) => {
                  setDisableButton(change)
                }}
              >
                <MultiPictureUpload
                  onDone={(value) => {
                    const picture = value.map((x) => x.url)[0]

                    formMethods.setValue('cover_picture', picture)
                    setDisableButton(false)
                  }}
                />
              </MultiPictureUploadContext>
            </div>
          </FormGroup>

          {/* ERROR */}
          {error && <ServerErrorMessage>{error.message}</ServerErrorMessage>}

          {/* CREATE */}
          <div className="pt-6">
            <Button
              type="submit"
              $type="primary"
              $fluid
              loading={loading}
              disabled={disableButton}
            >
              Save
            </Button>
          </div>

          <SpaceForKeyboard $size="lg" />
        </form>
      </FormProvider>
    </>
  )
}

export default FormStudioTeacher
