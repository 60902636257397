import React from 'react'
import moment from 'moment'
import Button from 'components/Button'
import getPersonName from 'utils/getPersonName'
import Container from 'components/Container'
import FormField, { ErrorMessage, DropdownOption } from 'components/FormField'
import FormStartEndTime, {
  yupStartEndTimeValidation,
} from 'components/FormStartEndTime'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import { useForm, useFieldArray } from 'react-hook-form'
import {
  Class_Recurrence_Enum,
  TeacherInfoByPersonIdDocument,
  TeacherInfoByPersonIdQuery,
  TeachersQuery,
} from 'generated/graphql'
import { useModalScreen, withModalScreen } from 'components/ModalScreen'
import { QUERY_TEACHERS } from 'graphql/queries'
import { TRANSITION_DURATION } from 'components/StackNavigation'
import { useApolloClient, useQuery } from '@apollo/client'
import { useAlert } from 'components/AlertModal'
import { useFormCarousel } from 'forms/FormCarousel'
import { useManageClassContext } from '../context'
import { useAuth } from 'stores/auth.store'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import validateDateTimesCollision from 'utils/validateDateTimesCollision'
import ordinalNumber from 'utils/ordinalNumber'
import FormStudioTeacher from 'forms/FormStudioTeacher'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'

const validationSchema = (type: Class_Recurrence_Enum, isTeacher: boolean) => {
  const common = {
    id: yup.string().optional(),
    start: yup.string().required('Start time is required'),
    end: yupStartEndTimeValidation(),
    teacher_id: isTeacher
      ? yup.string().optional()
      : yup.string().required('This field is required'), // teacher_id is compatible with graphql by nature
  }

  switch (type) {
    case Class_Recurrence_Enum.OneTime: {
      return yup.object().shape({
        dateTime: yup.array().of(
          yup.object().shape({
            date: yup.string().required('This field is required'),
            ...common,
          }),
        ),
      })
    }
    case Class_Recurrence_Enum.Recurring: {
      return yup.object().shape({
        dateTime: yup.array().of(
          yup.object().shape({
            day: yup.number().min(0).max(6).required('Please select a day'),
            ...common,
          }),
        ),
      })
    }
  }
}

type Props = {
  type: Class_Recurrence_Enum
}

const TimeForm = ({ type }: Props) => {
  const { isTeacher } = useAuth()

  if (isTeacher()) {
    return <TimeFormTeacher type={type} />
  }

  return <TimeFormStudioTeachers type={type} />
}

export default withModalScreen<Props>(TimeForm)

const TimeFormStudioTeachers = ({ type }: Props) => {
  const teachersState = useQuery<TeachersQuery>(QUERY_TEACHERS)

  if (teachersState.loading) {
    return <Loading context="Teachers" />
  }

  if (teachersState.error || !teachersState.data) {
    return (
      <ServerErrorMessage>
        {teachersState.error?.message || GENERIC_ERROR_MESSAGE}
      </ServerErrorMessage>
    )
  }

  console.log('--teachersState.data.teacher', teachersState.data.teacher)

  return (
    <TimeFormWithTeachers type={type} teachers={teachersState.data.teacher} />
  )
}

const TimeFormTeacher = ({ type }: Props) => {
  const { account } = useAuth()
  const teacherState = useQuery<TeacherInfoByPersonIdQuery>(
    TeacherInfoByPersonIdDocument,
    {
      variables: {
        personID: account.profile?.id,
      },
    },
  )

  if (teacherState.loading) {
    return <Loading context="Teachers" />
  }

  if (teacherState.error || !teacherState.data || !teacherState.data.teacher) {
    return (
      <ServerErrorMessage>
        {teacherState.error?.message || GENERIC_ERROR_MESSAGE}
      </ServerErrorMessage>
    )
  }

  console.log('--teachersState.data.teacher', teacherState.data.teacher)

  const { teacher } = teacherState.data

  return (
    <TimeFormWithTeachers
      type={type}
      teachers={teacher.map((t) => ({
        id: t.id,
        person: {
          firstName: t.person.firstName,
          lastName: t.person.lastName,
        },
      }))}
    />
  )
}

const TimeFormWithTeachers = ({
  type,
  teachers,
}: Props & {
  teachers: TeachersQuery['teacher']
}) => {
  const client = useApolloClient()
  const modal = useModalScreen()
  const { isTeacher } = useAuth()
  const { clazz } = useManageClassContext()
  const { initialValues, next } = useFormCarousel()
  const alert = useAlert()
  const defaultValues = {
    dateTime: (type === initialValues?.type
      ? initialValues.dateTime
      : undefined) || [
      type === Class_Recurrence_Enum.OneTime
        ? {
            id: '',
            date: '',
            start: '',
            end: '',
            teacher_id: '',
          }
        : {
            id: '',
            day: '',
            start: '',
            end: '',
            teacher_id: '',
          },
    ],
  }
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    control,
    reset,
    setError,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(type, isTeacher())),
    shouldUnregister: false,
  })
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'dateTime',
  })

  React.useEffect(() => {
    //reset to the initial values we pass
    reset(defaultValues)
  }, [type, reset])

  return (
    <>
      {/* Form */}
      <form
        onSubmit={handleSubmit((values) => {
          const collision = validateDateTimesCollision(values.dateTime)

          if (collision.overlap) {
            setError(`dateTime[${collision.indexA}].id`, {
              message: `The date and time conflicts with the ${ordinalNumber(
                collision.indexB + 1,
              )} date and time.`,
            })
            setError(`dateTime[${collision.indexB}].id`, {
              message: `The date and time conflicts with the ${ordinalNumber(
                collision.indexA + 1,
              )} date and time.`,
            })
          } else {
            next({
              type,
              dateTime: isTeacher()
                ? values.dateTime.map((x: any) => ({
                    ...x,
                    teacher_id: teachers[0].id,
                  }))
                : values.dateTime,
            })
          }
        })}
      >
        <ul className="mb-5">
          {fields.map((item: any, index: any) => (
            <li key={item.id}>
              {index > 0 && <hr className="my-4 border-gray-500" />}

              <input
                type="hidden"
                name={`dateTime[${index}].id`}
                value={item?.id}
                ref={register}
              />

              {type === Class_Recurrence_Enum.OneTime ? (
                <div className="py-2">
                  <div>
                    <FormField
                      type="date"
                      name={`dateTime[${index}].date`}
                      min={moment().format('YYYY-MM-DD')}
                      register={register}
                      label="Date"
                      defaultValue={item?.date}
                      data-testid={`input-date`}
                    />

                    {errors.dateTime?.[index]?.date && (
                      <ErrorMessage>
                        {errors.dateTime?.[index]?.date?.message}
                      </ErrorMessage>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className="py-2">
                    <FormField
                      type="dropdown"
                      name={`dateTime[${index}].day`}
                      defaultValue={item?.day}
                      error={errors.dateTime?.[index]?.day?.message}
                      placeholder="Select a Day"
                      control={control}
                      registerOptions={{
                        valueAsNumber: true,
                      }}
                      data-testid={`input-day`}
                    >
                      {moment.weekdays().map((text, day) => (
                        <DropdownOption key={`weekday-${day}`} value={day}>
                          {text}
                        </DropdownOption>
                      ))}
                    </FormField>
                  </div>
                </>
              )}

              {/* Date & Time */}
              <div className="py-2">
                <FormStartEndTime
                  editStart={item?.start}
                  editEnd={item?.end}
                  startFieldName={`dateTime[${index}].start`}
                  endFieldName={`dateTime[${index}].end`}
                  control={control}
                  errors={errors}
                />
              </div>

              {/* Teachers */}
              {!isTeacher() && (
                <div className="py-2">
                  <FormField
                    defaultValue={item?.teacher_id}
                    type="dropdown"
                    name={`dateTime[${index}].teacher_id`}
                    label="Teacher"
                    control={control}
                    error={errors.dateTime?.[index]?.teacher_id?.message}
                    placeholder="Select a teacher"
                    data-testid="input-teacher"
                    onChange={(value) => {
                      if (value === 'add') {
                        modal.open({
                          header: 'Add Teacher',
                          body: (
                            <Container topBottomSpace>
                              <FormStudioTeacher
                                onAdded={(newTeacher) => {
                                  client.writeQuery({
                                    query: QUERY_TEACHERS,
                                    data: {
                                      teacher: [...teachers, newTeacher],
                                    },
                                  })

                                  setTimeout(() => {
                                    setValue(
                                      `dateTime[${index}].teacher_id`,
                                      newTeacher?.id,
                                    )
                                    modal.close()
                                  })
                                }}
                              />
                            </Container>
                          ),
                        })

                        setTimeout(() => {
                          setValue(`dateTime[${index}].teacher_id`, '')
                        }, TRANSITION_DURATION)
                      }
                    }}
                  >
                    {teachers.map(({ id, person }: any) => (
                      <DropdownOption key={`teacher-${id}`} value={id}>
                        {getPersonName(person)}
                      </DropdownOption>
                    ))}
                    <DropdownOption value="add">Add Teacher</DropdownOption>
                  </FormField>
                </div>
              )}

              {errors.dateTime?.[index]?.id?.message && (
                <ErrorMessage>
                  {errors.dateTime?.[index]?.id?.message}
                </ErrorMessage>
              )}

              {/* Remove form fields section */}
              {fields.length > 1 && (
                <button
                  type="button"
                  className="py-2 text-primary"
                  data-testid="button-remove"
                  onClick={() => {
                    alert.send({
                      body: (
                        <>
                          <p>Do you want to remove this date and time?</p>
                          {initialValues ? (
                            <p className="pt-4 text-red-500">
                              This action will cancel all bookings related to
                              this class and date and time. Instead, we
                              recommend to edit the date and time.
                            </p>
                          ) : null}
                        </>
                      ),
                      buttons: [
                        {
                          children: 'Yes, remove',
                          onClick: () => {
                            remove(index)
                          },
                        },
                        {
                          $type: 'tertiary',
                          children: 'Cancel',
                        },
                      ],
                    })
                  }}
                >
                  Remove
                </button>
              )}
            </li>
          ))}
        </ul>

        {/* Add form section */}
        <div className="py-2">
          <Button
            type="button"
            $type="secondary"
            $fluid
            data-testid="button-add-time"
            onClick={() => {
              append({})
            }}
          >
            + Add another time
          </Button>
        </div>

        {/* Submit */}
        <div className="py-2">
          <Button
            type="submit"
            $type="primary"
            $fluid
            data-testid="button-submit"
          >
            {clazz ? 'Save changes' : 'Create'}
          </Button>
        </div>
      </form>

      <SpaceForKeyboard $size="lg" />
    </>
  )
}
