import moment from 'moment'
import milesToGeodistance from 'utils/milesToGeodistance'
import { VirtualClassOrLocation } from 'components/SearchLocation'
import { TimeOfTheDay } from 'types'
import {
  DB_DATE_FORMAT,
  SEARCH_CLASS_DISTANCE_IN_MILES,
  TIME_OF_THE_DAY_RANGE,
} from 'globalConstants'
import { Values } from 'screens/SortAndFilter'
import { daysToDates, shouldStartTomorrow } from './date'

/**
 * Creates GraphQL variables to filter Classes based on user selection (sortAndFilter) and location
 * @param location user's location
 * @param timeOfTheDay time of the day we currently are (e.g. afternoon)
 * @param sortAndFilter sort and filter data
 */
const searchFilterVariables = (
  location: VirtualClassOrLocation,
  timeOfTheDay: TimeOfTheDay,
  sortAndFilter: Values | null,
  accountID?: string,
): Record<string, any> => {
  const now = moment.utc()
  const startTomorrow = shouldStartTomorrow(now, timeOfTheDay)
  const from = moment.utc().add(startTomorrow ? 1 : 0, 'days')
  const until = moment.utc().add(startTomorrow ? 7 : 8, 'days')

  const variables = {
    where: {
      account_id: accountID ? { _neq: accountID } : undefined,
      online: location.virtualClassEverywhere
        ? {
            _eq: true,
          }
        : undefined,
      location: !location.virtualClassEverywhere
        ? {
            coordinates: {
              _st_d_within: {
                distance: milesToGeodistance(SEARCH_CLASS_DISTANCE_IN_MILES),
                from: {
                  type: 'Point',
                  coordinates: [
                    location.place?.coordinates?.lng,
                    location.place?.coordinates?.lat,
                  ],
                },
              },
            },
          }
        : undefined,
      yogaType:
        sortAndFilter?.types && sortAndFilter?.types.length > 0
          ? {
              _in: sortAndFilter?.types,
            }
          : undefined,
      level:
        sortAndFilter?.levels && sortAndFilter?.levels.length > 0
          ? {
              _in: [...sortAndFilter?.levels, 'all'],
            }
          : undefined,
      setting:
        sortAndFilter?.setting &&
        sortAndFilter?.setting.length > 0 &&
        !location.virtualClassEverywhere &&
        !sortAndFilter?.online
          ? {
              _in: sortAndFilter?.setting,
            }
          : undefined,
      dates_times: {
        _and: [
          !process.env.REACT_APP_EXPERIMENT_DISABLE_CLASSES_TABS
            ? timeOfTheDay === 'evening'
              ? {
                  _or: [
                    {
                      start: {
                        _gte: TIME_OF_THE_DAY_RANGE[timeOfTheDay][0],
                      },
                    },
                    {
                      start: {
                        _lt: TIME_OF_THE_DAY_RANGE[timeOfTheDay][1],
                      },
                    },
                  ],
                }
              : {
                  start: {
                    _gte: TIME_OF_THE_DAY_RANGE[timeOfTheDay][0],
                    _lt: TIME_OF_THE_DAY_RANGE[timeOfTheDay][1],
                  },
                }
            : undefined,
          {
            _or: [
              {
                date: {
                  _is_null: true,
                },
              },
              {
                date: {
                  _gte: from.format(DB_DATE_FORMAT),
                  _lte: until.format(DB_DATE_FORMAT),
                },
              },
            ],
          },
          sortAndFilter?.days &&
            sortAndFilter.days.length > 0 && {
              _or: [
                ...sortAndFilter.days.map((day) => ({
                  day: {
                    _eq: day,
                  },
                })),
                ...daysToDates(moment(), sortAndFilter.days, {
                  from: now,
                  formatted: true,
                }).map((day) => ({
                  date: {
                    _eq: day,
                  },
                })),
              ],
            },
        ].filter(Boolean),
      },
    },
  }

  return variables
}

export default searchFilterVariables
