import { RouteComponentProps } from '@reach/router'
import { ReactComponent as ChildPose } from 'assets/illustrations/childs-pose.svg'
import Container from 'components/Container'
import { useEffect, useState } from 'react'

const NotFound = (_props: RouteComponentProps) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRender(true)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (!render) {
    return null
  }

  return (
    <Container topBottomSpace>
      <div className="text-center p-12">
        <ChildPose className="fill-gray-500 h-16 inline-block" />
        <h1
          className="heading-1 text-gray-700 py-8"
          style={{ fontSize: '3em' }}
        >
          Not Found
        </h1>
        <p>Looks like what you are looking for is not available.</p>
      </div>
    </Container>
  )
}

export default NotFound
