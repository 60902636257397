import React from 'react'
import cc from 'classcat'
import useIsMounted from 'hooks/useIsMounted'

import styles from './index.module.scss'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const cache: Record<string, boolean> = {}

interface Props {
  image: string
  width?: number | string
  height?: number | string
  className?: string
  children?: any
  style?: any
  innerRef?: any
  loadHandler?: boolean
}

const BackgroundImage = ({
  image,
  width,
  height,
  className,
  children,
  style,
  innerRef,
  loadHandler,
}: Props) => {
  const isMounted = useIsMounted()
  // prevents flickering when we re-render the component
  const hasBeenLoadedBefore: boolean = cache[image]
  const [loaded, setLoaded] = React.useState(hasBeenLoadedBefore)

  React.useEffect(() => {
    if (loadHandler && !hasBeenLoadedBefore) {
      const img = new Image()

      img.onload = () => {
        cache[image] = true
        if (isMounted.current) {
          setLoaded(true)
        }
      }

      img.src = image
    }
  }, [loadHandler, hasBeenLoadedBefore, image])

  return (
    <SwitchTransition>
      <CSSTransition
        key={!loadHandler || loaded ? 'loaded' : 'not-loaded'}
        classNames={styles}
        timeout={200}
        appear
      >
        <div
          className={cc([
            styles.root,
            'block bg-gray-400 transition-all',
            className,
          ])}
          style={{
            ...style,
            backgroundImage: !loadHandler || loaded ? `url(${image})` : 'none',
            width,
            height,
          }}
          ref={innerRef}
        >
          {children}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
}

BackgroundImage.defaultProps = {
  style: {},
  loadHandler: true,
}

export default BackgroundImage
