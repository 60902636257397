const ordinalNumber = (n: number) => {
  if (n === 1) {
    return '1st'
  }
  if (n === 2) {
    return '2nd'
  }
  if (n === 3) {
    return '3rd'
  }
  if (n > 3) {
    return `${n}th`
  }

  return n
}

export default ordinalNumber
