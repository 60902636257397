import React from 'react'
import { Control, Controller } from 'react-hook-form'
import InputRange, { Range } from 'react-input-range'

type CommonProps = {
  min: number
  max: number
  prefix?: string
  postfix?: string
}

type SliderProps = CommonProps & {
  value?: string
  onChange: (value: string) => void
}

type Props = CommonProps & {
  name: string
  control: Control
}

const getValidValue = (value?: string): Range | number | null => {
  if (value == null) {
    return null
  }

  if (value.includes(',')) {
    const [min, max] = value.split(',')

    return {
      min: parseInt(min, 10),
      max: parseInt(max, 10),
    }
  }

  return parseInt(value, 10)
}

const Slider = ({
  min,
  max,
  prefix,
  postfix,
  value,
  onChange,
}: SliderProps) => {
  const validValue = getValidValue(value) || min

  return (
    <div className="relative mt-6">
      <InputRange
        draggableTrack
        minValue={min}
        maxValue={max}
        value={validValue}
        formatLabel={(value) =>
          [prefix, value, postfix].filter(Boolean).join('')
        }
        onChange={(value) => {
          if (typeof value === 'number') {
            onChange([value].join(''))
          } else {
            onChange([value.min, value.max].join(','))
          }
        }}
      />
    </div>
  )
}

const InputSlider = ({ control, name, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <Slider {...rest} value={value} onChange={onChange} />
      )}
    />
  )
}

export default InputSlider
