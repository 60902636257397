export const getEnv = (key: string, safe?: boolean) => {
  const possibleKeys = [key.toUpperCase(), `REACT_APP_${key.toUpperCase()}`]
  const value = possibleKeys.reduce(
    (value, key) => value || process.env[key] || '',
    '',
  )

  if (!safe && (value == null || value === '')) {
    throw new Error(
      `Missing required environment variable "${key}". Searched it as ${possibleKeys.join(
        ', ',
      )}`,
    )
  }

  return value
}

export const getAllEnvs = () => process.env

export const isWebapp = () => getEnv('source_mode', true) === 'webapp'
