import React from 'react'
import Button, { Props as ButtonProps } from 'components/Button'
import cc from 'classcat'
import styles from './index.module.scss'

type Type = 'info' | 'danger'

type Alert = {
  $type?: Type
  title?: string
  body?: React.ReactNode
  buttons: ButtonProps[]
} & (
  | {
      title: string
    }
  | {
      body: React.ReactNode
    }
)

type ContextValue = {
  send: (alert: Alert) => void
  dismiss: () => void
}

const Context = React.createContext<ContextValue>({
  send: () => {
    throw new Error(`Cannot use Alert 'send' outside "AlertProvider"`)
  },
  dismiss: () => {
    throw new Error(`Cannot use Alert 'dismiss' outside "AlertProvider"`)
  },
})

type AlertProviderProps = {
  children: any
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [props, setProps] = React.useState<Alert | null>(null)
  const send = (alert: Alert) => setProps(alert)
  const dismiss = () => setProps(null)

  return (
    <Context.Provider value={{ send, dismiss }}>
      {children}
      {props && <AlertModal {...props} dismiss={dismiss} />}
    </Context.Provider>
  )
}

export const useAlert = () => React.useContext(Context)

type AlertModalProps = {
  dismiss: () => void
} & Alert

const AlertModal = ({
  dismiss,
  $type,
  title,
  body,
  buttons,
}: AlertModalProps) => {
  return (
    <div
      className="w-screen h-screen bg-gray-900 bg-opacity-75 flex items-center justify-center fixed top-0 z-994"
      data-testid="alert"
    >
      <div
        className={cc([
          'w-full max-w-lg m-4 p-3 py-10 text-center space-y-3 shadow-lg overflow-y-auto',
          {
            'bg-white text-green': $type === 'info',
            'bg-red-600 text-white': $type === 'danger',
          },
          {
            [styles.alert]: true,
          },
        ])}
        data-testid={`alert-type-${$type}`}
        style={{
          maxHeight: '90%',
        }}
      >
        {/* Title */}
        {title && (
          <div data-testid="alert-title" className="heading-1">
            {title}
          </div>
        )}

        {/* Body */}
        {body && (
          <div data-testid="alert-body" className="pb-2">
            {body}
          </div>
        )}

        {/* Buttons */}
        {buttons.map(({ onClick, ...rest }, i) => (
          <Button
            data-testid={`alert-additional-button-${i}`}
            key={`additional-button-${i}`}
            {...rest}
            type="button"
            $fluid
            $inverted={$type === 'danger'}
            onClick={async () => {
              await onClick?.()

              dismiss()
            }}
          />
        ))}
      </div>
    </div>
  )
}

AlertModal.defaultProps = {
  $type: 'info',
}

export default AlertModal
