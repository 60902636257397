import React from 'react'
import { Control, Controller } from 'react-hook-form'
import cc from 'classcat'

type Grid = {
  rows?: number
  colums: number
  gap?: number
}

type TextSize = 'xs' | 'sm' | 'md'

type Props = {
  name: string
  control: Control
  options: string[]
  grid: Grid
  $textSize: TextSize
  squared?: boolean
}

type SelectorProps = {
  name: string
  value: string
  grid: Grid
  options: string[]
  onChange?: (values: string) => void
  $textSize: TextSize
  squared?: boolean
}

const initialValues = (options: string[], values: string[]) =>
  options.map((opt) => values.includes(opt))

const Selector = ({
  name,
  value,
  options,
  onChange,
  grid: { rows, colums, gap },
  $textSize,
  squared,
}: SelectorProps) => {
  const [activeValues, setActiveValues] = React.useState(
    initialValues(options, value.split(',')),
  )

  const setValues = (index: number) => {
    const newState = [...activeValues]

    newState[index] = !newState[index]

    setActiveValues(newState)

    if (onChange) {
      onChange(
        options?.filter((_v: string, i: number) => !!newState[i]).join(','),
      )
    }
  }

  return (
    <div
      className={cc([
        'grid gap-1',
        rows && `grid-rows-${rows}`,
        `grid-cols-${colums}`,
        gap && `gap-${gap}`,
      ])}
    >
      {options?.map((type, index) => {
        const isActive = activeValues[index]

        return (
          <button
            key={`circle-checkbox-${name}-${index}`}
            type="button"
            className={cc([
              'relative rounded-full w-full',
              {
                'bg-primary text-nude': isActive,
                'bg-white text-primary': !isActive,
                'p-6': !squared,
                'heading-2': $textSize === 'md',
                'heading-3': $textSize === 'sm',
                'heading-4': $textSize === 'xs',
              },
            ])}
            onClick={() => {
              setValues(index)
            }}
            style={
              squared
                ? {
                    paddingTop: '100%',
                  }
                : {}
            }
          >
            {squared ? (
              <span className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                {type}
              </span>
            ) : (
              type
            )}
          </button>
        )
      })}
    </div>
  )
}

const CirlceCheckboxes = ({ name, control, ...rest }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <Selector
          {...{
            name,
            value,
            onChange,
            ...rest,
          }}
        />
      )}
    />
  )
}

CirlceCheckboxes.defaultProps = {
  $textSize: 'sm',
}

export default CirlceCheckboxes
