import GenericQueryRender from 'components/GenericQueryRender'
import Container from 'components/Container'
import StackNavigation, { makeContext } from 'components/StackNavigation'
import { QUERY_LOCATIONS_BY_ACCOUNT } from 'graphql/queries'
import { useAuth } from 'stores/auth.store'
import NoResults from './NoResults'
import List from './List'

const Render = ({ navigate }: any) => {
  const { account, isAuthenticated } = useAuth()

  if (!isAuthenticated()) {
    return <Container topBottomSpace>You need to login first</Container>
  }

  return (
    <GenericQueryRender
      query={QUERY_LOCATIONS_BY_ACCOUNT}
      variables={{
        accountID: account.id,
      }}
      dataAccess="location"
      additionalProps={{
        navigate,
      }}
      NoResults={NoResults}
      Success={List}
    />
  )
}

const context = makeContext()

const Locations = () => {
  return <StackNavigation Context={context} Component={Render} />
}

export default Locations
