import React from 'react'
import useCamera, { PhotoBase64 } from 'hooks/useCamera'
import RoundedPicture from 'components/RoundedPicture'
import { useAuth } from 'stores/auth.store'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'
import { ApolloError, useApolloClient } from '@apollo/client'
import { UPLOAD_PICTURE } from 'graphql/mutations'
import Loading from 'components/Loading'
import ServerErrorMessage from 'components/ServerErrorMessage'
import useLogger from 'hooks/useLogger'
import patternMatching from 'utils/patternMatching'
import useIsMounted from 'hooks/useIsMounted'
import {
  UpdateProfilePersonDocument,
  UpdateProfileEntityDocument,
} from 'generated/graphql'

type Props = {
  size: number
  onChange?: (newPicture: string, hasAccountChanged: boolean) => void
  onAction?: (action: 'loading' | 'loaded') => void
}

const MyProfilePicture = ({ size, onChange, onAction }: Props) => {
  const { isAuthenticated, account, updateAccount, isEntity } = useAuth()
  const { log } = useLogger()
  const { mutate } = useApolloClient()
  const { getPhoto } = useCamera()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<ApolloError | null>()
  const [picture, setPicture] = React.useState(account.profile?.picture)
  const isMounted = useIsMounted()

  const onUpdate = ({ format, base64String }: PhotoBase64) => {
    setLoading(true)
    onAction?.('loading')

    mutate({
      mutation: UPLOAD_PICTURE,
      variables: {
        format,
        base64String,
      },
    })
      .then(({ data }) =>
        isAuthenticated()
          ? mutate({
              mutation: isEntity()
                ? UpdateProfileEntityDocument
                : UpdateProfilePersonDocument,
              variables: {
                id: account.profile?.id,
                set: {
                  picture: data.uploadPicture.url,
                },
                // TODO: Temporary until we migrate Profile into Account
                accountID: account.id,
                accountSet: {},
              },
            })
          : { data },
      )
      .then(({ data }) => {
        const newPicture = isAuthenticated()
          ? data[
              isEntity()
                ? 'update_profile_entity_by_pk'
                : 'update_profile_person_by_pk'
            ].picture
          : data.uploadPicture.url

        if (isAuthenticated()) {
          updateAccount({
            ...account,
            profile: account.profile
              ? {
                  ...account.profile,
                  picture: newPicture,
                }
              : undefined,
          })
        }

        if (isMounted.current) {
          setLoading(false)
          onAction?.('loaded')
          setError(null)
          setPicture(newPicture)
          onChange?.(newPicture, isAuthenticated())
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setLoading(false)
          onAction?.('loaded')
          setError(err)
          log('error', 'profile picture upload', {
            error: err.message,
          })
        }
      })
  }

  if (loading) {
    return (
      <div
        className="inline-flex flex-col justify-center items-center bg-white"
        style={{
          width: size,
          height: size,
          borderRadius: size,
        }}
      >
        <Loading>Uploading...</Loading>
      </div>
    )
  }

  if (picture) {
    return (
      <>
        <button
          className="relative inline-block"
          onClick={() => {
            getPhoto().then(onUpdate)
          }}
          data-testid="profile-picture-upload"
        >
          <RoundedPicture image={picture} size={size} />
          <div
            className="absolute inline-flex justify-center items-center bg-primary"
            style={{
              width: 35,
              height: 35,
              borderRadius: 35,
              bottom: -7,
              left: '50%',
              marginLeft: 20,
            }}
          >
            <IconCamera width={17} className="fill-white" />
          </div>
        </button>
        {error && (
          <div className="pt-2">
            <ServerErrorMessage>
              {patternMatching<string, string>(
                [['request entity too large', 'The picture is too large']],
                error.message,
              )(error.message)}
            </ServerErrorMessage>
          </div>
        )}
      </>
    )
  }

  // No picture yet
  return (
    <>
      <button
        className="inline-flex justify-center items-center bg-primary"
        style={{
          width: size,
          height: size,
          borderRadius: size,
        }}
        onClick={() => {
          getPhoto().then(onUpdate)
        }}
        data-testid="profile-picture-not-upload"
      >
        <IconCamera width={25} className="fill-white" />
      </button>
      {error && (
        <div className="pt-2">
          <ServerErrorMessage>
            {patternMatching<string, string>(
              [['request entity too large', 'The picture is too large']],
              error.message,
            )(error.message)}
          </ServerErrorMessage>
        </div>
      )}
    </>
  )
}

export default MyProfilePicture
