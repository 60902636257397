import { useEffect } from 'react'
import Button from 'components/Button'
import { useModalScreen } from 'components/ModalScreen'
import { TAWK_ID } from 'globalConstants'
import ExternalPage from 'screens/ExternalPage'
import { ReactComponent as IconShape } from 'assets/icons/headphones.svg'
import { getEnv, isWebapp } from 'utils/env'

const LiveSupportButton = () => {
  const modal = useModalScreen()

  useEffect(() => {
    if (isWebapp() && getEnv('NODE_ENV') === 'production') {
      const id = '__tawk'

      if (!document.getElementById(id)) {
        ;(window as any).Tawk_LoadStart = new Date()
        const tawkScript = document.createElement('script')
        const anyScript = document.getElementsByTagName('script')[0]

        tawkScript.id = id
        tawkScript.async = true
        tawkScript.src = `https://embed.tawk.to/${TAWK_ID}`
        tawkScript.setAttribute('crossorigin', '*')

        anyScript?.parentNode?.insertBefore(tawkScript, anyScript)
      }
    }
  }, [])

  if (isWebapp()) {
    return null
  }

  return (
    <Button
      type="button"
      $type="tertiary"
      $fluid
      onClick={() => {
        const { Tawk_API } = window as any

        if (Tawk_API) {
          Tawk_API.toggle()
        } else {
          modal.open({
            header: 'Live Support',
            body: (
              <ExternalPage
                title="Live Support"
                url={`https://tawk.to/chat/${TAWK_ID}/?$_tawk_popout=true`}
              />
            ),
          })
        }
      }}
      className="flex justify-center items-center "
    >
      <IconShape className="mr-3 fill-primary" width="20" />
      Live Support
    </Button>
  )
}

export default LiveSupportButton
