const minTwoDigitsNumber = (n: number, min = 1): string => {
  if (n < min) {
    return minTwoDigitsNumber(min)
  }
  if (n > 99) {
    return minTwoDigitsNumber(99)
  }

  return n < 10 ? `0${n}` : n.toString()
}

export default minTwoDigitsNumber
