import React, { Fragment } from 'react'
import cc from 'classcat'
import { take, first, drop } from 'lodash/fp'
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'components/GridDnD'
import Selected from './Selected'
import Empty from './Empty'
import { UPLOAD_PICTURE } from 'graphql/mutations'
import { useApolloClient } from '@apollo/client'
import useCamera, { PhotoBase64 } from 'hooks/useCamera'
import ProgressBar from 'components/ProgressBar'
import useIsMounted from 'hooks/useIsMounted'

type Status = {
  error?: string
}

type Picture = {
  key?: string | number
  url?: string
  raw?: PhotoBase64
} & Status

type ContextProps = {
  limit: number
  initialPictures?: string[]
  onChange?: (change: boolean) => void
  children: React.ReactNode
}

const Context = React.createContext<{
  trigger: () => void
  limit: number
  pictures: Picture[]
  setPictures: (newPictures: Picture[]) => void
  upload: number[] | null
  setUpload: (state: number[] | null) => void
  totalToUpload: number
  setTotalToUpload: (percentage: number) => void
  isDirty: boolean
  setIsDirty: (state: boolean) => void
  onChange?: (change: boolean) => void
}>({
  trigger: () => undefined,
  limit: 2,
  pictures: [],
  setPictures: () => undefined,
  upload: null,
  setUpload: () => undefined,
  totalToUpload: 0,
  setTotalToUpload: () => undefined,
  isDirty: false,
  setIsDirty: () => undefined,
})

export const MultiPictureUploadContext = ({
  limit,
  initialPictures,
  onChange,
  children,
}: ContextProps) => {
  const [pictures, setPictures] = React.useState<Picture[]>(
    take(
      limit,
      initialPictures?.map((url) => ({ url })),
    ),
  ) // supply your own state
  const [upload, setUpload] = React.useState<number[] | null>(null)
  const [totalToUpload, setTotalToUpload] = React.useState(0)
  const [isDirty, setIsDirty] = React.useState(false)
  /**
   * Triggers a change/save. It is exposed via the 'children' prop
   */
  const trigger = async () => {
    const newPicturesAdded = pictures
      .map((picture, index) => (picture !== null && !picture?.url ? index : -1))
      .filter((n) => n > -1) as number[]
    setUpload(newPicturesAdded)
    setTotalToUpload(newPicturesAdded.length)
  }

  return (
    <Context.Provider
      value={{
        trigger,
        limit,
        pictures,
        setPictures,
        upload,
        setUpload,
        totalToUpload,
        setTotalToUpload,
        isDirty,
        setIsDirty,
        onChange,
      }}
    >
      {children}
    </Context.Provider>
  )
}

type Props = {
  onDone?: (
    currentPictures: Picture[],
    setIsDirty: (state: boolean) => void,
  ) => void
  children?: (
    trigger: () => void,
    status: { isDirty: boolean; loading: boolean },
  ) => React.ReactNode
}

const MultiPictureUpload = ({ onDone, children }: Props) => {
  const {
    limit,
    pictures,
    setPictures,
    upload,
    setUpload,
    totalToUpload,
    setTotalToUpload,
    isDirty,
    setIsDirty,
    trigger,
    onChange,
  } = React.useContext(Context)
  const HEIGHT = 160
  const COLUMNS = 2
  const client = useApolloClient()
  const { getPhoto } = useCamera()
  const [isDragging, setIsDragging] = React.useState(false)

  const safePictureUpload = (picture: Picture) =>
    new Promise<{ error?: string; url?: string }>((resolve) => {
      client
        .mutate({
          mutation: UPLOAD_PICTURE,
          variables: {
            format: picture.raw?.format,
            base64String: picture.raw?.base64String,
          },
        })
        .then((response) => {
          if (response.errors) {
            resolve({
              error: response.errors[0].message,
            })
          } else {
            resolve({
              url: response.data.uploadPicture.url,
            })
          }
        })
        .catch((err) => {
          resolve({
            error: err.message,
          })
        })
    })

  React.useEffect(() => {
    if (upload) {
      if (limit === 1) {
        const picture = pictures[0] as Picture
        safePictureUpload(picture).then(({ error, url }) => {
          const newPicture = error
            ? {
                ...picture,
                error,
              }
            : {
                ...picture,
                url,
              }
          setPictures([newPicture])

          setUpload(null)
          onDone?.([newPicture], setIsDirty)
        })
      } else {
        if (upload.length > 0) {
          const index = first(upload) as number
          const picture = pictures[index] as Picture
          safePictureUpload(picture).then(({ error, url }) => {
            const newPicture = error
              ? {
                  ...picture,
                  error,
                }
              : {
                  ...picture,
                  url,
                }

            setPictures(
              pictures.map((pic, i) => (i === index ? newPicture : pic)),
            )
            setUpload(drop(1, upload))
          })
        } else {
          setUpload(null)
          setTotalToUpload(0)

          onDone?.(
            pictures.filter((picture) => picture !== null) as Picture[],
            setIsDirty,
          )
        }
      }
    }
  }, [upload])

  /**
   * Uses Camera plugin to select and set a picture
   */
  const addPictureFromDevice = async () => {
    getPhoto({
      width: 600,
    }).then((image) => {
      setPictures(
        pictures.concat({
          key: Date.now(),
          raw: image,
        }),
      )
      setIsDirty(true)
      if (limit === 1) {
        trigger()
        !!onChange && onChange(true)
      }
    })
  }

  return (
    <>
      <div className="relative -m-2">
        <ul
          className={cc([
            'grid',
            {
              'grid-cols-2': limit > 1,
            },
          ])}
        >
          {new Array(limit).fill(null).map((_v, index) => (
            <li
              key={`empty-${index}`}
              className={cc([
                'p-2',
                {
                  'z-2': !isDragging && pictures[index] == null,
                },
              ])}
              style={{
                height: HEIGHT,
              }}
            >
              {pictures[index] == null && (
                <Empty
                  onClick={() => {
                    addPictureFromDevice()
                  }}
                  disabled={totalToUpload > 0}
                />
              )}
            </li>
          ))}
        </ul>
        <div className="absolute top-0 left-0 w-full">
          <GridContextProvider
            onChange={(_sourceId, sourceIndex, targetIndex) => {
              const nextState = swap(pictures, sourceIndex, targetIndex)
              setPictures(nextState)
              setIsDirty(true)
            }}
          >
            <GridDropZone
              id="items"
              boxesPerRow={limit === 1 ? 1 : COLUMNS}
              rowHeight={HEIGHT}
              style={{
                height: HEIGHT * Math.ceil(pictures.length / COLUMNS),
              }}
              onMove={() => {
                setIsDragging(true)
              }}
              onEnd={() => {
                setIsDragging(false)
              }}
            >
              {pictures.map((picture, index) =>
                limit > 1 ? (
                  <GridItem
                    key={`selected-${picture.key || picture.url || index}`}
                  >
                    <>
                      <Selected
                        image={
                          picture.raw?.base64String
                            ? `data:image/png;base64,${picture.raw?.base64String}`
                            : (picture.url as string)
                        }
                        onRemove={() => {
                          setPictures(pictures.filter((pic) => pic !== picture))
                          setIsDirty(true)
                        }}
                        disabled={totalToUpload > 0}
                        active={index === 0}
                      />
                      {picture.error && (
                        <div className="absolute bottom-0 left-0 w-full p-2 body-2 text-white bg-red-500">
                          {picture.error}
                        </div>
                      )}
                    </>
                  </GridItem>
                ) : (
                  <Fragment
                    key={`selected-${picture.key || picture.url || index}`}
                  >
                    <Selected
                      image={
                        picture.raw?.base64String
                          ? `data:image/png;base64,${picture.raw?.base64String}`
                          : (picture.url as string)
                      }
                      onRemove={() => {
                        setPictures(pictures.filter((pic) => pic !== picture))
                        setIsDirty(true)
                      }}
                      disabled={totalToUpload > 0}
                      active={index === 0}
                    />
                    {picture.error && (
                      <div className="absolute bottom-0 left-0 w-full p-2 body-2 text-white bg-red-500">
                        {picture.error}
                      </div>
                    )}
                  </Fragment>
                ),
              )}
            </GridDropZone>
          </GridContextProvider>
        </div>
      </div>

      {upload && (
        <div className="pt-4">
          <ProgressBar
            value={
              totalToUpload - upload.length > 0
                ? ((totalToUpload - upload.length) * 100) / totalToUpload
                : 0
            }
          />
          <div className="pt-1 body-2 text-gray-600">
            {limit === 1 ? (
              <UploadingWithTimer />
            ) : (
              `Uploaded ${totalToUpload - upload.length} of ${totalToUpload}`
            )}
          </div>
        </div>
      )}

      {children?.(trigger, { isDirty, loading: totalToUpload > 0 })}
    </>
  )
}

const UploadingWithTimer = () => {
  const [takingTime, setTakingTime] = React.useState(false)
  const mount = useIsMounted()

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (mount.current) {
        setTakingTime(true)
      }
    }, 1000 * 5)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  if (takingTime) {
    return <>Still uploading... be patient, looks like your picture is large!</>
  }

  return <>Uploading...</>
}

export default MultiPictureUpload
