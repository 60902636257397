import React from 'react'
import { ReactComponent as Start } from 'assets/icons/star.svg'
import { ReactComponent as StartFilled } from 'assets/icons/star-filled.svg'

type Value = number

interface Props {
  value: Value
  color?: 'dark' | 'light'
  disabled?: boolean
}

const ReviewStars = ({ value, color, disabled }: Props) => {
  const parsedValue = numberToReviewValue(value)

  return (
    <div className="inline-grid grid-cols-5 gap-0.5">
      {new Array(5).fill(null).map((_v, index) => {
        const Component = parsedValue > index ? Start : StartFilled

        return (
          <Component
            key={`start-${index}`}
            width={13}
            height={13}
            className={
              disabled
                ? 'fill-gray-500'
                : color === 'light'
                ? 'fill-white'
                : 'fill-primary'
            }
          />
        )
      })}
    </div>
  )
}

ReviewStars.defaultProps = {
  value: 0,
}

export const numberToReviewValue = (n: number): Value => {
  if (n < 0 || !n) {
    return 0
  }

  if (n > 5) {
    return 5
  }

  const intN = parseInt(n.toString(), 10)

  return n - intN < 0.5 ? intN : intN + 1
}

export default ReviewStars
