export type Props = {
  title: string
  subtitle?: string
}

const FormGroupTitle = ({ title, subtitle }: Props) => {
  return (
    <>
      <h2 className="heading-2 pb-2 text-primary text-center">{title}</h2>
      {subtitle && (
        <h3 className="body-3 pb-2 text-gray-600 text-center">{subtitle}</h3>
      )}
    </>
  )
}

export default FormGroupTitle
