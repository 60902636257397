import React from 'react'
import cc from 'classcat'
import Button from 'components/Button'
import Container from 'components/Container'
import Loading from 'components/Loading'
import ClassCard from 'components/ClassCard'
import ClassDetailsScreen from 'screens/ClassDetails'
import ManageClass from 'screens/ManageClass'
import { useSubscription } from '@apollo/client'
import { useAuth } from 'stores/auth.store'
import { useModalScreen } from 'components/ModalScreen'
import { SUBSCRIPTION_MY_CLASSES } from 'graphql/subscriptions'
import { RouteComponentProps } from '@reach/router'
import { Class, useAccountStatusQuery } from 'generated/graphql'
import { useAlert } from 'components/AlertModal'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'
import NothingYet from 'components/NothingYet'
import { isWebapp } from 'utils/env'
import { WithSupportedCountry } from 'components/SupportedCountry'

const MyClasses = (props: RouteComponentProps) => {
  const modal = useModalScreen()
  const alert = useAlert()
  const { account } = useAuth()
  const { loading, error, data } = useSubscription(SUBSCRIPTION_MY_CLASSES, {
    variables: {
      accountID: account.id,
    },
  })
  const openCreateClass = () => {
    modal.open({
      body: (
        <ManageClass
          onSuccess={() => {
            modal.close()
          }}
          onCancel={() => {
            alert.send({
              body: 'You will lose any changes made.',
              buttons: [
                {
                  children: 'Yes, close',
                  onClick: () => {
                    modal.close()
                  },
                },
                {
                  $type: 'tertiary',
                  children: 'Cancel',
                },
              ],
            })
          }}
        />
      ),
    })
  }
  const state = props.location?.state as LocationState
  const shouldOpenClassModal = !!state?.openCreateClass

  React.useEffect(() => {
    if (shouldOpenClassModal && !modal.isOpen) {
      setTimeout(() => {
        openCreateClass()
      }, 200)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <Container topBottomSpace className="h-full">
        <Loading context="Studio/Teacher list of classes" />
      </Container>
    )
  }

  if (error) {
    console.error(error)
    return <div>Something wrong happened</div>
  }

  const classes = data.class

  if (classes.length < 1) {
    return (
      <div className="h-full">
        <NothingYet
          title="The best beginning is today."
          description={
            <>
              Create your first class to begin your
              <br />
              Ohmunity™ yoga journey.
            </>
          }
        />
        <CreateClassButton openCreateClass={openCreateClass} />
      </div>
    )
  }

  return (
    <Container topBottomSpace className="safe-area-mt">
      <CreateClassButton openCreateClass={openCreateClass} />

      <ul className="my-12">
        {classes.map((clazz: Class, i: number) => (
          <li key={`class-card-${i}`} className="py-2">
            <ClassCard
              clazz={clazz}
              onClick={(clazz) => {
                modal.open({
                  header: 'Class Details',
                  body: (
                    <ClassDetailsScreen
                      preloadedClass={clazz}
                      classId={clazz.id}
                      dateTimeID={clazz.dates_times?.[0]?.id}
                    />
                  ),
                })
              }}
              dateTimeID={clazz.dates_times?.[0]?.id}
              locatedAt={clazz.location?.name}
              to={`/class/${clazz.id}/${clazz.dates_times?.[0]?.id}`}
            />
          </li>
        ))}
      </ul>

      {classes.length >= 10 && (
        <CreateClassButton openCreateClass={openCreateClass} />
      )}
    </Container>
  )
}

export default WithSupportedCountry<RouteComponentProps>(MyClasses)

type LocationState = {
  openCreateClass?: boolean
}

const CreateClassButton = ({ openCreateClass }: any) => {
  const alert = useAlert()
  const { loading, data } = useAccountStatusQuery({
    fetchPolicy: 'network-only',
  })

  return (
    <div
      className={cc([
        {
          'fixed right-4 bottom-20 safe-area-mb': !isWebapp(),
          'py-2': isWebapp(),
        },
      ])}
    >
      <Button
        $type="info"
        $roundedSquare={!isWebapp()}
        $fluid={isWebapp()}
        onClick={() => {
          if (
            data &&
            data?.accountStatus.length > 0 &&
            data?.accountStatus.some((x) => !x?.completed)
          ) {
            alert.send({
              title: 'Your account is not quite ready yet',
              body:
                'The good news is you can still create classes and they will become public once the requirements are complete.',
              buttons: [
                {
                  children: 'Create class anyway',
                  onClick: () => {
                    openCreateClass()
                  },
                },
                {
                  $type: 'tertiary',
                  children: 'Cancel',
                },
              ],
            })
          } else {
            openCreateClass()
          }
        }}
        loading={loading}
        data-testid="button-create-class"
      >
        <Plus
          width={20}
          className="inline-block fill-white"
          style={{
            marginTop: -3,
          }}
        />{' '}
        {isWebapp() && 'Create Class'}
      </Button>
    </div>
  )
}
