import useMode from 'hooks/useMode'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import IOSSwitch from 'react-ios-switch'

import styles from './index.module.scss'

type UncontrolledProps = {
  value: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  ['data-testid']?: string
}

export const SwitchUncontrolled = ({
  value,
  disabled,
  onChange,
  ['data-testid']: dataTestID,
}: UncontrolledProps) => {
  const [mode] = useMode()

  return (
    <div className={styles.root} data-testid={dataTestID}>
      <IOSSwitch
        checked={!!value}
        disabled={disabled}
        onChange={onChange}
        offColor="#e0e0e0"
        onColor={mode === 'teach' ? '#8ea38d' : '#6f6d88'}
        style={{
          display: 'block',
        }}
      />
    </div>
  )
}

type Props = {
  name: string
  control: Control
  disabled?: boolean
  ['data-testid']?: string
}

const Switch = ({
  name,
  control,
  disabled,
  ['data-testid']: dataItemID,
}: Props) => (
  <Controller
    name={name}
    control={control}
    render={({ value, onChange }) => (
      <SwitchUncontrolled
        data-testid={dataItemID}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    )}
  />
)

Switch.defaultProps = {
  disabled: false,
}

export default Switch
