import React from 'react'
import { ReactComponent as Plus } from 'assets/symbols/plus.svg'
import { ReactComponent as Minus } from 'assets/symbols/minus.svg'
import useToggle from 'hooks/useToggle'
import Container from 'components/Container'

type Props = {
  label: string
  children: any
  noInnerSpace?: boolean
}

type ControllableProps = {
  isOpen: boolean
  toggle: () => void
}

export const CollapsableContentControllable = ({
  label,
  children,
  noInnerSpace,
  isOpen,
  toggle,
}: Props & ControllableProps) => {
  return (
    <div className="bg-nude">
      <button
        className="w-full flex justify-between items-center heading-3 text-primary py-6 px-5"
        onClick={() => {
          toggle()
        }}
        data-testid="button"
      >
        <div className="heading-3" data-testid="coll-label">
          {label}
        </div>
        {!isOpen ? <Plus className="w-4 h-4" /> : <Minus className="w-4 h-4" />}
      </button>
      {isOpen && (
        <Container xSpace={!noInnerSpace} bottomSpace={!noInnerSpace}>
          {children}
        </Container>
      )}
    </div>
  )
}

const CollapsableContent = (props: Props) => {
  const [isOpen, toggle] = useToggle(false)

  return (
    <CollapsableContentControllable
      {...props}
      isOpen={isOpen}
      toggle={toggle}
    />
  )
}

export default CollapsableContent
