import React from 'react'
import ReviewStars from 'components/ReviewStars'
import patternMatching from 'utils/patternMatching'
import { useQuery, DocumentNode } from '@apollo/client'
import {
  QUERY_TOTAL_CLASS_REVIEWS,
  QUERY_TOTAL_STUDIO_REVIEWS,
  QUERY_TOTAL_TEACHER_REVIEWS,
} from 'graphql/queries'
import useToggle from 'hooks/useToggle'
import Modal from 'components/Modal'
import Page from 'components/Page'
import ReviewsList from 'components/ReviewsList'
import { simpleHeader } from 'components/ModalScreen'
import Container from 'components/Container'

type Type = 'class' | 'studio' | 'teacher'

type Props = {
  type: Type
  id: string
}

const ReviewsButton = ({ type, id }: Props) => {
  const [query, variables, dataAccess] =
    patternMatching<Type, [DocumentNode, Record<string, string>, string]>([
      [
        'class',
        [QUERY_TOTAL_CLASS_REVIEWS, { classID: id }, 'class_review_aggregate'],
      ],
      [
        'studio',
        [
          QUERY_TOTAL_STUDIO_REVIEWS,
          { studioID: id },
          'studio_review_aggregate',
        ],
      ],
      [
        'teacher',
        [
          QUERY_TOTAL_TEACHER_REVIEWS,
          { teacherID: id },
          'teacher_review_aggregate',
        ],
      ],
    ])(type) || []
  const { loading, error, data } = useQuery(query!, {
    variables,
  })
  const [isOpen, toggle] = useToggle()

  const total = data ? data[dataAccess!].aggregate.count : undefined
  const average = data ? data[dataAccess!].aggregate.avg.value : undefined

  return (
    <>
      <button
        className="m-0"
        disabled={loading || !!error}
        onClick={() => {
          toggle()
        }}
        data-testid="review-button"
      >
        <div className="inline-flex items-center">
          <div className={loading ? 'animate-pulse' : ''}>
            <ReviewStars
              value={loading ? 5 : error ? 0 : average}
              disabled={!data}
            />
          </div>
          {!loading && !error && (
            <span className="text-primary pl-2 body-2">{total} Reviews</span>
          )}
        </div>
      </button>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        LazyChildren={() => (
          <Page>
            <Page.Header {...simpleHeader('Reviews', toggle)} />
            <Page.Content>
              <Container topBottomSpace>
                <ReviewsList type={type} id={id} nothingType="nothing-yet" />
              </Container>
            </Page.Content>
          </Page>
        )}
      />
    </>
  )
}

export default ReviewsButton
