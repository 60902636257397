import { useState, useEffect } from 'react'
import { Plugins } from '@capacitor/core'
import isBrowser from 'utils/isBrowser'

const { Keyboard } = Plugins

const useKeyboardListener = () => {
  const [isKeyboardOnScreen, setIsKeyboardOnScreen] = useState(false)

  useEffect(() => {
    if (!isBrowser()) {
      Keyboard.addListener('keyboardWillShow', () => {
        setIsKeyboardOnScreen(true)
      })
      Keyboard.addListener('keyboardDidShow', () => {
        setIsKeyboardOnScreen(true)
      })

      Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOnScreen(false)
      })
      Keyboard.addListener('keyboardDidHide', () => {
        setIsKeyboardOnScreen(false)
      })

      return () => {
        Keyboard.removeAllListeners()
      }
    }
  }, [])

  return isKeyboardOnScreen
}

export default useKeyboardListener
