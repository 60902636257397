import React from 'react'
import BackgroundImage from 'components/BackgroundImage'

interface Props {
  image: string
  size: number
}

const RoundedPicture = ({ image, size }: Props) => (
  <BackgroundImage
    image={image}
    width={size}
    height={size}
    style={{
      borderRadius: size,
    }}
  />
)

export default RoundedPicture
