import React from 'react'
import * as yup from 'yup'
import Button from 'components/Button'
import Container from 'components/Container'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useFormCarousel } from 'forms/FormCarousel/context'
import { useApolloClient } from '@apollo/client'
import * as Validation from 'utils/formFieldValidations'
import PhoneNumberField from 'formFields/PhoneNumberField'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { onlyNumbers } from 'utils/regex'

const PhoneNumber = () => {
  const client = useApolloClient()
  const { next, initialValues, isKeyboardOnScreen } = useFormCarousel()
  const validationSchema = yup.object().shape({
    phoneNumber: Validation.uniquePhoneNumber(client),
  })
  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      phoneNumber: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })
  const { isSubmitting } = formState

  const submit = async ({ phoneNumber }: any) => {
    next({ phoneNumber: onlyNumbers(phoneNumber) })
  }

  return (
    <Container topBottomSpace className="flex flex-col h-full justify-between">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">What is your phone number?</div>
      </div>

      <form onSubmit={handleSubmit(submit)}>
        {/* Phone Number */}
        <div className="py-1">
          <PhoneNumberField name="phoneNumber" control={control} isBig />
        </div>

        <div className="pt-4">
          <Button $fluid loading={isSubmitting}>
            Next
          </Button>
        </div>

        {isKeyboardOnScreen && (
          <SpaceForKeyboard $size="lg" autoControlled={false} />
        )}
      </form>
    </Container>
  )
}

export default PhoneNumber
