import { FC, ReactElement } from 'react'

const composeProviders = (
  providers: FC<any>[],
  children: ReactElement,
): ReactElement =>
  providers
    .reverse()
    .reduce((acc, Provider) => <Provider>{acc}</Provider>, children)

export default composeProviders
