import moment, { Moment } from 'moment'
import { isEqual } from 'lodash/fp'
import {
  TimeOfTheDay,
  LocalDate,
  LocalTime,
  UTCDate,
  UTCTime,
  LocalDay,
  UTCDay,
} from 'types'
import {
  DB_TIME_FORMAT,
  DB_DATE_FORMAT,
  TIME_OF_THE_DAY_RANGE,
  FRIENDLY_TIME_FORMAT,
  FRIENDLY_DATE_FORMAT_CALENDAR,
} from 'globalConstants'
import { Maybe } from 'generated/graphql'

export const utcDateToLocal = (date: UTCDate, time: UTCTime): LocalDate =>
  moment
    .utc(`${date} ${time}`, `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`)
    .local()
    .format(DB_DATE_FORMAT)

export const timestampToLocalDate = (timestamp: string) =>
  moment.utc(timestamp).local().format(DB_DATE_FORMAT)

export const timestampToLocalTime = (timestamp: string) =>
  moment.utc(timestamp).local().format(DB_TIME_FORMAT)

/**
 * UTC or Local date and time can be provided since ISO works for both
 * @param date
 * @param time
 * @returns
 */
export const dateTimeToISO = (
  date: LocalDate | UTCDate,
  time: LocalTime | UTCTime,
) =>
  moment(
    [date, time].join(' '),
    [DB_DATE_FORMAT, DB_TIME_FORMAT].join(' '),
  ).toISOString()

export const utcDayToLocalDay = (day: UTCDay, time: UTCTime): LocalDay =>
  moment.utc(time, DB_TIME_FORMAT).day(day).local().day()

export const localDayToUTCDay = (day: LocalDay, time: LocalTime): UTCDay =>
  moment.utc(time, DB_TIME_FORMAT).day(day).local().day()

export const fromUntil = (
  start: LocalTime | UTCTime,
  end: LocalTime | UTCTime,
) => [
  moment(start, DB_TIME_FORMAT).format(FRIENDLY_TIME_FORMAT),
  moment(end, DB_TIME_FORMAT).format(FRIENDLY_TIME_FORMAT),
]

// TODO: Should we care about next year? e.g. we are on Dec and the date is on Jan
export const friendlyLocalDate = (date: LocalDate) =>
  moment(date, DB_DATE_FORMAT).calendar(null, FRIENDLY_DATE_FORMAT_CALENDAR)

/**
 * @param date Date
 * @param time Time needed to correctly convert date to local time
 */
export const friendlyUTCToLocalDate = (date: UTCDate, time: UTCTime) =>
  moment(`${date} ${time}`, `${DB_DATE_FORMAT} ${DB_TIME_FORMAT}`).calendar(
    null,
    FRIENDLY_DATE_FORMAT_CALENDAR,
  )

type Options = {
  from?: Moment
  formatted?: boolean
  timeLimit?: string
  toLocal?: boolean
}

/**
 * Converts day of a week (from 0 to 6) to a date based on 'from'
 * @param day day of the week
 * @param options
 */

export const dayToDate = (
  fromDate: Moment,
  day: number,
  { timeLimit, formatted, toLocal }: Options = {},
): Moment | string => {
  const todayDayOfTheWeek = fromDate.day()

  let date = fromDate
  // same day but time already passed
  if (
    timeLimit &&
    todayDayOfTheWeek === day &&
    fromDate.diff(moment.utc()) <= 0
  ) {
    date = fromDate.clone().add(7, 'days')
  } else if (todayDayOfTheWeek < day) {
    date = fromDate.clone().add(day - todayDayOfTheWeek, 'days')
  } else if (todayDayOfTheWeek > day) {
    date = fromDate.clone().add(7 - (todayDayOfTheWeek - day), 'days')
  }

  if (toLocal) {
    date = date.local()
  }

  return formatted ? date.format(DB_DATE_FORMAT) : date
}

export const dayToLocalDate = (
  fromDate: Moment,
  day: UTCDay,
  opts: Pick<Options, 'from' | 'timeLimit' | 'formatted'> = {},
): Moment | string =>
  dayToDate(fromDate, day, {
    ...opts,
    toLocal: true,
  })

/**
 * Converts days of a week into dates
 * @param days days of a week
 * @param options
 */
export const daysToDates = (
  fromDate: Moment,
  days: number[],
  options?: Options,
): Array<string | Moment> =>
  days.map((day) => dayToDate(fromDate, day, options))

export const findDayInDays = (day: number, days: string): number | null =>
  days.split(',').map(Number).find(isEqual(day)) || null

export const shouldStartTomorrow = (
  now: Moment,
  timeOfTheDay: TimeOfTheDay,
) => {
  const diffFrom = now.diff(
    moment(TIME_OF_THE_DAY_RANGE[timeOfTheDay][0], DB_TIME_FORMAT),
  )
  const diffUntil = now.diff(
    moment(TIME_OF_THE_DAY_RANGE[timeOfTheDay][1], DB_TIME_FORMAT),
  )

  // evening is a special case because involves two days (today and tomorrow)
  if (timeOfTheDay === 'evening') {
    return !(diffFrom > 0 || diffUntil < 0)
  } else {
    return !(diffFrom > 0 && diffUntil < 0)
  }
}

export const utcDateOrDayToLocalDate = ({
  date,
  day,
  start,
}: {
  date?: UTCDate
  day?: Maybe<UTCDay>
  start: UTCTime
}): LocalDate =>
  date
    ? utcDateToLocal(date, start)
    : (dayToLocalDate(moment.utc(start, DB_TIME_FORMAT), day!, {
        formatted: true,
        timeLimit: start,
      }) as string)

export const utcDateOrDayToUTCDate = ({
  date,
  day,
  start,
}: {
  date?: UTCDate
  day?: Maybe<UTCDay>
  start: UTCTime
}): UTCDate =>
  date ||
  (dayToDate(moment.utc(start, DB_TIME_FORMAT), day!, {
    formatted: true,
    timeLimit: start,
    toLocal: false,
  }) as string)

export const utcDateOrDayToISO = ({
  date,
  day,
  start,
}: {
  date?: UTCDate
  day?: Maybe<UTCDay>
  start: UTCTime
}): string =>
  dateTimeToISO(
    utcDateOrDayToUTCDate({
      date,
      day,
      start,
    }) as string,
    start,
  )

export const localDateOrDayToISO = ({
  date,
  day,
  start,
}: {
  date?: UTCDate
  day?: Maybe<UTCDay>
  start: UTCTime
}): string =>
  dateTimeToISO(
    utcDateOrDayToUTCDate({
      date,
      day,
      start,
    }) as string,
    start,
  )
