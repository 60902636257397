import React from 'react'
import groupBy from 'lodash/fp/groupBy'
import set from 'lodash/fp/set'
import memoize from 'lodash/fp/memoize'
import omit from 'lodash/fp/omit'
import remove from 'lodash/fp/remove'
import Container from 'components/Container'
import { SwitchUncontrolled } from 'components/Switch'
import Button from 'components/Button'
import GenericQueryRender from 'components/GenericQueryRender'
import { useApolloClient } from '@apollo/client'
import { CREATE_NOTIFICATIONS, DELETE_NOTIFICATIONS } from 'graphql/mutations'
import { QUERY_NOTIFICATIONS } from 'graphql/queries'
import {
  Notification,
  Setting_Notification,
  Setting_Notification_Category,
  Setting_Notification_Category_Enum,
  Setting_Notification_Type,
  Setting_Notification_Type_Enum,
} from 'generated/graphql'
import { ModalScreenClose } from 'components/ModalScreen'
import ServerErrorMessage from 'components/ServerErrorMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'

function enumToMap<T = any>(arr: T[]): Record<string, string> {
  return arr.reduce(
    (obj, { value, description }: T & any) => ({
      ...obj,
      [value]: description,
    }),
    {},
  )
}

const fastEnumToMap = memoize(enumToMap)
const fastGroupBy = memoize(groupBy)
const existsInNotification = (
  notifications: Notification[],
  category: string,
  type: string,
) =>
  notifications.some(
    (current) => current.category === category && current.type === type,
  )

const identifyChanges = (
  touched: Record<string, boolean>,
  currentSelection: Notification[],
) => {
  return Object.keys(touched).reduce(
    (acc, key) => {
      const [category, type] = key.split('.')
      const value = touched[key]

      // Does not exist, we need to create
      if (value && !existsInNotification(currentSelection, category, type)) {
        return {
          ...acc,
          createList: [...acc.createList, key],
        }
      }

      // Exists, we need to delete
      if (!value && existsInNotification(currentSelection, category, type)) {
        return {
          ...acc,
          removeList: [...acc.removeList, key],
        }
      }

      return acc
    },
    {
      createList: [] as string[],
      removeList: [] as string[],
    },
  )
}

type CategoryTypeSelection = Record<string, Record<string, boolean>>

type Props = {
  close?: ModalScreenClose
  data: {
    notification: Notification[]
    setting_notification: Setting_Notification[]
    setting_notification_category: Setting_Notification_Category[]
    setting_notification_type: Setting_Notification_Type[]
  }
}

const RenderNotifications = ({ close, data }: Props) => {
  const client = useApolloClient()
  const { notify } = useToast()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const { log } = useLogger()
  const categorized = fastGroupBy('category', data.setting_notification)
  const categoryMap = fastEnumToMap<Setting_Notification_Category>(
    data.setting_notification_category,
  )
  const typeMap = fastEnumToMap<Setting_Notification_Type>(
    data.setting_notification_type,
  )
  const [touched, setTouched] = React.useState<Record<string, boolean>>({})
  const [status, setStatus] = React.useState<CategoryTypeSelection>(
    Object.values(Setting_Notification_Category_Enum).reduce(
      (categoryObj, category) => ({
        ...categoryObj,
        [category]: Object.values(Setting_Notification_Type_Enum).reduce(
          (typeObj, type) => ({
            ...typeObj,
            [type]: existsInNotification(data.notification, category, type),
          }),
          {},
        ),
      }),
      {},
    ),
  )

  const handleSubmit = async () => {
    const { createList, removeList } = identifyChanges(
      touched,
      data.notification,
    )

    setLoading(true)

    try {
      let errors = null

      if (!errors && createList.length) {
        const createResult = await client.mutate({
          mutation: CREATE_NOTIFICATIONS,
          variables: {
            objects: createList.map((pair) => {
              const [category, type] = pair.split('.')

              return {
                category,
                type,
              }
            }),
          },
        })
        if (createResult.errors) {
          errors = createResult.errors
        } else {
          client.writeQuery({
            query: QUERY_NOTIFICATIONS,
            data: {
              ...data,
              notification: [
                ...data.notification,
                ...createResult.data.insert_notification.returning,
              ],
            },
          })
        }
      }

      if (!errors && removeList.length) {
        const m = data.notification
          .filter(({ category, type }) =>
            removeList.includes([category, type].join('.')),
          )
          .map(({ id }) => ({
            id: {
              _eq: id,
            },
          }))

        const deleteResult = await client.mutate({
          mutation: DELETE_NOTIFICATIONS,
          variables: {
            match: m,
          },
        })

        if (deleteResult.errors) {
          errors = deleteResult.errors
        } else {
          client.writeQuery({
            query: QUERY_NOTIFICATIONS,
            data: {
              ...data,
              notification: remove(
                ({ id }) =>
                  deleteResult.data.delete_notification.returning.some(
                    (r: any) => r.id === id,
                  ),
                data.notification,
              ),
            },
          })
        }
      }

      if (errors) {
        log('error', 'Notifications change', {
          errors,
        })
        setError(GENERIC_ERROR_MESSAGE)
      } else {
        notify({
          type: 'success',
          message: 'Notifications changes have been successfuly saved.',
        })
        setTouched({})
        close?.()
      }
    } catch (err) {
      log('error', 'Notifications change', {
        err,
      })
      setError(GENERIC_ERROR_MESSAGE)
    }

    setLoading(false)
  }

  return (
    <div className="bg-gray-200" data-testid="notifications">
      <Container topBottomSpace>
        {Object.keys(categorized).map((category, i) => {
          return (
            <div key={[category, i].join('-')} data-testid="categorized">
              {i > 0 && <hr className="mb-5 border-gray-400" />}

              {/* Category */}
              <h2 className="heading-2 text-primary pb-1">{category}</h2>

              {/* Description */}
              <p className="pb-3">{categoryMap[category]}</p>
              {/* Controls */}
              <ul data-testid={`list-notifications-${i}`}>
                {categorized[category].map(({ type, customizable }, index) => (
                  <li
                    className="py-4"
                    key={[category, type, index].join('-')}
                    data-testid={`list-${i}`}
                  >
                    <div className="flex justify-between items-center">
                      <div>{typeMap[type]}</div>
                      <SwitchUncontrolled
                        data-testid={`controller-${index}-${i}`}
                        value={status[category][type]}
                        disabled={!customizable}
                        onChange={(checked: boolean) => {
                          const key = [category, type].join('.')
                          const alreadyTouched = touched[key]

                          setTouched(
                            alreadyTouched
                              ? omit(key, touched)
                              : {
                                  ...touched,
                                  [key]: checked,
                                },
                          )

                          setStatus(
                            set([category, type].join('.'), checked, {
                              ...status,
                            }),
                          )
                        }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </Container>

      <Container>
        {error && <ServerErrorMessage>{error}</ServerErrorMessage>}
      </Container>

      <Container className="bg-white py-8">
        <Button
          type="button"
          $fluid
          onClick={() => handleSubmit()}
          loading={loading}
          disabled={Object.keys(touched).length === 0}
        >
          SAVE CHANGES
        </Button>
      </Container>
    </div>
  )
}

const Notifications = ({
  close,
  develop,
}: {
  close?: ModalScreenClose
  develop?: boolean
}) => (
  <GenericQueryRender
    query={QUERY_NOTIFICATIONS}
    Success={({ data }) => <RenderNotifications close={close} data={data} />}
  />
)

export default Notifications
