import { Link } from '@reach/router'
import cc from 'classcat'
import { LoadingSpinner } from 'components/Loading'
import { ButtonHTMLAttributes } from 'react'

export type Type =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'info'

export type Props = {
  loading?: boolean
  $type?: Type
  $fluid?: boolean
  $inverted?: boolean
  $size?: 'sm' | 'md'
  $roundedSquare?: boolean
  $shadow?: boolean
  onClick?: () => void
} & (
  | {
      to: string
      children: any
      className?: string
    }
  | ButtonHTMLAttributes<HTMLButtonElement>
)

const Button = ({
  children,
  $type,
  $fluid,
  $inverted,
  loading,
  $size,
  $roundedSquare,
  $shadow,
  ...rest
}: Props) => {
  const classMap: {
    [key: string]: {
      normal: {
        enabled: string
        disabled: string
      }
      inverted: {
        enabled: string
        disabled: string
      }
    }
  } = {
    primary: {
      normal: {
        enabled: 'text-white bg-green',
        disabled: 'text-white bg-gray-600',
      },
      inverted: {
        enabled: 'text-green bg-white',
        disabled: 'text-green bg-gray-300 opacity-75',
      },
    },
    secondary: {
      normal: {
        enabled: 'bg-transparent text-green border-2 border-green',
        disabled: 'bg-transparent text-gray-600 border-2 border-gray-600',
      },
      inverted: {
        enabled: 'bg-transparent text-white border-2 border-white',
        disabled:
          'bg-transparent text-gray-300 border-2 border-gray-300 opacity-75',
      },
    },
    tertiary: {
      normal: {
        enabled: 'bg-transparent text-green',
        disabled: 'bg-transparent text-gray-600',
      },
      inverted: {
        enabled: 'bg-transparent text-white',
        disabled: 'text-gray-300 text-opacity-75',
      },
    },
    danger: {
      normal: {
        enabled: 'bg-red-500 text-white',
        disabled: 'text-red-600 bg-gray-400',
      },
      inverted: {
        enabled: 'bg-white text-red-500',
        disabled: 'bg-white text-red-500 opacity-75',
      },
    },
    info: {
      normal: {
        enabled: 'bg-primary text-white',
        disabled: 'text-gray-700 bg-gray-500',
      },
      inverted: {
        enabled: 'bg-white text-primary',
        disabled: 'bg-white text-primary opacity-75',
      },
    },
    link: {
      normal: {
        enabled: 'text-gray-700',
        disabled: 'text-gray-600',
      },
      inverted: {
        enabled: 'text-white',
        disabled: 'text-white text-opacity-75',
      },
    },
  }

  const className = cc([
    'relative inline-block heading-3 tracking-widest leading-none md:hover:opacity-90 text-center',
    {
      'w-full': $fluid,
      'p-2': !$roundedSquare && $size === 'sm',
      'p-5': !$roundedSquare && $size === 'md',
      'w-14 h-14 rounded-full p-1 text-center': $roundedSquare,
      'shadow-lg': $shadow,
    },
    $type &&
      classMap[$type][$inverted ? 'inverted' : 'normal'][
        'to' in rest ? 'enabled' : rest.disabled ? 'disabled' : 'enabled'
      ],

    // custom classes
    rest.className,
  ])

  if ('to' in rest) {
    return (
      <Link to={rest.to} className={className}>
        {children}
      </Link>
    )
  } else {
    return (
      <button
        {...rest}
        className={className}
        disabled={rest.disabled || loading}
      >
        {loading ? (
          <>
            <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <LoadingSpinner $size={$size === 'sm' ? 'xs' : 'sm'} />
            </span>
            {'\u00A0'}
          </>
        ) : (
          children
        )}
      </button>
    )
  }
}

Button.defaultProps = {
  $type: 'primary',
  $size: 'md',
}

export default Button
