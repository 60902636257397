import Button from 'components/Button'
import FormField, { FormGroup } from 'components/FormField'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useToast } from 'components/ToastMessage'
import FormAddress, {
  formAddressValidationSchema as addressValidationSchema,
} from 'forms/FormAddress'
import useLogger from 'hooks/useLogger'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import SpaceForKeyboard from 'components/SpaceForKeyboard'
import { useCreateCompanyAccountMutation } from 'generated/graphql'

const validationSchema = yup
  .object()
  .shape({
    name: Validation.entityName,
    taxID: Validation.ein,
    ...Validation.address,
  })
  .concat(addressValidationSchema as any)

export type Values = {
  name: string
  taxID: string
  address: {
    primary: string
    secondary?: string
    city: string
    state: string
    zipCode: string
  }
}

type Props = {
  onAdded: (data: Values) => void
  initialValues?: Partial<Values>
}

const CompanyLegalInformation = ({ initialValues, onAdded }: Props) => {
  const { notify } = useToast()
  const { log } = useLogger()
  const [mutate, { loading }] = useCreateCompanyAccountMutation()
  const form = useForm({
    defaultValues: {
      name: initialValues?.name || '',
      taxID: initialValues?.taxID || '',
      address: initialValues?.address?.primary || '',
      addressSecondary: initialValues?.address?.secondary || '',
      city: initialValues?.address?.city || '',
      state: initialValues?.address?.state || '',
      zipCode: initialValues?.address?.zipCode || '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { register, handleSubmit, errors } = form

  const submit = async (values: any) => {
    try {
      const parsedValues = {
        name: values?.name,
        taxID: values?.taxID,
        address: {
          primary: values?.address,
          secondary: values?.addressSecondary,
          city: values?.city,
          zipCode: values?.zipCode,
          state: values?.state,
        },
      }
      const response = await mutate({
        variables: parsedValues,
      })

      if (response.errors) {
        notify({
          message: GENERIC_ERROR_MESSAGE,
          type: 'failure',
        })
      } else {
        onAdded(parsedValues)
      }
    } catch (error) {
      log('error', 'Create Company Account', {
        error,
      })
      notify({
        message: error.message,
        type: 'failure',
      })
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(submit)}>
        <FormGroup>
          {/* Company Name */}
          <FormField
            type="text"
            label="Registered Company Name *"
            name="name"
            register={register}
            error={errors?.name?.message}
          />

          {/* Tax ID (EIN) */}
          <FormField
            type="tel"
            label="Employer Identification Number (EIN) *"
            name="taxID"
            register={register}
            error={errors?.taxID?.message}
          />
        </FormGroup>

        {/* Address */}
        <FormAddress type="new" />

        {/* CREATE */}
        <div className="pt-6 pb-6">
          <Button type="submit" $type="primary" $fluid loading={loading}>
            Save
          </Button>
        </div>
      </form>

      <SpaceForKeyboard />
    </FormProvider>
  )
}

export default CompanyLegalInformation
