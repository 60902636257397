import React from 'react'
import { capitalize, mapValues, join } from 'lodash/fp'
import FormCarousel, { FormCarouselSlide } from 'forms/FormCarousel'
import blackManMeditating from 'assets/photos/signup/black-man-meditating.jpg'
import asianWomanPose from 'assets/photos/signup/asian-woman-pose.jpg'
import whiteWomanMeditation from 'assets/photos/signup/white-woman-meditation.jpg'
import blondeManSideTwist from 'assets/photos/signup/blonde-man-side-twist.jpg'
import FirstAndLast from '../Reusable/FirstAndLast'
import Email from '../Reusable/Email'
import PhoneNumber from '../Reusable/PhoneNumber'
import Birthday from '../Reusable/Birthday'
import Picture from '../Reusable/Picture'
import { useMutation, useApolloClient } from '@apollo/client'
import { CREATE_STUDENT_ACCOUNT } from 'graphql/mutations'
import useLogger from 'hooks/useLogger'
import { useToast } from 'components/ToastMessage'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'
import { useLoadingBlock } from 'components/LoadingBlock'
import { useAlert } from 'components/AlertModal'
import {
  useGlobalProvider,
  _INTERNAL_getStoredPreferences,
} from 'stores/global.store'
import useSignupNavigation from 'hooks/useSignupNavigation'
import FormField, { FormGroup } from 'components/FormField'
import Button from 'components/Button'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as yup from 'yup'
import * as Validation from 'utils/formFieldValidations'
import { onlyNumbers } from 'utils/regex'
import { getDeviceTimezone } from 'utils/timezone'
import { isWebapp } from 'utils/env'
import FormInlineGroup from 'components/FormInlineGroup'
import TermsAndConditionsField from 'formFields/TermsAndConditionsField'
import PhoneNumberField from 'formFields/PhoneNumberField'
import BirthdayField from 'formFields/BirthdayField'
import { WithWebBackLayout } from 'components/WebBackLayout'
import Container from 'components/Container'

/*
<SpaceForKeyboard /> not needed
*/

const SignupStudent = () => {
  const { log } = useLogger()
  const toast = useToast()
  const loadingBlock = useLoadingBlock()
  const alert = useAlert()
  const [createStudent] = useMutation(CREATE_STUDENT_ACCOUNT)
  const global = useGlobalProvider()
  const preferences = global.preferences || _INTERNAL_getStoredPreferences()
  const { signupNavigate } = useSignupNavigation()

  React.useEffect(() => {
    if (
      !isWebapp() &&
      !preferences &&
      process.env.REACT_APP_STAGE !== 'alpha-1' &&
      process.env.REACT_APP_STAGE !== 'alpha-2'
    ) {
      signupNavigate('/preferences')
    }
  }, [preferences])

  const onSubmit = async (values: any) => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      birthday,
      picture,
      termsAndConditions,
    } = values

    loadingBlock.open()

    try {
      const result = await createStudent({
        variables: {
          profile: {
            firstName: capitalize(firstName),
            lastName: capitalize(lastName),
            email: email.toLowerCase(),
            phoneNumber: onlyNumbers(phoneNumber),
            picture,
            birthday,
          },
          // NOTE: we only 'setPreferences' if the user decided to create the account later
          preferences: mapValues(
            join(','),
            preferences ||
              _INTERNAL_getStoredPreferences() || {
                yogaTypes: [],
                settings: [],
                levels: [],
              },
          ),
          termsAndConditions,
          timezone: getDeviceTimezone(),
        },
      })

      if (result.errors) {
        toast.notify({
          type: 'failure',
          message: result.errors[0].message,
        })
        log('error', 'Student Signup', { errors: result.errors, values })
      } else {
        signupNavigate('/done', {
          state: {
            disableAnimation: true,
            result: result?.data?.signupStudent,
            forceRefreshPreferences: true,
          },
        })
      }

      loadingBlock.close()
    } catch (error) {
      log('error', 'Student Signup', { error, values })
      toast.notify({
        type: 'failure',
        message: GENERIC_ERROR_MESSAGE,
      })
      loadingBlock.close()
    }
  }

  if (isWebapp()) {
    return <SimpleForm onSubmit={onSubmit} />
  }

  if (!preferences) {
    return null
  }

  return (
    <FormCarousel
      onSubmit={onSubmit}
      onCancel={() => {
        alert.send({
          body: 'All information you have already entered will be lost.',
          buttons: [
            {
              children: 'Continue',
              onClick: () => {
                signupNavigate('/')
              },
            },
            {
              $type: 'tertiary',
              children: 'Cancel',
            },
          ],
        })
      }}
    >
      <FormCarouselSlide bgImage={blackManMeditating} bgFadedOnKeyboard>
        <FirstAndLast />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={asianWomanPose} bgFadedOnKeyboard>
        <Email />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={blondeManSideTwist} canSkip bgFadedOnKeyboard>
        <PhoneNumber />
      </FormCarouselSlide>
      <FormCarouselSlide bgImage={whiteWomanMeditation} bgFadedOnKeyboard>
        <Birthday />
      </FormCarouselSlide>
      <FormCarouselSlide canSkip>
        <Picture />
      </FormCarouselSlide>
    </FormCarousel>
  )
}
export default WithWebBackLayout<any>(SignupStudent)

type SimpleFormProps = {
  onSubmit: (values: any) => void
}

const SimpleForm = ({ onSubmit }: SimpleFormProps) => {
  const client = useApolloClient()
  const validationSchema = yup.object().shape({
    firstName: Validation.firstName,
    lastName: Validation.lastName,
    email: Validation.uniqueEmail(client),
    phoneNumber: Validation.uniquePhoneNumber(client),
    birthday: Validation.birthday,
    termsAndConditions: Validation.termsAndConditions,
  })
  const formMethods = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      birthday: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(validationSchema),
  })
  const { handleSubmit, register, errors, formState, control } = formMethods
  const { dirtyFields, isValid } = formState
  const canContinue = isValid || Object.keys(dirtyFields).length > 0

  return (
    <>
      <Container>
        <h1 className="heading-1 text-center pt-4 pb-8 text-primary">
          Welcome Yogi
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...formMethods}>
            <FormGroup>
              <FormInlineGroup>
                {/* First Name */}
                <div>
                  <FormField
                    type="text"
                    name="firstName"
                    label="First Name *"
                    placeholder="Enter your first name"
                    register={register}
                    error={errors?.firstName?.message}
                    data-testid="input-first-name"
                  />
                </div>

                {/* Last Name */}
                <div>
                  <FormField
                    type="text"
                    name="lastName"
                    label="Last Name *"
                    placeholder="Enter your last name"
                    register={register}
                    error={errors?.lastName?.message}
                    data-testid="input-last-name"
                  />
                </div>
              </FormInlineGroup>

              <FormInlineGroup>
                {/* Email */}
                <div>
                  <FormField
                    label="Email *"
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    register={register}
                    error={errors?.email?.message}
                    data-testid="input-email"
                  />
                </div>

                {/* Phone Number */}
                <div>
                  <PhoneNumberField
                    label="Phone Number"
                    name="phoneNumber"
                    control={control}
                  />
                </div>
              </FormInlineGroup>

              {/* Birthday */}
              <BirthdayField name="birthday" label="Date of Birth *" />

              <TermsAndConditionsField
                name="termsAndConditions"
                control={control}
              />
            </FormGroup>
          </FormProvider>

          <div className="pt-4">
            <Button $fluid disabled={!canContinue} data-testid="button-signup">
              Sign up
            </Button>
          </div>
        </form>
      </Container>
    </>
  )
}
