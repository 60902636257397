import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'

import CirlceCheckboxes from 'components/CircleCheckboxes'
import { useForm } from 'react-hook-form'
import { Class_Setting_Enum } from 'generated/graphql'
import { useFormCarousel } from 'forms/FormCarousel/context'

const Settings = () => {
  const { next, initialValues } = useFormCarousel()
  const { control, handleSubmit } = useForm({
    defaultValues: {
      settings: initialValues?.settings || '',
    },
  })

  return (
    <Container topBottomSpace className="flex flex-col h-full">
      <div className="h-14 text-center">
        <div className="heading-1 text-primary">
          What is your preferred
          <br />
          setting to practice yoga?
        </div>
      </div>

      <form
        onSubmit={handleSubmit((values) => {
          next(values)
        })}
        className="flex flex-col flex-grow justify-center"
        id="form-settings"
      >
        <CirlceCheckboxes
          control={control}
          name="settings"
          grid={{
            colums: 1,
            gap: 3,
          }}
          options={[...Object.values(Class_Setting_Enum), 'virtual']}
          $textSize="md"
        />
      </form>

      <Button $fluid form="form-settings">
        Next
      </Button>
    </Container>
  )
}

export default Settings
