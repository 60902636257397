import React from 'react'
import cc from 'classcat'
import { Control, Controller } from 'react-hook-form'
import { ErrorMessage } from 'components/FormField'

type Option = {
  id: string
  text: string
}

type CheckboxesAsListProps = {
  name: string
  options: Option[]
  value: string
  onChange: (newValue: string) => void
}

const CheckboxesAsList = ({
  name,
  options,
  value,
  onChange,
}: CheckboxesAsListProps) => {
  const selected = (value || '').split(',').filter(Boolean)

  return (
    <ul>
      {options.map(({ id, text }, index) => {
        const isActive = selected.includes(id)

        return (
          <li key={`option-${name}-${id}`}>
            <button
              type="button"
              className={cc([
                'block w-full border border-primary py-3 px-5 heading-3 text-left',
                {
                  'border-t-0': index !== 0,
                  'text-primary': !isActive,
                  'bg-primary text-white': isActive,
                },
              ])}
              onClick={() => {
                if (isActive) {
                  onChange(selected.filter((v) => v !== id).join(','))
                } else {
                  onChange([...selected, id].join(','))
                }
              }}
            >
              {text}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

type Props = {
  name: string
  control: Control
  options: Option[]
  error?: string
}

const CheckListField = ({ name, control, options, error }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => (
        <>
          <CheckboxesAsList
            options={options}
            name={name}
            value={value}
            onChange={onChange}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </>
      )}
    />
  )
}

export default CheckListField
