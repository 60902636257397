import { ReactElement, Children } from 'react'
import cc from 'classcat'

/*
<SpaceForKeyboard /> not needed in this file
*/

type Props = {
  children:
    | ReactElement
    | (ReactElement | null | '' | undefined | false | void | 0)[]
}

const MAX = 4

const FormInlineGroup = ({ children }: Props) => {
  const total = Children.count(children)

  if (total > MAX) {
    console.error(`FormInlineGroup supports up to ${MAX} fields`)
  }

  return (
    <div
      className={cc([
        'grid gap-6',
        {
          'md:grid-cols-2': total === 2,
          'md:grid-cols-3': total === 3,
          'md:grid-cols-4': total === 4,
        },
      ])}
    >
      {children}
    </div>
  )
}

export default FormInlineGroup
