import {
  ClassBookAggregateDocument,
  Class_Date_Time,
  useClassBookAggregateLazyQuery,
} from 'generated/graphql'
import { useEffect } from 'react'
import { useAuth } from 'stores/auth.store'
import { utcDateOrDayToISO } from 'utils/date'
import useLogger from './useLogger'

export const getVariables = (dateTime: Class_Date_Time) => ({
  where: {
    _and: [
      {
        _not: {
          class_date_time_id: {
            _is_null: true,
          },
          class_event_id: {
            _is_null: true,
          },
        },
      },
      {
        class_date_time_id: { _eq: dateTime.id },
        class_event: {
          start_timestamp: {
            _eq: utcDateOrDayToISO({
              date: dateTime.date,
              day: dateTime.day,
              start: dateTime.start,
            }),
          },
        },
        _or: [
          {
            payment_status: {
              _eq: 'succeeded',
            },
          },
          {
            payment_status: {
              _eq: 'processing',
            },
          },
        ],
      },
    ],
  },
})

export const refetchClassAlreadyBooked = (dateTime: Class_Date_Time) => ({
  query: ClassBookAggregateDocument,
  variables: getVariables(dateTime),
})

type Output = {
  loading: boolean
  isAlreadyBooked: boolean
  classBookID: string | null
  startTimestamp: string | null
  endTimestamp: string | null
  bookTime: string | null
}

const useClassAlreadyBooked = (dateTime: Class_Date_Time): Output => {
  const { log } = useLogger()
  const { isAuthenticated } = useAuth()
  const [makeRequest, { loading, data }] = useClassBookAggregateLazyQuery({
    variables: getVariables(dateTime),
    onError: (error) => {
      log('error', 'Maybe Book and Pay', {
        error,
        note:
          'Query that checks if the class is already booked and displays either Cancel or Book and Pay',
      })
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (isAuthenticated()) {
      makeRequest()
    }
  }, [isAuthenticated()])

  const isAlreadyBooked = !!data?.class_book_aggregate.aggregate?.count

  return {
    loading,
    isAlreadyBooked,
    classBookID: isAlreadyBooked
      ? data?.class_book_aggregate.nodes[0].id
      : null,
    startTimestamp: isAlreadyBooked
      ? data?.class_book_aggregate.nodes[0].class_event?.start_timestamp
      : null,
    endTimestamp: isAlreadyBooked
      ? data?.class_book_aggregate.nodes[0].class_event?.end_timestamp
      : null,
    bookTime: isAlreadyBooked
      ? data?.class_book_aggregate.nodes[0].created_at
      : null,
  }
}

export default useClassAlreadyBooked
