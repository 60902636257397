import React from 'react'
import Container from 'components/Container'
import Button from 'components/Button'
import { Navigate } from 'components/StackNavigation'

import Form from './Form'

type Props = {
  navigate?: Navigate
}

const NoResults = ({ navigate }: Props) => (
  <Container topBottomSpace>
    No locations yet
    <div className="py-3" data-testid="button-no-result">
      <Button
        type="button"
        $type="primary"
        $fluid
        onClick={() => {
          navigate?.({
            title: 'Add Location',
            Component: Form,
          })
        }}
      >
        Add Location
      </Button>
    </div>
  </Container>
)

export default NoResults
