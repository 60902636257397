import React from 'react'
import moment from 'moment'
import zip from 'lodash/fp/zip'
import memoize from 'lodash/fp/memoize'
import get from 'lodash/fp/get'
import * as yup from 'yup'
import FormField, { ErrorMessage, DropdownOption } from 'components/FormField'
import { get15minInterval } from 'utils/time'
import { Control } from 'react-hook-form'

/*
<SpaceForKeyboard /> not needed in this file
*/

function dateIsAfter(this: any, ref: any, msg: any) {
  return this.test({
    name: 'dateIsAfter',
    exclusive: false,
    message: msg || 'End time has to be after Start time',
    params: {
      reference: ref.path,
    },
    test: function (value: any) {
      const startDate = moment(this.resolve(ref), 'hh:mm')
      const endDate = moment(value, 'hh:mm')

      return (
        !startDate.isValid() ||
        !endDate.isValid() ||
        startDate.isBefore(endDate)
      )
    },
  })
}

yup.addMethod(yup.string, 'dateIsAfter', dateIsAfter)

export const yupStartEndTimeValidation = () =>
  (yup.string().required('End time is required') as any).dateIsAfter(
    yup.ref('start'),
  )

export const getTimeEvery15min = memoize(() => {
  const time = zip(get15minInterval('24-clock'), get15minInterval('12-clock'))
  const indexAt6AM = time.findIndex(([v]) => v === '06:00')

  return [...time.slice(indexAt6AM), ...time.slice(0, indexAt6AM)]
})

interface Props {
  startFieldName: string
  endFieldName: string
  control: Control
  errors?: any
  editStart?: string
  editEnd?: string
}

const FormStartEndTime = ({
  startFieldName,
  endFieldName,
  errors,
  editStart,
  editEnd,
  control,
}: Props) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <FormField
          data-testid="input-start"
          defaultValue={editStart ?? ''}
          type="dropdown"
          name={startFieldName}
          label="Start time"
          control={control}
          onChange={(newValue: string) => {
            const currentIndex = getTimeEvery15min().findIndex(
              ([v]) => v === newValue,
            )
            const newEndIndex = currentIndex + 4
            const newEndValue = getTimeEvery15min()[
              newEndIndex >= getTimeEvery15min().length
                ? newEndIndex - getTimeEvery15min().length
                : newEndIndex
            ]?.[0]

            control.setValue(startFieldName, newValue)

            if (currentIndex > -1) {
              control.setValue(endFieldName, newEndValue)
            }
          }}
          placeholder="Select time"
        >
          {getTimeEvery15min().map(([value, text]) => (
            <DropdownOption key={`start-time-${value}`} value={value}>
              {text?.toUpperCase() || ''}
            </DropdownOption>
          ))}
        </FormField>
      </div>

      <div>
        <FormField
          data-testid="input-end"
          defaultValue={editEnd ?? ''}
          type="dropdown"
          name={endFieldName}
          label="End time"
          control={control}
          onChange={(newValue: string) => {
            control.setValue(endFieldName, newValue)
          }}
          placeholder="Select time"
        >
          {getTimeEvery15min().map(([value, text]) => (
            <DropdownOption key={`end-time-${value}`} value={value}>
              {text?.toUpperCase() || ''}
            </DropdownOption>
          ))}
        </FormField>
      </div>
      {(get(startFieldName, errors) || get(endFieldName, errors)) && (
        <ErrorMessage>
          {get(startFieldName, errors)?.message ||
            get(endFieldName, errors)?.message ||
            'Field is invalid'}
        </ErrorMessage>
      )}
    </div>
  )
}

export default FormStartEndTime
