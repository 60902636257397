/**
 * Converts miles into PostGis distance in KMs
 * @param miles amount to be converted
 * @returns PostGis distance
 */
const milesToGeodistance = (miles: number) => {
  if (miles < 1) {
    return 0
  }

  const km = miles / 0.62137
  const postgisDistance = km * 1000

  return Math.round(postgisDistance)
}

export default milesToGeodistance
